import { WithDestroyComponent } from 'src/app/core/abstract/abstract-with-destroy-component';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLinkActive, RouterLink } from '@angular/router';
import { Store } from '@ngxs/store';
import { INavigationItem } from '../navigation-item/navigation-item.component';
import { SetMobileFooterNavigationDrawer } from 'src/app/core/layout/children/layout-mobile.actions';
import { IOrganization } from 'src/app/core/organizations/organizations.interface';
import { OrganizationState } from 'src/app/core/organizations/organizations.state';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../../../utility-modules/icon/components/icon/icon.component';
import { NgFor, NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'itd-footer-navigation-mobile',
    templateUrl: './footer-navigation-mobile.component.html',
    styleUrls: ['./footer-navigation-mobile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgFor,
        RouterLinkActive,
        RouterLink,
        IconComponent,
        NgIf,
        NgClass,
        TranslateModule,
    ],
})
export class FooterNavigationMobileComponent extends WithDestroyComponent implements OnInit {
	public isActiveSideDrawer: boolean = false;
	public navigation: INavigationItem[];
	public filteredNavigation: INavigationItem[];
	private organization: IOrganization;

	public get isMoreActive(): boolean {
		return !this.navigation.some(item => this.router.url.indexOf(item.url.join('/')) > -1);
	}

	constructor(public store: Store, private route: ActivatedRoute, private router: Router) {
		super();
	}

	public ngOnInit(): void {
		this.organization = this.store.selectSnapshot<IOrganization>(OrganizationState.organization);
		const { permissions } = this.route.snapshot.data;
		const navigation: INavigationItem[] = this.route.snapshot.data.navigation;

		if (permissions && permissions.length) {
			this.navigation = navigation.filter(item => {
				const permission = permissions.find(perm => perm.title === item.title);
				if (permission) {
					return this.organization[permission.permissionName];
				} else return true;
			});
		} else {
			this.navigation = navigation;
		}

		if (this.navigation.length > 4) {
			this.filteredNavigation = this.navigation.slice(0, 3);
		} else {
			this.filteredNavigation = this.navigation;
		}
	}

	public expandSideDrawer() {
		this.store.dispatch(new SetMobileFooterNavigationDrawer({ isActive: true }));
	}
}
