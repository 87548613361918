import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { FormState } from 'src/app/core/models/form.interface';
import { NgClass } from '@angular/common';
import { LoadingDirective } from '../../../../shared/directives/loading.directive';

type TSizeType = 'default' | 'small' | 'big';
type TStyleType = 'primary' | 'secondary';

@Component({
    selector: 'itd-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [LoadingDirective, NgClass],
})
export class ButtonComponent {
	@Input() public hasLoader: boolean = true;

	@Input() public setType: 'submit' | 'button' = 'submit';
	@Input() public styleType: TStyleType = 'primary';
	@Input() public sizeType: TSizeType = 'default';
	@Input() public formState: FormState;
	@Input() public enableAfterSuccess: boolean = false;

	@HostBinding('class.isBlock')
	@Input()
	public isBlock: boolean;

	@HostBinding('style.minWidth.px')
	@Input()
	public width: number;

	@Input() public set disabled(value: boolean) {
		this._disabled = value;
	}

	public get disabled(): boolean {
		if (this.formState) {
			if (this.formState === FormState.Loading || this.formState === FormState.BeforeLoading) {
				return true;
			}

			if (!this.enableAfterSuccess && this.formState === FormState.Success) {
				return true;
			}
		}

		return this._disabled;
	}

	public get loading(): boolean {
		if (!this.formState) {
			return false;
		}

		if (this.formState === FormState.Loading || this.formState === FormState.BeforeLoading) {
			return true;
		}

		if (!this.enableAfterSuccess && this.formState === FormState.Success) {
			return true;
		}

		return false;
	}

	private _disabled: boolean;

	constructor() {}
}
