import { ITableSnapshot } from 'src/app/utility-modules/table/models/table-snapshot.interface';

export class SetColorPrimary {
	public static readonly type = '[Layout] Set Color Primary';
	constructor(public payload?: { color: string }) {}
}

export class SetFontPrimary {
	public static readonly type = '[Layout] Set Font Primary';
	constructor(public payload?: { font: string }) {}
}

export class SetLanguage {
	public static readonly type = '[Layout] Set Language';
	constructor(public payload: { language: string }) {}
}

export class AddLoading {
	public static readonly type = '[Layout] Add Loading';
}

export class RemoveLoading {
	public static readonly type = '[Layout] Remove Loading';
}

export class ResetLoading {
	public static readonly type = '[Layout] Reset Loading';
}

export class SetTableSnapshot {
	public static type: string = '[Layout] SetTableSnapshot';

	constructor(public payload: { name: string; data: ITableSnapshot }) {}
}

export class SetTableSnapshotFilterQuery {
	public static type: string = '[Layout] SetTableSnapshotFilterQuery';

	constructor(public payload: { name: string; searchQuery: string }) {}
}
