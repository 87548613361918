import { AsyncPipe, NgClass, NgIf, NgStyle } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CreateDepartment, UpdateDepartment } from 'src/app/core/department/department.actions';
import { IDepartment, IDepartmentEdit } from 'src/app/core/department/department.interface';
import { DepartmentState } from 'src/app/core/department/department.state';
import { FontState } from 'src/app/core/fonts/font.state';
import { IOrganization } from 'src/app/core/organizations/organizations.interface';
import { OrganizationState } from 'src/app/core/organizations/organizations.state';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { ImageUrlFromKey } from 'src/app/core/utils/image-url-from-key';
import { AdminContentModuleSelectImageComponent } from 'src/app/modules/admin/modules/admin-content/modals/admin-content-module-select-image/admin-content-module-select-image.component';
import { AbstractFormComponent } from 'src/app/utility-modules/itd-form/components/abstract-form/abstract-form.component';
import { NotificationService } from 'src/app/utility-modules/notification/services/notification.service';
import { ButtonComponent } from '../../../utility-modules/itd-form/components/button/button.component';
import { FormErrorComponent } from '../../../utility-modules/itd-form/components/form-error/form-error.component';
import { InputColorPickerComponent } from '../../../utility-modules/itd-form/components/input-color-picker/input-color-picker.component';
import { InputComponent } from '../../../utility-modules/itd-form/components/input/input.component';
import { SelectComponent } from '../../../utility-modules/itd-form/components/select/select.component';
import { ModalComponent } from '../../../utility-modules/modals/components/modal/modal.component';
import { ImageComponent } from '../../components/image/image.component';

@Component({
	selector: 'itd-edit-department-modal',
	templateUrl: './edit-department-modal.component.html',
	styleUrls: ['./edit-department-modal.component.scss'],
	standalone: true,
	imports: [
		ReactiveFormsModule,
		ModalComponent,
		NgIf,
		InputComponent,
		ImageComponent,
		ButtonComponent,
		InputColorPickerComponent,
		NgStyle,
		NgClass,
		SelectComponent,
		FormErrorComponent,
		AsyncPipe,
		TranslateModule,
	],
})
export class EditDepartmentModalComponent extends AbstractFormComponent
	implements OnInit, OnChanges {
	public form: FormGroup;
	@Input() public data: {
		isEdit: boolean;
		department?: IDepartment;
		index?: number;
	};
	@Output() public error = new EventEmitter<HttpErrorResponse>();
	@Output() public success = new EventEmitter<void>();
	@Select(OrganizationState.organization) public organization$: Observable<IOrganization>;
	@Select(DepartmentState.getDepartmentsParent_id) public parent_ids$: Observable<Array<string>>;
	@Select(FontState.getDefaultFonts) public defaultFonts$: Observable<
		{ name: string; fontString: string }[]
	>;
	public color: string;
	public osFont: boolean = false;
	public parentChain: string = '';
	public parent_id: string | null = '';
	public departmentLogo: string = '';
	public departmentEdit: Partial<IDepartmentEdit> = {};
	public textColor: string = '';
	public message_from: string = '';
	public custom_from: string = '';
	constructor(
		public store: Store,
		public validationMessages: ValidationMessagesService,
		public elementRef: ElementRef,
		protected cd: ChangeDetectorRef,
		private activeModal: NgbActiveModal,
		private modals: NgbModal,
		public imageUrlFromKey: ImageUrlFromKey,
		private notification: NotificationService
	) {
		super(cd);
	}

	ngOnInit(): void {
		const title = this.data?.department?.title || '';
		const message_from = this.data?.department?.message_from || '';
		const custom_from = this.data?.department?.custom_from || '';

		if (this.data?.department?.font_type === 'default') {
			this.osFont = true;
		}
		this.form = new FormGroup({
			title: new FormControl(title, [Validators.required, Validators.minLength(2)]),
			file: new FormControl(''),
			font: new FormControl(this?.data?.department?.basic_font || ''),
			message_from: new FormControl(message_from),
			custom_from: new FormControl(custom_from),
		});
		this.color = this.data?.department?.primary_color || '#FFFFFF';
		this.getParentChain();
		this.getParent_id();
		this.afterInit();
		this.departmentEdit.logo_url = this.data?.department?.logo_url;
		this.cd.detectChanges();
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.departmentEdit.logo_url = this.data?.department?.logo_url;
		this.cd.detectChanges();
	}

	protected onSubmitAction(): Observable<any> {
		this.departmentEdit.active = true;
		this.departmentEdit.parent_id = this.parent_id;
		this.departmentEdit.parent_chain = this.parentChain;
		this.departmentEdit.title = this.formValue.title.trim();
		this.departmentEdit.message_from = this.formValue.message_from.trim();
		this.departmentEdit.custom_from = this.formValue.custom_from.trim();
		this.departmentEdit.logo_url = this.formValue.file;

		if (this.color !== '#FFFFFF' && this.color !== this?.data?.department?.primary_color) {
			this.departmentEdit.primary_color = this.color;
		}

		if (this.textColor) {
			this.departmentEdit.text_color = this.textColor;
		}

		if (this.formValue.font) {
			this.departmentEdit.basic_font = this.formValue.font;
		}

		if (this.formValue.file) {
		}

		if (this.data.isEdit) {
			return this.store.dispatch(
				new UpdateDepartment({
					parent_id: this.data.department.parent_id,
					department_id: this.data.department.department_id,
					department: {
						...this.departmentEdit,
					},
					index: this.data.index,
				})
			);
		}

		if (this.data.index === 0) {
			return this.store.dispatch([
				new CreateDepartment({
					department: {
						title: this.formValue.title.trim(),
						parent_chain: null,
						message_from: this.formValue.message_from.trim(),
						custom_from: this.formValue.custom_from.trim(),
					},
					index: this.data.index,
				}),
			]);
		}

		return this.store.dispatch(
			new CreateDepartment({
				department: this.departmentEdit,
				index: this.data.index,
			})
		);
	}

	public onSubmitSuccess() {
		this.notification.add({
			text: 'BACKEND_MESSAGES.CREATE_DEPARTMENT_SUCCESS',
		});
		this.activeModal.close();
	}

	private getParentChain() {
		this.parent_ids$.subscribe(val => {
			val = val?.filter((item, index) => index < this.data.index);
			this.parentChain = val?.join('.') || null;
		});
	}

	private getParent_id() {
		this.parent_ids$.subscribe(val => {
			this.parent_id = val[this.data.index - 1] || null;
		});
	}

	public openImageSelectionModal() {
		const component = this.modals.open(AdminContentModuleSelectImageComponent)
			.componentInstance as AdminContentModuleSelectImageComponent;

		component.onSubmitAction = () => {
			return new Observable(observer => {
				if (component.formValue) {
					this.form.patchValue({
						file: this.imageUrlFromKey.makeUrlFromKeyWithoutDomain(
							component.formValue.selectedImage
						),
					});
					this.departmentEdit.logo_url = this.formValue.file;
					this.cd.detectChanges();
					observer.next();
				}
			});
		};
	}

	public deleteImageSelection() {
		this.form.patchValue({ file: '' });
		this.departmentEdit.logo_url = '';
	}
}
