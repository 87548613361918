<label [itdLoading]="isLoading" *ngIf="profile$ | async as profile" class="label" [ngClass]="{
	'hasColor': !profile.image_key,
	'is-image': profile.image_key,
	'no-image': !profile.image_key
}">
	<input type="file" [value]="" class="hidden-input" accept="image/jpeg,image/png" (change)="onFileChange($event)">

	<!-- TODO: Could we refactor to use <itd-user-avatar> here? -->
	<itd-image [filename]="profile.image_key" folder="user-images" [width]="100" [height]="100" *ngIf="profile.image_key">
	</itd-image>

	<div class="decoration" >
		<itd-icon icon="camera" [width]="14" [height]="11"></itd-icon>
	</div>
</label>
