import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadUsers, UpdateUser } from 'src/app/core/users/users.action';
import { IUser } from 'src/app/core/users/users.interface';
import { Store } from '@ngxs/store';
import { NotificationService } from 'src/app/utility-modules/notification/services/notification.service';
import { ITimeline } from 'src/app/core/timelines/timelines.interface';
import { UpdateTimeline } from 'src/app/core/timelines/timelines.action';
import { IModule } from 'src/app/core/modules/modules.interface';
import { LoadModule, UpdateModule } from 'src/app/core/modules/modules.action';
import { ISurvey } from 'src/app/core/surveys/surveys.interface';
import { LoadSurvey, UpdateSurvey } from 'src/app/core/surveys/surveys.action';
import { IMessage } from 'src/app/core/messages/messages.interface';
import { LoadAllMessages, UpdateMessage } from 'src/app/core/messages/messages.action';
import { IBuddy } from 'src/app/core/buddy/buddy.interface';
import { UpdateBuddy } from 'src/app/core/buddy/buddy.action';
import { UpdateTasklist } from 'src/app/core/tasklist/tasklist.action';
import { ITasklist } from 'src/app/core/tasklist/tasklist.interface';
import { ITask } from 'src/app/core/task/task.interface';
import { UpdateTask } from 'src/app/core/task/task.action';
import { LoadAchievement, UpdateAchievement } from 'src/app/core/achievements/achievements.action';
import { IAchievement } from 'src/app/core/achievements/achievements.interface';
import { IMedia } from 'src/app/core/media/media.interface';
import { UpdateMedia } from 'src/app/core/media/media.action';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../../../../utility-modules/itd-form/components/button/button.component';
import { AdminDepartmentsPageComponent } from '../../../../pages/admin-departments-page/admin-departments-page.component';
import { ModalComponent } from '../../../../../../utility-modules/modals/components/modal/modal.component';

@Component({
	selector: 'itd-select-department-modal',
	templateUrl: './select-department-modal.component.html',
	styleUrls: ['./select-department-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ModalComponent, AdminDepartmentsPageComponent, ButtonComponent, TranslateModule],
})
export class SelectDepartmentModalComponent implements OnInit {
	@Input() public user: IUser;
	@Input() public timeline: ITimeline;
	@Input() public achievement: IAchievement;
	@Input() public module: IModule;
	@Input() public survey: ISurvey;
	@Input() public message: IMessage;
	@Input() public buddy: IBuddy;
	@Input() public task: ITask;
	@Input() public tasklist: ITasklist;
	@Input() public media: IMedia;
	@Input() public selectedDepartmentId: string;

	selectedDepartments: string[];

	constructor(
		private store: Store,
		private activeModal: NgbActiveModal,
		private notification: NotificationService
	) {}

	ngOnInit(): void {
		this.selectedDepartments = [];
	}

	departmentSelected(data: { selectedDepartmentId: string; i: number }) {
		this.selectedDepartments = this.selectedDepartments.slice(0, data.i);
		this.selectedDepartments[data.i] = data.selectedDepartmentId;
		this.selectedDepartmentId = data.selectedDepartmentId;
	}

	changeDepartmentForUser() {
		this.store
			.dispatch(
				new UpdateUser({
					id: this.user.user_id,
					data: {
						department_id: this.selectedDepartments.join('.'),
						user_role: this.user.user_role,
						first_name: this.user.first_name,
						last_name: this.user.last_name,
					},
				})
			)
			.subscribe(() => {
				this.store.dispatch(new LoadUsers({ params: { skip: 0, limit: 1500 } }));
				this.activeModal.close();
			});
	}

	changeDepartmentForBuddy() {
		this.store
			.dispatch(
				new UpdateBuddy({
					id: this.buddy.user_id,
					data: {
						department_id: this.selectedDepartments.join('.'),
					},
				})
			)
			.subscribe(() => {
				this.store.dispatch(new LoadUsers({ params: { skip: 0, limit: 1500 } }));
				this.activeModal.close();
			});
	}

	changeDepartmentForTimeline() {
		const timelineContents = [
			{ ...this.timeline.preboarding, title: this.timeline.preboarding.title || 'preboarding' },
			{ ...this.timeline.onboarding, title: this.timeline.onboarding.title || 'onboarding' },
			{ ...this.timeline.offboarding, title: this.timeline.offboarding.title || 'offboarding' },
		];
		this.store
			.dispatch(
				new UpdateTimeline({
					id: this.timeline.id,
					data: {
						title: this.timeline.title,
						department_id: this.selectedDepartments.join('.'),
						sections: timelineContents,
						show_in_learning_library:
							this.timeline.show_in_learning_library == 'true' ? true : false,
					},
				})
			)
			.subscribe(() => {
				this.store.dispatch(new LoadUsers({ params: { skip: 0, limit: 1500 } }));
				this.activeModal.close();
			});
	}

	changeDepartmentForAchievement() {
		this.store
			.dispatch(
				new UpdateAchievement({
					id: this.achievement.id,
					data: {
						department_id: this.selectedDepartments.join('.'),
					},
				})
			)
			.subscribe(() => {
				this.store.dispatch(new LoadAchievement({ id: this.achievement.id }));
				this.activeModal.close();
			});
	}

	chengeDepartmentForModule() {
		this.store
			.dispatch(
				new UpdateModule({
					id: this.module.id,
					data: {
						id: this.module.id,
						title: this.module.title,
						department_id: this.selectedDepartments.join('.'),
						achievementId: this.module.achievement_id,
						questions: this.module.questions,
						image_key: this.module.image_key,
					},
				})
			)
			.subscribe(() => {
				this.store.dispatch(new LoadModule({ id: this.module.id }));
				this.activeModal.close();
			});
	}

	changeDepartmentForSurvey() {
		this.store
			.dispatch(
				new UpdateSurvey({
					id: this.survey.id,
					data: {
						id: this.survey.id,
						title: this.survey.title,
						department_id: this.selectedDepartments.join('.'),
						anonymized: this.survey.anonymized,
						questions: this.survey.questions,
					},
				})
			)
			.subscribe(() => {
				this.store.dispatch(new LoadSurvey({ id: this.survey.id }));
				this.activeModal.close();
			});
	}

	changeDepartmentForMessage() {
		this.store
			.dispatch(
				new UpdateMessage({
					id: this.message.id,
					data: {
						id: this.message.id,
						message: this.message.message,
						department_id: this.selectedDepartments.join('.'),
					},
				})
			)
			.subscribe(() => {
				this.store.dispatch(new LoadAllMessages());
				this.activeModal.close();
			});
	}

	changeDepartmentForTask() {
		this.store
			.dispatch(
				new UpdateTask({
					id: this.task.id,
					data: {
						department_id: this.selectedDepartments.join('.'),
					},
				})
			)
			.subscribe(() => {
				this.activeModal.close();
			});
	}

	changeDepartmentForTasklist() {
		this.store
			.dispatch(
				new UpdateTasklist({
					id: this.tasklist.id,
					data: {
						department_id: this.selectedDepartments.join('.'),
					},
				})
			)
			.subscribe(() => {
				this.activeModal.close();
			});
	}

	changeDepartmentForMedia() {
		this.store
			.dispatch(
				new UpdateMedia({
					id: this.media.id,
					data: {
						department_id: this.selectedDepartments.join('.'),
					},
				})
			)
			.subscribe(() => {
				this.activeModal.close();
			});
	}

	onSelect() {
		if (!!this.selectedDepartments.length) {
			if (this.user) {
				this.changeDepartmentForUser();
			} else if (this.buddy) {
				this.changeDepartmentForBuddy();
			} else if (this.achievement) {
				this.changeDepartmentForAchievement();
			} else if (this.module) {
				this.chengeDepartmentForModule();
			} else if (this.survey) {
				this.changeDepartmentForSurvey();
			} else if (this.timeline) {
				this.changeDepartmentForTimeline();
			} else if (this.message) {
				this.changeDepartmentForMessage();
			} else if (this.task) {
				this.changeDepartmentForTask();
			} else if (this.tasklist) {
				this.changeDepartmentForTasklist();
			} else if (this.media) {
				this.changeDepartmentForMedia();
			}
		} else {
			this.notification.add({
				text: 'EMPLOYEES.NONE_DEPARTMENT_SELECTED',
			});
			this.activeModal.close();
		}
	}
}
