import { Dayjs } from 'dayjs';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../../../icon/components/icon/icon.component';

@Component({
	selector: 'itd-calendar-month-switcher',
	templateUrl: './calendar-month-switcher.component.html',
	styleUrls: ['./calendar-month-switcher.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [IconComponent, TranslateModule],
})
export class CalendarMonthSwitcherComponent {
	@Input() public date: Dayjs;

	@Output() public onChange = new EventEmitter<Dayjs>();

	constructor() {}

	public incrementMonth() {
		this.onChange.emit(this.date.add(1, 'month'));
	}

	public decrementMonth() {
		this.onChange.emit(this.date.subtract(1, 'month'));
	}
}
