import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Dayjs } from 'dayjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
	ITimelineAssign,
	ITimelineAssignDTO,
	ITimelineAssignEdit,
	ITimelineAssignEditDTO,
	ITimelineResendAssignment,
	QueryDTO,
} from 'src/app/core/timeline-assign/timeline-assign.interface';
import { AbstractApiService } from '../abstract/abstract-api-service';
import { IFilterAnalytics } from '../analytics/analytics.interface';
import { ApiClientService } from '../api/api.service';
import { AddLoading, RemoveLoading } from '../layout/layout.actions';
import { TResults } from '../models/results.interface';
import { IUser } from '../users/users.interface';
import { createParams } from '../utils/analytics-params.util';
import { AddPaginationForEmployees } from './timeline-assign.action';

type filtersObj = {
	preboarding: boolean;
	onboarding: boolean;
	offboarding: boolean;
};

@Injectable({
	providedIn: 'root',
})
export class TimelinesAssignService extends AbstractApiService<
	ITimelineAssign,
	ITimelineAssignDTO,
	ITimelineAssignEdit,
	ITimelineAssignEditDTO
> {
	protected key: string = 'timelineassign';

	constructor(protected api: ApiClientService, protected store: Store) {
		super(api, store);
	}
	public getByUser(): Observable<ITimelineAssign[]> {
		return this.api.get<TResults<ITimelineAssignDTO[]>>('api/timelineassign/user').pipe(
			map(response =>
				response.data.map(timeline => {
					return this.parse(timeline);
				})
			)
		);
	}

	public getSelectedTimelineAssignment(id: string): Observable<ITimelineAssign> {
		return this.api.get<TResults<any>>(`api/timelineassign/user/timeline/${id}`).pipe(
			map(response => {
				return this.parse(response.data);
			})
		);
	}

	public getOneUserTimelineAssignment(payload: {
		tl_id: string;
		user_id: string;
	}): Observable<ITimelineAssign> {
		return this.api
			.get<TResults<any>>(
				`api/timelineassign/user/timeline/${payload.tl_id}?user_id=${payload.user_id}`
			)
			.pipe(
				map(response => {
					return this.parse(response.data);
				})
			);
	}

	// TODO Add a state and action for this call, so we can store results in state and not have to load from API every time
	public getByDates(start: Dayjs, end: Dayjs, filters: filtersObj): Observable<ITimelineAssign[]> {
		return this.api
			.get<TResults<ITimelineAssignDTO[]>>(`api/timelineassign/by-dates`, {
				params: {
					start_date: start.format('DD-MM-YYYY'),
					end_date: end.format('DD-MM-YYYY'),
					preboarding: filters.preboarding,
					onboarding: filters.onboarding,
					offboarding: filters.offboarding,
				},
			})
			.pipe(map(response => response.data.map(timeline => this.parse(timeline))));
	}

	// TODO Move this API Call to analytics service
	public getOneTimelineAssignment(
		id: string,
		filters?: IFilterAnalytics
	): Observable<ITimelineAssign[]> {
		const params = createParams(filters, 'months', false, 1000, 0);

		return this.api
			.get<TResults<ITimelineAssignDTO[]>>(`api/v2/analytics/timelineassign/${id}`, {
				params,
			})
			.pipe(
				map(response =>
					response?.data?.map(timeline => {
						return this.parse(timeline);
					})
				)
			);
	}

	public deleteTimelineAssign(data: {
		tl_id: ITimelineAssign['tl_id'];
		user_id: ITimelineAssign['user_id'];
	}): Observable<boolean> {
		const { tl_id, user_id } = data;
		return this.api.delete<TResults>(`api/timelineassign/${tl_id}?user_id=${user_id}`).pipe(
			map(response => {
				return response.data;
			})
		);
	}

	public resendAssignment(data: ITimelineResendAssignment) {
		return this.api.post<TResults<ITimelineAssignDTO>>(`api/timelineassign/resend`, data).pipe(
			map(response => {
				return response.data;
			})
		);
	}

	public resendAllIncompleteAssignments(tlId: string, messageId: string): Observable<TResults> {
		return this.api
			.post<TResults>(`api/timelineassign/resend/${tlId}`, {
				message_id: messageId,
			})
			.pipe(
				map(response => {
					return response;
				})
			);
	}

	public getFilteredTimelinesAssign(id: string, query: QueryDTO) {
		this.store.dispatch(AddLoading);

		return this.api
			.get<TResults<ITimelineAssignDTO[]>>(`api/v2/analytics/timelineassign/${id}`, {
				params: {
					...query,
				},
			})
			.pipe(
				map(response => {
					this.store.dispatch(RemoveLoading);
					if (!response.data) {
						return;
					}
					return response.data.map(module => this.parse(module));
				})
			);
	}

	public getEmployees(params?: { skip: number; limit: number }) {
		this.store.dispatch(AddLoading);
		return this.api
			.get<TResults<IUser[]>>(`api/users`, {
				params,
			})
			.pipe(
				map(response => {
					this.store.dispatch(RemoveLoading);
					this.store.dispatch(new AddPaginationForEmployees(response.pagination));
					return response.data.map(module => ({
						...module,
						checked: false,
					}));
				})
			);
	}

	public create(data: ITimelineAssignEdit): Observable<any> {
		const DTO = this.parseEditDTO(data);

		return this.api.post<TResults<ITimelineAssign>>(`api/${this.key}`, DTO).pipe(
			map(response => {
				return response.data;
			})
		);
	}

	public parse(value: ITimelineAssignDTO): ITimelineAssign {
		if (!value) return;
		const { _id, ...rest } = value;

		return {
			id: _id,
			...rest,
		};
	}

	protected parseEditDTO(value: Partial<ITimelineAssignEdit>): ITimelineAssignEditDTO {
		const { id, user_ids, ...rest } = value;

		return {
			_id: id,
			user_ids,
			...rest,
		};
	}

	// TODO: Type this
	// {
	// 	module_id: string,
	// 	progress: [],
	// 	tl_id: string // (should be number)
	// }
	public finishModule(payload: any): Observable<any> {
		return this.api.put<any>(`api/${this.key}/${payload.tl_id}`, payload);
	}

	public getUserTimelines(userId: string): Observable<any> {
		return this.api.get<TResults<any>>(`api/timelineassign/user/${userId}`).pipe(
			map(response => {
				return response.data.map(data => {
					return this.parse(data);
				});
			})
		);
	}
}
