<div
	*ngIf="backgroundGradient && achievement"
	class="container"
	[style]="{ background: backgroundGradient, color: achievement.theme.text }"
>
	<ng-container *ngIf="menuEnabled">
		<div class="menu">
			<itd-menu>
				<itd-menu-item
					icon="pen"
					[width]="12"
					[height]="12"
					(onClick)="edit()"
					*ngIf="hasPermission(achievement)"
				>
					{{ 'EDIT' | translate }}
				</itd-menu-item>
				<itd-menu-item
					icon="bin"
					[width]="12"
					[height]="12"
					(onClick)="delete()"
					*ngIf="hasPermission(achievement)"
				>
					{{ 'DELETE' | translate }}
				</itd-menu-item>
				<itd-menu-item
					*ngIf="!achievement.department_id && this.isGlobalAdmin"
					icon="link"
					[width]="12"
					[height]="12"
					(click)="addDepartment(achievement)"
				>
					{{ 'DEPARTMENT_ADD' | translate }}
				</itd-menu-item>
				<itd-menu-item
					*ngIf="!!achievement.department_id"
					icon="link"
					[width]="12"
					[height]="12"
					(click)="changeDepartment(achievement)"
				>
					{{ 'DEPARTMENT_CHANGE' | translate }}
				</itd-menu-item>
				<itd-menu-item
					*ngIf="!!achievement.department_id && this.isGlobalAdmin"
					icon="link-off"
					[width]="12"
					[height]="12"
					(click)="removeDepartment(achievement)"
				>
					{{ 'DEPARTMENT_REMOVE' | translate }}
				</itd-menu-item>
			</itd-menu>
		</div>
	</ng-container>

	<div class="icon">
		<img src="/assets/icons/achievements-icons/{{ iconFilename }}.svg" />
	</div>

	<p class="title">
		{{ achievement.title }}
	</p>

	<p class="description" *ngIf="achievement.description">
		{{ achievement.description }}
	</p>
</div>
