export class LoadAllNetworkGroups {
	public static readonly type = '[Network] Load All Groups';
	constructor(public payload: { is_admin: boolean }) {}
}

export class LoadAllNetworkGroupsIfEmpty {
	public static readonly type = '[Network] Load All Groups If Empty';
}

export class DeleteNetworkGroup {
	public static readonly type = '[Network] Delete';
	constructor(public payload: { id: string }) {}
}

export class LoadMyUnreadMessages {
	public static readonly type = '[Network] Load Unread Messages';
}
