import { ICreateFastTrackAssignmentDTO } from './fast-track-assignment.interface';

export class LoadFastTrackAssignmentsForUser {
	public static readonly type = '[Fast Track Assignment] Load for User';
	constructor(public payload: { userId: string }) {}
}

export class LoadFastTrackAssignments {
	public static readonly type = '[Fast Track Assignment] Load all';
	constructor() {}
}

// export class UpdateFastTrackConfiguration {
// 	public static readonly type = '[Fast Track Configuration] Update';
// 	constructor(public payload: { id: string; data: IFastTrackConfigurationEditDTO }) {}
// }

export class CreateFastTrackAssignment {
	public static readonly type = '[Fast Track Assignment] Create';
	constructor(public payload: { data: ICreateFastTrackAssignmentDTO }) {}
}

export class DeleteFastTrackAssignment {
	public static readonly type = '[Fast Track Assignment] Delete Assignment';
	constructor(public payload: { id: string }) {}
}
