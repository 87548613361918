import { GenerateProfileOpenApiToken } from '../../../../../core/profile/profile.actions';
import { ChangeDetectionStrategy, Component, ChangeDetectorRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { IProfile } from 'src/app/core/profile/profile.interface';
import { ProfileState } from 'src/app/core/profile/profile.state';
import { AreYouSureModalComponent } from 'src/app/shared/modals/are-you-sure-modal/are-you-sure-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { CopyToClipboardDirective } from '../../../../directives/copyToClipboard.directive';
import { NgIf, AsyncPipe } from '@angular/common';
import { LoadingDirective } from '../../../../directives/loading.directive';

@Component({
    selector: 'itd-profile-settings-open-api',
    templateUrl: './profile-settings-open-api.component.html',
    styleUrls: ['./profile-settings-open-api.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        LoadingDirective,
        NgIf,
        CopyToClipboardDirective,
        AsyncPipe,
        TranslateModule,
    ],
})
export class ProfileSettingsOpenApiComponent {
	@Select(ProfileState.profile) public profile$: Observable<IProfile>;

	public isTokenShowed: boolean = false;
	public isLoading: boolean = false;

	public get buttonText(): string {
		if (this.isTokenShowed) {
			return 'UPDATE_API_TOKEN';
		}
		return 'SHOW_API_TOKEN';
	}

	constructor(private store: Store, private cd: ChangeDetectorRef, private modals: NgbModal) {}

	public async onClick() {
		if (this.isTokenShowed) {
			await this.generate();
		} else {
			const openApiToken = this.store.selectSnapshot(ProfileState.profile).api_token;

			if (!openApiToken) {
				await this.generate();
			}

			this.isTokenShowed = true;
		}

		this.cd.detectChanges();
	}

	public async generate() {
		const component = this.modals.open(AreYouSureModalComponent)
			.componentInstance as AreYouSureModalComponent;

		component.onSubmitAction = () => {
			return this.store.dispatch(GenerateProfileOpenApiToken);
		};
	}
}
