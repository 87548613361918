<div class="container" *ngIf="profile$ | async as profile">
	<!-- TODO: click notification button -->
	<button
		type="button"
		class="notification"
		itdTooltip="{{ 'NAVIGATION.NOTIFICATION_TOOLTIP' }}"
		tooltipPosition="right"
	>
		<itd-icon icon="bell" [width]="14" [height]="18"></itd-icon>
	</button>

	<div class="image" [ngClass]="{ 'has-background': profile.image_key }">
		<itd-user-avatar
			[user]="profile"
			folder="user-images"
			[width]="64"
			[height]="64"
		></itd-user-avatar>
	</div>

	<p class="title">{{ profile.first_name }} {{ profile.last_name }}</p>
</div>
