import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipDirective } from '../../directives/tooltip.directive';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { IconComponent } from '../../../utility-modules/icon/components/icon/icon.component';

export interface INavigationItem {
	title: string;
	url?: string[];
	icon?: string;
	tooltip?: string;
	footerMobilePosition?: number;
	isForAdmin?: boolean;
}

@Component({
    selector: 'itd-navigation-item',
    templateUrl: './navigation-item.component.html',
    styleUrls: ['./navigation-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        IconComponent,
        NgIf,
        RouterLinkActive,
        RouterLink,
        TooltipDirective,
        NgTemplateOutlet,
        TranslateModule,
    ],
})
export class NavigationItemComponent {
	@Input() public data: INavigationItem;
	@Input() public hasTooltip?: boolean = true;

	constructor() {}
}
