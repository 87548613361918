import { TResults } from './../models/results.interface';
import { IRefreshTokenResponse } from './../models/refresh-token';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ILoginResponse } from '../models/login.interface';
import { TLoginDTO } from '../profile/profile.service';
import { ApiClientService } from '../api/api.service';
import { NotificationService } from 'src/app/utility-modules/notification/services/notification.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(private api: ApiClientService, private notification: NotificationService) {}

	public login(data: TLoginDTO): Observable<ILoginResponse> {
		return this.api.post<TResults<ILoginResponse>>('security/user/authenticate', data).pipe(
			map(response => {
				if (response.success === false) {
					if (response.message) {
						this.notification.add({
							text: `BACKEND_MESSAGES.${response.message}`,
							type: 'error',
						});
					}
				}
				return response.data;
			})
		);
	}

	public loginWithoutPassword(token: string): Observable<ILoginResponse> {
		return this.api
			.get<TResults<ILoginResponse>>('security/user/authenticateWithLink', {
				params: {
					token: token,
				},
			})
			.pipe(
				map(response => {
					return response.data;
				})
			);
	}

	public magicLogin(email: string): Observable<ILoginResponse> {
		return this.api
			.post<TResults<ILoginResponse>>('/auth/magiclogin', {
				destination: email,
			})
			.pipe(
				map(response => {
					return response.data;
				})
			);
	}

	public logout(): Observable<void> {
		return of(undefined);
	}

	public refreshToken(token: string): Observable<string> {
		return this.api
			.get<IRefreshTokenResponse>('security/user/authenticate/refresh-token', {
				params: {
					token: token,
				},
			})
			.pipe(map(response => response.token));
	}

	public isTokenValid(token: string): Observable<boolean> {
		return this.api
			.post<TResults>('open-api/verify/token', {
				token,
			})
			.pipe(
				map(response => {
					return response.success;
				})
			);
	}

	public isActivated(id: string): Observable<boolean> {
		return this.api
			.post<TResults>(`security/user/is-activated/${id}`)
			.pipe(map(response => response.success));
	}
}
