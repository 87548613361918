import { Component, Input } from '@angular/core';
import { TErrorMessage } from 'src/app/core/services/validation-messages.service';
import { Observable } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'itd-form-error',
    templateUrl: './form-error.component.html',
    styleUrls: ['./form-error.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        AsyncPipe,
        TranslateModule,
    ],
})
export class FormErrorComponent {
	@Input() public errorObservable: Observable<TErrorMessage>;
	@Input() public type: 'simple' | 'normal' | 'full' = 'normal';
	@Input() public size: 'normal' | 'full' = 'normal';

	constructor() {}
}
