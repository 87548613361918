import { Component, ElementRef, HostBinding, ChangeDetectionStrategy, Input } from '@angular/core';
import { CellOutletDirective } from '../table-row/table-row.component';

@Component({
    selector: 'itd-table-header-row',
    template: `
		<ng-container itdCellOutlet></ng-container>
	`,
    host: {
        class: 'table__header-row',
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CellOutletDirective],
})
export class TableHeaderRowComponent {
	@Input() public rowStyle: 'default' | 'inline' = 'default';

	@HostBinding('class')
	public get class(): string {
		return 'is-' + this.rowStyle;
	}

	@HostBinding('class.isScrolled')
	public get isSticky(): boolean {
		const element = this._elementRef.nativeElement;

		if (!element) {
			return false;
		}

		const rect = element.parentElement.getBoundingClientRect();

		if (rect.height === 0) {
			return false;
		}

		return rect.y <= 0;
	}

	constructor(private _elementRef: ElementRef<HTMLElement>) {}
}
