import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { AbstractApiState } from '../abstract/abstract-api-state';
import {
	CreateFastTrackAssignment,
	DeleteFastTrackAssignment,
	LoadFastTrackAssignmentsForUser,
} from './fast-track-assignment.actions';
import {
	IFastTrackAssignment,
	IFastTrackAssignmentDTO,
	IFastTrackAssignmentEdit,
	IFastTrackAssignmentEditDTO,
} from './fast-track-assignment.interface';
import { FastTrackAssignmentService } from './fast-track-assignment.service';

export interface IFasttrackAssignmentStateModel {
	items: IFastTrackAssignment[];
	currentUserScope?: IFastTrackAssignment[];
}

@State<IFasttrackAssignmentStateModel>({
	name: 'fasttrackassignment',
	defaults: {
		items: null,
		currentUserScope: null,
	},
})
@Injectable()
export class FastTrackAssignmentState extends AbstractApiState<
	IFastTrackAssignment,
	IFastTrackAssignmentDTO,
	IFastTrackAssignmentEdit,
	IFastTrackAssignmentEditDTO
> {
	constructor(protected api: FastTrackAssignmentService, store: Store) {
		super(api, store);
	}

	@Selector()
	public static getState(state: IFasttrackAssignmentStateModel) {
		return state;
	}

	@Selector()
	public static items(state: IFasttrackAssignmentStateModel) {
		return state.items;
	}

	@Selector()
	public static currentUser(state: IFasttrackAssignmentStateModel) {
		return state.currentUserScope;
	}

	@Action(LoadFastTrackAssignmentsForUser)
	public loadAssignmentsForOneUser(
		ctx: StateContext<IFasttrackAssignmentStateModel>,
		data: LoadFastTrackAssignmentsForUser
	) {
		const { patchState } = ctx;
		return this.api.getFastTrackAssignmentsForUser(data.payload.userId).pipe(
			tap(result => {
				patchState({
					currentUserScope: result,
				});
			})
		);
	}

	// * Would have liked to just call this 'create' but that automatically infers from the abstract, in which the types don't match
	@Action(CreateFastTrackAssignment)
	public createFastTrackAssignment(
		ctx: StateContext<IFasttrackAssignmentStateModel>,
		data: CreateFastTrackAssignment
	) {
		return this.api.createFastTrackAssignment(data.payload.data).pipe(
			tap(result => {
				const _items = ctx.getState().items;
				let items = null;

				if (!_items || !_items.length) {
					items = [result];
				} else {
					items = [..._items, result];
				}

				ctx.patchState({
					items,
				});
			})
		);
	}

	@Action(DeleteFastTrackAssignment)
	public deleteFastTrackAssignment(
		ctx: StateContext<IFasttrackAssignmentStateModel>,
		data: DeleteFastTrackAssignment
	) {
		const { id } = data.payload;
		return this.api.deleteFastTrackAssignment(id).pipe(
			tap(() => {
				const _items = ctx.getState().items;
				const _currentUserItems = ctx.getState().currentUserScope;

				if (!_items || !_items.length) {
					return;
				}

				const items = [..._items];
				const index = items.findIndex(item => item.assignment_id === id);

				if (index === -1) {
					return;
				}
				// Treat current user scope, ie. side-drawer with assignments
				if (_currentUserItems?.length) {
					const currentUserItems = [..._currentUserItems];
					const currentUserIndex = currentUserItems.findIndex(item => item.assignment_id === id);

					if (currentUserIndex !== -1) {
						currentUserItems.splice(currentUserIndex, 1);

						ctx.patchState({
							currentUserScope: currentUserItems,
						});
					}
				}

				items.splice(index, 1);

				ctx.patchState({
					items,
				});
			})
		);
	}

	// @Action(UpdateFastTrackConfiguration)
	// public update(
	// 	ctx: StateContext<IFasttrackConfigurationStateModel>,
	// 	data: UpdateFastTrackConfiguration
	// ) {
	// 	return super.update(ctx, data);
	// }
}
