import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnInit,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { LoadAllMessagesIfEmpty } from 'src/app/core/messages/messages.action';
import { IMessage } from 'src/app/core/messages/messages.interface';
import { MessagesState } from 'src/app/core/messages/messages.state';
import { IOrganization } from 'src/app/core/organizations/organizations.interface';
import { OrganizationState } from 'src/app/core/organizations/organizations.state';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { TimelinesAssignService } from 'src/app/core/timeline-assign/timeline-assign.service';
import { UsersService } from 'src/app/core/users/users.service';
import { AbstractFormComponent } from 'src/app/utility-modules/itd-form/components/abstract-form/abstract-form.component';
import { ButtonComponent } from 'src/app/utility-modules/itd-form/components/button/button.component';
import { SelectComponent } from 'src/app/utility-modules/itd-form/components/select/select.component';
import { ModalComponent } from 'src/app/utility-modules/modals/components/modal/modal.component';
import { NotificationService } from 'src/app/utility-modules/notification/services/notification.service';

@Component({
	selector: 'itd-confirm-resend-invite-modal',
	templateUrl: './confirm-resend-invite-modal.component.html',
	styleUrls: ['./confirm-resend-invite-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		ReactiveFormsModule,
		ModalComponent,
		TranslateModule,
		SelectComponent,
		ButtonComponent,
		AsyncPipe,
	],
})
export class ConfirmResendInviteModalComponent extends AbstractFormComponent implements OnInit {
	@Input() data: {
		user_id?: string;
		tl_id?: string;
	};
	@Input() title = 'EMPLOYEES.RESEND_INVITE';
	@Input() messageType = 'welcome_message';

	@Select(OrganizationState.organization) public organization$: Observable<IOrganization>;
	@Select(MessagesState.items) public messages$: Observable<IMessage[]>;

	public defaultMessageValue$ = new BehaviorSubject('');
	private orgWelcomeMsgId: string;

	constructor(
		private notification: NotificationService,
		public activeModal: NgbActiveModal,
		public cd: ChangeDetectorRef,
		public elementRef: ElementRef,
		public validationMessages: ValidationMessagesService,
		private store: Store,
		private usersService: UsersService,
		private timelineAssignService: TimelinesAssignService
	) {
		super(cd);
		this.store.dispatch(LoadAllMessagesIfEmpty);
	}

	ngOnInit(): void {
		this.form = new FormGroup({
			message_id: new FormControl('', [Validators.required]),
		});

		this.organization$.subscribe(org => {
			const orgHasMessageType = org[this.messageType];

			this.orgWelcomeMsgId = orgHasMessageType ? orgHasMessageType : org.welcome_message;
		});
		this.messages$.subscribe((res: IMessage[]) => {
			if (res) {
				const index = res.findIndex(item => item.id === this.orgWelcomeMsgId);
				this.defaultMessageValue$.next(res[index]?.id);
			}
		});

		this.formValue.message_id = this.orgWelcomeMsgId;
		super.afterInit();
	}

	protected onSubmitSuccess() {
		this.activeModal.close();
	}

	public onSubmitAction() {
		if (this.data.user_id) {
			this.usersService
				.resendInvite(this.data.user_id, this.formValue.message_id)
				.subscribe(message => {
					this.notification.add({
						text: 'BACKEND_MESSAGES.' + message,
					});
				});
		}

		if (!this.data.user_id) {
			this.timelineAssignService
				.resendAllIncompleteAssignments(this.data.tl_id, this.formValue.message_id)
				.subscribe(response => {
					this.notification.add({
						text: 'BACKEND_MESSAGES.' + response.message,
					});
				});
		}

		// Returning an observable as false to satisfy parent class, as it must return an observable
		return of(false);
	}
}
