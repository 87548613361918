import { Observable } from 'rxjs';
import { ProfileState } from 'src/app/core/profile/profile.state';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { IProfile } from 'src/app/core/profile/profile.interface';
import { UserAvatarComponent } from '../../user-avatar/user-avatar.component';
import { IconComponent } from '../../../../utility-modules/icon/components/icon/icon.component';
import { TooltipDirective } from '../../../directives/tooltip.directive';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';

@Component({
    selector: 'itd-sidebar-profile',
    templateUrl: './sidebar-profile.component.html',
    styleUrls: ['./sidebar-profile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        TooltipDirective,
        IconComponent,
        NgClass,
        UserAvatarComponent,
        AsyncPipe,
    ],
})
export class SidebarProfileComponent {
	@Select(ProfileState.profile) public profile$: Observable<IProfile>;

	constructor() {}
}
