import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'itd-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
    standalone: true,
    imports: [NgIf, TranslateModule],
})
export class ProgressBarComponent {
	@Input() public value: number;
	@Input() public label?: string;
}
