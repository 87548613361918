<div
	class="container"
	[ngClass]="{
		'is-active': value,
		'is-focus': isFocus,
		'is-error': invalid,
		'is-warning': isWarning,
		hasShadow: hasShadow,
		hasSideLabel: sideLabel
	}"
>
	<label [ngClass]="{ label: label, sideLabel: sideLabel }" *ngIf="label || sideLabel">
		{{ label || sideLabel | translate }}
	</label>

	<div [ngClass]="{ hasLabel: label, hasBorder: hasBorder }">
		<input class="hidden-input" type="text" [name]="name" [(ngModel)]="value" />

		<input
			type="text"
			class="input size-{{ sizeType }}"
			[ngStyle]="{ 'width.px': width }"
			autocomplete="off"
			[placeholder]="placeholder"
			[dpDayPicker]="config"
			(onChange)="onModelValueChange($event)"
			[(ngModel)]="dataPickerValue"
			[disabled]="isDisabledInput"
		/>

		<div class="icon" *ngIf="icon">
			<itd-icon [icon]="icon" [width]="12" [height]="12"></itd-icon>
		</div>
		<div class="icon-reset" *ngIf="value" (click)="clearDate()">
			<div class="clear-Icon ng-clear-wrapper" *ngIf="clearIcon" (click)="clearDate()"></div>
		</div>
	</div>

	<div *ngIf="message$ | async as message" class="input-group__validation">
		<div *ngIf="invalid" [innerHTML]="message.text"></div>
	</div>
</div>
