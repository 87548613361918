import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs';
import { AbstractApiState } from '../abstract/abstract-api-state';
import {
	CreateTasklistAssignment,
	DeleteTasklistAssignment,
	LoadAllTasklistAssignments,
	LoadAllTasklistAssignmentsIfEmpty,
	LoadMyTasklistAssignments,
	LoadSelectedTasklistAssignment,
	UpdateTasklistAssignment,
	LoadTasklistAssignment,
	ClearTasklistAssignment,
} from './tasklist-assignment.action';
import {
	ITasklistAssignmentDTO,
	ITasklistAssignment,
	ITasklistAssignmentEdit,
	ITasklistAssignmentEditDTO,
} from './tasklist-assignment.interface';
import { TasklistAssignmentService } from './tasklist-assignment.service';

export interface TasklistAssignmentStateModel {
	items: ITasklistAssignment[];
	myAssignments?: ITasklistAssignment[];
	selectedTaskAssignment?: ITasklistAssignment;
}

@State<TasklistAssignmentStateModel>({
	name: 'tasklistAssignment',
	defaults: {
		items: null,
		myAssignments: null,
		selectedTaskAssignment: null,
	},
})
@Injectable()
export class TasklistAssignmentState extends AbstractApiState<
	ITasklistAssignment,
	ITasklistAssignmentDTO,
	ITasklistAssignmentEdit,
	ITasklistAssignmentEditDTO
> {
	constructor(private tasklistAssignmentService: TasklistAssignmentService, store: Store) {
		super(tasklistAssignmentService, store);
	}

	@Selector()
	public static getState(state: TasklistAssignmentStateModel) {
		return state;
	}

	@Selector()
	public static items(state: TasklistAssignmentStateModel): ITasklistAssignment[] {
		return state.items;
	}

	@Selector()
	public static getSelectedAssignment(state: TasklistAssignmentStateModel): ITasklistAssignment {
		return state.selectedTaskAssignment;
	}

	@Selector()
	public static myAssignments(state: TasklistAssignmentStateModel): ITasklistAssignment[] {
		return state.myAssignments;
	}

	@Action(LoadAllTasklistAssignments)
	public loadAll(ctx: StateContext<TasklistAssignmentStateModel>) {
		return super.loadAll(ctx);
	}

	@Action(LoadAllTasklistAssignmentsIfEmpty)
	public loadAllIfEmpty(ctx: StateContext<TasklistAssignmentStateModel>) {
		return super.loadAllIfEmpty(ctx);
	}

	@Action(LoadMyTasklistAssignments)
	public loadMyTasklistAssignments({ patchState }: StateContext<TasklistAssignmentStateModel>) {
		return this.tasklistAssignmentService.getMyAssignments().pipe(
			tap(result => {
				patchState({
					myAssignments: result,
				});
			})
		);
	}

	@Action(LoadSelectedTasklistAssignment)
	public loadSelectedTasklistAssignment(
		{ patchState }: StateContext<TasklistAssignmentStateModel>,
		data: LoadSelectedTasklistAssignment
	) {
		return this.tasklistAssignmentService.getSelectedAssignment(data.payload.user_id).pipe(
			tap(result => {
				patchState({
					selectedTaskAssignment: result,
				});
			})
		);
	}

	@Action(LoadTasklistAssignment)
	public getTasklistAssignmentById(
		{ patchState }: StateContext<TasklistAssignmentStateModel>,
		data: LoadTasklistAssignment
	) {
		return this.tasklistAssignmentService.getTasklistAssignmentById(data.payload.id).pipe(
			tap(items => {
				return patchState({
					myAssignments: items,
				});
			})
		);
	}

	@Action(CreateTasklistAssignment)
	public create(ctx: StateContext<TasklistAssignmentStateModel>, data: CreateTasklistAssignment) {
		return super.create(ctx, data);
	}

	@Action(UpdateTasklistAssignment)
	public update(ctx: StateContext<TasklistAssignmentStateModel>, data: UpdateTasklistAssignment) {
		return super.update(ctx, data);
	}

	@Action(DeleteTasklistAssignment)
	public delete(ctx: StateContext<TasklistAssignmentStateModel>, data: DeleteTasklistAssignment) {
		return super.delete(ctx, data);
	}

	@Action(ClearTasklistAssignment)
	public clearSelectedTasklistAssignment({
		patchState,
	}: StateContext<TasklistAssignmentStateModel>) {
		return patchState({
			selectedTaskAssignment: null,
		});
	}
}
