import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiClientService } from '../api/api.service';
import { TResults } from '../models/results.interface';
import {
	IProfile,
	IProfileDTO,
	IChangeProfilePasswordDTO,
	IActivateProfile,
	IActivateProfileResult,
	IUpdateProfileDTO,
} from './profile.interface';

export type TLoginDTO = {
	email: string;
	password: string;
};

export type TPasswordResetDTO = {
	email: string;
};

export type TNewPasswordDTO = {
	password: string;
};

@Injectable({
	providedIn: 'root',
})
export class ProfileService {
	constructor(private api: ApiClientService) {}

	public resetPassword(data: TPasswordResetDTO): Observable<boolean> {
		return this.api.post('security/user/forgotpwd', data);
	}

	public loginWithoutPassword(data: TPasswordResetDTO): Observable<boolean> {
		return this.api.post('security/user/generateLoginLink', data);
	}

	public setNewPassword(data: TNewPasswordDTO): Observable<boolean> {
		return this.api.post('api/users/reset-password', data);
	}

	public setAvatar(file: File): Observable<IProfile> {
		return this.api
			.uploadMedia<TResults<IProfile>>('api/media/user-image', [file])
			.pipe(map(response => response.data));
	}

	public get(): Observable<IProfile> {
		return this.api.get<TResults<IProfileDTO>>('api/users/profile').pipe(
			map(response => {
				return this.parse(response.data);
			})
		);
	}

	public activate(data: IActivateProfile): Observable<IActivateProfileResult> {
		return this.api.post<TResults<IActivateProfileResult>>('security/user/activate', data).pipe(
			map(response => {
				return response.data;
			})
		);
	}

	public update(id: string, profile: IUpdateProfileDTO): Observable<IProfile> {
		return this.api
			.put<TResults<IProfileDTO>>(`api/users/${id}`, profile)
			.pipe(map(response => this.parse(response.data)));
	}

	public generateApiToken(id: string): Observable<IProfile> {
		return this.api
			.post<TResults<IProfileDTO>>(`api/users/${id}/generate-api-token`)
			.pipe(map(response => this.parse(response.data)));
	}

	public changePassword(payload: IChangeProfilePasswordDTO): Observable<boolean> {
		return this.api
			.post<TResults<string>>('api/users/update-password', { ...payload })
			.pipe(map(response => response.success));
	}

	public delete(id: string): Observable<boolean> {
		return this.api
			.delete<TResults<string>>('api/user/delete', {
				params: {
					user_id: id,
				},
			})
			.pipe(map(response => response.success));
	}

	private parse(profile: IProfileDTO): IProfile {
		const { createdAt, updatedAt, ...rest } = profile;
		return {
			updatedAt: new Date(updatedAt),
			createdAt: new Date(createdAt),
			...rest,
		};
	}
}
