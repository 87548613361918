import { DictionaryService } from './dictionary.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ILanguage } from '../models/language.interface';
import { DictionaryLoadLanguages, DictionaryLoadLanguagesIfEmpty } from './dictionary.actions';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

export interface IDictionaryStateModel {
	languages: ILanguage[];
}

@State<IDictionaryStateModel>({
	name: 'dictionary',
	defaults: {
		languages: null,
	},
})
@Injectable()
export class DictionaryState {
	constructor(private service: DictionaryService) {}

	@Selector()
	public static languages(state: IDictionaryStateModel): ILanguage[] {
		return state.languages;
	}

	@Action(DictionaryLoadLanguages)
	public loadLanguages({ patchState }: StateContext<IDictionaryStateModel>) {
		return this.service.getLanguages().pipe(
			tap(languages => {
				return patchState({
					languages,
				});
			})
		);
	}

	@Action(DictionaryLoadLanguagesIfEmpty)
	public loadLanguagesIfEmpty({ getState, dispatch }: StateContext<IDictionaryStateModel>) {
		const languages = getState().languages;

		if (languages) {
			return;
		}

		return dispatch(DictionaryLoadLanguages);
	}
}
