import { NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonBackComponent } from '../../components/button-back/button-back.component';

@Component({
	selector: 'itd-result-template',
	templateUrl: './result-template.component.html',
	styleUrls: ['./result-template.component.scss'],
	standalone: true,
	imports: [ButtonBackComponent, NgIf, NgTemplateOutlet, TranslateModule],
})
export class ResultTemplateComponent implements OnInit {
	@Input() public backLink: string[];
	@Input() public title: string;
	@ContentChild('mainTemplateHeaderLeft') public headerLeft?: TemplateRef<any>;
	@ContentChild('mainTemplateHeaderRight') public headerRight?: TemplateRef<any>;
	@ContentChild('mainTemplateBody') public main: TemplateRef<any>;

	constructor(
		public translate: TranslateService,
		private router: Router,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {}
	public get hasHeader() {
		return this.headerLeft || this.headerRight;
	}

	goBackClick() {
		if (this.backLink) {
			this.router.navigate(this.backLink);
		} else {
			this.router.navigate(['../'], { relativeTo: this.route });
		}
	}
}
