import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	ElementRef,
	Input,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { LoadAllModules } from 'src/app/core/modules/modules.action';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { LoadAllSurveys } from 'src/app/core/surveys/surveys.action';
import { AbstractFormComponent } from 'src/app/utility-modules/itd-form/components/abstract-form/abstract-form.component';
import { NotificationService } from 'src/app/utility-modules/notification/services/notification.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormErrorComponent } from '../../../utility-modules/itd-form/components/form-error/form-error.component';
import { ButtonComponent } from '../../../utility-modules/itd-form/components/button/button.component';
import { CheckboxComponent } from '../../../utility-modules/itd-form/components/checkbox/checkbox.component';
import { NgIf } from '@angular/common';
import { ModalComponent } from '../../../utility-modules/modals/components/modal/modal.component';

@Component({
    selector: 'itd-confirm-duplicate-modal',
    templateUrl: './confirm-duplicate-modal.component.html',
    styleUrls: ['./confirm-duplicate-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ReactiveFormsModule,
        ModalComponent,
        NgIf,
        CheckboxComponent,
        ButtonComponent,
        FormErrorComponent,
        TranslateModule,
    ],
})
export class ConfirmDuplicateModalComponent extends AbstractFormComponent implements OnInit {
	@Input() data: {
		content?: string;
		showCheckbox?: boolean;
		title?: string;
	};
	@Input() checkboxText: string;
	@Input() warningText: string;
	@Input() public onSubmitAction: () => Observable<any>;
	@Input() deepCopyChecked: boolean;
	public deepCopy;

	constructor(
		private notification: NotificationService,
		public activeModal: NgbActiveModal,
		public validationMessages: ValidationMessagesService,
		public elementRef: ElementRef,
		public cd: ChangeDetectorRef,
		private store: Store
	) {
		super(cd);
	}

	public ngOnInit() {
		this.form = new FormGroup({
			deepCopy: new FormControl(this.deepCopy),
		});
		super.afterInit();
	}

	public checkDeepCopy() {
		this.deepCopyChecked = this.form.value.deepCopy;
	}

	protected onSubmitSuccess() {
		this.notification.add({
			text: 'CONFIRM_DUPLICATE.NOTIFICATION_SUCCESS',
		});

		this.store.dispatch([LoadAllModules, LoadAllSurveys]);

		this.activeModal.close();
	}
}
