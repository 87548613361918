<form (ngSubmit)="onSubmit()" [formGroup]="form">
	<itd-modal icon="{{ data.isEdit ? 'pen' : 'file' }}" [size]="600">
		<ng-template #header>
			{{
				data.isEdit
					? ('DEPARTMENTS.EDIT_DEPARTMENT' | translate)
					: ('DEPARTMENTS.CREATE_DEPARTMENT' | translate)
			}}
			<p id="department-id" *ngIf="data?.department?.department_id">
				ID: {{ data.department.department_id }}
			</p>
		</ng-template>

		<ng-template #content>
			<div class="wrapper">
				<div>
					<itd-input
						class="input"
						name="title"
						formControlName="title"
						placeholder="{{ 'DEPARTMENTS.NAME_PLACEHOLDER' | translate }}"
						label="{{ 'DEPARTMENTS.NAME_LABEL' | translate }}"
						sizeType="small"
						[focusAfterInit]="true"
						[hasShadow]="false"
						[hasBorder]="true"
					></itd-input>
				</div>
				<div *ngIf="(organization$ | async)?.has_department_branding" class="flex-col white-label">
					<h3>{{ 'DEPARTMENTS.WHITE_LABEL' | translate }}</h3>
					<strong>Logo</strong>
					<div class="p-4">
						<itd-image
							*ngIf="departmentEdit?.logo_url"
							[filename]="departmentEdit?.logo_url"
						></itd-image>

						<div class="logo-buttons">
							<itd-button
								styleType="primary"
								setType="button"
								(click)="openImageSelectionModal()"
								>{{
									departmentEdit?.logo_url
										? ('DEPARTMENTS.CHANGE_IMAGE' | translate)
										: ('DEPARTMENTS.ADD_IMAGE' | translate)
								}}</itd-button
							>

							<itd-button
								*ngIf="data?.department?.logo_url"
								styleType="secondary"
								setType="button"
								(click)="deleteImageSelection()"
								>{{ 'DEPARTMENTS.REMOVE_IMAGE' | translate }}</itd-button
							>
						</div>
					</div>
					<strong>{{ 'ORGANIZATIONS.COMPANY_COLOR' | translate }}</strong>
					<itd-input-color-picker [(color)]="color">
						<span>{{ color }}</span>
					</itd-input-color-picker>
					<strong>{{ 'ORGANIZATIONS.TEXT_COLOR' | translate }}</strong>
					<div>
						<button
							[ngStyle]="{ 'background-color': '#000', color: '#fff', margin: '10px' }"
							[ngClass]="{ active: textColor === '#ffffff' }"
							class="btn btn--size-default"
							type="button"
							(click)="textColor = '#ffffff'"
						>
							{{ 'White' | translate }}
						</button>
						<button
							[ngStyle]="{ 'background-color': '#fff', color: '#000' }"
							[ngClass]="{ active: textColor === '#000000' }"
							class="btn btn--size-default"
							type="button"
							(click)="textColor = '#000000'"
						>
							{{ 'Black' | translate }}
						</button>
					</div>
					<strong>Font</strong>
					<div>
						<button
							class="btn btn--size-default btn--color-{{ osFont ? 'primary' : 'third' }}"
							type="button"
							(click)="osFont = !osFont"
						>
							Default OS font
						</button>
					</div>
					<div>
						<div *ngIf="osFont">
							<label class="text-field__label info link"> Default system fonts </label>
							<itd-select
								sizeType="tiny"
								styleType="grey"
								printLabelIndex="name"
								formControlName="font"
								outputValueIndex="fontString"
								[isClearable]="false"
								[hasBorder]="true"
								[hasShadow]="false"
								[label]="'ORGANIZATIONS.COMPANY_FONT' | translate"
								[observableData]="defaultFonts$"
							></itd-select>
						</div>
					</div>
					<hr />
					<div>
						<h3 class="messages-title">{{ 'MESSAGES' | translate }}</h3>
						<itd-input
							class="input"
							name="message_from"
							formControlName="message_from"
							placeholder="{{ 'DEPARTMENTS.MESSAGE_FROM_PLACEHOLDER' | translate }}"
							label="{{ 'DEPARTMENTS.MESSAGE_FROM_LABEL' | translate }}"
							sizeType="small"
							[focusAfterInit]="false"
							[hasShadow]="false"
							[hasBorder]="true"
						></itd-input>
					</div>
					<div>
						<itd-input
							class="input"
							name="custom_from"
							formControlName="custom_from"
							placeholder="{{ 'DEPARTMENTS.CUSTOM_MESSAGE_PLACEHOLDER' | translate }}"
							label="{{ 'DEPARTMENTS.CUSTOM_MESSAGE_LABEL' | translate }}"
							sizeType="small"
							[focusAfterInit]="false"
							[hasShadow]="false"
							[hasBorder]="true"
						></itd-input>
					</div>
				</div>
			</div>
		</ng-template>
		<ng-template #footer>
			<itd-button [disabled]="!form.valid" [enableAfterSuccess]="false" [formState]="formState">
				{{ data.isEdit ? ('UPDATE' | translate) : ('CREATE' | translate) }}
			</itd-button>

			<itd-form-error [errorObservable]="errorMessage$"></itd-form-error>
		</ng-template>
	</itd-modal>
</form>
