import { LowerCasePipe, NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ITimelineAssign } from 'src/app/core/timeline-assign/timeline-assign.interface';
import { IconComponent } from '../../../utility-modules/icon/components/icon/icon.component';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { ModuleItemImageComponent } from '../image/module-item-image/module-item-image.component';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';

// TODO: Fix the HTML so its supports both ITimelineAssignNew and ITimeline
// Wait for backend fix first, as we can model data there to simplify the logic in this component

@Component({
	selector: 'itd-timeline-assign-card',
	templateUrl: './timeline-assign-card.component.html',
	styleUrls: ['./timeline-assign-card.component.scss'],
	standalone: true,
	imports: [
		NgIf,
		ModuleItemImageComponent,
		NgClass,
		ProgressBarComponent,
		IconComponent,
		LowerCasePipe,
		TranslateModule,
		TruncatePipe,
	],
})
export class TimelineAssignCardComponent {
	@Input() public data: ITimelineAssign;
	@Input() public imageUrl: string;
	@Input({ required: true }) public newLearning: boolean;
	@Input({ required: true }) public showTimeEstimate: boolean;

	@Output() public opened = new EventEmitter<ITimelineAssign['tl_id']>();

	constructor(private router: Router) {}

	public isTimelineOpen(timeline: ITimelineAssign): boolean {
		return timeline.isLocked;
	}
	//
	public open(id: ITimelineAssign['tl_id']) {
		if (this.newLearning) {
			this.opened.emit(id);
		} else {
			this.router.navigate(['/timeline-assign', id]);
		}
	}
}
