import * as moment from 'moment';
import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { SetColorPrimary, SetFontPrimary } from 'src/app/core/layout/layout.actions';
import { IOrganization } from 'src/app/core/organizations/organizations.interface';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { PublicSignUp } from 'src/app/core/users/users.action';
import { AbstractFormComponent } from 'src/app/utility-modules/itd-form/components/abstract-form/abstract-form.component';
import { ImageComponent } from 'src/app/shared/components/image/image.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { InputComponent } from 'src/app/utility-modules/itd-form/components/input/input.component';
import { InputDateComponent } from 'src/app/utility-modules/itd-form/components/input-date/input-date.component';
import { FormErrorComponent } from 'src/app/utility-modules/itd-form/components/form-error/form-error.component';
import { ButtonComponent } from 'src/app/utility-modules/itd-form/components/button/button.component';

type FormValue = {
	email: string;
	start_date: string;
};

@Component({
	selector: 'itd-user-signup-page',
	templateUrl: './user-signup-page.component.html',
	styleUrls: ['./user-signup-page.component.scss'],
	standalone: true,
	imports: [
		NgIf,
		TranslateModule,
		ReactiveFormsModule,
		ImageComponent,
		InputComponent,
		InputDateComponent,
		FormErrorComponent,
		ButtonComponent,
	],
})
export class UserSignupPageComponent extends AbstractFormComponent<FormValue> implements OnInit {
	public publicSignUpSuccess: boolean = false;
	public signUpObject: any = {};
	public linkId: string;
	public departmentId: string;
	public startDate: string;
	public paramsStartDate: string;
	public organization: IOrganization;
	public preboarding: string[];
	public onboarding: string[];
	public isDisabledPicker: boolean;

	constructor(
		public route: ActivatedRoute,
		private store: Store,
		public validationMessages: ValidationMessagesService,
		public elementRef: ElementRef,
		public cd: ChangeDetectorRef
	) {
		super(cd);
		this.organization = this.route.snapshot.data.id as IOrganization;
		this.store.dispatch(new SetColorPrimary({ color: this.organization.primary_color }));
		this.store.dispatch(new SetFontPrimary({ font: this.organization.basic_font }));
	}

	ngOnInit(): void {
		this.route.queryParams.subscribe(params => {
			this.linkId = params['link_id'];
			this.departmentId = params['department_id'];
			this.paramsStartDate = params['start_date']?.replace(/[^0-9]+/g, '-');
			this.preboarding = params['preboarding'];
			this.onboarding = params['onboarding'];
			this.isDisabledPicker = !!this.paramsStartDate;
		});
		this.form = new FormGroup({
			email: new FormControl('', [Validators.required]),
			start_date: new FormControl(this.paramsStartDate ? this.paramsStartDate : '', [
				Validators.required,
			]),
		});
		super.afterInit();
	}
	protected onSubmitAction() {
		this.signUpObject.email = this.formValue.email.toLowerCase().trim();
		if (this.linkId) {
			this.signUpObject.link_id = this.linkId;
		}
		if (this.departmentId) {
			this.signUpObject.department_id = this.departmentId;
		}
		if (this.paramsStartDate || this.startDate) {
			this.signUpObject.start_date = this.paramsStartDate
				? this.paramsStartDate
				: moment(this.startDate).format('MM DD YYYY');
		}

		if (this.preboarding) {
			this.signUpObject.preboarding_timelines = this.preboarding;
		}

		if (this.onboarding) {
			this.signUpObject.onboarding_timelines = this.onboarding;
		}

		return this.store.dispatch(
			new PublicSignUp({
				org_id: this.organization.org_id,
				signUpObject: this.signUpObject,
			})
		);
	}
	protected onSubmitSuccess(result: any): void {
		this.publicSignUpSuccess = true;
	}

	public changeStartDate(date: any) {
		if (!date) {
			return;
		} else {
			this.startDate = new Date(date).toISOString();
		}
	}
}
