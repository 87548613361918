import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { LoadAllDepartmentsIfEmpty } from 'src/app/core/department/department.actions';
import { IDepartment } from 'src/app/core/department/department.interface';
import { DepartmentState } from 'src/app/core/department/department.state';
import { ProfileState } from 'src/app/core/profile/profile.state';
import {
	getDataInAnyDepartment,
	getDataInsideGivenDepartment,
	getDataWithoutDepartment,
} from 'src/app/core/utils/departments.util';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../../../utility-modules/icon/components/icon/icon.component';
import { ButtonBackComponent } from '../button-back/button-back.component';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
	selector: 'itd-folders',
	templateUrl: './folders.component.html',
	styleUrls: ['./folders.component.scss'],
	standalone: true,
	imports: [NgIf, ButtonBackComponent, NgFor, NgClass, IconComponent, TranslateModule],
})
export class FoldersComponent implements OnInit, OnChanges {
	@Select(DepartmentState.departments) public departments$: Observable<IDepartment[]>;

	@Input() public data: any[];
	@Input() public startingData: any[];
	@Input() public sizeSmall: boolean = false;

	@Output() public newData = new EventEmitter<any[]>();

	public currentFolderHistory: { name: string; id: string }[] = [];
	public folders: { name: string; id: string }[] = [];
	public foldersLimited: { name: string; id: string }[] = [];
	public maxFoldersNumber = 12;
	public showAllFoldersActive: boolean = false;
	private currentFolder: { name: string; id: string } = null;
	private departments: IDepartment[] = null;
	private userRole: string;
	private userDepartmentId: string;

	constructor(private store: Store, private cd: ChangeDetectorRef) {
		this.userRole = this.store.selectSnapshot(ProfileState.role);
		this.userDepartmentId = this.store.selectSnapshot(ProfileState.departmentId);
		this.departments = this.store.selectSnapshot(DepartmentState.departments);
	}

	ngOnInit(): void {
		this.store.dispatch(LoadAllDepartmentsIfEmpty);
		this.departments$.subscribe(departments => {
			if (departments) {
				this.departments = departments;
				this.dataChanged();
			}
		});
	}

	ngOnChanges(): void {
		if (this.data) {
			this.startingData = this.data?.map(item => {
				// If user is a department admin we set data from his department to level 1
				if (item.department_id && this.userRole === 'dept_admin') {
					if (item.department_id === this.userDepartmentId) {
						// Removing department so the item's on level 1
						return {
							...item,
							department_id: item.department_id.replace(`${this.userDepartmentId}`, ''),
						};
					} else {
						// Removing departments above (parents of) user's department
						return {
							...item,
							department_id: item.department_id.replace(`${this.userDepartmentId}.`, ''),
						};
					}
				} else {
					// Items that don't belong to any department
					return item;
				}
			});
		}

		if (!this.startingData?.length) {
			if (this.departments) {
				this.dataChanged();
			}
		} else {
			this.dataChanged();
		}
	}

	public folderClicked(folder) {
		this.currentFolder = folder;
		this.currentFolderHistory.push(folder);
		this.dataChanged();
	}

	private dataChanged() {
		this.renderFolders();
		this.setNewDataList();
		this.cd.detectChanges();
	}

	private renderFolders() {
		this.folders = [];
		this.setFoldersLimited();

		if (!this.currentFolder) {
			let data = getDataInAnyDepartment(this.startingData);
			data?.forEach(item => {
				this.makeDepartmentFolder(item.department_id);
			});
		} else {
			let data = getDataInAnyDepartment(this.startingData);
			data = data.filter(
				item =>
					item.department_id.includes(this.currentFolder.id) &&
					!item.department_id.endsWith(this.currentFolder.id)
			);

			data?.forEach(item => {
				let indexOfDotBeforeChildDepartment =
					item.department_id.indexOf(this.currentFolder.id) + this.currentFolder.id.length;
				let childDepartmentIds = item.department_id.substring(indexOfDotBeforeChildDepartment + 1);
				this.makeDepartmentFolder(childDepartmentIds);
			});
		}
	}

	private makeDepartmentFolder(childDepartmentsString: string) {
		let dotIndex = childDepartmentsString.indexOf('.');
		let childDepartment = '';
		if (dotIndex !== -1) {
			childDepartment = childDepartmentsString.substring(0, dotIndex);
		} else {
			childDepartment = childDepartmentsString;
		}
		if (!this.folders.map(folder => folder.id).includes(childDepartment)) {
			let department = this.departments?.find(d => d.department_id === childDepartment);
			if (department) {
				this.folders.push({ name: department.title, id: childDepartment });
				this.folders.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
				this.setFoldersLimited();
			}
		}
	}

	private setNewDataList() {
		if (!this.currentFolder) {
			this.newData.emit(
				this.returnDepartmentPrefixToData(getDataWithoutDepartment(this.startingData))
			);
		} else {
			this.newData.emit(
				this.returnDepartmentPrefixToData(
					getDataInsideGivenDepartment(this.currentFolder?.id, this.startingData)
				)
			);
		}
	}

	// We return initial department_id to items if user is a department admin
	private returnDepartmentPrefixToData(data: any[]) {
		if (this.userRole === 'dept_admin') {
			return data?.map(item => {
				let itemDepartmentId = this.data.find(i => i.id === item.id).department_id;
				return {
					...item,
					department_id: itemDepartmentId,
				};
			});
		} else {
			return data;
		}
	}

	private setFoldersLimited() {
		if (this.folders.length > this.maxFoldersNumber) {
			this.foldersLimited = this.folders.slice(0, this.maxFoldersNumber);
		} else {
			this.foldersLimited = this.folders;
		}
	}

	public showAllFolders() {
		this.showAllFoldersActive = true;
	}

	public hideFolders() {
		this.showAllFoldersActive = false;
	}

	public goBack() {
		this.currentFolderHistory.pop();
		this.currentFolder = this.currentFolderHistory[this.currentFolderHistory.length - 1];
		this.dataChanged();
	}
}
