import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch } from '@ngxs/store/operators';
import { lastValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AbstractApiState } from '../abstract/abstract-api-state';
import { IPagination } from '../constans';
import { IUser } from '../users/users.interface';
import {
	AddPaginationForEmployees,
	CreateTimelineAssign,
	DeleteTimelineAssign,
	GetAllTimelinesAssign,
	GetAllTimelinesAssignIfEmpty,
	GetFilteredTimelinesAssign,
	GetUserTimelines,
	LoadAllEmployees,
	LoadAllEmployeesIfEmpty,
	ResendAssignment,
} from './timeline-assign.action';
import {
	IEmployeeFilter,
	ITimelineAssign,
	ITimelineAssignDTO,
	ITimelineAssignEdit,
	ITimelineAssignEditDTO,
} from './timeline-assign.interface';
import { TimelinesAssignService } from './timeline-assign.service';

export interface TimelineAssignStateModel {
	items: ITimelineAssign[];
	employees: IUser[];
	pagination: IPagination;
	filterValue: IEmployeeFilter;
	userTimelines: ITimelineAssign[];
}

@State<TimelineAssignStateModel>({
	name: 'timelineAssign',
	defaults: {
		items: null,
		employees: [],
		pagination: null,
		filterValue: null,
		userTimelines: null,
	},
})
@Injectable()
export class TimelineAssignState extends AbstractApiState<
	ITimelineAssign,
	ITimelineAssignDTO,
	ITimelineAssignEdit,
	ITimelineAssignEditDTO
> {
	constructor(private timelineAssignService: TimelinesAssignService, store: Store) {
		super(timelineAssignService, store);
	}

	@Selector()
	public static getState(state: TimelineAssignStateModel) {
		return state;
	}

	@Selector()
	public static items(state: TimelineAssignStateModel) {
		return state.items;
	}

	@Selector()
	public static userTimelines(state: TimelineAssignStateModel) {
		return state.userTimelines;
	}

	@Selector()
	public static getEmployees(state: TimelineAssignStateModel) {
		return state.filterValue === null
			? state.employees
			: state.employees.filter(employee => {
					return (
						employee.first_name === state.filterValue.first_name ||
						employee.last_name === state.filterValue.last_name ||
						employee.email === state.filterValue.email ||
						employee.phone_number === state.filterValue.phone ||
						employee.user_role === state.filterValue.user_role ||
						employee.code === state.filterValue.user_status ||
						employee.department_id === state.filterValue.department
					);
			  });
	}

	@Selector()
	public static getPagination(state: TimelineAssignStateModel) {
		return state.pagination;
	}

	// TODO Change this state to appropriately call by user, and then store in state.
	// If this is current user, perhaps we should have a separate state than 'items' for that
	// TODO Consider its relation to "GetUserTimelines" and how we can ensure the two has no overlap
	@Action(GetAllTimelinesAssign)
	public get({ patchState }: StateContext<TimelineAssignStateModel>) {
		return this.timelineAssignService.getByUser().pipe(
			tap(items => {
				return patchState({
					items,
				});
			})
		);
	}

	// TODO Fix this getter so it doesn't need to ping the API every single call, it should be able to get the data from the state
	// This is a special case, because it takes a userId as input, so it may need a state per user or some in-memory management of different users (that probably costs a lot of RAM/CPU)
	@Action(GetUserTimelines)
	public getUserTimelines({ patchState }: StateContext<TimelineAssignStateModel>, data) {
		return this.timelineAssignService.getUserTimelines(data.payload.userId).pipe(
			tap(items => {
				return patchState({
					userTimelines: items,
				});
			})
		);
	}

	@Action(GetAllTimelinesAssignIfEmpty)
	public getIfEmpty({ dispatch, getState }: StateContext<TimelineAssignStateModel>) {
		const timelines = getState().items;

		if (timelines) {
			return;
		}

		return lastValueFrom(dispatch(GetAllTimelinesAssign));
	}

	@Action(ResendAssignment)
	public resendAssignment(
		{ patchState }: StateContext<TimelineAssignStateModel>,
		data: ResendAssignment
	) {
		return this.timelineAssignService.resendAssignment(data.payload);
	}

	@Action(DeleteTimelineAssign)
	public deleteTimelineAssign(
		ctx: StateContext<TimelineAssignStateModel>,
		data: DeleteTimelineAssign
	) {
		return this.timelineAssignService.deleteTimelineAssign(data.payload);
	}

	@Action(GetFilteredTimelinesAssign)
	protected loadFilteredTimelines(
		ctx: StateContext<{ items: ITimelineAssign[] }>,
		data: GetFilteredTimelinesAssign
	) {
		const { patchState } = ctx;

		return this.timelineAssignService
			.getFilteredTimelinesAssign(data.payload.id, data.payload.query)
			.pipe(
				tap(items => {
					patchState({
						items,
					});
				})
			);
	}

	// TODO Add input from action to the service
	@Action(CreateTimelineAssign)
	public createTimelineAssign(
		ctx: StateContext<TimelineAssignStateModel>,
		{ payload }: CreateTimelineAssign
	) {
		const data = this.timelineAssignService.create(payload);
		// TODO Add the following to give admin users the ability to see the new timeline immediately (but verify that the correct state is being patched and it works)
		// * Its possible to add something like the following to give admin users the ability to see the new timeline immediately
		// However, in its current state it doesnt fix the issue
		// .pipe(
		// 	tap(items => {
		// 		ctx.setState(
		// 			patch({
		// 				items,
		// 			})
		// 		);
		// 	})
		// );

		return data;
	}

	@Action(LoadAllEmployees)
	public loadAllEmployees(ctx: StateContext<TimelineAssignStateModel>, data: LoadAllEmployees) {
		return this.timelineAssignService.getEmployees(data.payload.params).pipe(
			tap(items => {
				ctx.setState(
					patch({
						employees: append(items),
					})
				);
			})
		);
	}

	@Action(LoadAllEmployeesIfEmpty)
	public loadAllEmployeesIfEmpty(
		ctx: StateContext<TimelineAssignStateModel>,
		data: LoadAllEmployeesIfEmpty
	) {
		return this.timelineAssignService.getEmployees(data.payload.params).pipe(
			tap(items => {
				ctx.setState(
					patch({
						employees: items,
					})
				);
			})
		);
	}

	@Action(AddPaginationForEmployees)
	public addPaginationForEmployees(
		ctx: StateContext<TimelineAssignStateModel>,
		data: AddPaginationForEmployees
	) {
		ctx.setState(
			patch<TimelineAssignStateModel>({
				pagination: data.payload,
			})
		);
	}
}
