import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag, CdkDragPlaceholder, CdkDragHandle } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, ContentChild, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { CheckboxListLabelDirective } from './checkbox-list-label.directive';
import { IconComponent } from '../../../icon/components/icon/icon.component';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { NgClass, NgFor, NgTemplateOutlet, NgIf } from '@angular/common';

@Component({
    selector: 'itd-checkbox-list',
    templateUrl: './checkbox-list.component.html',
    styleUrls: ['./checkbox-list.component.scss'],
    standalone: true,
    imports: [
        CdkDropList,
        NgClass,
        NgFor,
        CdkDrag,
        CdkDragPlaceholder,
        CheckboxComponent,
        NgTemplateOutlet,
        NgIf,
        IconComponent,
        CdkDragHandle,
    ],
})
export class CheckboxListComponent<N> {
	@ContentChild(CheckboxListLabelDirective) public labelRef: CheckboxListLabelDirective;

	@Input() public data: N[];
	@Input() public formGroup: FormGroup;
	@Input() public controlName: string;
	@Input() public isSortable?: boolean = true;
	@Input() public searchProperty?: string;

	public _searchQuery: string = '';
	@Input() public set searchQuery(value: string) {
		this._searchQuery = value.toLowerCase();
	}

	public get formCheckboxes(): FormArray {
		return this.formGroup.get(this.controlName) as FormArray;
	}

	constructor(private cd: ChangeDetectorRef) {}

	ngOnInit() {
		this.formGroup.addControl(this.controlName, new FormArray([]));
	}

	public isItemSelected(item) {
		return item.id ? this.formGroup.value.items.findIndex(i => i.id === item.id) !== -1 : false;
	}

	public sort(event: CdkDragDrop<FormGroup>) {
		const dir = event.currentIndex > event.previousIndex ? 1 : -1;
		const from = event.previousIndex;
		const to = event.currentIndex;

		const temp = this.formCheckboxes.at(from);
		for (let i = from; i * dir < to * dir; i = i + dir) {
			const current = this.formCheckboxes.at(i + dir);
			this.formCheckboxes.setControl(i, current);
		}
		this.formCheckboxes.setControl(to, temp);

		moveItemInArray(this.data, event.previousIndex, event.currentIndex);

		this.cd.detectChanges();
	}

	public click(item, index: number, value: boolean) {
		if (value) {
			this.formCheckboxes.insert(index, new FormControl(this.data[index]));
		} else {
			const itemIndexInFormCheckboxes = this.formCheckboxes.value.findIndex(i => i.id === item.id);
			this.formCheckboxes.removeAt(itemIndexInFormCheckboxes);
		}
	}

	public isHidden(index: number): boolean {
		if (this._searchQuery === '') {
			return false;
		}

		const searchProperty = this.data[index][this.searchProperty].toLowerCase();

		return !new RegExp(this._searchQuery).test(searchProperty);
	}
}
