import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor } from '@angular/common';
import { ModalComponent } from '../../../utility-modules/modals/components/modal/modal.component';

@Component({
	selector: 'itd-choose-language-modal',
	templateUrl: './choose-language-modal.component.html',
	styleUrls: ['./choose-language-modal.component.scss'],
	standalone: true,
	imports: [ModalComponent, NgFor, TranslateModule],
})
export class ChooseLanguageModalComponent implements OnInit {
	@Output() public languageChoosen = new EventEmitter<string>();

	public languages: string[] = [
		'Chinese',
		'Danish',
		'English',
		'Finnish',
		'German',
		'Norwegian',
		'Portuguese',
		'Swedish',
	];

	constructor(public activeModal: NgbActiveModal) {}

	ngOnInit(): void {}

	public emitQuestionType(language: string): void {
		this.languageChoosen.emit(language);
		this.activeModal.close();
	}
}
