import { Pipe, PipeTransform } from '@angular/core';
import { IDepartment } from 'src/app/core/department/department.interface';
@Pipe({
    name: 'search',
    standalone: true
})
export class SearchPipe implements PipeTransform {
 /**
   * Pipe filters the list of elements based on the search text provided
   *
   * @param items list of elements to search in
   * @param searchText search string
   * @returns list of elements filtered by search text or []
   */
  transform(items: IDepartment[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter(it => {
      return it.title.toLocaleLowerCase().includes(searchText);
    });
  }
}