<form (ngSubmit)="onSubmit()" [formGroup]="form">
	<itd-modal icon="cube" [headerButton]="true">
		<ng-template #header>
			<h1>{{ 'CONTENT.MODULES.SELECT_IMAGE' | translate }}</h1>
			<itd-button styleType="secondary" setType="button" [width]="120" (click)="upload()">
				{{ 'UPLOAD' | translate }}
			</itd-button>
		</ng-template>

		<ng-template #content>
			<div class="scrollable" *ngIf="images$ | async as images">
				<div class="scrollable__inner">
					<div class="flex">
						<div class="" *ngFor="let image of images">
							<itd-input-custom-radio
								[data]="image.id"
								formControlName="selectedImage"
							>
								<ng-template itdInputCustomRadio let-isActive="isActive">
									<div class="asset" [ngClass]="{ 'is-active': isActive }">
										<div class="image-holder">
											<itd-image [filename]="image.url"> </itd-image>
										</div>
										<itd-icon
											*ngIf="isActive"
											[width]="16"
											[height]="16"
											icon="checkmark-circle"
											class="asset__icon"
										></itd-icon>
									</div>
								</ng-template>
							</itd-input-custom-radio>
						</div>
					</div>
				</div>
			</div>

			<itd-form-error [errorObservable]="errorMessage$"></itd-form-error>
		</ng-template>

		<ng-template #footer>
			<div>
				<itd-button [formState]="formState" [width]="120" setType="submit">
					{{ 'CONFIRM' | translate }}
				</itd-button>
			</div>
		</ng-template>
	</itd-modal>
</form>
