import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from 'src/environments/environment.interface';

@Injectable({ providedIn: 'root' })
export class MakeUrlUtil {
	constructor(@Inject('ENV') private env: IEnvironment) {}

	makeUrl(givenUrl: string): string {
		return [this.env.mediaUrl, givenUrl].filter(value => value).join('/');
	}
}
