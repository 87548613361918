import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { SwiperModule } from 'swiper/angular';
import { AdminContentAchievementPreviewComponent } from '../modules/admin/modules/admin-content/components/admin-content-achievement-preview/admin-content-achievement-preview.component';
import { InviteModalComponent } from '../modules/admin/modules/admin-employees/modals/invite-modal/invite-modal.component';
import { AdminDepartmentsPageComponent } from '../modules/admin/pages/admin-departments-page/admin-departments-page.component';
import { DepartmentSectionComponent } from '../modules/admin/pages/admin-departments-page/components/department-section/department-section.component';
import { SearchPipe } from '../modules/admin/pages/admin-departments-page/pipes/search.pipe';
import { GraphsModule } from '../utility-modules/graphs/graphs.module';
import { IconModule } from '../utility-modules/icon/icon.module';
import { NotificationModule } from '../utility-modules/notification/notification.module';
import { MatSideBarModule } from '../utility-modules/sidebar/matsidebar.module';
import { ModuleUsedInTimelinesComponent } from '../utility-modules/sidebar/module-used-in-timelines/module-used-in-timelines.component';
import { TableModule } from '../utility-modules/table/table.module';
import { CalendarModule } from './../utility-modules/calendar/calendar.module';
import { GridModule } from './../utility-modules/grid/grid.module';
import { ItdFormModule } from './../utility-modules/itd-form/itd-form.module';
import { ModalsModule } from './../utility-modules/modals/modals.module';
import { AccordionItemComponent } from './components/accordion-item/accordion-item.component';
import { ButtonBackComponent } from './components/button-back/button-back.component';
import { CreatePanelComponent } from './components/create-panel/create-panel.component';
import { CustomDataSourceDropdownComponent } from './components/custom-data-source-dropdown/custom-data-source-dropdown.component';
import { CustomDataSourceInputComponent } from './components/custom-data-source-search-input/custom-data-source-search-input.component';
import { DotStatusComponent } from './components/dot-status/dot-status.component';
import { EmptyContentComponent } from './components/empty-content/empty-content.component';
import { FiltersComponent } from './components/filters/filters.component';
import { FoldersComponent } from './components/folders/folders.component';
import { FooterNavigationMobileComponent } from './components/footer-navigation-mobile/footer-navigation-mobile.component';
import { FooterNavigationSideDrawerMobileComponent } from './components/footer-navigation-side-drawer-mobile/footer-navigation-side-drawer-mobile.component';
import { HeaderMobileComponent } from './components/header-mobile/header-mobile.component';
import { HeaderProfileSideDrawerMobileComponent } from './components/header-mobile/header-profile-side-drawer-mobile/header-profile-side-drawer-mobile.component';
import { ImageComponent } from './components/image/image.component';
import { ModuleItemImageComponent } from './components/image/module-item-image/module-item-image.component';
import { MenuItemComponent } from './components/menu/menu-item/menu-item.component';
import { MenuParentComponent } from './components/menu/menu-parent/menu-parent.component';
import { MenuComponent } from './components/menu/menu.component';
import { NavigationItemComponent } from './components/navigation-item/navigation-item.component';
import { OrganizationLogoComponent } from './components/organization-logo/organization-logo.component';
import { PageSelectComponent } from './components/page-select/page-select.component';
import { PanelComponent } from './components/panel/panel.component';
import { PasswordStrengthComponent } from './components/password-strength/password-strength.component';
import { PdfReaderComponent } from './components/pdf-reader/pdf-reader.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { SideDrawerComponent } from './components/side-drawer/side-drawer.component';
import { HeadwayWidgetBadgeComponent } from './components/sidebar/headway-widget/headway-widget-badge/headway-widget-badge.component';
import { HeadwayWidgetTriggerComponent } from './components/sidebar/headway-widget/headway-widget-trigger/headway-widget-trigger.component';
import { SidebarModuleSwitcherComponent } from './components/sidebar/sidebar-module-switcher/sidebar-module-switcher.component';
import { SidebarProfileComponent } from './components/sidebar/sidebar-profile/sidebar-profile.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TabItemDirective } from './components/tabs/tab-item.directive';
import { TabsComponent } from './components/tabs/tabs.component';
import { TaskImageComponent } from './components/task-image/task-image.component';
import { TextChoiceComponent } from './components/text-choice/text-choice.component';
import { TimelineAssignCardComponent } from './components/timeline-assign-card/timeline-assign-card.component';
import { TimelineRadioItemDirective } from './components/timeline-radio/timeline-radio-item.directive';
import { TimelineRadioComponent } from './components/timeline-radio/timeline-radio.component';
import { TimelineSurveyResultsComponent } from './components/timeline-survey-results/timeline-survey-results.component';
import { TimelineSurveyComponent } from './components/timeline-survey/timeline-survey.component';
import { TruncateComponent } from './components/truncate/truncate.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { UserSideDrawerComponent } from './components/user-sidedrawer/user-sidedrawer.component';
import { UserTasksImageComponent } from './components/user-tasks-image/user-tasks-image.component';
import { VideoComponent } from './components/video/video.component';
import { YoutubeComponent } from './components/youtube/youtube.component';
import { CopyToClipboardDirective } from './directives/copyToClipboard.directive';
import { TooltipDirective } from './directives/tooltip.directive';
import { markedOptionsFactory } from './markdown-options-factory';
import { AchievementPreviewModalComponent } from './modals/achievement-preview-modal/achievement-preview-modal.component';
import { AddModulesModalComponent } from './modals/add-modules-modal/add-modules-modal.component';
import { AddSurveysModalComponent } from './modals/add-surveys-modal/add-surveys-modal.component';
import { AddTimelinesModalComponent } from './modals/add-timelines-modal/add-timelines-modal.component';
import { AreYouSureModalComponent } from './modals/are-you-sure-modal/are-you-sure-modal.component';
import { CantdoTaskAssignmentModalComponent } from './modals/cantdo-task-assignment-modal/cantdo-task-assignment-modal.component';
import { ChooseLanguageModalComponent } from './modals/choose-language-modal/choose-language-modal.component';
import { ChooseQuestionTypeComponent } from './modals/choose-question-type/choose-question-type.component';
import { ConfirmDeleteModalComponent } from './modals/confirm-delete-modal/confirm-delete-modal.component';
import { ConfirmDuplicateModalComponent } from './modals/confirm-duplicate-modal/confirm-duplicate-modal.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { ConfirmResendInviteModalComponent } from './modals/confirm-resend-invite-modal/confirm-resend-invite-modal.component';
import { ConfirmResendTimelineModalComponent } from './modals/confirm-resend-timeline-modal/confirm-resend-timeline-modal.component';
import { CreateTaskModalComponent } from './modals/create-task-modal/create-task-modal.component';
import { CropImageModalComponent } from './modals/crop-image-modal/crop-image-modal.component';
import { EditDepartmentModalComponent } from './modals/edit-department-modal/edit-department-modal.component';
import { EditTaskModalComponent } from './modals/edit-task-modal/edit-task-modal.component';
import { EditUserModalComponent } from './modals/edit-user-modal/edit-user-modal.component';
import { LogoutModalComponent } from './modals/logout-modal/logout-modal.component';
import { PreviewModalComponent } from './modals/preview-modal/preview-modal.component';
import { SelectUserModalComponent } from './modals/select-user-modal/select-user-modal.component';
import { SimpleModalComponent } from './modals/simple-modal/simple-modal.component';
import { SurveyResultsModal } from './modals/survey-results-modal/survey-results-modal.component';
import { TasklistModalComponent } from './modals/tasklist-modal/tasklist-modal.component';
import { EditPageComponent } from './pages/edit-page/edit-page.component';
import { HelpCenterPageComponent } from './pages/help-center-page/help-center-page.component';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { ProfileSettingsAlertsComponent } from './pages/profile-settings-page/components/profile-settings-alerts/profile-settings-alerts.component';
import { ProfileSettingsChangePasswordComponent } from './pages/profile-settings-page/components/profile-settings-change-password/profile-settings-change-password.component';
import { ProfileSettingsEditAvatar } from './pages/profile-settings-page/components/profile-settings-edit-avatar/profile-settings-edit-avatar.component';
import { ProfileSettingsEditFormComponent } from './pages/profile-settings-page/components/profile-settings-edit-form/profile-settings-edit-form.component';
import { ProfileSettingsOpenApiComponent } from './pages/profile-settings-page/components/profile-settings-open-api/profile-settings-open-api.component';
import { ProfileSettingsPageComponent } from './pages/profile-settings-page/profile-settings-page.component';
import { FormatFileSizePipe } from './pipes/format-file-size.pipe';
import { HighlightLinksPipe } from './pipes/highlight-links.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { CreateNewAddingTemplateComponent } from './templates/create-new-adding-template/create-new-adding-template.component';
import { EditTemplateComponent } from './templates/edit-template/edit-template.component';
import { MainTemplateComponent } from './templates/main-template/main-template.component';
import { ResultTemplateComponent } from './templates/result-template/result-template.component';
import { StatsTemplateComponent } from './templates/stats-template/stats-template.component';

const declarations = [
	CreatePanelComponent,
	TextChoiceComponent,
	CustomDataSourceDropdownComponent,
	ChooseQuestionTypeComponent,
	ChooseLanguageModalComponent,
	MainPageComponent,
	SidebarComponent,
	SidebarProfileComponent,
	ImageComponent,
	YoutubeComponent,
	VideoComponent,
	ModuleItemImageComponent,
	TooltipDirective,
	CopyToClipboardDirective,
	EditPageComponent,
	HelpCenterPageComponent,
	HeaderMobileComponent,
	ProfileSettingsPageComponent,
	ProfileSettingsEditAvatar,
	ProfileSettingsEditFormComponent,
	ProfileSettingsAlertsComponent,
	ProfileSettingsOpenApiComponent,
	ProfileSettingsChangePasswordComponent,
	LogoutModalComponent,
	SimpleModalComponent,
	SidebarModuleSwitcherComponent,
	HeadwayWidgetBadgeComponent,
	HeadwayWidgetTriggerComponent,
	FooterNavigationMobileComponent,
	SideDrawerComponent,
	FooterNavigationSideDrawerMobileComponent,
	NavigationItemComponent,
	OrganizationLogoComponent,
	HeaderProfileSideDrawerMobileComponent,
	MainTemplateComponent,
	MenuComponent,
	ModuleUsedInTimelinesComponent,
	FoldersComponent,
	MenuItemComponent,
	MenuParentComponent,
	ConfirmDeleteModalComponent,
	ConfirmModalComponent,
	AreYouSureModalComponent,
	ProgressBarComponent,
	ButtonBackComponent,
	EmptyContentComponent,
	PdfReaderComponent,
	TimelineRadioComponent,
	TimelineRadioItemDirective,
	PanelComponent,
	TabsComponent,
	TabItemDirective,
	AccordionItemComponent,
	PageSelectComponent,
	DotStatusComponent,
	EditTemplateComponent,
	CreateNewAddingTemplateComponent,
	TruncateComponent,
	CustomDataSourceInputComponent,
	AddModulesModalComponent,
	AddTimelinesModalComponent,
	SelectUserModalComponent,
	AddSurveysModalComponent,
	ConfirmDuplicateModalComponent,
	InviteModalComponent,
	ResultTemplateComponent,
	ConfirmResendTimelineModalComponent,
	ConfirmResendInviteModalComponent,
	StatsTemplateComponent,
	FiltersComponent,
	UserSideDrawerComponent,
	EditDepartmentModalComponent,
	TimelineAssignCardComponent,
	EditUserModalComponent,
	AdminDepartmentsPageComponent,
	DepartmentSectionComponent,
	SearchPipe,
	SurveyResultsModal,
	UserAvatarComponent,
	PreviewModalComponent,
	AchievementPreviewModalComponent,
	AdminContentAchievementPreviewComponent,
	TimelineSurveyComponent,
	TimelineSurveyResultsComponent,
	TaskImageComponent,
	UserTasksImageComponent,
	CreateTaskModalComponent,
	TasklistModalComponent,
	EditTaskModalComponent,
	CantdoTaskAssignmentModalComponent,
	HighlightLinksPipe,
	TruncatePipe,
	FormatFileSizePipe,
	CropImageModalComponent,
	PasswordStrengthComponent,
];

const modules = [
	RoundProgressModule,
	SwiperModule,
	PdfJsViewerModule,
	CommonModule,
	TranslateModule,
	FormsModule,
	ReactiveFormsModule,
	IconModule,
	ItdFormModule,
	GridModule,
	ModalsModule,
	TableModule,
	CalendarModule,
	MatSideBarModule,
	ImageCropperModule,
	ClipboardModule,
];

@NgModule({
	imports: [
		...modules,
		GraphsModule,
		RouterModule,
		HttpClientModule,
		NotificationModule,
		NgSelectModule,
		MatTabsModule,
		MatIconModule,
		MatSliderModule,
		MatSidenavModule,
		MatSlideToggleModule,
		InfiniteScrollModule,
		NgCircleProgressModule.forRoot({
			radius: 19,
			outerStrokeWidth: 3,
			innerStrokeWidth: 0,
			titleFontSize: '12',
			unitsFontSize: '9',
			showSubtitle: false,
			animation: true,
			animationDuration: 800,
			clockwise: false,
		}),
		MarkdownModule.forRoot({
			markedOptions: {
				provide: MarkedOptions,
				useFactory: markedOptionsFactory,
			},
		}),
		VgCoreModule,
		VgControlsModule,
		VgOverlayPlayModule,
		VgBufferingModule,
		...declarations,
	],
	exports: [
		...modules,
		...declarations,
		MarkdownModule,
		MatSidenavModule,
		MatSlideToggleModule,
		MatTabsModule,
		MatIconModule,
	],
})
export class SharedModule {}
