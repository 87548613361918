import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { firstValueFrom, Observable } from 'rxjs';
import { IProfile } from 'src/app/core/profile/profile.interface';
import { SetProfileAvatar } from 'src/app/core/profile/profile.actions';
import { ProfileState } from 'src/app/core/profile/profile.state';
import { NotificationService } from 'src/app/utility-modules/notification/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { IconComponent } from '../../../../../utility-modules/icon/components/icon/icon.component';
import { ImageComponent } from '../../../../components/image/image.component';
import { LoadingDirective } from '../../../../directives/loading.directive';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';

@Component({
    selector: 'itd-profile-settings-edit-avatar',
    templateUrl: './profile-settings-edit-avatar.component.html',
    styleUrls: ['./profile-settings-edit-avatar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        LoadingDirective,
        NgClass,
        ImageComponent,
        IconComponent,
        AsyncPipe,
    ],
})
export class ProfileSettingsEditAvatar {
	@Select(ProfileState.profile) public profile$: Observable<IProfile>;

	public isLoading: boolean = false;

	constructor(
		private store: Store,
		private notification: NotificationService,
		private cd: ChangeDetectorRef,
		private translate: TranslateService
	) {}

	public async onFileChange(e: Event) {
		const target = e.target as HTMLInputElement;
		const avatar = target.files[0];

		if (!avatar) {
			return;
		}

		if (avatar.size > 512 * 1000) {
			this.notification.add({
				text: await firstValueFrom(
					this.translate.get('SETTINGS.AVATAR_MAX_FILE_SIZE')
				),
				type: 'error',
			});

			target.value = null;
			return;
		}

		this.isLoading = true;
		await firstValueFrom(this.store.dispatch(new SetProfileAvatar({ avatar })));
		this.isLoading = false;

		this.cd.detectChanges();
	}
}
