<form (ngSubmit)="onSubmit()" [formGroup]="form">
	<itd-modal [size]="800" icon="plus">
		<ng-template #header>
			{{ 'TASKS.CREATE' | translate }}
		</ng-template>

		<ng-template #content>
			<itd-grid>
				<itd-grid-item size="100%">
					<itd-input
						class="input"
						name="title"
						type="text"
						formControlName="title"
						placeholder="{{ 'TASKS.TITLE_PLACEHOLDER' | translate }}"
						label="{{ 'TASKS.TITLE_LABEL' | translate }}"
						[hasBorder]="true"
						[isRequired]="true"
					></itd-input>
				</itd-grid-item>
			</itd-grid>

			<itd-grid>
				<itd-grid-item size="100%">
					<label class="label">{{ 'TASKS.DESCRIPTION' | translate }}</label>

					<textarea
						placeholder="{{ 'TASKS.DESCRIPTION' | translate }}"
						formControlName="description"
						name="description"
						class="input-text-area input"
					></textarea>
				</itd-grid-item>
			</itd-grid>

			<itd-grid>
				<itd-grid-item class="color" size="100%">
					<label class="label">{{ 'COLOR' | translate }}</label>

					<itd-input-color-picker [(color)]="color">
						<span>{{ color }}</span>
					</itd-input-color-picker>
				</itd-grid-item>
			</itd-grid>

			<itd-grid>
				<itd-grid-item>
					<label class="label">{{ 'ICON' | translate }}*</label>

					<div class="icon-sec">
						<div
							*ngFor="let icon of icons"
							(click)="onSelectIcon(icon)"
							class="icon-list"
							[ngClass]="{ active: icon === selectedIcon }"
						>
							<itd-icon
								[icon]="icon"
								[type]="'task'"
								[width]="50"
								[height]="50"
								[fill]="color"
							></itd-icon>
						</div>
					</div>
				</itd-grid-item>
			</itd-grid>

			<itd-grid>
				<itd-grid-item size="100%">
					<itd-input
						*ngIf="!assignmentActive"
						class="input"
						name="days_prior"
						type="positiveNegativeNumbers"
						formControlName="days_prior"
						placeholder="{{ 'TASKS.DAYS_PRIOR' | translate }}"
						label="{{ 'DEFAULT' | translate }} {{ 'DUE_DATE' | translate | lowercase }}"
						[hasBorder]="true"
						[itdTooltip]="'TASKS.DAYS_PRIOR_TOOLTIP' | translate"
					>
					</itd-input>

					<itd-input-date
						*ngIf="assignmentActive"
						[label]="'DUE_DATE' | translate"
						[hasShadow]="true"
						[hasBorder]="true"
						[startDate]="startDate"
						[minDate]="now"
						[itdTooltip]="'TASKS.DUE_DATE_TOOLTIP' | translate"
						name="due_date"
						formControlName="due_date"
						(write)="changeStartDate($event)"
					></itd-input-date>
				</itd-grid-item>
			</itd-grid>

			<itd-grid>
				<itd-grid-item size="100%">
					<label class="label" *ngIf="!assignmentActive">
						{{ 'DEFAULT' | translate }}
						{{ 'REMINDERS' | translate | lowercase }}
					</label>
					<label class="label" *ngIf="assignmentActive">
						{{ 'REMINDERS' | translate }}
					</label>
				</itd-grid-item>
				<itd-grid-item
					size="25%"
					*ngFor="let reminder of form.get('reminders')['controls']; let i = index"
				>
					<itd-input
						class="input"
						name="reminder_{{ i }}"
						type="positiveNegativeNumbers"
						[formControlName]="'reminder_' + i"
						placeholder="{{ 'TASKS.REMINDER_DAYS_PRIOR' | translate }}"
						[hasBorder]="true"
						[hasDelete]="true"
						(onDelete)="removeReminder(i)"
					>
					</itd-input>
				</itd-grid-item>
				<itd-grid-item *ngIf="form.get('reminders')['controls'].length < 8">
					<button class="add-button" type="button" (click)="addReminder()">
						<itd-icon icon="plus" [width]="24" [height]="24"></itd-icon>
					</button>
				</itd-grid-item>
			</itd-grid>

			<itd-grid>
				<itd-grid-item size="100%">
					<itd-input-user
					[label]="
							assignmentActive ? 'TASKS.ASSIGNEE_LABEL' : 'TASKS.DEFAULT_RESPONSIBLE_LABEL'
						"
					placeholder="CHOOSE"
					modalTitle="CHOOSE"
					formControlName="default_assignee_id"
					[hasBorder]="true"
					[hasShadow]="true"
				/>
				</itd-grid-item>
			</itd-grid>

			<div class="py-4"></div>

			<itd-form-error [errorObservable]="errorMessage$"></itd-form-error>
		</ng-template>

		<ng-template #footer>
			<itd-button [formState]="formState" [width]="120">
				{{ 'CONFIRM' | translate }}
			</itd-button>
		</ng-template>
	</itd-modal>
</form>
