import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ChartModule } from 'angular-highcharts';
import { SeriesOptionsType } from 'highcharts';
import { AbstractGraphComponent } from '../../abstract/abstract-graph/abstract-graph.component';

export type TFlexibleGraphData = {
	type: any; // TODO: Fix the typing to come from highcharts 'series.type' typing
	data: any; // TODO Fix this, can be object or array of objects
	color?: string;
	label?: any;
	name?: any;
}[];

@Component({
	selector: 'itd-graph-flexible',
	templateUrl: './graph-flexible.component.html',
	styleUrls: ['./graph-flexible.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ChartModule],
})
export class GraphFlexibleComponent extends AbstractGraphComponent implements OnInit {
	@Input() public data: TFlexibleGraphData; // TODO see if there is a built-in type for this
	@Input() public height: number;
	// TODO Make required in angular 16
	// TODO Fix the 'type' typing
	// @Input() public type: Highcharts.Options['chart']['type']
	@Input() public type: any;
	@Input() public showLegend: boolean = false;
	@Input() public showHoverTooltip: boolean = true;
	@Input() public yAxis: Highcharts.Options['yAxis'];
	@Input() public xAxis: Highcharts.Options['xAxis'];

	constructor() {
		super();
	}

	protected getChartOptions(): Highcharts.Options {
		let series: SeriesOptionsType[] = this.data.map(item => {
			return {
				type: item.type,
				data: item.data,
				color: item.color,
				label: item?.label,
				name: item?.label,
			};
		});

		const baseConfig: Highcharts.Options = {
			chart: {
				type: this.type,
				plotBackgroundColor: null,
				plotBorderWidth: 0,
				plotShadow: false,
				animation: false,
				height: this.height ? `${this.height}px` : '200px',
				width: null,
			},
			yAxis: {
				...this.yAxis,
			},
			xAxis: {
				...this.xAxis,
			},
			credits: { enabled: false },
			tooltip: {
				enabled: this.showHoverTooltip,
			},
			title: null,
			exporting: {
				enabled: false,
				chartOptions: {
					title: {
						text: this.title,
					},
				},
			},
			legend: {
				enabled: this.showLegend,
				align: 'left',
				verticalAlign: 'top',
			},
			series,
			plotOptions: {
				series: {
					label: {
						connectorAllowed: false,
					},
					marker: {
						enabled: false,
						states: {
							hover: {
								enabled: false,
							},
						},
					},
				},
			},
			drilldown: {
				animation: {
					duration: 0,
				},
			},
		};

		return baseConfig;
	}

	// TODO Create helper method to set low/high for Y axis based on all arrays of incoming data
}
