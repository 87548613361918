import { IPagination } from '../constans';
import { ISuperAdminUserInvite } from './superadmin-users.interface';

export class LoadAllSuperAdminUsers {
	public static readonly type = '[SuperAdmin User] Load All';
	constructor(public params: { params: { skip: number; limit: number; all: boolean } }) {}
}

export class LoadSuperAdminUsers {
	public static readonly type = '[SuperAdmin User] Load Users';
	constructor(public params: { params: { skip: number; limit: number; all: boolean } }) {}
}

export class LoadAllSuperAdminUsersIfEmpty {
	public static readonly type = '[SuperAdmin User] Load All If Empty';
	constructor(public params: { params: { skip: number; limit: number; all: boolean } }) {}
}

export class DeleteSuperAdminUser {
	public static readonly type = '[SuperAdmin User] Delete';
	constructor(public payload: { id: string }) {}
}
export class AddPagination {
	public static readonly type = '[SuperAdmin User] Add Pagination';
	constructor(public payload?: { pagination: IPagination }) {}
}

export class InviteUser {
	public static readonly type = '[SuperAdmin User] Invite';
	constructor(public payload?: { data: ISuperAdminUserInvite }) {}
}
