import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
} from '@angular/core';
import { CustomDataSource } from 'src/app/core/data-source/data-source';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { SelectComponent } from '../../../utility-modules/itd-form/components/select/select.component';
import { NgStyle } from '@angular/common';

@Component({
    selector: 'itd-custom-data-source-dropdown',
    templateUrl: './custom-data-source-dropdown.component.html',
    styleUrls: ['./custom-data-source-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgStyle, SelectComponent],
})
export class CustomDataSourceDropdownComponent<T> implements OnDestroy {
	private dataSourceSubscription: Subscription;

	@Input() public hasBorder: boolean = false;
	@Input() public hasShadow: boolean = false;
	@Input() public placeholder?: string;
	@Input() public maxWidth: number;
	@Input() public width: number;
	@Input() public dropdownData: Observable<any[]>;
	@Input() public outputValueIndex?: string = 'value';
	@Input() public isDepartmentDropdown: boolean = true;
	@Input() public multiple: boolean = false;

	@Output() departmentId = new EventEmitter<string>();
	// @Output() userId = new EventEmitter<string>();

	@Input() public set dataSource(value: CustomDataSource<T>) {
		if (this.source !== value) {
			this.source = value;
			this.connect();
		}
	}

	public get dataSource(): CustomDataSource<T> {
		return this.source;
	}

	public value: string;
	private source: CustomDataSource<T>;

	constructor(private cd: ChangeDetectorRef) {}

	public ngOnDestroy(): void {
		if (this.dataSourceSubscription) {
			this.dataSourceSubscription.unsubscribe();
		}
	}

	public connect(): void {
		if (this.dataSourceSubscription) {
			this.dataSourceSubscription.unsubscribe();
		}

		if (!this.dataSource) {
			return;
		}

		this.dataSourceSubscription = this.dataSource.search$.subscribe((v: any) => {
			if (v !== this.value) {
				if (typeof v === 'string') {
					this.value = v;
				} else {
					this.value = v.value;
				}

				this.source.search(this.value);
				this.cd.detectChanges();
			}
		});
	}

	public valueChange(val: string | string[]): void {
		if (this.multiple) {
			this.value = (val as string[]).join(', ');
		} else {
			this.value = val as string;
		}

		if (!this.source) {
			this.departmentId.emit(this.value);
			return;
		}

		this.source.search(this.value);
		this.departmentId.emit(this.value);
		this.cd.detectChanges();
	}

	// public userValueChange(val: any): void {
	// 	this.value = val;
	// 	if (!this.source) {
	// 		this.userId.emit(this.value);
	// 		return;
	// 	}
	// 	this.userId.emit(this.value);
	// 	this.cd.detectChanges();
	// }
}
