<div class="page">
	<div class="content wrap">
		<h1 class="title">
			{{ 'PASSWORD_RESET' | translate }}
		</h1>

		<div class="content">
			<form (ngSubmit)="onSubmit()" [formGroup]="form">
				<itd-input
					class="input"
					type="password"
					formControlName="password"
					placeholder="PASSWORD_NEW"
					[focusAfterInit]="true"
				></itd-input>
				<itd-password-strength [passwordToCheck]="form.value.password"></itd-password-strength>
				<itd-input
					class="input"
					name="confirm_password"
					type="password"
					formControlName="confirm_password"
					placeholder="{{ 'SETTINGS.CHANGE_PASSWORD.PASSWORD_CONFIRMATION' | translate }}"
				></itd-input>

				<div class="form-footer">
					<itd-button [formState]="formState" sizeType="big" [isBlock]="true">
						{{ 'CONFIRM' | translate }}
					</itd-button>

					<itd-form-error [errorObservable]="errorMessage$"></itd-form-error>
				</div>
			</form>
		</div>

		<div class="footer">
			<a [routerLink]="['./../']" class="link link--color-primary link--size-tiny">
				{{ 'BACK_TO_LOGIN' | translate }}
			</a>
		</div>
	</div>
</div>
