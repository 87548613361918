import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { AbstractGraphComponent } from '../../abstract/abstract-graph/abstract-graph.component';
import { ChartModule } from 'angular-highcharts';

export type TLineGraphData = {
	x: number;
	y: number;
	name?: string;
	color?: string;
}[];

@Component({
	selector: 'itd-graph-line',
	templateUrl: './graph-line.component.html',
	styleUrls: ['./graph-line.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ChartModule],
})
export class GraphLineComponent extends AbstractGraphComponent implements OnInit {
	@Input() public data: TLineGraphData;
	@Input() public labels: string[];
	@Input() public height: number;
	@Input() public includeColumn = false;
	colors: string[] = ['#1F3479', '#7E4A9D', '#D25899', '#FF5553'];

	constructor() {
		super();
	}

	protected getChartOptions(): Highcharts.Options {
		// Calculate the minimum y value, subtract 5, and ensure it's not less than 0
		const minYValue = Math.max(0, Math.min(...this.data.map(point => point.y)) - 5);

		const baseConfig: Highcharts.Options = {
			chart: {
				type: 'spline',
				plotBackgroundColor: null,
				plotBorderWidth: 0,
				plotShadow: false,
				animation: false,
				height: this.height ? `${this.height}px` : '200px',
				width: null,
			},
			yAxis: {
				min: minYValue,
				max: 100,
				title: null,
			},
			xAxis: {
				type: 'datetime',
				tickInterval: 30 * 24 * 3600000, // 30 days in milliseconds
			},
			credits: { enabled: false },
			tooltip: {
				headerFormat: '<b>{series.name}</b><br>',
				pointFormat: '{point.x:%b}: {point.y}',
			},
			title: null,
			exporting: {
				enabled: false,
				chartOptions: {
					title: {
						text: this.title,
					},
				},
			},
			legend: {
				align: 'left',
				verticalAlign: 'top',
			},
			series: [
				{
					type: 'spline',
					data: this.data,
				},
			],
			plotOptions: {
				series: {
					label: {
						connectorAllowed: false,
					},
					marker: {
						enabled: false,
						states: {
							hover: {
								enabled: false,
							},
						},
					},
				},
			},
			colors: this.colors,
			drilldown: {
				animation: {
					duration: 0,
				},
			},
		};

		if (this.includeColumn) {
			baseConfig.series.push({
				type: 'column',
				data: this.data,
				maxPointWidth: 30,
			});
		}

		return baseConfig;
	}
}
