import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnInit,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { LoadAllMediaIfEmpty } from 'src/app/core/media/media.action';
import { IMedia } from 'src/app/core/media/media.interface';
import { MediaState } from 'src/app/core/media/media.state';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { AbstractFormComponent } from 'src/app/utility-modules/itd-form/components/abstract-form/abstract-form.component';
import { NotificationService } from 'src/app/utility-modules/notification/services/notification.service';
import { AdminContentMediaUploadModalComponent } from '../admin-content-media-upload-modal/admin-content-media-upload-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormErrorComponent } from '../../../../../../utility-modules/itd-form/components/form-error/form-error.component';
import { IconComponent } from '../../../../../../utility-modules/icon/components/icon/icon.component';
import { ImageComponent } from '../../../../../../shared/components/image/image.component';
import { InputCustomRadioComponent, InputCustomRadioDirective } from '../../../../../../utility-modules/itd-form/components/input-custom-radio/input-custom-radio.component';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { ButtonComponent } from '../../../../../../utility-modules/itd-form/components/button/button.component';
import { ModalComponent } from '../../../../../../utility-modules/modals/components/modal/modal.component';

type FormValue = {
	selectedImage: string;
};

@Component({
    selector: 'itd-admin-content-module-select-image',
    templateUrl: './admin-content-module-select-image.component.html',
    styleUrls: ['./admin-content-module-select-image.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ReactiveFormsModule,
        ModalComponent,
        ButtonComponent,
        NgIf,
        NgFor,
        InputCustomRadioComponent,
        InputCustomRadioDirective,
        NgClass,
        ImageComponent,
        IconComponent,
        FormErrorComponent,
        AsyncPipe,
        TranslateModule,
    ],
})
export class AdminContentModuleSelectImageComponent extends AbstractFormComponent<FormValue>
	implements OnInit {
	@Input() public onSubmitAction: () => any;
	@Input() public aspectRatio: number;
	@Select(MediaState.getImages) public images$: Observable<IMedia[]>;

	constructor(
		public validationMessages: ValidationMessagesService,
		public elementRef: ElementRef,
		protected cd: ChangeDetectorRef,
		private activeModal: NgbActiveModal,
		private notification: NotificationService,
		private modals: NgbModal,
		private store: Store
	) {
		super(cd);
	}

	ngOnInit(): void {
		this.store.dispatch(LoadAllMediaIfEmpty);
		this.form = new FormGroup({
			selectedImage: new FormControl(''),
		});
	}

	public upload() {
		const modal = this.modals.open(AdminContentMediaUploadModalComponent);
		const component = modal.componentInstance as AdminContentMediaUploadModalComponent;
		modal.closed.subscribe(data => {
			if (data) {
				this.form.get('selectedImage').setValue(data.id);
				this.onSubmit();
			}
		});

		component.aspectRatio = this.aspectRatio;
		component.afterSubmitSuccess = () => {
			this.onSubmitAction();
		};
	}

	protected onSubmitSuccess() {
		this.notification.add({
			text: 'CONTENT.MODULES.IMAGE_UPDATED',
		});
		this.activeModal.close();
	}
}
