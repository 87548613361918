import { IMessageEdit } from './messages.interface';

export class LoadMessage {
	public static readonly type = '[Messages] Load';
	constructor(public payload: { id: string }) {}
}

export class LoadAllMessages {
	public static readonly type = '[Messages] Load All';
}

export class LoadAllMessagesIfEmpty {
	public static readonly type = '[Messages] Load All If Empty';
}

export class CreateMessage {
	public static readonly type = '[Messages] ICreate';
	constructor(public payload: { data: IMessageEdit }) {}
}

export class UpdateMessage {
	public static readonly type = '[Messages] Update';
	constructor(public payload: { id: string; data: IMessageEdit }) {}
}

export class DeleteMessage {
	public static readonly type = '[Messages] Delete';
	constructor(public payload: { id: string }) {}
}

export class DuplicateMessage {
	public static readonly type = '[Messages] Duplicate';
	constructor(public payload: { id: string }) {}
}
