import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ProfileState } from 'src/app/core/profile/profile.state';
import { Tabs } from 'src/app/modules/superadmin/pages/superadmin-edit-organization/superadmin-edit-organization-page.component';
import { ProfileSettingsChangePasswordComponent } from './components/profile-settings-change-password/profile-settings-change-password.component';
import { ProfileSettingsAlertsComponent } from './components/profile-settings-alerts/profile-settings-alerts.component';
import { ProfileSettingsEditFormComponent } from './components/profile-settings-edit-form/profile-settings-edit-form.component';
import { isUserAllowed } from 'src/app/core/utils/isUserAllowed.util';
import { IUserRole } from 'src/app/core/users/users.interface';
import { ProfileSettingsOpenApiComponent } from './components/profile-settings-open-api/profile-settings-open-api.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { NgFor, NgIf, NgComponentOutlet } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { ProfileSettingsEditAvatar } from './components/profile-settings-edit-avatar/profile-settings-edit-avatar.component';
import { EditPageComponent } from '../edit-page/edit-page.component';
import { MainTemplateComponent } from '../../templates/main-template/main-template.component';

@Component({
    selector: 'itd-profile-settings-page',
    templateUrl: './profile-settings-page.component.html',
    styleUrls: ['./profile-settings-page.component.scss'],
    standalone: true,
    imports: [
        MainTemplateComponent,
        EditPageComponent,
        ProfileSettingsEditAvatar,
        MatTabsModule,
        NgFor,
        NgIf,
        MatIconModule,
        NgComponentOutlet,
        TranslateModule,
    ],
})
export class ProfileSettingsPageComponent implements OnInit {
	public userRole: IUserRole;
	public tabs: Tabs[] = [
		{
			icon: 'person_outline',
			label: 'BASIC_INFORMATION',
			component: ProfileSettingsEditFormComponent,
		},
		{
			icon: 'lock_outline',
			label: 'PASSWORD',
			component: ProfileSettingsChangePasswordComponent,
		},
		{
			icon: 'notifications_none',
			label: 'ALERTS',
			component: ProfileSettingsAlertsComponent,
		},
		{
			icon: 'webhook',
			label: 'API',
			component: ProfileSettingsOpenApiComponent,
			roleRequirement: 'org_admin',
		},
	];
	public isUserAllowed = isUserAllowed; // expose method to html

	constructor(private store: Store) {}

	public ngOnInit(): void {
		this.userRole = this.store.selectSnapshot(ProfileState.profile).user_role;
	}
}
