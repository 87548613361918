import { fadeAnimation } from 'src/app/core/animations/fade.animation';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { NavigationEnd, Router, RouterLinkActive, RouterLink } from '@angular/router';
import { fromEvent } from 'rxjs';
import { WithDestroyComponent } from 'src/app/core/abstract/abstract-with-destroy-component';
import { checkOutsideClick } from 'src/app/core/utils/check-outside-click';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../../../utility-modules/icon/components/icon/icon.component';
import { NgClass, NgIf, NgFor } from '@angular/common';
// import { Store } from '@ngxs/store';
// import { SetAdminAnalyticsFrom, SetAdminAnalyticsTo } from 'src/app/modules/admin/modules/admin-analytics/store/admin-analytics.action';

export interface IPageSelectItem {
	title: string;
	url: string;
}

@Component({
    selector: 'itd-page-select',
    templateUrl: './page-select.component.html',
    styleUrls: ['./page-select.component.scss'],
    animations: [fadeAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        IconComponent,
        NgFor,
        RouterLinkActive,
        RouterLink,
        TranslateModule,
    ],
})
export class PageSelectComponent extends WithDestroyComponent implements OnInit, OnDestroy {
	@ViewChild('container', { static: true }) public container: ElementRef<HTMLElement>;

	@Input() public items: IPageSelectItem[] = [];

	public activeItem: IPageSelectItem;
	public isActive: boolean;

	constructor(private cd: ChangeDetectorRef, private router: Router) {
		super();
	}

	ngOnInit(): void {
		if (!this.items.length) {
			return;
		}
		this.activeItem = this.items.find((item) => this.router.url === item.url);
	}
	detectActiveItem() {
		this.router.events.subscribe((e) => {
			if (e instanceof NavigationEnd) {
				this.activeItem = this.items.find((item) => e.url === item.url);
			}
		});
	}
	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	public toggle() {
		this.isActive = !this.isActive;
		if (this.isActive) {
			this.eventSubscriptions.push(
				fromEvent(window, 'click').subscribe((e: MouseEvent) => {
					this.clickOutside(e);
				})
			);
		} else {
			// this.store.dispatch(new SetAdminAnalyticsFrom({ date: null }))
			// this.store.dispatch(new SetAdminAnalyticsTo({ date: null }))
			this.removeSubscriptions();
		}
		//this.cd.detectChanges();
		this.detectActiveItem();
	}

	private clickOutside(event: MouseEvent): void {
		const isClickedOutside = checkOutsideClick(this.container, event);
		if (!isClickedOutside) {
			return;
		}
		this.toggle();
		this.cd.detectChanges();
	}
}
