<form (ngSubmit)="onSubmit()" [formGroup]="form">
	<itd-modal icon="cube">
		<ng-template #header>
			{{
				isEdit
					? ('CONTENT.ACHIEVEMENTS.EDIT_HEADER' | translate)
					: ('CONTENT.ACHIEVEMENTS.CREATE_HEADER' | translate)
			}}
		</ng-template>

		<ng-template #content>
			<itd-input
				class="input"
				name="title"
				formControlName="title"
				placeholder="{{ 'CONTENT.ACHIEVEMENTS.TITLE_PLACEHOLDER' | translate }}"
				sizeType="small"
				[hasShadow]="false"
				[hasBorder]="true"
			></itd-input>
			<itd-input
				class="input"
				name="description"
				formControlName="description"
				placeholder="{{ 'CONTENT.ACHIEVEMENTS.DESCRIPTION_PLACEHOLDER' | translate }}"
				sizeType="small"
				[hasShadow]="false"
				[hasBorder]="true"
			></itd-input>

			<p class="label">
				{{ 'ICONS' | translate }}
			</p>

			<div class="scrollable" *ngIf="icons$ | async as icons">
				<div class="scrollable__inner">
					<div class="row row--assets">
						<div class="col-2" *ngFor="let icon of icons">
							<itd-input-custom-radio [data]="icon.id" formControlName="iconId">
								<ng-template itdInputCustomRadio let-isActive="isActive">
									<div class="asset" [ngClass]="{ 'is-active': isActive }">
										<img
											class="asset__image"
											src="/assets/icons/achievements-icons/{{ icon.filename }}.svg"
										/>

										<itd-icon
											@fadeAnimation
											*ngIf="isActive"
											[width]="16"
											[height]="16"
											icon="checkmark-circle"
											class="asset__icon"
										></itd-icon>
									</div>
								</ng-template>
							</itd-input-custom-radio>
						</div>
					</div>
				</div>
			</div>

			<p class="label">
				{{ 'COLORS' | translate }}
			</p>

			<div class="scrollable">
				<div class="scrollable__inner">
					<div class="row row--themes" *ngIf="themes$ | async as themes">
						<div class="col-2" *ngFor="let theme of themes">
							<itd-input-custom-radio [data]="theme.colors" formControlName="theme">
								<ng-template itdInputCustomRadio let-isActive="isActive">
									<div class="theme">
										<div
											class="theme__inner"
											style="background: linear-gradient({{ theme.colors.gradientStart }},{{
												theme.colors.gradientStop
											}});"
										></div>

										<itd-icon
											@fadeAnimation
											*ngIf="isActive"
											[width]="16"
											[height]="16"
											icon="checkmark-circle"
											class="theme__icon"
										></itd-icon>
									</div>
								</ng-template>
							</itd-input-custom-radio>
						</div>
					</div>
				</div>
			</div>

			<itd-form-error [errorObservable]="errorMessage$"></itd-form-error>
		</ng-template>

		<ng-template #footer>
			<itd-button [formState]="formState" [width]="120">
				{{ isEdit ? ('CONFIRM' | translate) : ('CONTENT.ACHIEVEMENTS.CREATE_BUTTON' | translate) }}
			</itd-button>
		</ng-template>
	</itd-modal>
</form>
