<div class="title">
	{{ 'SETTINGS.API_TOKEN' | translate }}
</div>

<div>
	<button
		type="button"
		[itdLoading]="isLoading"
		(click)="onClick()"
		class="btn--size-default button btn btn--color-third"
	>
		{{ 'SETTINGS.' + buttonText | translate }}
	</button>
</div>

<div *ngIf="isTokenShowed" class="content">
	<ng-container *ngIf="profile$ | async as profile">
		<p class="item">
			<strong class="item__title">USER ID: </strong>
			<span class="item__content" [itdCopyToClipboard]="profile.user_id">{{
				profile.user_id
			}}</span>
		</p>
		<p class="item">
			<strong class="item__title">API TOKEN: </strong>
			<span class="item__content" [itdCopyToClipboard]="profile.api_token">{{
				profile.api_token
			}}</span>
		</p>
	</ng-container>
</div>
