<div class="select-wrapper" [ngStyle]="{ 'width.px': width, 'maxWidth.px': maxWidth }">
	<itd-select
		sizeType="small"
		dropdownPosition="bottom"
		printLabelIndex="title"
		outputValueIndex="department_id"
		[hasParentDepartment]="isDepartmentDropdown"
		[isClearable]="true"
		[hasShadow]="true"
		[observableData]="dropdownData"
		[placeholder]="placeholder"
		[multiple]="multiple"
		(write)="valueChange($event)"
	></itd-select>
</div>
