import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { AbstractApiService } from '../abstract/abstract-api-service';
import { ApiClientService } from '../api/api.service';
import { AddLoading, RemoveLoading } from '../layout/layout.actions';
import { TResults } from '../models/results.interface';
import {
	ITaskAssignment,
	ITaskAssignmentDTO,
	ITaskAssignmentEdit,
	ITaskAssignmentEditDTO,
} from './task-assignment.interface';

@Injectable({
	providedIn: 'root',
})
export class TaskAssignmentService extends AbstractApiService<
	ITaskAssignment,
	ITaskAssignmentDTO,
	ITaskAssignmentEdit,
	ITaskAssignmentEditDTO
> {
	protected key = 'task-assignments';
	constructor(protected api: ApiClientService, protected store: Store) {
		super(api, store);
	}

	public getMyAssignments(): Observable<ITaskAssignment[]> {
		return this.api.get<TResults<any>>(`api/${this.key}/me`).pipe(
			map(response => {
				return response.data;
			})
		);
	}

	public getTaskAssignments(id: string): Observable<ITaskAssignment[]> {
		this.store.dispatch(AddLoading);

		return this.api.get<TResults<ITaskAssignmentDTO[]>>(`api/${this.key}/by-new-hire/${id}`).pipe(
			map(response => {
				this.store.dispatch(RemoveLoading);
				return response.data.map(item => this.parse(item));
			})
		);
	}

	protected parse(value: ITaskAssignmentDTO): ITaskAssignment {
		const { _id } = value;
		return {
			...value,
			id: _id,
		};
	}
	protected parseEditDTO(value: ITaskAssignmentEdit): ITaskAssignmentEditDTO {
		const { id } = value;
		return {
			...value,
			_id: id,
		};
	}
}
