import { ElementRef } from '@angular/core';

export const checkOutsideClick = (
	element: ElementRef<HTMLElement>,
	event: MouseEvent
): boolean => {
	if (!element) {
		return;
	}

	const composedPath = event.composedPath();
	const isClicked: boolean | number =
		composedPath.indexOf(element.nativeElement) !== -1;

	if (isClicked) {
		return;
	}

	return true;
};
