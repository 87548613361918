<form (ngSubmit)="onSubmit()" [formGroup]="form">
	<itd-modal>
		<ng-template #header>
			{{ 'CONFIRM.HEADER' | translate }}
		</ng-template>

		<ng-template #content>
			<p>
				{{ data.confirmText }}
				<br />
			</p>
			<b class="confirm-text">{{ 'CONFIRM' | translate | uppercase }}</b>

			<div class="input">
				<itd-input
					placeholder="{{ 'CONFIRM.INPUT' | translate }}"
					formControlName="confirm"
					[focusAfterInit]="true"
					[hasBorder]="true"
					[hasShadow]="false"
				>
				</itd-input>
			</div>
		</ng-template>

		<ng-template #footer>
			<itd-button [disabled]="!form.valid" [enableAfterSuccess]="false" [formState]="formState">
				{{ 'CONFIRM' | translate }}
			</itd-button>

			<itd-form-error [errorObservable]="errorMessage$"></itd-form-error>
		</ng-template>
	</itd-modal>
</form>
