import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '../icon/icon.module';
import { RouterModule } from '@angular/router';
import { TableComponent } from './components/table/table.component';
import { HeaderCellDefDirective } from './directives/header-cell-def.directive';
import { CellDefDirective } from './directives/cell-def.directive';
import { ColumnDefDirective } from './directives/column-def.directive';
import { RowOutletDirective } from './directives/row-outlet.directive';
import {
	TableRowComponent,
	CellOutletDirective,
	RowBeforeOutletDirective,
	RowAfterOutletDirective,
} from './components/table-row/table-row.component';
import { RowDefDirective } from './directives/row-def.directive';
import { TableHeaderRowComponent } from './components/table-header-row/table-header-row.component';
import { HeaderRowOutletDirective } from './directives/header-row-outlet.directive';
import { HeaderRowDefDirective } from './directives/header-row-def.directive';
import { TableColumnSorterComponent } from './components/table-column-sorter/table-column-sorter.component';
import { TableLoadingStateComponent } from './components/table-loading-state/table-loading-state.component';
import { TableEmptyComponent } from './components/table-empty/table-empty.component';
import { RowBeforeDefDirective } from './directives/row-before-def.directive';
import { RowAfterDefDirective } from './directives/row-after-def.directive';

const privateDeclarations = [
	RowOutletDirective,
	HeaderRowOutletDirective,
	CellOutletDirective,
	RowBeforeOutletDirective,
	RowAfterOutletDirective,
];

const declarations = [
	TableComponent,
	HeaderCellDefDirective,
	CellDefDirective,
	ColumnDefDirective,
	TableRowComponent,
	RowDefDirective,
	RowBeforeDefDirective,
	RowAfterDefDirective,
	RowBeforeDefDirective,
	HeaderRowDefDirective,
	TableHeaderRowComponent,
	TableColumnSorterComponent,
	TableLoadingStateComponent,
	TableEmptyComponent,
];

@NgModule({
    exports: [...declarations],
    imports: [CommonModule, RouterModule, IconModule, ...declarations, ...privateDeclarations],
})
export class TableModule {}
