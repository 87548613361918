import { fadeAnimation } from './../../../../../../core/animations/fade.animation';
import { IAchievementsIcon } from './../../../../../../core/achievements/achievements-icons';
import { Observable } from 'rxjs';
import { AchievementsState } from 'src/app/core/achievements/achievements.state';
import { CreateAchievement } from './../../../../../../core/achievements/achievements.action';
import { IAchievement } from 'src/app/core/achievements/achievements.interface';
import {
	Component,
	ChangeDetectionStrategy,
	Input,
	ChangeDetectorRef,
	ElementRef,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { AbstractFormComponent } from 'src/app/utility-modules/itd-form/components/abstract-form/abstract-form.component';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { NotificationService } from 'src/app/utility-modules/notification/services/notification.service';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { UpdateAchievement } from 'src/app/core/achievements/achievements.action';
import { IAchievementsThemes } from 'src/app/core/achievements/achievements-themes';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../../../../utility-modules/itd-form/components/button/button.component';
import { FormErrorComponent } from '../../../../../../utility-modules/itd-form/components/form-error/form-error.component';
import { IconComponent } from '../../../../../../utility-modules/icon/components/icon/icon.component';
import { InputCustomRadioComponent, InputCustomRadioDirective } from '../../../../../../utility-modules/itd-form/components/input-custom-radio/input-custom-radio.component';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { InputComponent } from '../../../../../../utility-modules/itd-form/components/input/input.component';
import { ModalComponent } from '../../../../../../utility-modules/modals/components/modal/modal.component';

@Component({
    selector: 'itd-admin-content-achievement-edit-modal',
    templateUrl: './admin-content-achievement-edit-modal.component.html',
    styleUrls: ['./admin-content-achievement-edit-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeAnimation],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        ModalComponent,
        InputComponent,
        NgIf,
        NgFor,
        InputCustomRadioComponent,
        InputCustomRadioDirective,
        NgClass,
        IconComponent,
        FormErrorComponent,
        ButtonComponent,
        AsyncPipe,
        TranslateModule,
    ],
})
export class AdminContentAchievementEditModalComponent extends AbstractFormComponent {
	@Select(AchievementsState.icons) public icons$: Observable<IAchievementsIcon[]>;
	@Select(AchievementsState.themes) public themes$: Observable<IAchievementsThemes[]>;

	@Input() public achievement?: IAchievement;

	public get isEdit(): boolean {
		return !!this.achievement;
	}

	constructor(
		public store: Store,
		public validationMessages: ValidationMessagesService,
		public elementRef: ElementRef,
		protected cd: ChangeDetectorRef,
		private activeModal: NgbActiveModal,
		private notification: NotificationService
	) {
		super(cd);
	}

	ngOnInit(): void {
		this.form = new FormGroup({
			title: new FormControl('', [Validators.required, Validators.minLength(4)]),
			description: new FormControl('', [Validators.required, Validators.minLength(4)]),
			iconId: new FormControl(null, [Validators.required]),
			theme: new FormControl(null, [Validators.required]),
		});

		this.afterInit();
	}

	protected setInitialFormValue() {
		return this.achievement;
	}

	protected onSubmitAction() {
		const data = this.formValue;

		if (this.isEdit) {
			const { id } = this.achievement;

			return this.store.dispatch(new UpdateAchievement({ id, data }));
		}

		return this.store.dispatch(new CreateAchievement({ data }));
	}

	protected onSubmitSuccess() {
		this.notification.add({
			text: 'BACKEND_MESSAGES.ACHIEVEMENTS_UPDATE_SUCCESS',
		});

		this.activeModal.close();
	}
}
