import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AbstractGraphComponent } from '../../abstract/abstract-graph/abstract-graph.component';
import * as Highcharts from 'highcharts';
import { IAnalyticsAssignOverTime } from '../../../../../app/core/analytics/analytics.interface';
import { ChartModule } from 'angular-highcharts';

export type TMultiGraphData = Array<IAnalyticsAssignOverTime>;

@Component({
    selector: 'itd-graph-multiple-axis',
    templateUrl: './graph-multiple-axis.component.html',
    styleUrls: ['./graph-multiple-axis.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ChartModule],
})
export class GraphMultipleAxisComponent extends AbstractGraphComponent {
	@Input() public data: any;
	@Input() public labels: string[];

	constructor() {
		super();
	}

	protected getChartOptions(): Highcharts.Options {
		return {
			chart: {
				zooming: {
					type: 'xy',
				},
			},
			xAxis: [
				{
					categories: this.labels,
					crosshair: true,
				},
			],
			yAxis: [
				{
					// Primary yAxis
					title: undefined,
					labels: {
						format: undefined,
						style: {
							color: Highcharts.getOptions().colors[2].toString(),
						},
					},
				},
				{
					// Secondary yAxis
					gridLineWidth: 0,
					title: {
						text: 'Count',
						style: {
							color: Highcharts.getOptions().colors[0].toString(),
						},
					},
					labels: {
						format: '{value}',
						style: {
							color: Highcharts.getOptions().colors[0].toString(),
						},
					},
				},
				{
					// Tertiary yAxis
					gridLineWidth: 0,
					title: {
						text: 'Time',
						style: {
							color: Highcharts.getOptions().colors[1].toString(),
						},
					},
					labels: {
						format: '{value} hour',
						style: {
							color: Highcharts.getOptions().colors[1].toString(),
						},
					},
					opposite: true,
				},
			],
			tooltip: {
				shared: true,
			},
			legend: {
				layout: 'horizontal',
				verticalAlign: 'top',
			},
			series: [
				{
					name: this.data[0].name,
					type: this.data[0].type,
					yAxis: this.data[0].yAxis,
					data: this.data[0].data,
				},
				{
					name: this.data[1].name,
					type: this.data[1].type,
					yAxis: this.data[1].yAxis,
					data: this.data[1].data,
				},
				{
					name: this.data[2].name,
					type: this.data[2].type,
					yAxis: this.data[2].yAxis,
					data: this.data[2].data,
					marker: {
						enabled: true,
					},
				},
				{
					name: this.data[3].name,
					type: this.data[3].type,
					yAxis: this.data[3].yAxis,
					data: this.data[3].data,
				},
				{
					name: this.data[4].name,
					type: this.data[4].type,
					yAxis: this.data[4].yAxis,
					data: this.data[4].data,
				},
			],
			responsive: {
				rules: [
					{
						condition: {
							maxWidth: 500,
						},
						chartOptions: {
							legend: {
								floating: false,
								layout: 'horizontal',
								align: 'center',
								verticalAlign: 'bottom',
								x: 0,
								y: 0,
							},
							yAxis: [
								{
									labels: {
										align: 'right',
										x: 0,
										y: -6,
									},
									showLastLabel: false,
								},
								{
									labels: {
										align: 'left',
										x: 0,
										y: -6,
									},
									showLastLabel: false,
								},
								{
									visible: false,
								},
							],
						},
					},
				],
			},
		};
	}
}
