import { Component, Input, OnInit } from '@angular/core';
import { IMessage } from 'src/app/core/messages/messages.interface';
import { OrganizationLogoComponent } from '../../components/organization-logo/organization-logo.component';
import { NgIf } from '@angular/common';
import { ModalComponent } from '../../../utility-modules/modals/components/modal/modal.component';

interface PreviewMessage {
	title: string;
	message: string;
	btnText?: string;
}

@Component({
    selector: 'itd-preview-modal',
    templateUrl: './preview-modal.component.html',
    styleUrls: ['./preview-modal.component.scss'],
    standalone: true,
    imports: [
        ModalComponent,
        NgIf,
        OrganizationLogoComponent,
    ],
})
export class PreviewModalComponent implements OnInit {
	@Input() typeOfPreview: string;
	@Input() message: IMessage;
	@Input() form: any;
	previewMessage: PreviewMessage = {
		title: '',
		message: '',
		btnText: '',
	};
	constructor() {}

	ngOnInit(): void {
		if (this.message) {
			this.previewMessage.message = this.message.message;
			this.previewMessage.title = this.message.subject;
			this.previewMessage.btnText = this.message.button_text;
		} else {
			this.previewMessage.message = this.form.message;
			this.previewMessage.title = this.form.subject;
			this.previewMessage.btnText = this.form.button_text;
		}
	}
}
