import { trigger, transition, style, animate, query } from '@angular/animations';

export const routeAnimations = trigger('routeAnimations', [
	transition('* <=> *', [
		query(
			':enter, :leave',
			[
				style({
					position: 'absolute',
					left: 0,
					width: '100%',
					opacity: 0,
				}),
			],
			{ optional: true }
		),
		query(':enter', [animate('.2s', style({ opacity: 1 }))], { optional: true }),
	]),
]);
