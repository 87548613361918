<ng-container itdHeaderRowOutlet></ng-container>

<div class="table__content" #content [ngStyle]="{ 'height.px': _virtualScrollHeight }">
	<div class="table__content-inner" [ngClass]="{ 'is-virtual': virtualScroll }" #tableContentInner>
		<ng-container itdRowOutlet></ng-container>
	</div>

	<div @blockInitialRenderAnimation>
		<div *ngIf="isLoading" @fadeAnimation>
			<itd-table-loading-state></itd-table-loading-state>
		</div>
	</div>
</div>

<div *ngIf="isEmpty && !isFiltering && !isSearching">
	<div *ngIf="emptyComponent">
		<ng-content select="itd-table-empty"></ng-content>
	</div>
	<div *ngIf="!emptyComponent">
		<itd-table-empty
			[heading]="emptyText"
			[rowCount]="emptyRowsCount"
			[rowHeight]="emptyColumnHeight"
			[rowElements]="emptyRowElements"
		></itd-table-empty>
	</div>
</div>

<ng-template #emptyCell>
	<div></div>
</ng-template>
