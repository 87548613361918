import { Select } from '@ngxs/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { IDepartment } from '../department/department.interface';
import { DepartmentState } from '../department/department.state';

export class DataSourceSearch<T> {
	private _parseItemFunc: (item: T) => string[];
	@Select(DepartmentState.departments) public departments$: Observable<IDepartment[]>;

	// tslint:disable-next-line: no-any
	public parseItemKeys: Array<keyof T>;

	constructor() {}

	public setCustomParseFunc(fn: (item: T) => string[]): void {
		this._parseItemFunc = fn;
	}

	public search(data: T[], query: string | any): T[] {
		let searchedData;
		if (!data || !query) {
			return data;
		}
		const searchValues = query.toLowerCase().replace(/,/g, '').split(' ');
		searchedData = [...data].filter((d) => {
			const values: string[] = [];
			if (this.parseItemKeys) {
				for (const key of this.parseItemKeys) {
					values.push(...this.getValues(d[key]));
				}
			} else {
				values.push(...this.getValues(d));
			}

			if (this._parseItemFunc) {
				const additionalValues = this._parseItemFunc(d);

				if (additionalValues) {
					values.push(...additionalValues);
				}
			}

			const valueResults: boolean[] = searchValues.map((value) => {
				for (let element of values) {
					element = element?.toLowerCase();
					if (element?.indexOf(value) >= 0) {
						return true;
					}
				}

				return false;
			});

			return valueResults.indexOf(true) !== -1;
		});
		return searchedData;
	}

	private getValues(object: unknown): string[] {
		if (!object) {
			return [];
		}

		if (typeof object === 'string') {
			return [object];
		}

		if (typeof object === 'number') {
			return [object.toString()];
		}

		if (object instanceof Date) {
			return [object.toDateString()];
		}

		if (moment.isMoment(object)) {
			const data = [object.creationData().input as string];
			const current = moment().format('DD.MM.YYYY');

			if (current !== object.format('DD.MM.YYYY')) {
				data.push(object.format('DD.MM.YYYY'));
			}
			return data;
		}

		if (Array.isArray(object)) {
			const data = [];
			for (const inner of object) {
				data.push(...this.getValues(inner));
			}

			return data;
		}

		if (object.constructor === Object) {
			const data = [];
			for (const key in object as Object) {
				if (key === 'id') {
					continue;
				}

				const element = object[key];

				data.push(...this.getValues(element));
			}

			return data;
		}

		return [];
	}
}
