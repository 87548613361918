import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Input,
	ChangeDetectorRef,
	OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomDataSource } from 'src/app/core/data-source/data-source';
import { WithDestroyComponent } from 'src/app/core/abstract/abstract-with-destroy-component';
import { takeUntil } from 'rxjs/operators';
import { IconComponent } from '../../../icon/components/icon/icon.component';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'itd-table-column-sorter',
    templateUrl: './table-column-sorter.component.html',
    styleUrls: ['./table-column-sorter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        IconComponent,
    ],
})
export class TableColumnSorterComponent<T> extends WithDestroyComponent
	implements OnInit, OnDestroy {
	@Input() public dataSource: CustomDataSource<T>;
	@Input() public sortBy: string;
	@Input() public label: string;

	private _sortSubscription: Subscription;

	public get isCurrentSortBy(): boolean {
		return this.dataSource ? this.dataSource.currentSortValue.name === this.sortBy : false;
	}

	public get direction(): 'ASC' | 'DESC' {
		return this.dataSource ? this.dataSource.currentSortValue.direction : null;
	}

	constructor(private _cd: ChangeDetectorRef) {
		super();
	}

	public ngOnInit(): void {
		if (this.dataSource) {
			this._sortSubscription = this.dataSource.sort$
				.pipe(takeUntil(this.destroy$))
				.subscribe(() => {
					this._cd.detectChanges();
				});
		}
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		if (this._sortSubscription) {
			this._sortSubscription.unsubscribe();
		}
	}

	public sort(): void {
		if (this.dataSource && this.sortBy) {
			this.dataSource.sort(this.sortBy);
		}
	}
}
