import { Logout } from 'src/app/core/auth/auth.action';
import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { TranslateModule } from '@ngx-translate/core';
import { ModalComponent } from '../../../utility-modules/modals/components/modal/modal.component';

@Component({
    selector: 'itd-logout-modal',
    templateUrl: './logout-modal.component.html',
    styleUrls: ['./logout-modal.component.scss'],
    standalone: true,
    imports: [ModalComponent, TranslateModule],
})
export class LogoutModalComponent {
	constructor(private store: Store) {}

	public logout() {
		this.store.dispatch(new Logout({ navigate: true }));
	}
}
