<form (ngSubmit)="onSubmit()" [formGroup]="form">
	<itd-modal icon="envelope">
		<ng-template #header>
			{{ title | translate }}
		</ng-template>

		<ng-template #content>
			<div class="content"></div>
			<itd-select
				sizeType="default"
				styleType="primary"
				printLabelIndex="title"
				formControlName="message_id"
				outputValueIndex="id"
				label="ASSIGNMENT.RESEND_SELECT_MESSAGE"
				[isClearable]="false"
				[hasBorder]="true"
				[hasShadow]="false"
				[observableData]="messages$"
				[defaultValue]="defaultMessageValue$ | async"
				[organization]="organization$ | async"
			></itd-select>
		</ng-template>

		<ng-template #footer>
			<itd-button [formState]="formState">
				{{ 'CONFIRM' | translate }}
			</itd-button>
		</ng-template>
	</itd-modal>
</form>
