import { Directive, ViewContainerRef, ElementRef } from '@angular/core';
import { RowOutletDirective } from './row-outlet.directive';

@Directive({
    selector: '[itdHeaderRowOutlet]',
    standalone: true
})
export class HeaderRowOutletDirective extends RowOutletDirective {
	// tslint:disable-next-line: no-any
	constructor(public viewContainer: ViewContainerRef, public elementRef: ElementRef) {
		super(viewContainer, elementRef);
	}
}
