import { Component, Input } from '@angular/core';
import { ISurveyAnswerDetails, ISurveyExtended } from 'src/app/core/surveys/surveys.interface';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { IconComponent } from '../../../utility-modules/icon/components/icon/icon.component';
import { TooltipDirective } from '../../directives/tooltip.directive';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'itd-timeline-survey-results',
    templateUrl: './timeline-survey-results.component.html',
    styleUrls: ['./timeline-survey-results.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        NgClass,
        TooltipDirective,
        IconComponent,
        MatSliderModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
    ],
})
export class TimelineSurveyResultsComponent {
	@Input() activeSurvey: ISurveyExtended;
	@Input() surveyAnswers: ISurveyAnswerDetails[];

	public selectedAnswerIndex: number = null;
	public answers: ISurveyAnswerDetails[] = [];
	public truncateLength = 47;

	constructor() {}

	isSelected(question, answerIndex) {
		let surveyAnswerIndex = this.surveyAnswers.findIndex(ans => ans.id === question.id);
		return this.surveyAnswers[surveyAnswerIndex]?.option_answer === answerIndex;
	}

	public getAnswer(questionIndex: number): any {
		return this.surveyAnswers.find(
			answer => answer.id === this.activeSurvey.questions[questionIndex].id
		);
	}
}
