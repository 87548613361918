<div class="container">
	<ul class="row">
		<li *ngFor="let item of filteredNavigation">
			<a [routerLink]="item.url" class="item" routerLinkActive="isActive">
				<itd-icon [icon]="item.icon"></itd-icon>

				<p>{{ 'NAVIGATION.' + item.title | translate }}</p>
			</a>
		</li>

		<li *ngIf="navigation.length > 4">
			<button
				type="button"
				class="item"
				(click)="expandSideDrawer()"
				[ngClass]="{ isActive: isMoreActive }"
			>
				<itd-icon icon="more"></itd-icon>

				<p>{{ 'MORE' | translate }}</p>
			</button>
		</li>
	</ul>
</div>
