import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';

type TType = 'success' | 'error' | 'warning' | 'info';

export interface NotificationConfig {
	text: string;
	title?: string;
	type?: TType;
}

@Injectable()
export class NotificationService {
	constructor(private toastr: ToastrService, private translate: TranslateService) {}

	public async add(config: NotificationConfig): Promise<void> {
		const { text, title } = config;

		const translatedText = await lastValueFrom(this.translate.get(text));
		let translatedTitle: string;

		if (title) {
			translatedTitle = await lastValueFrom(this.translate.get(title));
		}

		if (config.type === 'error') {
			this.toastr.error(translatedText, translatedTitle);
		} else if (config.type === 'info') {
			this.toastr.info(translatedText, translatedTitle);
		} else if (config.type === 'warning') {
			this.toastr.warning(translatedText, translatedTitle);
		} else {
			this.toastr.success(translatedText, translatedTitle);
		}
	}
}
