import { IEnvironment } from 'src/environments/environment.interface';
import { Component, Inject, Input } from '@angular/core';
import { MediaService } from 'src/app/core/media/media.service';
import { NgStyle, NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
	selector: 'itd-image',
	templateUrl: './image.component.html',
	styleUrls: ['./image.component.scss'],
	standalone: true,
	imports: [NgStyle, NgIf, NgTemplateOutlet],
})
export class ImageComponent {
	@Input() public filename: string;
	@Input() public url: string;
	@Input() public height: number;
	@Input() public width: number;
	@Input() public alt: string;
	@Input() public folder?: string | number;
	@Input() public hasRetina?: boolean = true;

	@Input() public dimension?: number;
	@Input() public borderRadius?: number = 0;

	public get src(): string {
		return this.getImagePath(this.width, this.height);
	}

	public get srcSet(): string {
		return `${this.src} 1x, ${this.getImagePath(this.width * 2, this.height * 2)} 2x`;
	}

	constructor(@Inject('ENV') private env: IEnvironment, protected mediaService: MediaService) {}

	getImagePath(width: number, height: number): string {
		if (this.url) {
			return this.url;
		}

		const path = [this.env.mediaUrl, this.folder, `${this.filename}`]
			.filter(value => value)
			.join('/');

		if (this.width || this.height) {
			// TODO: Replace hardcoded dimension using component parameters
			// return path.join('/') + `_${width}x${height}`;
			return `${path}_${200}x${200}`;
		}

		return path;
	}
}
