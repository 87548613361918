import { ChangeDetectionStrategy, Component, Optional } from '@angular/core';
import { AbstractInputComponent } from '../abstract-input/abstract-input.component';
import { ValidationMessagesService } from '../../../../core/services/validation-messages.service';
import { NgControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MatSliderModule } from '@angular/material/slider';

@Component({
    selector: 'itd-input-range',
    templateUrl: './input-range.component.html',
    styleUrls: ['./input-range.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatSliderModule,
        ReactiveFormsModule,
        FormsModule,
        NgxIntlTelInputModule,
    ],
})
export class InputRangeComponent extends AbstractInputComponent<number> {
	constructor(@Optional() control: NgControl, validationMessages: ValidationMessagesService) {
		super(control, validationMessages);
	}

	public ngOnInit(): void {
		super.ngOnInit();
	}

	public displayThumbValue(value: number): string {
		return value + '%';
	}

	public changeValue(value) {
		this.writeValue(value.value);
	}
}
