import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { ChartModule } from 'angular-highcharts';
import dayjs from 'dayjs';
import { AbstractGraphComponent } from '../../abstract/abstract-graph/abstract-graph.component';

export type TMultiLineGraphData = {
	name: string;
	data: {
		_id: string | dayjs.Dayjs; // Assuming dayjs type is imported and available
		count: number;
		average: number;
	}[];
	color?: string;
	labelsType?: 'string' | 'date';
}[];

@Component({
	selector: 'itd-multi-graph-line',
	templateUrl: './multi-graph-line.component.html',
	styleUrls: ['./multi-graph-line.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ChartModule],
})
export class GraphMultiLineComponent extends AbstractGraphComponent implements OnInit {
	@Input() public data: TMultiLineGraphData;
	@Input() public labels: string[];
	@Input() public height: number;
	@Input() public includeColumn = false;
	colors: string[] = ['#1F3479', '#7E4A9D', '#D25899', '#FF5553'];

	constructor() {
		super();
	}

	protected getChartOptions(): Highcharts.Options {
		const isIdString = this.data[0]?.labelsType === 'string';
		let xAxisConfig: Highcharts.XAxisOptions;
		let categories: string[] = [];

		if (isIdString) {
			categories = this.data[0].data.map(point => point._id.toString());
			xAxisConfig = {
				type: 'category',
				categories: categories,
			};
		} else {
			xAxisConfig = {
				type: 'datetime',
				tickInterval: 30 * 24 * 3600000, // 30 days in milliseconds
			};
		}

		const series = this.data.map(line => ({
			type: 'spline',
			name: line.name,
			color: line.color,
			data: line.data.map(point => ({
				x: isIdString ? categories.indexOf(point._id.toString()) : dayjs(point._id).valueOf(),
				y: point.average,
			})),
		})) as Highcharts.SeriesOptionsType[];

		// Find the minimum value among all 'average' values and subtract 5. Caps at 0 on the lower end
		const minYValue = Math.max(
			0,
			this.data
				.reduce((acc, line) => acc.concat(line.data.map(point => point.average)), [] as number[])
				.reduce((min, val) => Math.min(min, val), Infinity) - 5
		);

		const baseConfig: Highcharts.Options = {
			chart: {
				type: 'spline',
				plotBackgroundColor: null,
				plotBorderWidth: 0,
				plotShadow: false,
				animation: false,
				height: this.height ? `${this.height}px` : '200px',
				width: null,
			},
			xAxis: { ...xAxisConfig },
			yAxis: {
				min: minYValue,
				max: 100,
				title: null,
			},
			credits: { enabled: false },
			tooltip: {
				headerFormat: '<b>{series.name}</b><br>',
				pointFormat: '{point.x:%b}: {point.y}',
			},
			title: null,
			exporting: {
				enabled: false,
				chartOptions: {
					title: {
						text: this.title,
					},
				},
			},
			legend: {
				align: 'left',
				verticalAlign: 'top',
			},
			series: series,
			plotOptions: {
				series: {
					label: {
						connectorAllowed: false,
					},
					marker: {
						enabled: false,
						states: {
							hover: {
								enabled: false,
							},
						},
					},
				},
			},
			colors: this.colors,
			drilldown: {
				animation: {
					duration: 0,
				},
			},
		};

		// if (this.includeColumn) {
		// 	baseConfig.series.push({
		// 		type: 'column',
		// 		data: this.data,
		// 		maxPointWidth: 15,
		// 	});
		// }

		// if (typeof data )

		return baseConfig;
	}
}
