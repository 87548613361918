import { Injectable } from '@angular/core';
import { ApiClientService } from '../api/api.service';
import { Store } from '@ngxs/store';
import {
	ISurvey,
	ISurveyAnswerDTO,
	ISurveyAnswerDetails,
	ISurveyDTO,
	ISurveyEdit,
	ISurveyEditDTO,
	ISurveyResults,
	ISurveyResultsDTO,
} from './surveys.interface';
import { AbstractApiService } from '../abstract/abstract-api-service';
import { Observable, map } from 'rxjs';
import { TResults } from '../models/results.interface';

@Injectable({
	providedIn: 'root',
})
export class SurveysService extends AbstractApiService<
	ISurvey,
	ISurveyDTO,
	ISurveyEdit,
	ISurveyEditDTO
> {
	protected key = 'surveys';

	constructor(protected api: ApiClientService, protected store: Store) {
		super(api, store);
	}

	// TODO: Should we have a separate entity folder for 'Survey Answers'? 🤔

	// TODO: How is this different from 'getAllSurveyAnswers' and can we leave one out?
	public getSurveyAnswers(id: string): Observable<ISurveyAnswerDetails[]> {
		return this.api
			.get<any>(`api/${this.key}/${id}/answers`)
			.pipe(
				map(response => (response?.data?.survey_answers ? response.data.survey_answers : null))
			);
	}

	public deleteSurveyAnswer(id: string): Observable<any> {
		return this.api.delete<any>(`api/${this.key}/answer/${id}`);
	}

	public getSurveyResults(id: string): Observable<ISurveyResults> {
		return this.api.get<TResults<ISurveyResultsDTO>>(`api/${this.key}/${id}/results`).pipe(
			map(response => {
				return this.parseSurveyResults(response.data);
			})
		);
	}

	public getAllSurveyAnswers(id: string, data?: any): Observable<ISurveyAnswerDTO[]> {
		return this.api
			.get<any>(`api/${this.key}/v2/${id}/answers`)
			.pipe(map(response => (response?.data ? response.data : null)));
	}

	public getSurveyStats(id: string, departmentId?: string): Observable<any> {
		const params: any = {
			params: {
				survey_id: id,
			},
		};

		if (departmentId !== undefined) {
			params.params.department_id = departmentId;
		}
		return this.api.get<any>(`api/${this.key}/answer/stats`, params);
	}

	public createSurveyAnswer(id: string, answers: any): Observable<any> {
		let payload = { survey_id: id, survey_answers: answers };
		return this.api.post<any>(`api/${this.key}/${id}/answer`, payload);
	}

	public updateSurveyAnswer(id: string, answers: any): Observable<any> {
		let payload = { survey_id: id, survey_answers: answers };
		return this.api.put<any>(`api/${this.key}/${id}/answer`, payload);
	}

	protected parse(value: ISurveyDTO): ISurvey {
		const { survey_data, survey_id, survey_title } = value;

		return {
			...value,
			title: survey_title,
			id: survey_id,
			questions: survey_data.map(item => ({
				...item,
				question: item.survey_question,
				allowComment: !!item.comment_on,
			})),
		};
	}

	public parseEditDTO(value: ISurveyEdit): ISurveyEditDTO {
		const { questions, title, id } = value;

		return {
			...value,
			survey_id: id,
			survey_title: title,
			survey_data: questions.map(item => ({
				...item,
				survey_question: item.question,
				comment_on: item.allowComment,
			})),
		};
	}

	protected parseSurveyResults(value: ISurveyResultsDTO): ISurveyResults {
		const { survey_data, survey_id, survey_title, ...rest } = value;

		return {
			...rest,
			title: survey_title,
			id: survey_id,
			questions: survey_data.map(item => ({
				...item,
				question: item.survey_question,
				allowComment: !!item.comment_on,
			})),
		};
	}
}
