import { IconModule } from './../icon/icon.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarMonthSwitcherComponent } from './components/calendar-month-switcher/calendar-month-switcher.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CalendarFiltersComponent } from './components/calendar-filters/calendar-filters.component';
import { CalendarDayComponent } from './components/calendar-day/calendar-day.component';
import { CalendarItemDirective } from './directives/calendar-item.directive';

const privateDeclarations = [
	CalendarMonthSwitcherComponent,
	CalendarFiltersComponent,
	CalendarDayComponent,
];
const declarations = [CalendarComponent, CalendarItemDirective];

@NgModule({
    exports: [...declarations],
    imports: [CommonModule, TranslateModule, IconModule, ...declarations, ...privateDeclarations],
})
export class CalendarModule {}
