<div class="title" *ngIf="toggle" (click)="toggleInput()">
  {{title | titlecase}} <itd-icon [classList]="['pointer']" icon="pen" [width]="20" [height]="20" (click)="toggleInput()"></itd-icon>
</div>
<ng-container *ngIf="!toggle">
  <div class="title-input">
    <ng-template [ngTemplateOutlet]="input"></ng-template>
    <itd-icon [classList]="['pointer']" icon="checkmark" [width]="30" [height]="30" (click)="toggleInput()"></itd-icon>
  </div>
  <ng-container *ngIf="message$ | async as message">
		<p *ngIf="invalid" class="validation">
			{{ message.text }}
		</p>
	</ng-container>
</ng-container>
