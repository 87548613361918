import { TResults } from './../models/results.interface';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IHelpArticle } from './help.interface';
import { map } from 'rxjs/operators';
import { ApiClientService } from '../api/api.service';

@Injectable({
	providedIn: 'root',
})
export class HelpService {
	constructor(private api: ApiClientService) {}

	public getArticles(): Observable<IHelpArticle[]> {
		return this.api
			.get<TResults<IHelpArticle[]>>('api/help/articles')
			.pipe(map(results => results.data));
	}
}
