import { ITaskAssignmentEdit } from './task-assignment.interface';

export class LoadAllTaskAssignments {
	public static readonly type = '[Task Assignment] Load All';
}

export class LoadAllTaskAssignmentsIfEmpty {
	public static readonly type = '[Task Assignment] Load All If Empty';
}

export class LoadTaskAssignments {
	public static readonly type = '[Task Assignment] Load';
	constructor(public payload: { id: string }) {}
}

export class LoadMyTaskAssignments {
	public static readonly type = '[Task Assignment] Load Mine';
}

export class CreateTaskAssignment {
	public static readonly type = '[Task Assignment] Create';
	constructor(public payload: { data: ITaskAssignmentEdit }) {}
}

export class UpdateTaskAssignment {
	public static readonly type = '[Task Assignment] Update';
	constructor(public payload: { id: string; data: ITaskAssignmentEdit }) {}
}

export class DeleteTaskAssignment {
	public static readonly type = '[Task Assignment] Delete';
	constructor(public payload: { id: string }) {}
}

export class ClearTaskAssignments {
	public static readonly type = '[Task Assignment] Clear';
	constructor() {}
}
