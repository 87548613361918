import {
	ChangeDetectionStrategy,
	Component,
	OnInit,
	ChangeDetectorRef,
	EventEmitter,
	Output,
	Input,
	ContentChild,
} from '@angular/core';
import { Dayjs } from 'dayjs';
import {
	TCalendarFilter,
	CalendarFiltersComponent,
} from '../calendar-filters/calendar-filters.component';
import { CalendarItemDirective } from '../../directives/calendar-item.directive';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarDayComponent } from '../calendar-day/calendar-day.component';
import { NgFor, NgClass, NgTemplateOutlet, NgIf } from '@angular/common';
import { CalendarMonthSwitcherComponent } from '../calendar-month-switcher/calendar-month-switcher.component';

@Component({
	selector: 'itd-calendar',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CalendarMonthSwitcherComponent,
		CalendarFiltersComponent,
		NgFor,
		NgClass,
		CalendarDayComponent,
		NgTemplateOutlet,
		NgIf,
		TranslateModule,
	],
})
export class CalendarComponent implements OnInit {
	@ContentChild(CalendarItemDirective)
	public item: CalendarItemDirective;

	@Input()
	public focusDay: Dayjs;
	@Input()
	public isLoading: boolean;
	@Input()
	public filters: TCalendarFilter;

	@Output()
	public onChange = new EventEmitter<{ start: Dayjs; end: Dayjs }>();
	@Output()
	public onFiltersChange = new EventEmitter<TCalendarFilter>();

	public currentMonth: Dayjs;
	public visibleDays: Dayjs[];

	public daysOfWeek: string[] = [
		'MONDAY',
		'TUESDAY',
		'WEDNESDAY',
		'THURSDAY',
		'FRIDAY',
		'SATURDAY',
		'SUNDAY',
	];

	public get isCurrentMonth(): boolean {
		return this.currentMonth.format('YYYY-MM') === this.focusDay.format('YYYY-MM');
	}

	constructor(private cd: ChangeDetectorRef) {}

	async ngOnInit(): Promise<void> {
		this.changeMonth(this.focusDay.clone());
	}

	public changeMonth(value: Dayjs) {
		this.currentMonth = value;

		this.setVisibleDays();

		this.onChange.emit({
			start: this.visibleDays[0],
			end: this.visibleDays[this.visibleDays.length - 1],
		});

		this.cd.detectChanges();
	}

	public filtersChange(value: TCalendarFilter) {
		this.onFiltersChange.emit(value);
	}

	public moveToCurrent() {
		if (this.isCurrentMonth) {
			return;
		}

		this.changeMonth(this.focusDay);
	}

	private setVisibleDays() {
		const firstDayInCurrentMonth = this.currentMonth.set('date', 0).day();
		const daysInCurrentMonth = this.currentMonth.daysInMonth();

		const prevMonthVisibleDays = firstDayInCurrentMonth === 6 ? 6 : firstDayInCurrentMonth;
		const nextMonthVisibleDays = 6 - this.currentMonth.set('date', daysInCurrentMonth).day();

		const length = daysInCurrentMonth + prevMonthVisibleDays + nextMonthVisibleDays + 1;
		const eldestDay = this.currentMonth.set('date', 0).subtract(prevMonthVisibleDays, 'd');

		this.visibleDays = Array.from({ length }, (_, i) => {
			return eldestDay.add(++i, 'd');
		});
	}
}
