import { Component, Input } from '@angular/core';

@Component({
    selector: 'itd-truncate',
    templateUrl: './truncate.component.html',
    styleUrls: ['./truncate.component.scss'],
    standalone: true,
})
export class TruncateComponent {
	@Input() public value: string;
}
