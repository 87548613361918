<itd-modal *ngIf="results$ | async as survey" [size]="1200">
	<ng-template #header>{{ survey?.activeSurvey?.survey_title }}</ng-template>

	<ng-template #content>
		<itd-timeline-survey-results
			[activeSurvey]="survey?.activeSurvey"
			[surveyAnswers]="survey?.surveyAnswers"
		>
		</itd-timeline-survey-results>
	</ng-template>
</itd-modal>
