import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { IDepartment } from './department.interface';
import { DepartmentService } from './department.service';
import { tap } from 'rxjs/operators';
import {
	AddParent_id,
	CreateDepartment,
	DeleteDepartment,
	LoadAllDepartments,
	LoadAllDepartmentsIfEmpty,
	LoadAllParentDepartments,
	LoadDepartmentsFromParent,
	MoveDepartment,
	UpdateDepartment,
} from './department.actions';
import { patch } from '@ngxs/store/operators';
import { ProfileState } from '../profile/profile.state';
import { getDepartmentAndChildDepartments, getUserDepartmentOnly } from '../utils/departments.util';

export interface DepartmentStateModel {
	departments: IDepartment[];
	departmentsSection: IDepartment[][];
	departmentsParent_id: Array<string>;
}

@State<DepartmentStateModel>({
	name: 'department',
	defaults: {
		departments: [],
		departmentsSection: [],
		departmentsParent_id: [],
	},
})
@Injectable()
export class DepartmentState {
	constructor(private service: DepartmentService, protected store: Store) {}

	@Selector()
	public static departments(state: DepartmentStateModel): IDepartment[] {
		return state.departments;
	}

	@Selector()
	public static departmentsSection(state: DepartmentStateModel): IDepartment[][] {
		return state.departmentsSection;
	}

	@Selector()
	public static getDepartmentsParent_id(state: DepartmentStateModel): Array<string> {
		return state.departmentsParent_id;
	}

	@Action(LoadAllDepartmentsIfEmpty)
	public loadIfEmpty(ctx: StateContext<DepartmentStateModel>) {
		const departments = ctx.getState().departments;

		if (departments.length) {
			return;
		}

		return this.loadAll(ctx);
	}

	@Action(LoadAllDepartments)
	public loadAll(ctx: StateContext<DepartmentStateModel>) {
		return this.service.getAll().pipe(
			tap(departments => {
				ctx.patchState({
					departments: this.store.selectSnapshot(ProfileState.isGlobalAdmin)
						? departments
						: getDepartmentAndChildDepartments(
								this.store.selectSnapshot(ProfileState.departmentId),
								departments
						  ),
				});
			})
		);
	}

	@Action(LoadAllParentDepartments)
	public loadAllParentDepartments(ctx: StateContext<DepartmentStateModel>) {
		if (this.store.selectSnapshot(ProfileState.isGlobalAdmin)) {
			return this.service.getParents().pipe(
				tap(departments => {
					ctx.setState(
						patch<DepartmentStateModel>({
							departmentsSection: [departments],
						})
					);
				})
			);
		} else {
			return this.service.getAll().pipe(
				tap(departments => {
					ctx.setState(
						patch<DepartmentStateModel>({
							departmentsSection: [
								getUserDepartmentOnly(
									this.store.selectSnapshot(ProfileState.departmentId),
									departments
								),
							],
						})
					);
				})
			);
		}
	}

	@Action(LoadDepartmentsFromParent)
	public loadDepartmentsFromParent(
		ctx: StateContext<DepartmentStateModel>,
		{ payload }: LoadDepartmentsFromParent
	) {
		let { departmentsSection } = ctx.getState();
		return this.service.getDepartmentsFromParent(payload.department_id).pipe(
			tap(departments => {
				let filteredDepartments = this.store.selectSnapshot(ProfileState.isGlobalAdmin)
					? departments
					: getDepartmentAndChildDepartments(
							this.store.selectSnapshot(ProfileState.departmentId),
							departments
					  );
				departmentsSection = departmentsSection.filter((arr, index) => index < payload.index);
				departmentsSection.push(filteredDepartments);
				ctx.setState(
					patch<DepartmentStateModel>({
						departmentsSection,
					})
				);
			})
		);
	}

	@Action(DeleteDepartment)
	public deleteDepartment(ctx: StateContext<DepartmentStateModel>, { payload }: DeleteDepartment) {
		return this.service.delete(payload.department_id).pipe(
			tap(response => {
				if (payload.index !== 0 && response) {
					ctx.dispatch(
						new LoadDepartmentsFromParent({
							department_id: payload.department_id,
							index: payload.index,
						})
					);
				} else if (response) {
					ctx.dispatch(LoadAllParentDepartments);
				}
			})
		);
	}

	@Action(CreateDepartment)
	public createDepartment(ctx: StateContext<DepartmentStateModel>, { payload }: CreateDepartment) {
		return this.service.create(payload.department).pipe(
			tap(response => {
				if (payload.index !== 0 && response) {
					ctx.dispatch(
						new LoadDepartmentsFromParent({
							department_id: payload.department.parent_id,
							index: payload.index,
						})
					);
				} else if (response) {
					ctx.dispatch(LoadAllParentDepartments);
				}
			})
		);
	}

	@Action(AddParent_id)
	public updateParent_id(ctx: StateContext<DepartmentStateModel>, { payload }: AddParent_id) {
		let { departmentsParent_id } = ctx.getState();
		departmentsParent_id = departmentsParent_id.filter((item, index) => index < payload.index);
		if (payload.parent_id) {
			departmentsParent_id[payload.index] = payload.parent_id;
		}
		ctx.setState(
			patch<DepartmentStateModel>({
				departmentsParent_id,
			})
		);
	}

	@Action(UpdateDepartment)
	public updateDepartment(ctx: StateContext<DepartmentStateModel>, { payload }: UpdateDepartment) {
		return this.service
			.update({ department: payload.department, department_id: payload.department_id })
			.pipe(
				tap(response => {
					if (payload.index !== 0 && response) {
						ctx.dispatch(
							new LoadDepartmentsFromParent({
								department_id: payload.parent_id,
								index: payload.index,
							})
						);
					} else if (response) {
						ctx.dispatch(LoadAllParentDepartments);
					}
				})
			);
	}

	@Action(MoveDepartment)
	public moveDepartment(ctx: StateContext<DepartmentStateModel>, { payload }: MoveDepartment) {
		return this.service.move(payload).pipe(
			tap(response => {
				if (response) {
					ctx.dispatch(LoadAllParentDepartments);
				}
			})
		);
	}
}
