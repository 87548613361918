import { WithDestroyComponent } from 'src/app/core/abstract/abstract-with-destroy-component';
import {
	Component,
	ViewChild,
	ElementRef,
	Input,
	QueryList,
	OnDestroy,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	ContentChildren,
	AfterContentInit,
	EventEmitter,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { fadeAnimation } from 'src/app/core/animations/fade.animation';
import { takeUntil } from 'rxjs/operators';
import { checkOutsideClick } from 'src/app/core/utils/check-outside-click';
import { IconComponent } from '../../../utility-modules/icon/components/icon/icon.component';
import { NgClass, NgIf, NgStyle } from '@angular/common';

@Component({
	selector: 'itd-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
	animations: [fadeAnimation],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass, NgIf, IconComponent, NgStyle],
})
export class MenuComponent extends WithDestroyComponent implements OnDestroy, AfterContentInit {
	@ViewChild('container', { static: true }) public container: ElementRef<HTMLElement>;
	@ViewChild('link', { static: true }) public link: ElementRef<HTMLElement>;
	@ViewChild('content', { static: false }) public content: ElementRef<HTMLElement>;

	@ContentChildren(MenuItemComponent) public items: QueryList<MenuItemComponent>;

	@Input() public position: 'bottom' | 'left' = 'left';
	@Input() public positionX: 'right' | 'left' = 'left';
	@Input() public offsetPosition: number = 10;
	@Input() public htmlElementIncluded: boolean = false;

	public isActive: boolean = false;
	public positionStyle: {
		[key: string]: number | string;
	};
	static onToggle = new EventEmitter<MenuComponent>();

	constructor(private cd: ChangeDetectorRef) {
		super();
	}

	public ngAfterContentInit(): void {
		if (this.items) {
			this.items.forEach(item => {
				item.onClick.pipe(takeUntil(this.destroy$)).subscribe(() => {
					this.toggle();
				});
			});
		}

		MenuComponent.onToggle.pipe(takeUntil(this.destroy$)).subscribe(clickedMenu => {
			if (clickedMenu !== this && this.isActive) {
				this.isActive = false;
				this.cd.detectChanges();
			}
		});
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	private targetPosition(): void {
		if (!this.link || !this.content) {
			return;
		}

		const linkRect = this.link.nativeElement.getBoundingClientRect();
		const contentRect = this.content.nativeElement.getBoundingClientRect();

		let positionY: number;
		let positionX: number = linkRect.left - contentRect.width;

		if (this.positionX) {
			this.position = 'bottom';
		}

		switch (this.position) {
			case 'bottom':
				positionY = linkRect.bottom - linkRect.height + this.offsetPosition;
				positionX += linkRect.width;
				break;

			case 'left':
				positionY = linkRect.bottom - linkRect.height / 2 - contentRect.height / 2;
				positionX -= this.offsetPosition;
				break;
		}

		switch (this.positionX) {
			case 'right':
				positionX = linkRect.left;
				break;

			case 'left':
				positionX -= this.offsetPosition;
				break;
		}

		this.positionStyle = {
			'top.px': Math.floor(positionY),
			'left.px': Math.floor(positionX),
		};

		if (window.innerHeight < positionY + contentRect.height + this.offsetPosition) {
			this.positionStyle = {
				'bottom.px': this.offsetPosition,
				'left.px': Math.floor(positionX),
			};
		}

		this.cd.detectChanges();
	}

	public toggle(event?) {
		if (!this.isActive) {
			MenuComponent.onToggle.emit(this);
		}

		this.isActive = !this.isActive;

		if (this.isActive) {
			this.detectPosition();
		} else {
			this.removeSubscriptions();
		}

		this.cd.detectChanges();

		if (event) {
			event.stopPropagation();
		}
	}

	private detectPosition() {
		setTimeout(() => {
			this.targetPosition();
		});

		this.eventSubscriptions.push(
			fromEvent(window, 'resize').subscribe(e => {
				this.targetPosition();
			})
		);

		this.eventSubscriptions.push(
			fromEvent(window, 'scroll').subscribe(e => {
				this.targetPosition();
			})
		);

		this.eventSubscriptions.push(
			fromEvent(window, 'click').subscribe((e: MouseEvent) => {
				this.clickOutside(e);
			})
		);
	}

	private clickOutside(event: MouseEvent): void {
		const isClickedOutside = checkOutsideClick(this.container, event);

		if (!isClickedOutside) {
			return;
		}

		this.toggle();
		this.cd.detectChanges();
	}
}
