import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'itd-youtube',
    templateUrl: './youtube.component.html',
    styleUrls: ['./youtube.component.scss'],
    standalone: true,
})
export class YoutubeComponent {
	@Input() public url: string;

	constructor(private sanitizer: DomSanitizer) {}

	getUrl(url) {
		// Replace original URL with an embed friendly one
		url = url.replace('watch?v=', 'embed/');
		if (url.includes('&', 0)) {
			url = url.substring(0, url.indexOf('&'));
		}

		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
