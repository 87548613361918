import { IMediaEdit } from './media.interface';

export class LoadAllMedia {
	public static readonly type = '[Media] Load All';
}

export class LoadAllMediaIfEmpty {
	public static readonly type = '[Media] Load All If Empty';
}

export class CreateMedia {
	public static readonly type = '[Media] Create';
	constructor(public payload: { files: File[] }) {}
}

export class UpdateMedia {
	public static readonly type = '[Media] Update';
	constructor(public payload: { id: string; data: IMediaEdit }) {}
}

export class DeleteMedia {
	public static readonly type = '[Media] Delete';
	constructor(public payload: { id: string }) {}
}

export class SearchMedia {
	public static readonly type = '[Media] Search Media';
	constructor(public payload: { searchText: string }) {}
}

export class FilterMedia {
	public static readonly type = '[Media] Filter Media';
	constructor(public payload: { fileTypeFilter: string }) {}
}
