import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'itd-menu-parent',
    templateUrl: './menu-parent.component.html',
    styleUrls: ['./menu-parent.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class MenuParentComponent {
	constructor() {}
}
