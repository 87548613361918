<itd-main-template>
	<ng-template #mainTemplateBody>
		<itd-edit-page>
			<ng-template #left>
				<itd-profile-settings-edit-avatar></itd-profile-settings-edit-avatar>
			</ng-template>
			<ng-template #right>
				<mat-tab-group
					mat-align-tabs="start"
					[mat-stretch-tabs]="false"
					animationDuration="0ms"
					disableRipple="false"
					dynamicHeight
				>
					<div *ngFor="let tab of tabs">
						<mat-tab *ngIf="isUserAllowed(userRole, tab.roleRequirement)">
							<ng-template mat-tab-label>
								<mat-icon class="tab-icon">{{ tab.icon }}</mat-icon>
								{{ tab.label | translate }}
							</ng-template>
							<div class="itd-tabs-wrapper">
								<ng-container *ngComponentOutlet="tab.component"> </ng-container>
							</div>
						</mat-tab>
					</div>
				</mat-tab-group>
			</ng-template>
		</itd-edit-page>
	</ng-template>
</itd-main-template>
