<div
	class="container"
	itdDrop
	[ngClass]="{ 'is-active': isDraggedFocused }"
	(onFileDropped)="droppedFiles($event)"
	(onChangeDragStatus)="isDraggedFocused = $event"
>
	<label class="input-label">
		<input
			#file
			type="file"
			class="hidden-input"
			[multiple]="isMultiple"
			(change)="inputChange($event)"
			[accept]="allowedFormats"
		/>

		<div class="files" *ngIf="!isDraggedFocused && hasFiles">
			<div class="files__inner row">
				<div *ngFor="let item of value; let index = index">
					<button type="button" class="files-item" (click)="deleteFile($event, index)">
						<p class="files-item__name">{{ item.name }}</p>

						<itd-icon
							class="files-item__icon"
							icon="close"
							[width]="8"
							[height]="8"
						></itd-icon>
					</button>
				</div>
			</div>
		</div>
	</label>

	<div class="empty" *ngIf="!isDraggedFocused && !hasFiles">
		<itd-icon class="empty__icon" icon="file-small" [width]="208" [height]="208"></itd-icon>

		<p class="empty__label" *ngIf="label">
			{{ label }}
		</p>
	</div>

	<div class="dragged" *ngIf="isDraggedFocused">
		<p class="dragged__label">
			{{ 'CONTENT.MEDIA_UPLOAD.DROP_FILES_HERE' | translate }}
		</p>
	</div>
</div>

<ng-container *ngIf="message$ | async as message">
	<p *ngIf="invalid" class="validation">
		{{ message.text }}
	</p>
</ng-container>
