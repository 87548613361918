import { Injectable } from '@angular/core';
import { ApiClientService } from '../api/api.service';
import { Store } from '@ngxs/store';
import { AbstractApiService } from '../abstract/abstract-api-service';
import { map, Observable } from 'rxjs';
import { TResults } from '../models/results.interface';
import { AddLoading, RemoveLoading } from '../layout/layout.actions';

@Injectable({
	providedIn: 'root',
})
export class NetworkService extends AbstractApiService<any> {
	protected key = 'network/groups';

	constructor(protected api: ApiClientService, protected store: Store) {
		super(api, store);
	}

	public getOne(id: string): Observable<any> {
		this.store.dispatch(AddLoading);

		return this.api.get<TResults<any>>(`api/${this.key}/${id}`).pipe(
			map(response => {
				this.store.dispatch(RemoveLoading);
				return this.parse(response.data[0]);
			})
		);
	}

	public getAll(data): Observable<any[]> {
		this.store.dispatch(AddLoading);

		let params = {
			params: data,
		};

		return this.api.get<TResults<any[]>>(`api/${this.key}`, params).pipe(
			map(response => {
				this.store.dispatch(RemoveLoading);
				return response.data.map(module => this.parse(module));
			})
		);
	}

	public create(data: any): Observable<any> {
		const DTO = this.parseEditDTO(data);
		return this.api.post<any>(`api/${this.key}/create`, DTO).pipe(
			map(response => {
				if (response.success == false) {
					return response;
				}

				return this.parse(response.data);
			})
		);
	}

	public update(groupId: string | number, data: Partial<any>): Observable<any> {
		const DTO = this.parseEditDTO(data);
		return this.api.put<TResults<any>>(`api/${this.key}/${groupId}`, DTO).pipe(
			map(response => {
				return this.parse(response.data);
			})
		);
	}

	public addGroupMember(groupId: string, userId: string): Observable<any> {
		return this.api.put<TResults<any>>(`api/${this.key}/${groupId}/add-member/${userId}`).pipe(
			map(response => {
				return this.parse(response.data);
			})
		);
	}

	public removeGroupMember(groupId: string, userId: string): Observable<any> {
		return this.api.put<TResults<any>>(`api/${this.key}/${groupId}/remove-member/${userId}`).pipe(
			map(response => {
				return this.parse(response.data);
			})
		);
	}

	public getMyUnreadMessages(): Observable<any> {
		return this.api.get<TResults<any>>(`api/network/unread`).pipe(
			map(response => {
				return response.data;
			})
		);
	}

	public getGroupMessages(groupId: string): Observable<any[]> {
		return this.api.get<TResults<any[]>>(`api/${this.key}/${groupId}/messages`).pipe(
			map(response => {
				return response.data.map(module => this.parse(module));
			})
		);
	}

	public addGroupMessage(groupId: string, userId: string, message: string): Observable<any> {
		return this.api
			.put<TResults<any>>(`api/${this.key}/${groupId}/messages`, {
				user_id: userId,
				group_id: groupId,
				message: message,
			})
			.pipe(
				map(response => {
					return this.parse(response.data);
				})
			);
	}

	protected parse(value: any): any {
		return {
			...value,
		};
	}

	public parseEditDTO(value: any): any {
		return {
			...value,
		};
	}
}
