import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { AuthState } from './auth.state';

export const NoAuthGuard: CanActivateFn = () => {
	const store = inject(Store);
	const router = inject(Router);

	return store.select(AuthState.loggedIn).pipe(
		map(loggedIn => {
			if (loggedIn) {
				router.navigateByUrl('/user');

				return false;
			}

			return true;
		})
	);
};
