import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraphDoughnutComponent } from './components/graph-doughnut/graph-doughnut.component';
import { GraphLineComponent } from './components/graph-line/graph-line.component';
import exporting from 'highcharts/modules/exporting';
import offlineExporting from 'highcharts/modules/offline-exporting';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import more from 'highcharts/highcharts-more.src';
import { GraphMultipleAxisComponent } from './components/graph-multiple-axis/graph-multiple-axis.component';
import { GraphLineAndColumnComponent } from './components/graph-line-column/graph-line-column.component';
import { GraphColumnComponent } from './components/graph-column/graph-column.component';
import { GraphMultiLineComponent } from './components/multi-graph-line/multi-graph-line.component';
import { GraphFlexibleComponent } from './components/graph-flexible/graph-flexible.component';

const declarations = [
	GraphDoughnutComponent,
	GraphLineComponent,
	GraphMultiLineComponent,
	GraphMultipleAxisComponent,
	GraphLineAndColumnComponent,
	GraphColumnComponent,
	GraphFlexibleComponent,
];

@NgModule({
	imports: [CommonModule, ChartModule, TranslateModule, ...declarations],
	exports: [...declarations],
	providers: [
		{ provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting, offlineExporting] },
	],
})
export class GraphsModule {}
