import { FormControl } from '@angular/forms';

export function emailValidator(c: FormControl) {
	const value = c.value as string;
	if (!c.value) {
		return null;
	}

	const regexp = new RegExp(
		/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
	);

	return regexp.test(value)
		? null
		: {
				email: {
					valid: false,
				},
		  };
}
