<button (click)="toggle()" class="button">
	<div class="title">
		{{ title }}
	</div>
	<span class="close-icon" [ngClass]="{ isActive: isActive }"></span>
</button>

<div class="container" *ngIf="isActive" [@expandAnimation]>
	<ng-content></ng-content>
</div>
