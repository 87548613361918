import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiClientService } from '../api/api.service';
import { AddLoading, RemoveLoading } from '../layout/layout.actions';
import { TResults } from '../models/results.interface';

export abstract class AbstractApiService<
	Interface = any,
	InterfaceDTO = Interface,
	InterfaceEdit = Interface,
	InterfaceEditDTO = InterfaceDTO
> {
	// Endpoint path
	protected abstract key: string;

	constructor(protected api: ApiClientService, protected store: Store) {}

	public getOne(id: string): Observable<Interface> {
		this.store.dispatch(AddLoading);

		return this.api.get<TResults<InterfaceDTO>>(`api/${this.key}/${id}`).pipe(
			map(response => {
				this.store.dispatch(RemoveLoading);
				return this.parse(response.data);
			})
		);
	}

	public getAll(
		params = {
			params: {
				limit: 9999,
			},
		}
	): Observable<Interface[]> {
		this.store.dispatch(AddLoading);

		return this.api.get<TResults<InterfaceDTO[]>>(`api/${this.key}`, params).pipe(
			map(response => {
				this.store.dispatch(RemoveLoading);
				return response.data.map(module => this.parse(module));
			})
		);
	}

	public delete(id: number | string): Observable<boolean> {
		return this.api.delete<TResults>(`api/${this.key}/${id}`).pipe(
			map(response => {
				return response.data;
			})
		);
	}

	public create(data: InterfaceEdit): Observable<Interface> {
		const DTO = this.parseEditDTO(data);

		return this.api.post<TResults>(`api/${this.key}`, DTO).pipe(
			map(response => {
				return this.parse(response.data);
			})
		);
	}

	public update(id: number | string, data: Partial<InterfaceEdit>): Observable<Interface> {
		const DTO = this.parseEditDTO(data);

		return this.api.put<TResults>(`api/${this.key}/${id}`, DTO).pipe(
			map(response => {
				return this.parse(response.data);
			})
		);
	}

	public duplicate(id: number | string, deepCopy: boolean): Observable<Interface> {
		return this.api.post<TResults>(`api/${this.key}/${id}/duplicate?deepcopy=${deepCopy}`).pipe(
			map(response => {
				return this.parse(response.data);
			})
		);
	}

	protected abstract parse(value: InterfaceDTO): Interface;
	protected abstract parseEditDTO(value: Partial<InterfaceEdit>): InterfaceEditDTO;
}
