import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'itd-grid-item',
    templateUrl: './grid-item.component.html',
    styleUrls: ['./grid-item.component.scss'],
    standalone: true,
})
export class GridItemComponent {
	@HostBinding('class.is-mobile-responsive')
	@Input()
	public mobileResponsive: boolean = true;

	@HostBinding('style.maxWidth')
	@Input()
	public size: number | string;

	constructor() {}
}
