import { WithDestroyComponent } from 'src/app/core/abstract/abstract-with-destroy-component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { routeAnimations } from 'src/app/core/animations/router.animation';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { SidenavService } from 'src/app/utility-modules/sidebar/matsidebar.service';
import { Store } from '@ngxs/store';
import { ProfileState } from 'src/app/core/profile/profile.state';
import { FooterNavigationSideDrawerMobileComponent } from '../../components/footer-navigation-side-drawer-mobile/footer-navigation-side-drawer-mobile.component';
import { FooterNavigationMobileComponent } from '../../components/footer-navigation-mobile/footer-navigation-mobile.component';
import { ModuleUsedInTimelinesComponent } from '../../../utility-modules/sidebar/module-used-in-timelines/module-used-in-timelines.component';
import { UserSideDrawerComponent } from '../../components/user-sidedrawer/user-sidedrawer.component';
import { FiltersComponent } from '../../components/filters/filters.component';
import { SidebarComponent } from '../../components/sidebar/sidebar.component';
import { HeaderMobileComponent } from '../../components/header-mobile/header-mobile.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'itd-main-page',
    templateUrl: './main-page.component.html',
    styleUrls: ['./main-page.component.scss'],
    animations: [routeAnimations],
    standalone: true,
    imports: [
        NgIf,
        HeaderMobileComponent,
        SidebarComponent,
        MatSidenavModule,
        FiltersComponent,
        UserSideDrawerComponent,
        ModuleUsedInTimelinesComponent,
        RouterOutlet,
        FooterNavigationMobileComponent,
        FooterNavigationSideDrawerMobileComponent,
    ],
})
export class MainPageComponent extends WithDestroyComponent implements OnInit {
	@ViewChild('drawer') public sidenav: MatSidenav;
	public isMobile: boolean;
	isStdUser = true;

	constructor(public sidenavService: SidenavService, private store: Store) {
		super();
	}

	public ngOnInit() {
		this.isMobile = window.innerWidth <= 768 ? true : false;
		this.isStdUser = this.store.selectSnapshot(ProfileState.role) === 'std_user';

		// TODO: Can we update state based on the above and set 'LayoutMobileState.isMobile' to be used elsewhere?
	}
	ngAfterViewInit(): void {
		this.sidenavService.setSidenav(this.sidenav);
	}

	public prepareRoute(outlet: RouterOutlet) {
		return outlet && !this.isMobile && outlet.activatedRouteData;
	}
}
