import { StateContext, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ProfileState } from '../profile/profile.state';
import { removeAllInOtherDepartments } from '../utils/departments.util';
import { AbstractApiService } from './abstract-api-service';

export interface IDeletePayload {
	id: string | string;
}

export abstract class AbstractApiState<
	Interface extends { id: string | number },
	InterfaceDTO = Interface,
	InterfaceEdit = Interface,
	InterfaceEditDTO = InterfaceDTO
> {
	constructor(
		protected service: AbstractApiService<Interface, InterfaceDTO, InterfaceEdit, InterfaceEditDTO>,
		protected store: Store
	) {}

	protected loadOne(id: string): Observable<Interface> {
		return this.service.getOne(id);
	}
	protected loadAllAndAddToState(ctx: StateContext<{ items: Interface[] }>, params?) {
		const { patchState, getState } = ctx;
		const { items } = getState();
		return this.service.getAll(params).pipe(
			tap(responseItems => {
				patchState({
					items: [...items, ...responseItems],
				});
			})
		);
	}
	protected loadAll(ctx: StateContext<{ items: Interface[] }>, params?) {
		const { patchState } = ctx;
		return this.service.getAll(params).pipe(
			tap(items => {
				patchState({
					items: this.store.selectSnapshot(ProfileState.isGlobalAdmin)
						? items
						: removeAllInOtherDepartments(
								this.store.selectSnapshot(ProfileState.departmentId),
								this.store.selectSnapshot(ProfileState.role),
								items
						  ),
				});
			})
		);
	}

	protected loadAllIfEmpty(ctx: StateContext<{ items: Interface[] }>, params?) {
		const { items } = ctx.getState();

		if (items) {
			return;
		}

		return this.loadAll(ctx, params);
	}

	protected create(
		ctx: StateContext<{ items: Interface[] }>,
		_data: { payload: { data: InterfaceEdit } }
	) {
		const { data } = _data.payload;

		return this.service.create(data).pipe(
			tap(result => {
				const _items = ctx.getState().items;
				let items = null;

				if (!_items || !_items.length) {
					items = [result];
				} else {
					items = [..._items, result];
				}

				ctx.patchState({
					items,
				});
			})
		);
	}

	protected update(
		ctx: StateContext<{ items: Interface[] }>,
		_data: { payload: { id: string; data: Partial<InterfaceEdit> } }
	) {
		const { id, data } = _data.payload;

		return this.service.update(id, data).pipe(
			tap(result => {
				const _items = ctx.getState().items;

				if (!_items || !_items.length) {
					return;
				}

				const items = [..._items];
				const index = items.findIndex(item => item.id === id);

				if (index === -1) {
					return;
				}

				items.splice(index, 1, result);

				ctx.patchState({
					items,
				});

				return result;
			})
		);
	}

	protected delete(ctx: StateContext<{ items: Interface[] }>, data: { payload: IDeletePayload }) {
		const { id } = data.payload;

		return this.service.delete(id).pipe(
			tap(() => {
				const _items = ctx.getState().items;

				if (!_items || !_items.length) {
					return;
				}

				const items = [..._items];
				const index = items.findIndex(item => item.id === id);

				if (index === -1) {
					return;
				}

				items.splice(index, 1);

				ctx.patchState({
					items,
				});
			})
		);
	}

	protected duplicate(
		ctx: StateContext<{ items: Interface[] }>,
		_data: { payload: { id: string | number; deepCopy?: boolean } }
	) {
		const { id, deepCopy } = _data.payload;

		return this.service.duplicate(id, deepCopy).pipe(
			tap(result => {
				const _items = ctx.getState().items;

				if (!_items || !_items.length) {
					return;
				}

				const items = [..._items, result];

				ctx.patchState({
					items,
				});
			})
		);
	}
}
