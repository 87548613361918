import { AsyncPipe, NgClass, NgIf, NgStyle } from '@angular/common';
import {
	AfterContentInit,
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Optional,
	Output,
	ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { IconComponent } from '../../../icon/components/icon/icon.component';
import { AbstractInputComponent } from '../abstract-input/abstract-input.component';

export type TInputType =
	| 'text'
	| 'password'
	| 'tel'
	| 'email'
	| 'number'
	| 'positiveNegativeNumbers';

@Component({
	selector: 'itd-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		NgClass,
		NgStyle,
		NgIf,
		ReactiveFormsModule,
		FormsModule,
		NgxIntlTelInputModule,
		IconComponent,
		AsyncPipe,
		TranslateModule,
	],
})
export class InputComponent extends AbstractInputComponent<string>
	implements OnInit, AfterContentInit, AfterViewInit, ControlValueAccessor {
	@Input() public type: TInputType = 'text';
	@Input() public minWidth?: number = 0;
	@Input() public maxWidth?: number;
	@Input() public icon: string;
	@Input() public iconPosition: 'left' | 'right' = 'right';
	@Input() public searchWithIcon: boolean = false;

	@Input() public label?: string;
	@Input() public sufix?: string;
	@Input() public disabledKeys: Array<string | RegExp> = [];
	@Input() public allowedKeys: Array<string | RegExp> = [];
	@Input() public disabled: boolean = false;
	@Input() public focusAfterInit: boolean = false;
	@Input() public alwaysActive: boolean = false;
	@Input() public labelOptional: boolean = true;
	@Input() public labelRequired: boolean = false;
	@Input() public isActiveHideLabel: boolean = false;
	@Input() public hasAutocomplete: boolean = false;
	@Input() public height?: number;
	@Input() public isInline?: boolean;
	@Input() public hasDelete?: boolean;
	@Input() public hasSend?: boolean;
	@Input() public pattern?: boolean;
	@Input() public canClear: boolean;
	@Input() public isRequired: boolean = false;

	@Input() public controlInvalid?: boolean = false;
	@Input() public invalidMessage?: string = 'This field is required';

	@Input() public maxLength?: number;
	@Input() public labelMaxValueLengthType: 'default' | 'hidden' | 'warning' = 'default';

	@Output() public inputkey = new EventEmitter<void>();
	@Output() public onDelete = new EventEmitter<void>();
	@Output() public onSend = new EventEmitter<void>();

	@ViewChild('input', { static: false }) public inputRef: ElementRef<HTMLInputElement>;

	public initialType: TInputType;
	public caretPosition: number;

	private defaultDisabledKeys: { [key: string]: Array<string | RegExp> } = {
		// 'text': ['a', /[0-9]/]
		// 'email': [/[\sąĄćĆęĘłŁńŃóÓśŚźŹżŻ]/]
	};

	private defaultAllowedKeys: { [key: string]: Array<string | RegExp> } = {
		tel: ['([0-9+]+)'],
		number: ['([0-9]+)'],
	};

	public get iconStyle(): string {
		switch (this.iconPosition) {
			case 'left':
				return 'left: 10px';
			case 'right':
				return 'right: 10px';
		}
	}

	public get hasIcon(): boolean {
		return !!(this.icon || this.isPassword || this.hasDelete);
	}

	public get hasSpaceLeft(): boolean {
		if (this.hasIcon && this.iconPosition === 'left') {
			return true;
		}

		return false;
	}

	public get hasSpaceRight(): boolean {
		if (this.hasIcon && this.iconPosition === 'right') {
			return true;
		}

		return false;
	}

	public get hasMaxValueLengthLabel(): boolean {
		if (this.type === 'tel' || !this.maxLength) {
			return;
		}

		if (this.labelMaxValueLengthType !== 'hidden') {
			if (this.labelMaxValueLengthType === 'warning') {
				return this.hasMaxValueLengthWarning;
			}

			return true;
		}

		return;
	}

	public get hasMaxValueLengthWarning(): boolean {
		if (!this.maxLength || !this.actualLength) {
			return;
		}

		return this.maxLength - this.actualLength <= 10;
	}

	public get isPassword(): boolean {
		return this.initialType === 'password';
	}

	constructor(@Optional() control: NgControl, validationMessages: ValidationMessagesService) {
		super(control, validationMessages);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		this.initialType = this.type;

		if (this.type === 'email') {
			this.type = 'text';
		}
	}

	public ngAfterContentInit(): void {
		setTimeout(() => {
			if (this.focusAfterInit && this.inputRef) {
				this.inputRef.nativeElement.focus();
			}
		});
	}

	public toggleType(type: TInputType = 'text') {
		this.type = this.type !== type ? type : this.initialType;
	}

	public onModelChange(value: string): void {
		this.writeValue(value.toString());
	}

	public writeValue(value: string): void {
		if (value) {
			value = value.toString();
			value = this.parseValue(value);
		}

		const hasRenderedElement = !!this.inputRef && !!this.inputRef.nativeElement;

		if (hasRenderedElement) {
			this.caretPosition = this.inputRef.nativeElement.selectionEnd;

			this.inputRef.nativeElement.value = value || '';
		}

		super.writeValue(value);
	}

	public setCaretPosition(value?: number) {
		if (!this.inputRef) {
			return;
		}

		if (!value) {
			value = this.inputRef.nativeElement.value.length;
		}

		if (this.inputRef.nativeElement.type === 'number') {
			return;
		}

		this.inputRef.nativeElement.selectionStart = value;
		this.inputRef.nativeElement.selectionEnd = value;
	}

	public parseNewKey(event: KeyboardEvent): boolean {
		const key = event.key;
		const ignoredKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Del', 'Enter'];

		if (ignoredKeys.indexOf(key) !== -1 || event.ctrlKey) {
			return true;
		}

		const results: boolean[] = [];
		const allowed = this.defaultAllowedKeys[this.type];
		const disabled = [...(this.defaultDisabledKeys[this.type] || []), ...this.disabledKeys];

		if (allowed) {
			results.push(...allowed.map(s => new RegExp(s as string, 'g').test(key) === true));
		}

		if (disabled.length > 0) {
			results.push(...disabled.map(s => new RegExp(s as string, 'g').test(key) === false));
		}

		this.inputkey.emit();
		return results.indexOf(false) === -1;
	}

	public parseValue(value: string): string {
		const allowed = [...(this.defaultAllowedKeys[this.type] || []), ...this.allowedKeys];
		const disabled = [...(this.defaultDisabledKeys[this.type] || []), ...this.disabledKeys];

		if (allowed) {
			for (const allowedChar of allowed) {
				const reg = typeof allowedChar === 'string' ? new RegExp(allowedChar, 'g') : allowedChar;
				const match = value.match(reg);

				if (match) {
					value = match.join('');
				}
			}
		}

		if (disabled.length > 0) {
			for (const disabledChar of disabled) {
				const reg = new RegExp(disabledChar as string, 'g');
				value = value.replace(reg, '');
			}
		}

		switch (this.initialType) {
			// case 'tel':
			// 	value = this.parseTelValue(value);
			// 	break;
			case 'email':
				value = value.toLowerCase();
				break;
		}

		if (this.maxLength && value) {
			value = value.substring(0, this.maxLength);
		}

		return value;
	}

	public clear(): void {
		this.value = '';
		this.writeValue(this.value);

		if (this.inputRef && this.inputRef.nativeElement) {
			this.inputRef.nativeElement.focus();
		}
	}

	public setFocus(isFocus: boolean): void {
		super.setFocus(isFocus);
	}

	public clickDelete() {
		this.onDelete.emit();
	}

	public clickSend() {
		this.onSend.emit();
	}
}
