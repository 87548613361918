import { IMessageTypeEdit } from '../messages/messages.interface';
import { IOrganizationEdit } from './organizations.interface';

export class GetMyOrganization {
	public static readonly type = '[Organization] Get';
}

export class LoadAllOrganizations {
	public static readonly type = '[Organization] Load All';
}

export class LoadAllOrganizationsIfEmpty {
	public static readonly type = '[Organization] Load All If Empty';
}

export class CreateOrganization {
	public static readonly type = '[Organization] Create';
	constructor(public payload: { data: IOrganizationEdit }) {}
}

export class UpdateOrganization {
	public static readonly type = '[Organization] Update';
	constructor(public payload: { id: string; data: IOrganizationEdit }) {}
}

export class UpdateOrganizationMessage {
	public static readonly type = '[Organization] Update Message';
	constructor(public payload: { data: IMessageTypeEdit }) {}
}

export class DeleteOrganization {
	public static readonly type = '[Organization] Delete';
	constructor(public payload: { id: string }) {}
}
