import { State, Selector, Action, StateContext, createSelector } from '@ngxs/store';
import {
	SetLanguage,
	AddLoading,
	RemoveLoading,
	ResetLoading,
	SetColorPrimary,
	SetTableSnapshot,
	SetTableSnapshotFilterQuery,
	SetFontPrimary,
} from './layout.actions';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutMobileState } from './children/layout-mobile.state';
import { ITableSnapshot } from 'src/app/utility-modules/table/models/table-snapshot.interface';
import { lightenDarkenColor } from '../utils/lighten-darken-color';
import { AMThemeColor, computeColors, updateThemeColors } from './amThemeHelper';

export interface ILayoutStateModel {
	primaryColor: string;
	primaryFont: string;
	loading: number;
	language: string;
	tableSnapshots: { [key: string]: ITableSnapshot };
}

@State<ILayoutStateModel>({
	name: 'layout',
	defaults: {
		primaryColor: '#56418e',
		primaryFont: 'Sofia Pro, Helvetica, sans-serif',
		language: null,
		loading: 0,
		tableSnapshots: {},
	},
	children: [LayoutMobileState],
})
@Injectable()
export class LayoutState {
	constructor(private translateService: TranslateService) {}

	@Selector()
	public static getState(state: ILayoutStateModel) {
		return state;
	}

	public static tableSnapshot(id: string) {
		return createSelector([this], (state: ILayoutStateModel) => {
			return state.tableSnapshots[id];
		});
	}

	@Action(SetTableSnapshot)
	public setDataSourceQueryData(
		{ patchState, getState }: StateContext<ILayoutStateModel>,
		{ payload }: SetTableSnapshot
	) {
		const data = getState().tableSnapshots;
		data[payload.name] = payload.data;

		patchState({
			tableSnapshots: data,
		});
	}

	@Action(SetTableSnapshotFilterQuery)
	public setTableSnapshotFilterQuery(
		{ patchState, getState }: StateContext<ILayoutStateModel>,
		{ payload }: SetTableSnapshotFilterQuery
	) {
		const data = getState().tableSnapshots;
		data[payload.name] = data[payload.name] || {
			dataSourceSettings: {
				searchQuery: '',
				sortType: '',
			},
		};

		data[payload.name].dataSourceSettings.searchQuery = payload.searchQuery;

		patchState({
			tableSnapshots: data,
		});
	}

	@Selector()
	public static colorPrimary(state: ILayoutStateModel): string {
		return state.primaryColor;
	}

	@Selector()
	public static fontPrimary(state: ILayoutStateModel): string {
		return state.primaryFont;
	}

	@Action(SetColorPrimary)
	public setColorPrimary(
		{ patchState }: StateContext<ILayoutStateModel>,
		{ payload }: SetColorPrimary
	) {
		const { color } = payload;

		patchState({
			primaryColor: color,
		});

		const amPrimaryColors = computeColors(color);
		const amPrimaryColor: AMThemeColor = 'primary';
		updateThemeColors(amPrimaryColors, amPrimaryColor);
		const amAccentColors = computeColors(color);
		const amAccentColor: AMThemeColor = 'accent';
		updateThemeColors(amAccentColors, amAccentColor);

		document.documentElement.style.setProperty('--color-primary', color);
		document.documentElement.style.setProperty(
			'--color-primary-lighten',
			lightenDarkenColor(color, 150)
		);
	}

	@Action(SetFontPrimary)
	public SetFontPrimary(
		{ patchState }: StateContext<ILayoutStateModel>,
		{ payload }: SetFontPrimary
	) {
		const { font } = payload;
		const customFontOrDefault = font || 'Sofia Pro, Helvetica, sans-serif';

		patchState({
			primaryFont: font,
		});

		document.documentElement.style.setProperty('--font-primary', customFontOrDefault);
	}

	@Action(SetLanguage)
	public setLanguage({ patchState }: StateContext<ILayoutStateModel>, { payload }: SetLanguage) {
		const { language } = payload;

		patchState({
			language,
		});

		this.translateService.setDefaultLang(language);
	}

	@Selector()
	public static language(state: ILayoutStateModel): string {
		return state.language;
	}

	@Selector()
	public static loading(state: ILayoutStateModel): number {
		return state.loading;
	}

	@Action(AddLoading)
	public addLoading(ctx: StateContext<ILayoutStateModel>) {
		const loading = ctx.getState().loading + 1;

		ctx.patchState({
			loading,
		});
	}

	@Action(RemoveLoading)
	public removeLoading(ctx: StateContext<ILayoutStateModel>) {
		let loading = ctx.getState().loading - 1;

		if (loading < 0) {
			loading = 0;
		}

		ctx.patchState({
			loading,
		});
	}

	@Action(ResetLoading)
	public resetLoading(ctx: StateContext<ILayoutStateModel>) {
		ctx.patchState({
			loading: 0,
		});
	}
}
