import { Component, Input } from '@angular/core';
import { HeadwayWidgetId, HeadwayWidgetTriggerClassName } from '../headwayWidgetConstants';

@Component({
    selector: 'headway-widget-trigger',
    templateUrl: './headway-widget-trigger.component.html',
    styleUrls: ['./headway-widget-trigger.component.scss'],
    standalone: true,
})
export class HeadwayWidgetTriggerComponent {
	@Input() widgetId: string = HeadwayWidgetId;
	headwayWidgetTriggerClassName = HeadwayWidgetTriggerClassName;
}
