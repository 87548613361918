import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnInit,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { lastValueFrom, Observable } from 'rxjs';
import { IDepartment } from 'src/app/core/department/department.interface';
import { DepartmentState } from 'src/app/core/department/department.state';
import { IOrganization } from 'src/app/core/organizations/organizations.interface';
import { OrganizationState } from 'src/app/core/organizations/organizations.state';
import { UpdateProfileAlertConfiguration } from 'src/app/core/profile/profile.actions';
import { IProfileAlertConfiguration } from 'src/app/core/profile/profile.interface';
import { ProfileState } from 'src/app/core/profile/profile.state';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { AbstractFormComponent } from 'src/app/utility-modules/itd-form/components/abstract-form/abstract-form.component';
import { NotificationService } from 'src/app/utility-modules/notification/services/notification.service';
import { ButtonComponent } from '../../../../../utility-modules/itd-form/components/button/button.component';
import { FormErrorComponent } from '../../../../../utility-modules/itd-form/components/form-error/form-error.component';
import { IconComponent } from '../../../../../utility-modules/icon/components/icon/icon.component';
import { InputComponent } from '../../../../../utility-modules/itd-form/components/input/input.component';
import { CheckboxComponent } from '../../../../../utility-modules/itd-form/components/checkbox/checkbox.component';
import { GridItemComponent } from '../../../../../utility-modules/grid/components/grid-item/grid-item.component';
import { GridComponent } from '../../../../../utility-modules/grid/components/grid/grid.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
	selector: 'itd-profile-settings-alerts',
	templateUrl: './profile-settings-alerts.component.html',
	styleUrls: ['./profile-settings-alerts.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		ReactiveFormsModule,
		NgIf,
		GridComponent,
		GridItemComponent,
		CheckboxComponent,
		InputComponent,
		NgFor,
		IconComponent,
		FormErrorComponent,
		ButtonComponent,
		AsyncPipe,
		TranslateModule,
	],
})
export class ProfileSettingsAlertsComponent
	extends AbstractFormComponent<IProfileAlertConfiguration>
	implements OnInit {
	public form = new FormGroup({
		index_under: new FormControl(false),
		index_over: new FormControl(false),
		index_under_threshold: new FormControl(20),
		index_over_threshold: new FormControl(80),
		// TODO: This wont save for orgs without fasttrack because of FE and BE validations
		// index_under_threshold: new FormControl(0, [Validators.min(1), Validators.max(99)]),
		// index_over_threshold: new FormControl(0, [Validators.min(1), Validators.max(99)]),
		not_answered: new FormControl(false),
		receive_email: new FormControl(false),
		receive_sms: new FormControl(false),
	});
	public departments: IDepartment[];
	@Select(OrganizationState.organization) public organization$: Observable<IOrganization>;

	constructor(
		public store: Store,
		public validationMessages: ValidationMessagesService,
		public elementRef: ElementRef,
		public cd: ChangeDetectorRef,
		private notification: NotificationService,
		private translate: TranslateService
	) {
		super(cd);
	}

	public async ngOnInit(): Promise<void> {
		const alertConfiguration = this.store.selectSnapshot(ProfileState.alertConfiguration);
		const userRole = this.store.selectSnapshot(ProfileState.role);

		// Only loads departments if not std user.
		if (userRole !== 'std_user') {
			this.departments = this.store.selectSnapshot(DepartmentState.departments);
		}

		this.form.patchValue(alertConfiguration);
		super.afterInit();
	}

	// TODO Fix error:
	// It looks like you're using the disabled attribute with a reactive form directive. If you set disabled to true
	// when you set up this control in your component class, the disabled attribute will actually be set in the DOM for
	// you. We recommend using this approach to avoid 'changed after checked' errors.

	// Example:
	// // Specify the `disabled` property at control creation time:
	// form = new FormGroup({
	//   first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
	//   last: new FormControl('Drew', Validators.required)
	// });

	// // Controls can also be enabled/disabled after creation:
	// form.get('first')?.enable();
	// form.get('last')?.disable();
	// * We need to find a better way of setting disabled for some fields

	public isChecked(field: string) {
		return this.form.get(field).value === false;
	}

	public onSubmitAction() {
		const alertConfiguration = this.formValue;

		return this.store.dispatch(new UpdateProfileAlertConfiguration(alertConfiguration));
	}

	public async onSubmitSuccess() {
		const text = await lastValueFrom(this.translate.get(`BACKEND_MESSAGES.USER_UPDATE_SUCCESS`));

		this.notification.add({
			text,
		});
	}
}
