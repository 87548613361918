<div
	class="container"
	[ngClass]="{
		'is-active': value,
		'is-focus': isFocus,
		'is-error': invalid,
		'is-warning': isWarning,
		hasShadow: hasShadow,
		hasSideLabel: sideLabel
	}"
>
	<label class="label">
		{{ label | translate }}
	</label>

	<div (click)="openSelectUserModal()" class="hasLabel" [ngClass]="{ hasBorder: hasBorder }">
		<input
			type="text"
			class="input size-{{ sizeType }}"
			[ngStyle]="{ 'width.px': width }"
			autocomplete="off"
			[placeholder]="placeholder | translate"
			[(ngModel)]="currentlySelectedUserName"
		/>
		<div class="icon">
			<itd-icon [icon]="'user'" [width]="12" [height]="12"></itd-icon>
		</div>
		<div class="icon-reset" *ngIf="value" (click)="removeSelected($event)">
			<div class="clear-Icon ng-clear-wrapper" (click)="removeSelected($event)"></div>
		</div>
	</div>

	<div *ngIf="message$ | async as message" class="input-group__validation">
		<div *ngIf="invalid" [innerHTML]="message.text"></div>
	</div>
</div>
