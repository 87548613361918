import {
	DeleteAchievement,
	LoadAchievement,
	UpdateAchievement,
} from './../../../../../../core/achievements/achievements.action';
import { firstValueFrom, Observable } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { achievementsIcons } from 'src/app/core/achievements/achievements-icons';
import { IAchievement } from 'src/app/core/achievements/achievements.interface';
import { ConfirmDeleteModalComponent } from 'src/app/shared/modals/confirm-delete-modal/confirm-delete-modal.component';
import { AdminContentAchievementEditModalComponent } from '../../modals/admin-content-achievement-edit-modal/admin-content-achievement-edit-modal.component';
import { ProfileState } from 'src/app/core/profile/profile.state';
import { IProfile } from 'src/app/core/profile/profile.interface';
import { IDepartment } from 'src/app/core/department/department.interface';
import { DepartmentState } from 'src/app/core/department/department.state';
import { SelectDepartmentModalComponent } from '../../../admin-employees/modals/select-department-modal/select-department-modal.component';
import { FindDepartmentService } from 'src/app/utility-modules/find-department/findDepartment.service';
import { MenuItemComponent } from '../../../../../../shared/components/menu/menu-item/menu-item.component';
import { MenuComponent } from '../../../../../../shared/components/menu/menu.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'itd-admin-content-achievement-preview',
    templateUrl: './admin-content-achievement-preview.component.html',
    styleUrls: ['./admin-content-achievement-preview.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MenuComponent,
        MenuItemComponent,
        TranslateModule,
    ],
})
export class AdminContentAchievementPreviewComponent implements OnInit {
	@Input() public achievement: IAchievement;
	@Input() public menuEnabled: boolean = true;
	@Select(ProfileState.profile) public user$: Observable<IProfile>;
	@Select(DepartmentState.departments) public departments$: Observable<IDepartment[]>;
	public userDepartmentId: string;
	public isGlobalAdmin: boolean;

	ngOnInit(): void {
		this.user$.subscribe((usr) => {
			this.userDepartmentId = usr?.department_id;
			this.isGlobalAdmin = usr?.user_role === 'super_admin' || usr?.user_role === 'org_admin';
		});
	}

	public get iconFilename(): string {
		return achievementsIcons[this.achievement.iconId].filename;
	}

	public get backgroundGradient(): string {
		if (this.achievement?.theme) {
			const { gradientStart, gradientStop } = this.achievement.theme;

			return `linear-gradient(${gradientStart}, ${gradientStop})`;
		}
	}

	constructor(
		public store: Store,
		private modals: NgbModal,
		private translate: TranslateService,
		public departmentServ: FindDepartmentService
	) {}

	public async delete() {
		const component = this.modals.open(ConfirmDeleteModalComponent)
			.componentInstance as ConfirmDeleteModalComponent;

		const _content: string = await firstValueFrom(
			this.translate.get(`CONTENT.ACHIEVEMENTS.DELETE_CONFIRM`)
		);

		const { title } = this.achievement;

		component.data = {
			content: _content.replace('{{title}}', title),
			confirmText: title,
		};

		component.onSubmitAction = () => {
			const { id } = this.achievement;

			return this.store.dispatch(new DeleteAchievement({ id }));
		};
	}

	public edit() {
		const component = this.modals.open(AdminContentAchievementEditModalComponent)
			.componentInstance as AdminContentAchievementEditModalComponent;

		component.achievement = this.achievement;
	}

	public hasPermission(achievement: IAchievement) {
		if (this.isGlobalAdmin) {
			return true;
		}

		if (achievement.department_id) {
			if (achievement.department_id.includes(this.userDepartmentId)) {
				return true;
			}
		}

		return false;
	}

	public addDepartment(achievement: IAchievement) {
		const component = this.modals.open(SelectDepartmentModalComponent)
			.componentInstance as SelectDepartmentModalComponent;
		component.user = null;
		component.achievement = achievement;
		component.selectedDepartmentId = null;
	}

	public changeDepartment(achievement: IAchievement) {
		const component = this.modals.open(SelectDepartmentModalComponent)
			.componentInstance as SelectDepartmentModalComponent;
		component.user = null;
		component.achievement = achievement;
		component.selectedDepartmentId = achievement.department_id;
	}

	public removeDepartment(achievement: IAchievement) {
		this.store
			.dispatch(
				new UpdateAchievement({
					id: achievement.id,
					data: {
						department_id: null,
					},
				})
			)
			.subscribe(() => {
				this.store.dispatch(new LoadAchievement({ id: achievement.id }));
			});
	}
}
