import { Component, Inject, Input, OnInit } from '@angular/core';
import { MediaService } from 'src/app/core/media/media.service';
import { IUser } from 'src/app/core/users/users.interface';
import { IEnvironment } from 'src/environments/environment.interface';
import { NgStyle, NgIf, NgClass } from '@angular/common';
import { UserAvatarComponent } from '../user-avatar/user-avatar.component';

@Component({
    selector: 'itd-user-tasks-image',
    templateUrl: './user-tasks-image.component.html',
    styleUrls: ['./user-tasks-image.component.scss'],
    standalone: true,
    imports: [
        UserAvatarComponent,
        NgStyle,
        NgIf,
        NgClass,
    ],
})
export class UserTasksImageComponent implements OnInit {
	@Input() public user: Partial<IUser>;
	@Input() public numberOfTasks: number;
	@Input() public height: number;
	@Input() public width: number;
	@Input() public secondary: boolean = false;
	@Input() public showUserName: boolean = false;
	public fullNameString;

	constructor(@Inject('ENV') public env: IEnvironment, protected mediaService: MediaService) {}

	ngOnInit(): void {
		this.height = this.height | 34;
		this.width = this.width | 34;

		if (this.showUserName) {
			this.getUserName();
		}
	}

	private getUserName() {
		if (this.user?.first_name && this.user?.last_name) {
			this.fullNameString = `${this.user?.first_name} ${this.user?.last_name}`;
		} else if (this.user?.first_name && !this.user?.last_name) {
			this.fullNameString = `${this.user?.first_name}`;
		}
	}
}
