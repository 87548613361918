import { AsyncPipe, NgClass, NgIf, NgStyle } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	Optional,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import dayjs, { Dayjs } from 'dayjs';
import { DpDatePickerModule, IDatePickerDirectiveConfig } from 'ng2-date-picker';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { IconComponent } from '../../../icon/components/icon/icon.component';
import { AbstractInputComponent } from '../abstract-input/abstract-input.component';

@Component({
	selector: 'itd-input-date',
	templateUrl: './input-date.component.html',
	styleUrls: ['./input-date.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		NgClass,
		NgIf,
		ReactiveFormsModule,
		FormsModule,
		NgxIntlTelInputModule,
		NgStyle,
		DpDatePickerModule,
		IconComponent,
		AsyncPipe,
		TranslateModule,
	],
})
export class InputDateComponent extends AbstractInputComponent<Dayjs>
	implements OnInit, AfterViewInit, ControlValueAccessor {
	public config: IDatePickerDirectiveConfig;
	@Input() public icon = 'calendar-stroke';
	@Input() public minDate = null;
	@Input() public maxDate = null;
	@Input() public startDate: string;
	@Input() public clearIcon: boolean = false;
	@Input() public width: number;
	@Input() public isDisabledInput: boolean = false;

	constructor(@Optional() control: NgControl, validationMessages: ValidationMessagesService) {
		super(control, validationMessages);
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this.config = {
			hideInputContainer: true,
			showGoToCurrent: false,
			dayBtnFormat: 'D',
			firstDayOfWeek: 'mo',
			weekDayFormat: 'dd',
			closeOnSelect: true,
			monthFormat: 'MMMM YYYY',
			format: 'DD-MM-YYYY',
			min: this.minDate,
			max: this.maxDate,
		};
	}

	public get dataPickerValue(): string {
		if (this.startDate) {
			return this.startDate;
		}
		if (!this.value) {
			return;
		}

		if (typeof this.value === 'string') {
			this.value = dayjs(this.value);
		}

		return this.value.format(this.config.format);
	}

	public set dataPickerValue(value: string) {}

	public onModelValueChange(value: any): Dayjs {
		if (!value) {
			return;
		}

		this.writeValue(value);
	}

	public clearDate(): void {
		this.writeValue(null);
	}
}
