<itd-modal [size]="800">
	<ng-template #header>
		{{ 'TASK_TEMPLATE.TITLE' | translate }}
	</ng-template>

	<ng-template #content>
		<div class="content">
			<div class="list" *ngIf="tasklists$ | async">
				<div *ngFor="let taskTemplate of tasklists$ | async">
					<div class="item" (click)="selectTaskTemplate(taskTemplate)">
						<div class="close-and-text">
							<itd-checkbox
								[value]="selected(taskTemplate)"
								(write)="selectTaskTemplate(taskTemplate)"
							></itd-checkbox>
							<div style="cursor: pointer">
								{{ taskTemplate.title }}
							</div>
						</div>
						<div class="close-and-text" *ngIf="getUser(taskTemplate?.default_assignee) as user">
							<itd-user-avatar [user]="user" folder="user-images"></itd-user-avatar>
							<div style="cursor: pointer">
								<p>{{ user?.first_name + ' ' + user?.last_name }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<itd-button (click)="save()" class="confirm-button" setType="button">{{
				'CONFIRM' | translate
			}}</itd-button>
		</div>
	</ng-template>

	<ng-template #footer> </ng-template>
</itd-modal>
