<form (ngSubmit)="onSubmit()" [formGroup]="form">
	<itd-modal icon="bin">
		<ng-template #header>
			{{ 'CONFIRM_DELETE.HEADER' | translate }}
		</ng-template>

		<ng-template #content>
			<div *ngIf="data.content" class="content-section" [innerHTML]="data.content"></div>
			<div *ngIf="data.reason" class="deletion-date">
				<b>{{ 'STOP_DATE' | translate }}*</b>
				<itd-input-date
					sizeType="small"
					formControlName="contract_end_date"
					[hasShadow]="true"
					[required]="true"
				></itd-input-date>
			</div>

			<div *ngIf="data.reason" class="deletion-reason">
				<p>{{ 'EMPLOYEES.PROVIDE_DELETION_REASON' | translate }} ({{ 'OPTIONAL' | translate }})</p>
				<itd-select
					sizeType="small"
					styleType="grey"
					printLabelIndex="name"
					formControlName="reason"
					outputValueIndex="value"
					[isClearable]="false"
					[hasBorder]="true"
					[hasShadow]="false"
					[label]="'EMPLOYEES.DELETION_REASON' | translate"
					[data]="reasons"
				></itd-select>
			</div>

			<div class="confirm">
				<p class="warning" *ngIf="data.warningText">
					{{ data.warningText }}
				</p>
				<p *ngIf="data.confirmText">
					{{ 'CONFIRM_DELETE.CONFIRM_TEXT' | translate }}
					<br />
				</p>
				<b *ngIf="data.confirmText" class="confirm-text">DELETE</b>
				<div class="input" *ngIf="data.confirmText">
					<itd-input
						placeholder="{{ 'CONFIRM_DELETE.INPUT' | translate }}"
						formControlName="confirm"
						[focusAfterInit]="true"
						[hasBorder]="true"
						[hasShadow]="false"
					>
					</itd-input>
				</div>
			</div>
		</ng-template>

		<ng-template #footer>
			<itd-button [disabled]="!form.valid" [enableAfterSuccess]="false" [formState]="formState">
				{{ 'CONFIRM_DELETE.BUTTON' | translate }}
			</itd-button>

			<itd-form-error [errorObservable]="errorMessage$"></itd-form-error>
		</ng-template>
	</itd-modal>
</form>
