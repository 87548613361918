import { FormControl } from '@angular/forms';

export function confirmationValidator(text: string) {
	return (control: FormControl) => {
		return control.value === text
			? null
			: {
					valid: false,
			  };
	};
}
