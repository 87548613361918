import {
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	Input,
	TemplateRef,
} from '@angular/core';
import { NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'itd-create-panel',
    templateUrl: './create-panel.component.html',
    styleUrls: ['./create-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgTemplateOutlet],
})
export class CreatePanelComponent {
	@ContentChild('header') public header: TemplateRef<any>;
	@ContentChild('body') public body: TemplateRef<any>;

	@Input() public maxWidth: number;
	@Input() public backLink: string;
	@Input() public backLinkData: any;

	constructor() {}
}
