import { NgClass, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
	selector: 'itd-module-item-image',
	templateUrl: './module-item-image.component.html',
	styleUrls: ['./module-item-image.component.scss'],
	standalone: true,
	imports: [NgStyle, NgClass, NgIf, NgTemplateOutlet],
})
export class ModuleItemImageComponent {
	@Input() public alt: string;
	@Input() public dimension?: number;
	@Input() public borderRadius?: number = 0;
	@Input() public url: string;
	@Input() public placement: 'swiper' | 'article' | 'card' = null;
}
