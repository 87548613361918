import { IModule, IModuleDTO, IModuleEdit, IModuleEditDTO } from './modules.interface';
import { Injectable } from '@angular/core';
import { ApiClientService } from '../api/api.service';
import { Store } from '@ngxs/store';
import { AbstractApiService } from '../abstract/abstract-api-service';

@Injectable({
	providedIn: 'root',
})
export class ModulesService extends AbstractApiService<
	IModule,
	IModuleDTO,
	IModuleEdit,
	IModuleEditDTO
> {
	protected key = 'modules';

	constructor(api: ApiClientService, store: Store) {
		super(api, store);
	}

	protected parse(value: IModuleDTO): IModule {
		const { module_id, module_title } = value;

		return {
			...value,
			title: module_title,
			id: module_id,
		};
	}

	protected parseEditDTO(value: IModuleEdit): IModuleEditDTO {
		const { id, title, blocks, questions } = value;

		return {
			...value,
			module_id: id,
			module_title: title,
			blocks,
			questions,
		};
	}
}
