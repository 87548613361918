import { Injectable } from '@angular/core';
import { ApiClientService } from '../api/api.service';
import { Store } from '@ngxs/store';
import { AbstractApiService } from '../abstract/abstract-api-service';
import { ITaskDTO, ITask, ITaskEdit, ITaskEditDTO } from './task.interface';
import { map, Observable } from 'rxjs';
import { AddLoading, RemoveLoading } from '../layout/layout.actions';
import { TResults } from '../models/results.interface';

@Injectable({
	providedIn: 'root',
})
export class TaskService extends AbstractApiService<ITask, ITaskDTO, ITaskEdit, ITaskEditDTO> {
	protected key = 'tasks';

	constructor(protected api: ApiClientService, protected store: Store) {
		super(api, store);
	}

	public getAllTasks(): Observable<any[]> {
		this.store.dispatch(AddLoading);

		return this.api.get<TResults<any[]>>(`api/${this.key}`).pipe(
			map(response => {
				this.store.dispatch(RemoveLoading);
				return response.data.map(module => this.parse(module));
			})
		);
	}

	protected parse(value: ITaskDTO): ITask {
		const { _id, updatedAt, createdAt } = value;

		return {
			...value,
			id: _id,
			createdAt: new Date(createdAt),
			updatedAt: new Date(updatedAt),
		};
	}

	public parseEditDTO(value: ITaskEdit): ITaskDTO {
		const { id } = value;

		return {
			...value,
			_id: id,
		};
	}
}
