import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { combineLatest, map, Observable, of } from 'rxjs';
import { CustomDataSource } from 'src/app/core/data-source/data-source';
import { IDepartment } from 'src/app/core/department/department.interface';
import { DepartmentState } from 'src/app/core/department/department.state';
import { IProfile } from 'src/app/core/profile/profile.interface';
import { ProfileState } from 'src/app/core/profile/profile.state';
import { IUser } from 'src/app/core/users/users.interface';
import { UsersState } from 'src/app/core/users/users.state';
import {
	getDepartmentAndChildDepartments,
	removeAllOutsideDepartment,
} from 'src/app/core/utils/departments.util';
import { FindDepartmentService } from 'src/app/utility-modules/find-department/findDepartment.service';
import { ModalComponent } from '../../../utility-modules/modals/components/modal/modal.component';
import { TableEmptyComponent } from '../../../utility-modules/table/components/table-empty/table-empty.component';
import { TableHeaderRowComponent } from '../../../utility-modules/table/components/table-header-row/table-header-row.component';
import { TableRowComponent } from '../../../utility-modules/table/components/table-row/table-row.component';
import { TableComponent } from '../../../utility-modules/table/components/table/table.component';
import { CellDefDirective } from '../../../utility-modules/table/directives/cell-def.directive';
import { ColumnDefDirective } from '../../../utility-modules/table/directives/column-def.directive';
import { HeaderCellDefDirective } from '../../../utility-modules/table/directives/header-cell-def.directive';
import { HeaderRowDefDirective } from '../../../utility-modules/table/directives/header-row-def.directive';
import { RowDefDirective } from '../../../utility-modules/table/directives/row-def.directive';
import { CustomDataSourceInputComponent } from '../../components/custom-data-source-search-input/custom-data-source-search-input.component';
import { TruncateComponent } from '../../components/truncate/truncate.component';
import { UserAvatarComponent } from '../../components/user-avatar/user-avatar.component';

@Component({
	selector: 'itd-select-user-modal',
	templateUrl: './select-user-modal.component.html',
	styleUrls: ['./select-user-modal.component.scss'],
	standalone: true,
	imports: [
		ModalComponent,
		CustomDataSourceInputComponent,
		TableComponent,
		TableEmptyComponent,
		ColumnDefDirective,
		CellDefDirective,
		UserAvatarComponent,
		HeaderCellDefDirective,
		TruncateComponent,
		HeaderRowDefDirective,
		TableHeaderRowComponent,
		RowDefDirective,
		TableRowComponent,
		TranslateModule,
	],
})
export class SelectUserModalComponent implements OnInit {
	@Input() initialUserId: string;
	@Input() omitUserId?: string | undefined;
	@Input() userList$?: Observable<IUser[]>;
	@Input({ required: true }) title: string;

	@Select(UsersState.items) public employees$: Observable<IUser[]>;
	@Select(DepartmentState.departments) public departments$: Observable<IDepartment[]>;
	@Select(ProfileState.profile) public user$: Observable<IProfile>;

	public userDepartmentId: string;
	public isGlobalAdmin: boolean;
	public employeesDataSource: CustomDataSource<IUser> = new CustomDataSource();
	public filteredDepartments$: Observable<IDepartment[]> = of(null);

	constructor(public departmentServ: FindDepartmentService, public activeModal: NgbActiveModal) {}

	ngOnInit(): void {
		this.prepareData();
		this.employees$.subscribe(() => {
			this.prepareTable();
		});
	}

	onScroll() {
		this.prepareTable();
	}

	private async prepareTable() {
		this.employeesDataSource.addSortType('full_name', (a, b) => {
			return a.full_name.localeCompare(b.full_name);
		});

		this.employeesDataSource.sort('full_name', 'ASC');
		this.employeesDataSource.next(this.prepareTableData());
	}

	public selectUser(user: IUser) {
		this.activeModal.close(user);
	}

	private prepareData() {
		combineLatest([this.departments$, this.user$]).subscribe(([departments, currentUser]) => {
			this.userDepartmentId = currentUser?.department_id;
			this.isGlobalAdmin =
				currentUser.user_role === 'super_admin' || currentUser.user_role === 'org_admin';
			if (!!departments) {
				if (['super_admin', 'org_admin'].includes(currentUser.user_role)) {
					this.filteredDepartments$ = this.departments$;
				} else if (['dept_admin'].includes(currentUser.user_role)) {
					this.filteredDepartments$ = of(
						getDepartmentAndChildDepartments(currentUser?.department_id, departments)
					);
				} else {
					this.filteredDepartments$ = of([]);
				}
			}
		});
	}

	private prepareTableData() {
		if (this.userList$) {
			return this.userList$.pipe(
				map(users => {
					users = users?.filter(user => user?.first_name && user?.last_name);
					return this.isGlobalAdmin
						? users
						: removeAllOutsideDepartment(this.userDepartmentId, users);
				})
			);
		} else {
			return this.employees$.pipe(
				map(employees => {
					employees = employees?.filter(
						user =>
							user?.first_name &&
							user?.last_name &&
							// TODO Instead of this filtering, let's indicate the user on the list that has the initialUserId by coloring it grey or highlighted
							(!this.initialUserId || user.user_id !== this.initialUserId) &&
							user.user_id !== this.omitUserId
					);
					return this.isGlobalAdmin
						? employees
						: removeAllOutsideDepartment(this.userDepartmentId, employees);
				})
			);
		}
	}
}
