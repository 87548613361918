import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChange,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'itd-password-strength',
    templateUrl: './password-strength.component.html',
    styleUrls: ['./password-strength.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, TranslateModule],
})
export class PasswordStrengthComponent implements OnChanges {
	bar0: string;
	bar1: string;
	bar2: string;
	bar3: string;

	@Input() public passwordToCheck: string;

	@Output() passwordStrength = new EventEmitter<boolean>();

	private colors = ['darkred', 'orangered', 'orange', 'yellowgreen'];

	message: string;
	messageColor: string;

	checkStrength(password: string) {
		// 1
		let force = 0;

		// 2
		const regex = /[$-/:-?{-~!"^_@`\[\]]/g;
		const lowerLetters = /[a-z]+/.test(password);
		const upperLetters = /[A-Z]+/.test(password);
		const numbers = /[0-9]+/.test(password);
		const symbols = regex.test(password);

		// 3
		const flags = [lowerLetters, upperLetters, numbers, symbols];

		// 4
		let passedMatches = 0;
		for (const flag of flags) {
			passedMatches += flag === true ? 1 : 0;
		}

		// 5
		force += 2 * password.length + (password.length >= 10 ? 1 : 0);
		force += passedMatches * 10;

		// 6
		force = password.length <= 6 ? Math.min(force, 10) : force;

		// 7
		force = passedMatches === 1 ? Math.min(force, 10) : force;
		force = passedMatches === 2 ? Math.min(force, 20) : force;
		force = passedMatches === 3 ? Math.min(force, 30) : force;
		force = passedMatches === 4 ? Math.min(force, 40) : force;

		return force;
	}

	ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
		const password = changes.passwordToCheck.currentValue;

		this.setBarColors(4, '#DDD');

		if (password) {
			const color = this.getColor(this.checkStrength(password));
			this.setBarColors(color.index, color.color);

			const pwdStrength = this.checkStrength(password);
			pwdStrength === 40 ? this.passwordStrength.emit(true) : this.passwordStrength.emit(false);

			switch (pwdStrength) {
				case 10:
					this.message = 'POOR';
					break;
				case 20:
					this.message = 'AVERAGE';
					break;
				case 30:
					this.message = 'GOOD';
					break;
				case 40:
					this.message = 'EXCELLENT';
					break;
			}
		} else {
			this.message = '';
		}
	}

	private getColor(strength: number) {
		let index = 0;

		if (strength === 10) {
			index = 0;
		} else if (strength === 20) {
			index = 1;
		} else if (strength === 30) {
			index = 2;
		} else if (strength === 40) {
			index = 3;
		} else {
			index = 4;
		}

		this.messageColor = this.colors[index];

		return {
			index: index + 1,
			color: this.colors[index],
		};
	}

	private setBarColors(count: number, color: string) {
		for (let i = 0; i < count; i++) {
			(this as any)['bar' + i] = color;
		}
	}
}
