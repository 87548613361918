// import { ITask } from './task.interface';

export class LoadAllTasks {
	public static readonly type = '[Task] Load All';
}

export class LoadAllTasksIfEmpty {
	public static readonly type = '[Task] Load All If Empty';
}

export class CreateTask {
	public static readonly type = '[Task] Create';

	constructor(public payload: { data: any }) {}
}

export class UpdateTask {
	public static readonly type = '[Task] Update';

	constructor(public payload: { id: string; data: any }) {}
}

export class DeleteTask {
	public static readonly type = '[Task] Delete';

	constructor(public payload: { id: string }) {}
}

export class ClearTasks {
	public static readonly type = '[Task] Clear';

	constructor() {}
}
