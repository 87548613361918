import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoadAllMessagesIfEmpty } from 'src/app/core/messages/messages.action';
import { IMessage } from 'src/app/core/messages/messages.interface';
import { MessagesState } from 'src/app/core/messages/messages.state';
import { IOrganization } from 'src/app/core/organizations/organizations.interface';
import { OrganizationState } from 'src/app/core/organizations/organizations.state';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { ResendAssignment } from 'src/app/core/timeline-assign/timeline-assign.action';
import { ITimelineResendAssignment } from 'src/app/core/timeline-assign/timeline-assign.interface';
import { AbstractFormComponent } from 'src/app/utility-modules/itd-form/components/abstract-form/abstract-form.component';
import { NotificationService } from 'src/app/utility-modules/notification/services/notification.service';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../utility-modules/itd-form/components/button/button.component';
import { SelectComponent } from '../../../utility-modules/itd-form/components/select/select.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { ModalComponent } from '../../../utility-modules/modals/components/modal/modal.component';

@Component({
	selector: 'itd-confirm-resend-timeline-modal',
	templateUrl: './confirm-resend-timeline-modal.component.html',
	styleUrls: ['./confirm-resend-timeline-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		ReactiveFormsModule,
		ModalComponent,
		NgIf,
		SelectComponent,
		ButtonComponent,
		AsyncPipe,
		TranslateModule,
	],
})
export class ConfirmResendTimelineModalComponent extends AbstractFormComponent implements OnInit {
	@Input() data: {
		confirmText?: string;
		content: string;
		timeline: {
			id: string;
			user_id: string;
		};
	};
	@Select(OrganizationState.organization) public organization$: Observable<IOrganization>;
	@Select(MessagesState.items) public messages$: Observable<IMessage[]>;
	public defaultMessageValue$ = new BehaviorSubject('');
	private orgReminderMessageId: string;

	constructor(
		private notification: NotificationService,
		public activeModal: NgbActiveModal,
		public cd: ChangeDetectorRef,
		public elementRef: ElementRef,
		public validationMessages: ValidationMessagesService,
		private store: Store
	) {
		super(cd);
		this.store.dispatch(LoadAllMessagesIfEmpty);
	}

	ngOnInit(): void {
		this.form = new FormGroup({
			message_id: new FormControl('', [Validators.required]),
		});

		this.organization$.subscribe(org => {
			this.orgReminderMessageId = org.reminder_message;
		});
		this.messages$.subscribe((res: IMessage[]) => {
			if (res) {
				const index = res.findIndex(item => item.id === this.orgReminderMessageId);
				this.defaultMessageValue$.next(res[index]?.id);
			}
		});

		this.formValue.message_id = this.orgReminderMessageId;
		super.afterInit();
	}
	protected onSubmitSuccess() {
		this.notification.add({
			text: 'CONFIRM_RESEND.NOTIFICATION_SUCCESS',
		});

		this.activeModal.close();
	}
	public onSubmitAction() {
		const assignment: ITimelineResendAssignment = {
			...this.formValue,
			tl_id: this.data.timeline.id,
			user_id: this.data.timeline.user_id,
		};
		return this.store.dispatch(new ResendAssignment(assignment));
	}
}
