import { AbstractFormComponent } from 'src/app/utility-modules/itd-form/components/abstract-form/abstract-form.component';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	OnInit,
	Output,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeProfilePassword } from 'src/app/core/profile/profile.actions';
import { NotificationService } from 'src/app/utility-modules/notification/services/notification.service';
import { matchValidator } from 'src/app/core/validators/match.validator';
import { IEnvironment } from 'src/environments/environment.interface';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../../../utility-modules/itd-form/components/button/button.component';
import { FormErrorComponent } from '../../../../../utility-modules/itd-form/components/form-error/form-error.component';
import { PasswordStrengthComponent } from '../../../../components/password-strength/password-strength.component';
import { InputComponent } from '../../../../../utility-modules/itd-form/components/input/input.component';
import { GridItemComponent } from '../../../../../utility-modules/grid/components/grid-item/grid-item.component';
import { GridComponent } from '../../../../../utility-modules/grid/components/grid/grid.component';

@Component({
	selector: 'itd-profile-settings-change-password',
	templateUrl: './profile-settings-change-password.component.html',
	styleUrls: ['./profile-settings-change-password.component.scss'],
	standalone: true,
	imports: [
		ReactiveFormsModule,
		GridComponent,
		GridItemComponent,
		InputComponent,
		PasswordStrengthComponent,
		FormErrorComponent,
		ButtonComponent,
		TranslateModule,
	],
})
export class ProfileSettingsChangePasswordComponent extends AbstractFormComponent
	implements OnInit {
	public form: FormGroup;

	@Output() public error = new EventEmitter<HttpErrorResponse>();
	@Output() public success = new EventEmitter<void>();

	constructor(
		public store: Store,
		public validationMessages: ValidationMessagesService,
		public elementRef: ElementRef,
		protected cd: ChangeDetectorRef,
		private notification: NotificationService,
		@Inject('ENV') private env: IEnvironment
	) {
		super(cd);
	}

	ngOnInit(): void {
		this.form = new FormGroup({
			old_password: new FormControl('', [
				Validators.required,
				Validators.minLength(this.env.passwordLength),
			]),
			new_password: new FormControl('', [
				Validators.required,
				Validators.minLength(this.env.passwordLength),
				matchValidator('confirm_new_password', true),
			]),
			confirm_new_password: new FormControl('', [
				Validators.required,
				matchValidator('new_password'),
			]),
		});

		this.afterInit();
	}

	public onSubmitAction() {
		return this.store.dispatch(new ChangeProfilePassword(this.formValue));
	}

	public onSubmitSuccess() {
		this.notification.add({
			text: 'BACKEND_MESSAGES.PASSWORD_UPDATE_SUCCESS',
		});
	}
}
