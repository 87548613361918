import {
	Component,
	ChangeDetectionStrategy,
	ContentChild,
	TemplateRef,
	Input,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IconComponent } from '../../../icon/components/icon/icon.component';
import { NgStyle, NgIf, NgClass, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'itd-modal',
    exportAs: 'modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgStyle,
        NgIf,
        NgClass,
        IconComponent,
        NgTemplateOutlet,
    ],
})
export class ModalComponent {
	@ContentChild('header') header: TemplateRef<any>;
	@ContentChild('content') content: TemplateRef<any>;
	@ContentChild('footer') footer: TemplateRef<any>;

	@Input() public icon?: string;
	@Input() public padding?: number = 0;
	@Input() public size: number = 480;
	@Input() public minWidth: number = 0;
	@Input() public headerButton: boolean = false;

	constructor(private activeModal: NgbActiveModal) {}

	public close() {
		this.activeModal.close();
	}
}
