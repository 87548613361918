import { IFilterAnalytics } from '../analytics/analytics.interface';

export const createParams = (
	payload: IFilterAnalytics,
	interval?: string,
	dept_show_above?: boolean,
	limit?: number,
	skip?: number
) => {
	const params: { [key: string]: any } = {};

	// Check for the existence of payload to avoid null or undefined access
	if (payload) {
		// Consolidate checking for date range and department ID
		const hasDateRange = payload.from && payload.to;
		const hasDepartment = !!payload.department_id; // Convert to boolean

		if (hasDateRange) {
			params.from = payload.from;
			params.to = payload.to;
		}

		if (hasDepartment) {
			params.department_id = payload.department_id;
		}

		// Avoid setting an empty object if neither condition is true
		if (!hasDateRange && !hasDepartment) {
			return {};
		}
	} else {
		return {};
	}

	// Additional parameters
	if (dept_show_above) params.dept_show_above = true;
	if (interval) params.interval = interval;
	if (limit !== undefined && skip !== undefined) {
		// Check for undefined explicitly
		params.limit = limit;
		params.skip = skip;
	}

	return params;
};
