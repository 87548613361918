import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../../../utility-modules/icon/components/icon/icon.component';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'itd-button-back',
    templateUrl: './button-back.component.html',
    styleUrls: ['./button-back.component.scss'],
    standalone: true,
    imports: [
        RouterLink,
        IconComponent,
        TranslateModule,
    ],
})
export class ButtonBackComponent {
	@Input() public routerLink: string[];

	constructor() {}

	ngOnInit(): void {}
}
