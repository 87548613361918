import { Injectable } from '@angular/core';
import { ApiClientService } from '../api/api.service';
import { Store } from '@ngxs/store';
import { AbstractApiService } from '../abstract/abstract-api-service';
import {
	IAchievement,
	IAchievementDTO,
	IAchievementEdit,
	IAchievementEditDTO,
} from './achievements.interface';

@Injectable({
	providedIn: 'root',
})
export class AchievementsService extends AbstractApiService<
	IAchievement,
	IAchievementDTO,
	IAchievementEdit,
	IAchievementEditDTO
> {
	protected key = 'achievements';

	constructor(protected api: ApiClientService, protected store: Store) {
		super(api, store);
	}

	protected parse(value: IAchievementDTO): IAchievement {
		const { _id, card_color_1, card_color_2, card_text_color, icon_image_id } = value;

		return {
			...value,
			id: _id,
			iconId: parseInt(icon_image_id),
			theme: {
				gradientStart: card_color_1,
				gradientStop: card_color_2,
				text: card_text_color,
			},
		};
	}

	protected parseEditDTO(value: IAchievement): IAchievementEditDTO {
		const { iconId, theme } = value;

		return {
			...value,
			card_color_1: theme?.gradientStart,
			card_color_2: theme?.gradientStop,
			card_text_color: theme?.text,
			icon_image_id: iconId?.toString(),
		};
	}
}
