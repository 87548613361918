import { expandAnimation } from '../../../core/animations/expand.animation';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'itd-accordion-item',
    templateUrl: './accordion-item.component.html',
    styleUrls: ['./accordion-item.component.scss'],
    animations: [expandAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, NgIf],
})
export class AccordionItemComponent {
	@Input() public title: string;

	public isActive: boolean = false;

	public toggle() {
		this.isActive = !this.isActive;
	}
}
