import { Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { CommonModule, NgClass, NgIf, NgStyle } from '@angular/common';
import { FormsModule, NgControl } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AbstractInputComponent } from '../../../utility-modules/itd-form/components/abstract-input/abstract-input.component';
import { cloneDeep } from 'lodash';
import { IconComponent } from '../../../utility-modules/icon/components/icon/icon.component';
import { TagService } from 'src/app/core/tag/tag.service';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import {
	distinctUntilChanged,
	Observable,
	of,
	Subject,
	switchMap,
	concat,
	tap,
	catchError,
	debounceTime,
} from 'rxjs';
import { DropdownPosition, NgSelectModule } from '@ng-select/ng-select';

@Component({
	selector: 'itd-tags-input',
	templateUrl: './tags-input.component.html',
	styleUrls: ['./tags-input.component.scss'],
	standalone: true,
	imports: [
		NgClass,
		NgIf,
		NgStyle,
		NgSelectModule,
		CommonModule,
		FormsModule,
		TranslateModule,
		IconComponent,
	],
})
export class TagsInputComponent extends AbstractInputComponent<string[]> implements OnInit {
	@Input() public tags: string[];
	@Input() public width: number;
	@Input() public clearIcon: boolean = false;
	@Input() public dropdownPosition: DropdownPosition = 'bottom';
	@Input() public allowTagCreation: boolean = false;
	@Input() public placeholder: string;
	@Input() public typeToSearchText: string = 'Please enter 2 or more characters';
	@Input() public sizeType: 'tiny' | 'small' | 'default' | 'big' = 'default';
	@Input() public hasBorder: boolean = false;
	@Input() public hasShadow: boolean = true;
	@Input() public sideLabel: string = '';
	@Input() public label: string = '';

	@Output() public tagsChange = new EventEmitter<string[]>();

	constructor(
		@Optional() control: NgControl,
		validationMessages: ValidationMessagesService,
		private tagService: TagService
	) {
		super(control, validationMessages);
	}

	public tags$: Observable<any[]>;
	public tagsLoading = false;
	public tagsInput$ = new Subject<string>();
	public selectedTags: string[] = [];

	public ngOnInit(): void {
		super.ngOnInit();
		this.selectedTags = cloneDeep(this.tags);
		this.loadTags();
	}

	trackByFn(item: any) {
		return item.name;
	}

	private loadTags() {
		this.tags$ = concat(
			of([]),
			this.tagsInput$.pipe(
				debounceTime(300),
				distinctUntilChanged(),
				tap(() => (this.tagsLoading = true)),
				switchMap((term: string) =>
					this.tagService.search(term).pipe(
						catchError(() => of([])),
						tap(() => (this.tagsLoading = false))
					)
				)
			)
		);
	}

	public clearSelectedTags() {
		this.selectedTags = [];
		this.tagsChange.emit(this.selectedTags);
	}

	public submitTags() {
		this.tagsChange.emit(this.selectedTags);
	}
}
