import { ChangeDetectorRef, Component, ElementRef, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { ImageCroppedEvent, ImageCropperModule } from 'ngx-image-cropper';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../utility-modules/itd-form/components/button/button.component';
import { ModalComponent } from '../../../utility-modules/modals/components/modal/modal.component';

@Component({
    selector: 'itd-crop-image-modal',
    templateUrl: './crop-image-modal.component.html',
    styleUrls: ['./crop-image-modal.component.scss'],
    standalone: true,
    imports: [
        ModalComponent,
        ImageCropperModule,
        ButtonComponent,
        TranslateModule,
    ],
})
export class CropImageModalComponent implements OnInit {
	@Input() aspectRatio: number = 21 / 9;
	@Input() image: File;
	croppedImage: string = '';

	constructor(
		public store: Store,
		public validationMessages: ValidationMessagesService,
		public elementRef: ElementRef,
		protected cd: ChangeDetectorRef,
		private activeModal: NgbActiveModal
	) {}

	async ngOnInit(): Promise<void> {}

	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
	}

	dataURItoBlob(dataURI: string) {
		const byteString = window.atob(dataURI.substring(dataURI.indexOf(',') + 1));
		const arrayBuffer = new ArrayBuffer(byteString.length);
		const int8Array = new Uint8Array(arrayBuffer);
		for (let i = 0; i < byteString.length; i++) {
			int8Array[i] = byteString.charCodeAt(i);
		}
		const blob = new Blob([int8Array], { type: 'image/webp' });
		return blob;
	}

	addImage() {
		const base64 = this.croppedImage;
		let imageNameArray = this.image.name.split('.');
		const imageName =
			imageNameArray[0].slice(0, 30) +
			'_cropped_' +
			this.aspectRatio.toFixed(2).replace('.', '_') +
			'.webp';
		const imageBlob = this.dataURItoBlob(base64);
		const imageFile = new File([imageBlob], imageName, { type: 'image/webp' });
		this.activeModal.close(imageFile);
	}
}
