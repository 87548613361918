import {
	IFastTrackConfigurationEdit,
	IFastTrackConfigurationEditDTO,
} from './fast-track-configuration.interface';

export class LoadFastTrackConfiguration {
	public static readonly type = '[Fast Track Configuration] Load';
	constructor(public payload: { id: string }) {}
}

export class LoadAllFastTrackConfigurations {
	public static readonly type = '[Fast Track Configuration] Load All';
}

export class UpdateFastTrackConfiguration {
	public static readonly type = '[Fast Track Configuration] Update';
	constructor(public payload: { id: string; data: IFastTrackConfigurationEditDTO }) {}
}

export class CreateFastTrackConfiguration {
	public static readonly type = '[Fast Track Configuration] Create';
	constructor(public payload: { data: IFastTrackConfigurationEdit }) {}
}
