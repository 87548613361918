<div class="container">
	<div class="item" *ngFor="let item of items" [ngClass]="{ isActiveRadio: active === item.key }">
		<button type="button" class="button" (click)="click(item.key)">
			<span class="decoration"></span>
			<p class="title">
				<ng-template [ngTemplateOutlet]="item.tpl"></ng-template>
			</p>
		</button>
	</div>
</div>
