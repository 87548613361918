<ng-container *ngIf="link">
	<a
		class="link is-{{ type }}"
		[routerLink]="!link ? [] : link"
		[ngClass]="{ 'is-disabled': isDisabled }"
		(click)="click()"
	>
		<ng-container *ngTemplateOutlet="buttonContent"></ng-container>
	</a>
</ng-container>

<ng-container *ngIf="!link">
	<button
		type="button"
		class="link is-{{ type }}"
		[ngClass]="{ 'is-disabled': isDisabled }"
		(click)="click()"
	>
		<ng-container *ngTemplateOutlet="buttonContent"></ng-container>
	</button>
</ng-container>

<ng-template #buttonContent>
	<div class="link__wrapper">
		<itd-icon [icon]="icon" [width]="width" [height]="height" *ngIf="icon"></itd-icon>

		<span class="link__text">
			<ng-content></ng-content>
		</span>
	</div>
</ng-template>
