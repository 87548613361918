import { Component, Inject, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IMedia } from 'src/app/core/media/media.interface';
import { IEnvironment } from 'src/environments/environment.interface';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { NgStyle } from '@angular/common';
import { VgCoreModule } from '@videogular/ngx-videogular/core';

@Component({
	selector: 'itd-video',
	templateUrl: './video.component.html',
	styleUrls: ['./video.component.scss'],
	standalone: true,
	imports: [VgCoreModule, NgStyle, VgOverlayPlayModule, VgBufferingModule, VgControlsModule],
})
export class VideoComponent {
	@Input({ required: true }) public file: IMedia;
	@Input() public borderRadius?: number = 0;

	public get src(): string {
		return this.getVideoPath();
	}

	constructor(@Inject('ENV') public env: IEnvironment, private sanitizer: DomSanitizer) {}

	getVideoPath(): string {
		if (!this.file) {
			return;
		}

		return this.env.mediaUrl + '/' + this.file.url;
	}

	public sanitizeURL() {
		if (!this.file?.subtitle_track) {
			return;
		}

		return this.sanitizer.bypassSecurityTrustUrl(this.file.subtitle_track);
	}
}
