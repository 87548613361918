export class SetIsMobile {
	public static readonly type = '[Layout] Set Is Mobile';
	constructor(public payload: { isMobile: boolean }) {}
}

export class SetMobileFooterNavigationDrawer {
	public static readonly type = '[Layout] Set Mobile Footer Navigation Drawer';
	constructor(public payload: { isActive: boolean }) {}
}

export class SetMobileHeaderProfileDrawer {
	public static readonly type = '[Layout] Set Mobile Header Profile Drawer';
	constructor(public payload: { isActive: boolean }) {}
}
