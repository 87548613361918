import { LoadAllSurveysIfEmpty } from '../../../core/surveys/surveys.action';
import { ISurvey } from '../../../core/surveys/surveys.interface';
import { SurveysState } from '../../../core/surveys/surveys.state';
import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { AbstractAddModalComponent } from '../abstract-add-modal/abstract-add-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../utility-modules/itd-form/components/button/button.component';
import { CheckboxListLabelDirective } from '../../../utility-modules/itd-form/components/checkbox-list/checkbox-list-label.directive';
import { CheckboxListComponent } from '../../../utility-modules/itd-form/components/checkbox-list/checkbox-list.component';
import { FoldersComponent } from '../../components/folders/folders.component';
import { NgIf } from '@angular/common';
import { InputComponent } from '../../../utility-modules/itd-form/components/input/input.component';
import { ModalComponent } from '../../../utility-modules/modals/components/modal/modal.component';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
    selector: 'itd-add-surveys-modal',
    templateUrl: './add-surveys-modal.component.html',
    styleUrls: ['./add-surveys-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ReactiveFormsModule,
        ModalComponent,
        InputComponent,
        NgIf,
        FoldersComponent,
        CheckboxListComponent,
        CheckboxListLabelDirective,
        ButtonComponent,
        TranslateModule,
    ],
})
export class AddSurveysModalComponent extends AbstractAddModalComponent<ISurvey> {
	public key = 'SURVEYS';
	public departmentFoldersActive = true;

	@Select(SurveysState.items) protected items$: Observable<ISurvey[]>;

	constructor(activeModal: NgbActiveModal, private store: Store, cd: ChangeDetectorRef) {
		super(activeModal, cd);
	}

	protected onLoad = () => {
		return this.store.dispatch(LoadAllSurveysIfEmpty);
	};
}
