import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

export type TValidationMessages = {
	required?: string;
	minlength?: string;
	[key: string]: string;
};

export type TErrorMessage = {
	key: string;
	text: string;
};

@Injectable({
	providedIn: 'root',
})
export class ValidationMessagesService {
	private messages: TValidationMessages = {
		required: 'This field is required',
		minlength: 'This fields required %length% characters.',
		email: 'Please enter a valid email address.',
		passwordMatching: 'Passwords do not match.',
	};

	public parseMessage(
		errors: ValidationErrors,
		customMessages: TValidationMessages = {}
	): TErrorMessage {
		if (!errors) {
			return { key: '', text: '' };
		}

		const messages = Object.assign({}, this.messages, customMessages);

		const key = Object.keys(errors)[0];
		const error = errors[key];
		let message = messages[key];

		switch (key) {
			case 'minlength':
				message = message.replace(/%length%/g, error.requiredLength);
				break;
		}

		if (!message && typeof error === 'string') {
			return { key, text: error };
		}

		return { key, text: message };
	}
}
