import {
	LoadAllMedia,
	LoadAllMediaIfEmpty,
	UpdateMedia,
	CreateMedia,
	DeleteMedia,
	SearchMedia,
	FilterMedia,
} from './media.action';
import { IMedia, IMediaDTO, IMediaEdit, IMediaEditDTO } from './media.interface';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { MediaService } from './media.service';
import { AbstractApiState } from '../abstract/abstract-api-state';
import { tap } from 'rxjs';
import { patch } from '@ngxs/store/operators';

export interface MediaStateModel {
	items: IMedia[];
	result?: IMedia;
	searchText?: string;
	fileTypeFilter?: string;
	lastCreated?: IMedia;
}

@State<MediaStateModel>({
	name: 'media',
	defaults: {
		items: null,
		searchText: '',
		fileTypeFilter: '',
		result: null,
		lastCreated: null,
	},
})
@Injectable()
export class MediaState extends AbstractApiState<IMedia, IMediaDTO, IMediaEdit, IMediaEditDTO> {
	constructor(public media: MediaService, store: Store) {
		super(media, store);
	}

	@Selector()
	public static getState(state: MediaStateModel) {
		return state;
	}

	@Selector()
	public static items(state: MediaStateModel) {
		if (state.fileTypeFilter && !state.searchText) {
			return state.items.filter(item => item.file_type === state.fileTypeFilter);
		} else if (state.searchText && !state.fileTypeFilter) {
			return state.items.filter(item => item.title.includes(state.searchText));
		} else if (state.searchText && state.fileTypeFilter) {
			return state.items.filter(
				item => item.title.includes(state.searchText) && item.file_type === state.fileTypeFilter
			);
		} else {
			return state.items;
		}
	}

	@Selector()
	public static getImages(state: MediaStateModel) {
		return state.items.filter(media => media.file_type === 'images');
	}

	@Selector()
	public static getResult(state: MediaStateModel) {
		return state.result;
	}

	@Selector()
	public static getLastCreated(state: MediaStateModel) {
		return state.lastCreated;
	}

	@Selector()
	public static getImageById(state: MediaStateModel) {
		return (id: string) => {
			return state.items.find(media => media.id === id);
		};
	}

	@Action(LoadAllMedia)
	public loadAll(ctx: StateContext<MediaStateModel>) {
		return super.loadAll(ctx);
	}

	@Action(LoadAllMediaIfEmpty)
	public loadIfEmpty(ctx: StateContext<MediaStateModel>) {
		return super.loadAllIfEmpty(ctx);
	}

	@Action(CreateMedia)
	public upload(ctx: StateContext<MediaStateModel>, { payload }: CreateMedia) {
		const { files } = payload;

		return this.media.upload(files).pipe(
			tap(result => {
				ctx.setState(
					patch<MediaStateModel>({
						result,
					})
				);
				const _items = ctx.getState().items;
				ctx.setState(
					patch<MediaStateModel>({
						result,
					})
				);
				if (!_items || !_items.length) {
					return;
				}

				const items = [..._items, result];

				ctx.patchState({
					items,
				});

				ctx.patchState({
					lastCreated: result[0] ? result[0] : result,
				});

				return result;
			})
		);
	}

	@Action(UpdateMedia)
	public update(ctx: StateContext<MediaStateModel>, data: UpdateMedia) {
		return super.update(ctx, data);
	}

	@Action(DeleteMedia)
	public delete(ctx: StateContext<MediaStateModel>, data: DeleteMedia) {
		return super.delete(ctx, data);
	}

	@Action(SearchMedia)
	public searchMedia(ctx: StateContext<MediaStateModel>, { payload }: SearchMedia) {
		ctx.setState(
			patch<MediaStateModel>({
				searchText: payload.searchText,
			})
		);
	}
	@Action(FilterMedia)
	public filterMedia(ctx: StateContext<MediaStateModel>, { payload }: FilterMedia) {
		ctx.setState(
			patch<MediaStateModel>({
				fileTypeFilter: payload.fileTypeFilter,
			})
		);
	}
}
