// import { TResults } from './../models/results.interface';
// import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
// import { ILanguage } from '../models/language.interface';
// import { map } from 'rxjs/operators';
import { ApiClientService } from '../api/api.service';
import { AbstractApiService } from '../abstract/abstract-api-service';
import {
	IPublicSignupLink,
	IPublicSignupLinkDTO,
	IPublicSignupLinkEdit,
	IPublicSignupLinkEditDTO,
} from './public-signup-links.interface';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { TResults } from '../models/results.interface';

@Injectable({
	providedIn: 'root',
})
export class PublicSignupLinksService extends AbstractApiService<
	IPublicSignupLink,
	IPublicSignupLinkDTO,
	IPublicSignupLinkEdit,
	IPublicSignupLinkEditDTO
> {
	protected key = 'org/public-signup';
	constructor(protected api: ApiClientService, protected store: Store) {
		super(api, store);
	}

	public getMyPublicSignupLink(id: string): Observable<IPublicSignupLink[]> {
		return this.api.get<TResults<IPublicSignupLinkDTO[]>>(`api/${this.key}/${id}`).pipe(
			map(response => {
				console.log(`api/${this.key}/${id}`, response.data.map(this.parse));
				// if (!response.success) {
				// 	return null;
				// }
				// console.log(response);
				return response.data.map(this.parse);
			})
		);
	}

	protected parse(value: IPublicSignupLinkDTO): IPublicSignupLink {
		return {
			...value,
		};
	}
	protected parseEditDTO(value: IPublicSignupLink): IPublicSignupLinkDTO {
		return {
			...value,
		};
	}
}
