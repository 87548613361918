import { Injectable } from '@angular/core';
import { ApiClientService } from '../api/api.service';
import { Store } from '@ngxs/store';
import { AbstractApiService } from '../abstract/abstract-api-service';
import { IMedia, IMediaDTO, IMediaEdit, IMediaEditDTO } from './media.interface';
import { AddLoading, RemoveLoading } from '../layout/layout.actions';
import { map, Observable } from 'rxjs';
import { TResults } from '../models/results.interface';
import { HttpParams } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class MediaService extends AbstractApiService<IMedia, IMediaDTO, IMediaEdit, IMediaEditDTO> {
	protected key = 'media';

	constructor(protected api: ApiClientService, protected store: Store) {
		super(api, store);
	}

	public upload(file: File[]): Observable<IMedia> {
		this.store.dispatch(AddLoading);

		return this.api.uploadMedia<TResults<IMediaDTO>>(`api/${this.key}`, file).pipe(
			map(response => {
				this.store.dispatch(RemoveLoading);

				return this.parse(response.data);
			})
		);
	}

	protected parse(value: IMediaDTO): IMedia {
		const { media_id } = value;

		return {
			...value,
			id: media_id,
		};
	}

	public parseEditDTO(value: IMedia): IMediaDTO {
		const { id } = value;

		return {
			...value,
			media_id: id,
		};
	}

	public getImagesByKeys(keys: string[]): Observable<any> {
		let params = new HttpParams();

		keys.forEach(key => (params = params.append('image_keys', key)));
		return this.api
			.get<any>(`api/${this.key}/by-keys`, { params: params })
			.pipe(map(response => response.data));
	}
}
