import { ITasklistAssignmentEdit } from './tasklist-assignment.interface';

export class LoadAllTasklistAssignments {
	public static readonly type = '[Tasklist Assignment] Load All';
}

export class LoadAllTasklistAssignmentsIfEmpty {
	public static readonly type = '[Tasklist Assignment] Load All If Empty';
}

export class LoadSelectedTasklistAssignment {
	public static readonly type = '[Tasklist Assignment] Load Selected';
	constructor(public payload: { user_id: string }) {}
}

export class LoadTasklistAssignment {
	public static readonly type = '[Tasklist Assignment] Load One';
	constructor(public payload: { id: string }) {}
}

export class LoadMyTasklistAssignments {
	public static readonly type = '[Tasklist Assignment] Load Mine';
}

export class CreateTasklistAssignment {
	public static readonly type = '[Tasklist Assignment] Create';
	constructor(public payload: { data: ITasklistAssignmentEdit }) {}
}

export class UpdateTasklistAssignment {
	public static readonly type = '[Tasklist Assignment] Update';
	constructor(public payload: { id: string; data: ITasklistAssignmentEdit }) {}
}

export class DeleteTasklistAssignment {
	public static readonly type = '[Tasklist Assignment] Delete';
	constructor(public payload: { id: string }) {}
}

export class ClearTasklistAssignment {
	public static readonly type = '[Tasklist Assignment] Clear';
	constructor() {}
}
