import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { TagsInputComponent } from '../tags-input/tags-input.component';
import { TagService } from 'src/app/core/tag/tag.service';

@Component({
	selector: 'itd-tags-assign',
	standalone: true,
	imports: [TranslateModule, NgIf, TagsInputComponent],
	templateUrl: './tags-assign.component.html',
	styleUrls: ['./tags-assign.component.scss'],
})
export class TagsAssignComponent {
	@Input() public tags: string[] = [];
	@Input() public label: string;
	@Input() public hasBorder: boolean = true;
	@Input() public hasShadow: boolean = false;
	@Input({ required: true }) public entityType: string;
	@Input({ required: true }) public entityId: string;

	@Output() public tagsChange = new EventEmitter<string[]>();

	constructor(private tagService: TagService) {}

	public onTagsChange(tags: string[]): void {
		this.tags = tags;
		this.assignTags(this.tags);
	}

	private assignTags(tags: string[]): void {
		if (this.entityId === 'new') {
			this.tagsChange.emit(tags);
			return;
		}
		this.tagService.assignTags(this.entityType, this.entityId, tags).subscribe(() => {
			this.tagsChange.emit(tags);
		});
	}
}
