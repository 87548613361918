import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { IconComponent } from '../../../../utility-modules/icon/components/icon/icon.component';
import { RouterLink } from '@angular/router';
import { NgIf, NgClass, NgTemplateOutlet } from '@angular/common';

@Component({
	selector: 'itd-menu-item',
	templateUrl: './menu-item.component.html',
	styleUrls: ['./menu-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, RouterLink, NgClass, NgTemplateOutlet, IconComponent],
})
export class MenuItemComponent {
	@Input() public icon: string;
	@Input() public link: string[];
	@Input() public width: number = 13;
	@Input() public height: number = 13;
	@Input() public type: 'default' | 'error' = 'default';
	@Input() public isDisabled: boolean;

	@Output() public onClick = new EventEmitter<void>();

	constructor() {}

	public click(): void {
		if (this.isDisabled) {
			return;
		}

		this.onClick.emit(null);
	}
}
