<form (ngSubmit)="onSubmit()" [formGroup]="form">
	<itd-modal icon="pen" [size]="800">
		<ng-template #header>
			{{ 'EMPLOYEES.EDIT' | translate }}
		</ng-template>

		<ng-template #content>
			<itd-grid *ngIf="user.external_id">
				<itd-grid-item size="33.33%">
					<itd-input
						name="external_id"
						[value]="user.external_id"
						[label]="'EXTERNAL_ID' | translate"
						[disabled]="true"
					>
					</itd-input>
				</itd-grid-item>
			</itd-grid>
			<hr *ngIf="user.external_id" />
			<itd-grid>
				<itd-grid-item size="33.33%">
					<itd-input
						class="input"
						name="email"
						type="email"
						formControlName="email"
						placeholder="{{ 'EMAIL' | translate }}"
						label="EMAIL"
						[hasBorder]="true"
					></itd-input>
				</itd-grid-item>
				<itd-grid-item size="33.33%">
					<itd-input
						class="input"
						name="first-name"
						type="text"
						formControlName="first_name"
						placeholder="{{ 'FIRST_NAME' | translate }}"
						label="FIRST_NAME"
						[hasBorder]="true"
					></itd-input>
				</itd-grid-item>
				<itd-grid-item size="33.33%">
					<itd-input
						class="input"
						name="last_name"
						type="text"
						formControlName="last_name"
						placeholder="{{ 'LAST_NAME' | translate }}"
						label="LAST_NAME"
						[hasBorder]="true"
					></itd-input>
				</itd-grid-item>
			</itd-grid>
			<div class="py-4"></div>
			<itd-grid>
				<itd-grid-item size="33.33%">
					<itd-input-phone
						class="input"
						name="phone_number"
						[maxLength]="16"
						formControlName="phone_number"
						[label]="'PHONE' | translate"
						placeholder="{{ 'SETTINGS.PHONE_PLACEHOLDER' | translate }}"
						[hasBorder]="true"
					>
					</itd-input-phone>
				</itd-grid-item>
				<itd-grid-item size="33.33%">
					<itd-input
						class="input"
						name="job_title"
						type="text"
						formControlName="job_title"
						placeholder="{{ 'SETTINGS.JOB_TITLE_PLACEHOLDER' | translate }}"
						label="SETTINGS.JOB_TITLE"
						[hasBorder]="true"
					></itd-input>
				</itd-grid-item>
				<itd-grid-item size="33.33%">
					<itd-input-user
						label="MANAGER"
						placeholder="CHOOSE_MANAGER"
						modalTitle="EMPLOYEES.SELECT_MANAGER"
						formControlName="manager_id"
						[hasBorder]="true"
						[user]="user"
					></itd-input-user>
				</itd-grid-item>
			</itd-grid>
			<div class="py-4"></div>
			<itd-grid>
				<itd-grid-item size="50%">
					<itd-select
						formControlName="user_role"
						printLabelIndex="title"
						outputValueIndex="value"
						[isClearable]="false"
						[hasBorder]="true"
						[hasShadow]="false"
						label="USER_ROLE"
						[data]="availableUserRoles"
						[defaultValue]="user.user_role"
					></itd-select>
				</itd-grid-item>
				<itd-grid-item size="50%">
					<itd-input-date
						name="start_date"
						formControlName="start_date"
						icon="calendar"
						[label]="'START_DATE' | translate"
						[clearIcon]="true"
						[hasBorder]="true"
						[hasShadow]="false"
					>
					</itd-input-date>
				</itd-grid-item>
			</itd-grid>
			<div class="py-4"></div>
			<itd-grid>
				<itd-grid-item size="100%" *ngIf="superAdmin">
					<itd-select
						formControlName="organization"
						[defaultValue]="user.org_id"
						printLabelIndex="name"
						outputValueIndex="org_id"
						[isClearable]="false"
						[hasBorder]="true"
						[hasShadow]="false"
						label="Organization"
						[observableData]="organizations$"
					></itd-select>
				</itd-grid-item>
			</itd-grid>
			<itd-grid>
				<itd-grid-item size="100%">
					<itd-tags-assign
						formControlName="tags"
						[label]="'TAGS' | translate"
						entityType="user"
						[entityId]="user.user_id"
						[tags]="user.tags"
					></itd-tags-assign>
				</itd-grid-item>
			</itd-grid>
			<itd-form-error [errorObservable]="errorMessage$"></itd-form-error>
		</ng-template>

		<ng-template #footer>
			<itd-button [formState]="formState" [width]="120">
				{{ 'UPDATE' | translate }}
			</itd-button>
		</ng-template>
	</itd-modal>
</form>
