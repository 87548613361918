import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Observable } from 'rxjs';
import { ProfileService } from 'src/app/core/profile/profile.service';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { matchValidator } from 'src/app/core/validators/match.validator';
import { AbstractFormComponent } from 'src/app/utility-modules/itd-form/components/abstract-form/abstract-form.component';
import { NotificationService } from 'src/app/utility-modules/notification/services/notification.service';
import { IEnvironment } from 'src/environments/environment.interface';
import { TranslateModule } from '@ngx-translate/core';
import { FormErrorComponent } from '../../../../utility-modules/itd-form/components/form-error/form-error.component';
import { ButtonComponent } from '../../../../utility-modules/itd-form/components/button/button.component';
import { PasswordStrengthComponent } from '../../../../shared/components/password-strength/password-strength.component';
import { InputComponent } from '../../../../utility-modules/itd-form/components/input/input.component';

@Component({
    selector: 'itd-login-reset-password',
    templateUrl: './login-reset-password.component.html',
    styleUrls: ['./login-reset-password.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        InputComponent,
        PasswordStrengthComponent,
        ButtonComponent,
        FormErrorComponent,
        RouterLink,
        TranslateModule,
    ],
})
export class LoginResetPasswordComponent extends AbstractFormComponent<string> implements OnInit {
	constructor(
		private profile: ProfileService,
		private notification: NotificationService,
		private route: ActivatedRoute,
		private router: Router,
		public validationMessages: ValidationMessagesService,
		public elementRef: ElementRef,
		public cd: ChangeDetectorRef,
		@Inject('ENV') private env: IEnvironment
	) {
		super(cd);
	}

	public ngOnInit(): void {
		const { token } = this.route.snapshot.queryParams;

		this.form = new FormGroup({
			token: new FormControl(token),
			password: new FormControl('', [
				Validators.required,
				Validators.minLength(this.env.passwordLength),
				matchValidator('confirm_password', true),
			]),
			confirm_password: new FormControl('', [Validators.required, matchValidator('password')]),
		});

		super.afterInit();
	}

	public onSubmitAction(): Observable<boolean> {
		const data = this.parseData(this.form.value);
		return this.profile.setNewPassword(data);
	}

	public onSubmitSuccess(): void {
		this.router.navigateByUrl('/login');

		this.notification.add({
			text: 'PASSWORD_RESET_SUCCESS',
		});
	}

	private parseData(formValue: any): any {
		const { password, token, confirm_password } = formValue;

		return {
			token: token,
			password: password,
			confirm_password: confirm_password,
		};
	}
}
