import { HttpErrorResponse } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnInit,
	Output,
	EventEmitter,
	Input,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { firstValueFrom, map, Observable } from 'rxjs';
import { LoadAllDepartmentsIfEmpty } from 'src/app/core/department/department.actions';
import { IDepartment } from 'src/app/core/department/department.interface';
import { DepartmentState } from 'src/app/core/department/department.state';
import { ProfileState } from 'src/app/core/profile/profile.state';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { GetFilteredTimelinesAssign } from 'src/app/core/timeline-assign/timeline-assign.action';
import { LoadUsersWithFilters, LoadUsers } from 'src/app/core/users/users.action';
import { AbstractFormComponent } from 'src/app/utility-modules/itd-form/components/abstract-form/abstract-form.component';
import { NotificationService } from 'src/app/utility-modules/notification/services/notification.service';
import { SidenavService } from 'src/app/utility-modules/sidebar/matsidebar.service';
import { ButtonComponent } from '../../../utility-modules/itd-form/components/button/button.component';
import { CheckboxComponent } from '../../../utility-modules/itd-form/components/checkbox/checkbox.component';
import { SelectComponent } from '../../../utility-modules/itd-form/components/select/select.component';
import { InputComponent } from '../../../utility-modules/itd-form/components/input/input.component';
import { GridItemComponent } from '../../../utility-modules/grid/components/grid-item/grid-item.component';
import { NgIf } from '@angular/common';
import { MatSidebarComponent } from '../../../utility-modules/sidebar/sidebar/matsidebar.component';
import { TagsInputComponent } from '../../../shared/components/tags-input/tags-input.component';

type FormValue = {
	email: string;
	phone_number: string;
	first_name: string;
	last_name: string;
	is_pending: string;
	user_role: string;
	dept_string: string;
	is_deleted: boolean;
};
@Component({
	selector: 'itd-filters',
	templateUrl: './filters.component.html',
	styleUrls: ['./filters.scss'],
	standalone: true,
	imports: [
		ReactiveFormsModule,
		MatSidebarComponent,
		NgIf,
		GridItemComponent,
		InputComponent,
		SelectComponent,
		CheckboxComponent,
		ButtonComponent,
		TranslateModule,
		TagsInputComponent,
	],
})
export class FiltersComponent extends AbstractFormComponent<FormValue> implements OnInit {
	[x: string]: any;
	public form: FormGroup;
	public availableUserRoles: object[];
	public userRole: string;
	private timelineId: string;
	@Select(DepartmentState.departments) public departments$: Observable<IDepartment[]>;
	shownFields = {
		firstName: true,
		lastName: true,
		email: true,
		phone: true,
		department: true,
		tags: true,
		userPending: true,
		userRole: true,
		userStatus: false,
	};
	@Output() public error = new EventEmitter<HttpErrorResponse>();
	@Output() public success = new EventEmitter<void>();
	@Input() public activeFields: any;
	pipedDepartments$: Observable<IDepartment[]>;

	constructor(
		public store: Store,
		public validationMessages: ValidationMessagesService,
		public elementRef: ElementRef,
		protected cd: ChangeDetectorRef,
		private notification: NotificationService,
		private translate: TranslateService,
		public sidenavService: SidenavService,
		private router: Router
	) {
		super(cd);
		this.pipedDepartments$ = this.departments$.pipe(
			map(departments => {
				return departments.map(department => {
					if (!department.materialized_parent_chain) {
						return department;
					} else {
						let parentDepartments = department.materialized_parent_chain
							.substring(1, department.materialized_parent_chain.length - 1)
							.split(',');

						parentDepartments.push(department.department_id);
						let departmentId = parentDepartments.join('.');
						return { ...department, department_id: departmentId };
					}
				});
			})
		);
	}

	async ngOnInit(): Promise<void> {
		this.activeFields ? (this.shownFields = this.activeFields) : this.shownFields;
		this.userRole = this.store.selectSnapshot(ProfileState.profile).user_role;
		this.store.dispatch(LoadAllDepartmentsIfEmpty);
		this.form = new FormGroup({
			email: new FormControl(''),
			phone_number: new FormControl(''),
			first_name: new FormControl(''),
			last_name: new FormControl(''),
			is_pending: new FormControl(''),
			status: new FormControl(''),
			user_role: new FormControl(''),
			dept_string: new FormControl(''),
			is_deleted: new FormControl(''),
			tags: new FormControl([]),
		});

		this.availableUserRoles = await this.loadAvailableUserRoles(this.userRole);
		this.afterInit();
	}

	public onTagsChange(tags: string[]): void {
		this.form.controls['tags'].setValue(tags);
	}

	public onSubmitSuccess() {
		this.notification.add({
			text: 'FILTERS.APPLIED',
		});
	}
	public onSubmitAction = () => {
		this.sidenavService.setResetFilterStatus(true);
		const filters = { ...this.formValue };

		Object.keys(filters).forEach(key => (filters[key] === '' ? delete filters[key] : {}));
		if (Object.keys(filters).length !== 0) {
			switch (this.sidenavService.getFilterType()) {
				case 'timelines-result':
					this.timelineId = this.router.url.match(/\d+/g)[0];
					return this.store.dispatch(
						new GetFilteredTimelinesAssign({ id: this.timelineId, query: filters })
					);
					break;
				case 'admin-employees':
					return this.store.dispatch(
						new LoadUsersWithFilters({ params: { skip: 0, limit: 99999, ...filters } })
					);
					break;
				default:
					return this.store.dispatch(new LoadUsers({ params: { skip: 0, limit: 1500 } }));
			}
		} else {
			return this.store.dispatch(new LoadUsers({ params: { skip: 0, limit: 1500 } }));
		}
	};

	public prependCountryCodeIndicator() {
		if (this.form.value?.phone_number?.indexOf('+') !== 0 && this.form.value.phone_number !== '') {
			this.form.patchValue({ phone_number: '+' + this.form.value.phone_number });
		}
	}

	public async loadAvailableUserRoles(role: string): Promise<object[]> {
		if (role === 'super_admin') {
			return [
				{ value: 'super_admin', title: await this.getRoleTranslation('USER_ROLE_SUPER_ADMIN') },
				{ value: 'org_admin', title: await this.getRoleTranslation('USER_ROLE_ORG_ADMIN') },
				{ value: 'dept_admin', title: await this.getRoleTranslation('USER_ROLE_DEPT_ADMIN') },
				{ value: 'std_user', title: await this.getRoleTranslation('USER_ROLE_STD_USER') },
			];
		} else if (role === 'org_admin') {
			return [
				{ value: 'org_admin', title: await this.getRoleTranslation('USER_ROLE_ORG_ADMIN') },
				{ value: 'dept_admin', title: await this.getRoleTranslation('USER_ROLE_DEPT_ADMIN') },
				{ value: 'std_user', title: await this.getRoleTranslation('USER_ROLE_STD_USER') },
			];
		} else if (role === 'dept_admin') {
			return [
				{ value: 'dept_admin', title: await this.getRoleTranslation('USER_ROLE_DEPT_ADMIN') },
				{ value: 'std_user', title: await this.getRoleTranslation('USER_ROLE_STD_USER') },
			];
		}
	}

	public async getRoleTranslation(role: string) {
		return await firstValueFrom(this.translate.get(`${role}`));
	}

	public resetFilters() {
		this.form.reset();
		this.sidenavService.setResetFilterStatus(false);
		this.store.dispatch(new LoadUsers({ params: { skip: 0, limit: 1500 } }));
		this.sidenavService.resetFilterType();
	}
}
