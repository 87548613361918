import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs';
import { AbstractApiState } from '../abstract/abstract-api-state';
import { LoadAllPublicSignupLinks } from './public-signup-links.actions';
import {
	IPublicSignupLink,
	IPublicSignupLinkDTO,
	IPublicSignupLinkEdit,
	IPublicSignupLinkEditDTO,
} from './public-signup-links.interface';
import { PublicSignupLinksService } from './public-signup-links.service';

export interface IPublicSignupLinkStateModel {
	items: IPublicSignupLink[];
}

@State<IPublicSignupLinkStateModel>({
	name: 'publicSignupLinks',
	defaults: {
		items: [],
	},
})
@Injectable()
export class PublicSignupLinkState extends AbstractApiState<
	IPublicSignupLink,
	IPublicSignupLinkDTO,
	IPublicSignupLinkEdit,
	IPublicSignupLinkEditDTO
> {
	constructor(protected service: PublicSignupLinksService, protected store: Store) {
		super(service, store);
	}

	@Selector()
	public static getState(state: IPublicSignupLinkStateModel) {
		return state.items;
	}

	@Action(LoadAllPublicSignupLinks)
	public loadAllPublicSignupLinks(
		ctx: StateContext<IPublicSignupLinkStateModel>,
		data: LoadAllPublicSignupLinks
	) {
		return this.service.getMyPublicSignupLink(data.payload.id).pipe(
			tap(links => {
				ctx.patchState({ items: links });
			})
		);
	}
}
