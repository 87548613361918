import {
	Component,
	ContentChild,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipDirective } from '../../directives/tooltip.directive';
import { NgIf } from '@angular/common';

@Component({
	selector: 'itd-create-new-adding-template',
	templateUrl: './create-new-adding-template.component.html',
	styleUrls: ['./create-new-adding-template.component.scss'],
	standalone: true,
	imports: [NgIf, TooltipDirective, TranslateModule],
})
export class CreateNewAddingTemplateComponent implements OnInit {
	@Input() disabled: boolean = false;
	@Input() disabledTooltip: string = null;
	@Input() noContentDisplayText: string;
	@Input() btnText: string;
	@Input() removeBtnText: string = null;
	@Output() add = new EventEmitter<boolean>(null);
	@Output() remove = new EventEmitter<boolean>(null);

	@ContentChild('content') content: ElementRef;

	constructor() {}

	ngOnInit(): void {}

	public btnClick() {
		this.add.emit(true);
	}

	public removeAttachment() {
		this.remove.emit(true);
	}
}
