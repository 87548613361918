<itd-side-drawer *ngIf="isActive$ | async" (onClose)="closeSideDrawer()">
	<ng-template #header>
		<!-- <button type="button" class="notification" itdTooltip="{{ 'NAVIGATION.NOTIFICATION_TOOLTIP' | translate }}"
			tooltipPosition="right">
			<itd-icon icon="bell" [width]="18" [height]="22"></itd-icon>
		</button> -->
	</ng-template>

	<ng-template #body>
		<div class="profile" *ngIf="profile$ | async as profile">
			<itd-user-avatar
				[user]="profile"
				folder="user-images"
			></itd-user-avatar>

			<div class="profile__title">
				{{profile.first_name}} {{profile.last_name}}
			</div>
		</div>

		<ul class="list">
			<ng-container *ngFor="let item of navigation">
				<li>
					<itd-navigation-item [data]="item" (click)="closeSideDrawer()"></itd-navigation-item>
				</li>
			</ng-container>

			<li>
				<itd-navigation-item [data]="{
					icon: 'exit',
					title: 'LOGOUT'
				}" (click)="logout()"></itd-navigation-item>
			</li>
		</ul>
	</ng-template>
</itd-side-drawer>
