import { Select, Store } from '@ngxs/store';
import { ChangeDetectorRef } from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AbstractAddModalComponent } from '../abstract-add-modal/abstract-add-modal.component';
import { TimelineState } from 'src/app/core/timelines/timelines.state';
import { ITimeline } from 'src/app/core/timelines/timelines.interface';
import { LoadAllTimelines } from 'src/app/core/timelines/timelines.action';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../utility-modules/itd-form/components/button/button.component';
import { CheckboxListLabelDirective } from '../../../utility-modules/itd-form/components/checkbox-list/checkbox-list-label.directive';
import { CheckboxListComponent } from '../../../utility-modules/itd-form/components/checkbox-list/checkbox-list.component';
import { FoldersComponent } from '../../components/folders/folders.component';
import { NgIf } from '@angular/common';
import { InputComponent } from '../../../utility-modules/itd-form/components/input/input.component';
import { ModalComponent } from '../../../utility-modules/modals/components/modal/modal.component';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
    selector: 'itd-add-timelines-modal',
    templateUrl: './add-timelines-modal.component.html',
    styleUrls: ['./add-timelines-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ReactiveFormsModule,
        ModalComponent,
        InputComponent,
        NgIf,
        FoldersComponent,
        CheckboxListComponent,
        CheckboxListLabelDirective,
        ButtonComponent,
        TranslateModule,
    ],
})
export class AddTimelinesModalComponent extends AbstractAddModalComponent<ITimeline> {
	public key = 'TIMELINES';
	public departmentFoldersActive = true;

	@Select(TimelineState.items) protected items$: Observable<ITimeline[]>;

	constructor(activeModal: NgbActiveModal, private store: Store, cd: ChangeDetectorRef) {
		super(activeModal, cd);
	}

	protected onLoad = () => {
		return this.store.dispatch(LoadAllTimelines);
	};
}
