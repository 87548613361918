import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { ApiClientService } from '../api/api.service';
import { IEIS } from '../employee-integration-score/eis.interface';
import { ISurveyDTO } from '../surveys/surveys.interface';
import { ITasklistAssignment } from '../tasklist-assignment/tasklist-assignment.interface';
import { createParams } from '../utils/analytics-params.util';
import { TResults } from './../models/results.interface';
import {
	IAnalyticsEmployeeActivity,
	IAnalyticsFastTrackReport,
	IAnalyticsLogin,
	IAnalyticsLoginDTO,
	IAnalyticsOnboardingIndex,
	IAnalyticsOnboardingIndexOverEmploymentTime,
	IAnalyticsOnboardingIndexOverTime,
	IAnalyticsSurveyAnswersOverTime,
	IAnalyticsSurveyOverTime,
	IAnalyticsTimelineDetails,
	IAnalyticsUser,
	IAnalyticsUserDTO,
	IAnalyticsUserTask,
	IAnalyticsUserType,
	IFilterAnalytics,
	IGenericAnalyticsDTO,
	TAnalyticsFastTrackDimensions,
} from './analytics.interface';

// ! It would probably be wise to split this file into several analytics services, one for their respective realm of the platform

@Injectable({
	providedIn: 'root',
})
export class AnalyticsService {
	constructor(protected api: ApiClientService) {}

	public getUserTypes(payload: IFilterAnalytics): Observable<IAnalyticsUserType> {
		const params = createParams(payload);

		return this.api
			.get<TResults<IAnalyticsUserType>>('api/v2/analytics/user-types', {
				params,
			})
			.pipe(
				take(1),
				map(result => {
					return result.data;
				})
			);
	}

	public getUserTasks(payload: IFilterAnalytics): Observable<IAnalyticsUserTask> {
		const params = createParams(payload);

		return this.api
			.get<TResults<IAnalyticsUserTask>>('api/v2/analytics/tasks-overview', {
				params,
			})
			.pipe(
				take(1),
				map(result => {
					return result.data;
				})
			);
	}

	public getLogins(payload: IFilterAnalytics): Observable<IAnalyticsLogin[]> {
		const params = createParams(payload, 'months');
		return this.api
			.get<TResults<IAnalyticsLoginDTO>>('api/v2/analytics/logins-over-time', {
				params,
			})
			.pipe(
				take(1),
				map(result => {
					const interval = result.data.interval;
					return result.data.dataPoints.map(item => {
						const { _id, count } = item;
						return {
							date:
								interval && interval === 'month'
									? dayjs(_id).format('MMM')
									: dayjs(_id).format('DD MMM'),
							count: count,
						};
					});
				})
			);
	}

	public getUsers(payload: IFilterAnalytics): Observable<IAnalyticsUser[]> {
		const params = createParams(payload);

		return this.api
			.get<TResults<IAnalyticsUserDTO>>('api/v2/analytics/users-over-time', {
				params,
			})
			.pipe(
				take(1),
				map(result => {
					const interval = result.data.interval;
					return result.data.dataPoints.map(item => {
						const { _id, count } = item;
						return {
							date:
								interval && interval === 'month'
									? dayjs(_id).format('MMM')
									: dayjs(_id).format('DD MMM'),
							count: count,
						};
					});
				})
			);
	}

	public getEmployeeActivity(payload: IFilterAnalytics): Observable<IAnalyticsEmployeeActivity> {
		const params = createParams(payload);

		return this.api
			.get<TResults<IAnalyticsEmployeeActivity>>('api/v2/analytics/employee-activity', {
				params,
			})
			.pipe(
				take(1),
				map(result => {
					return result.data;
				})
			);
	}

	public getEmployeeIntegrationScore(payload: IFilterAnalytics): Observable<IEIS> {
		const params = createParams(payload);

		return this.api
			.get<any>(`api/eis/organization`, { params })
			.pipe(map(response => response?.data));
	}

	public analyticsTaskLists(payload: IFilterAnalytics): Observable<ITasklistAssignment> {
		const params = createParams(payload, 'months', false, 25, 0);

		return this.api
			.get<TResults<ITasklistAssignment>>('api/v2/analytics/search-tasklists', {
				params,
			})
			.pipe(
				take(1),
				map(result => {
					return result.data;
				})
			);
	}

	public analyticsTimeLine(payload: IFilterAnalytics): Observable<IAnalyticsTimelineDetails[]> {
		const params = createParams(payload, 'months', false, 100, 0);

		return (
			this.api
				// NOTE : from and to parameters not working
				.get<TResults<IAnalyticsTimelineDetails[]>>('api/v2/analytics/timelines/search-analytics', {
					params,
				})
				.pipe(
					take(1),
					map(result => {
						return result.data;
					})
				)
		);
	}

	public analyticsSurveyOverTime(
		payload: IFilterAnalytics
	): Observable<IAnalyticsSurveyOverTime[]> {
		const params = createParams(payload);

		return (
			this.api
				// NOTE : from and to parameters not working
				.get<TResults<IGenericAnalyticsDTO>>('api/v2/analytics/surveys-over-time', {
					params,
				})
				.pipe(
					take(1),
					map(result => {
						const interval = result.data.interval;
						return result.data.dataPoints.map(item => {
							const { _id, count } = item;
							return {
								date:
									interval && interval === 'month'
										? dayjs(_id).format('MMM')
										: dayjs(_id).format('DD MMM'),
								count: count,
							};
						});
					})
				)
		);
	}

	public analyticsSurveyAnswersOverTime(
		payload: IFilterAnalytics
	): Observable<IAnalyticsSurveyAnswersOverTime[]> {
		const params = createParams(payload);

		return (
			this.api
				// NOTE : from and to parameters not working
				.get<TResults<IGenericAnalyticsDTO>>('api/v2/analytics/survey-answers-over-time', {
					params,
				})
				.pipe(
					take(1),
					map(result => {
						const interval = result.data.interval;
						return result.data.dataPoints.map(item => {
							const { _id, count } = item;
							return {
								date:
									interval && interval === 'month'
										? dayjs(_id).format('MMM')
										: dayjs(_id).format('DD MMM'),
								count: count,
							};
						});
					})
				)
		);
	}

	public analyticsSurvey(payload: IFilterAnalytics): Observable<ISurveyDTO[]> {
		const params = createParams(payload, '', true, 25, 0);

		return (
			this.api
				// NOTE : from and to parameters not working
				.get<TResults<ISurveyDTO[]>>('api/v2/analytics/surveys/search-analytics', {
					params,
				})
				.pipe(
					take(1),
					map(result => {
						return result.data;
					})
				)
		);
	}

	public loadAnalyticsFastTrackReports(
		filtersObject: any
	): Observable<IAnalyticsFastTrackReport[]> {
		const { payload }: { payload: IFilterAnalytics } = filtersObject;
		const params = createParams(payload);

		return this.api
			.get<TResults<IAnalyticsFastTrackReport[]>>('api/v2/analytics/fast-track/reports', {
				params,
			})
			.pipe(
				take(1),
				map(result => {
					return result.data;
				})
			);
	}

	public loadAnalyticsOnboardingIndex(filtersObject: any): Observable<number> {
		const { payload }: { payload: IFilterAnalytics } = filtersObject;
		const params = createParams(payload);

		return this.api
			.get<TResults<IAnalyticsOnboardingIndex>>('api/v2/analytics/fast-track/onboarding-index', {
				params,
			})
			.pipe(
				take(1),
				map(result => {
					return result.data?.onboarding_index;
				})
			);
	}

	public loadAnalyticsOnboardingIndexOverTime(
		payload: IFilterAnalytics
	): Observable<IAnalyticsOnboardingIndexOverTime[]> {
		const params = createParams(payload);

		return this.api
			.get<TResults<IAnalyticsOnboardingIndexOverTime[]>>(
				'api/v2/analytics/fast-track/onboarding-index-over-time',
				{
					params,
				}
			)
			.pipe(
				take(1),
				map(result => {
					return result.data;
				})
			);
	}

	public loadAnalyticsOnboardingIndexOverEmploymentTime(
		payload: IFilterAnalytics
	): Observable<IAnalyticsOnboardingIndexOverEmploymentTime[]> {
		const params = createParams(payload);

		return this.api
			.get<TResults<IAnalyticsOnboardingIndexOverEmploymentTime[]>>(
				'api/v2/analytics/fast-track/onboarding-index-over-employment-time',
				{
					params,
				}
			)
			.pipe(
				take(1),
				map(result => {
					return result.data;
				})
			);
	}

	public loadAnalyticsFastTrackDimensions(filtersObject: any): Observable<any> {
		const { payload }: { payload: IFilterAnalytics } = filtersObject;
		const params = createParams(payload);

		return this.api
			.get<TResults<TAnalyticsFastTrackDimensions>>('api/v2/analytics/fast-track/dimensions', {
				params,
			})
			.pipe(
				take(1),
				map(result => {
					return result.data;
				})
			);
	}

	public loadAnalyticsFastTrackDimensionsOverTime(filtersObject: any): Observable<any> {
		const { payload }: { payload: IFilterAnalytics } = filtersObject;
		const params = createParams(payload);

		return this.api
			.get<TResults<TAnalyticsFastTrackDimensions>>(
				'api/v2/analytics/fast-track/dimensions-over-time',
				{
					params,
				}
			)
			.pipe(
				take(1),
				map(result => {
					return result.data;
				})
			);
	}

	public loadAnalyticsFastTrackDimensionsOverEmploymentTime(filtersObject: any): Observable<any> {
		const { payload }: { payload: IFilterAnalytics } = filtersObject;
		const params = createParams(payload);

		return this.api
			.get<TResults<TAnalyticsFastTrackDimensions>>(
				'api/v2/analytics/fast-track/dimensions-over-employment-time',
				{
					params,
				}
			)
			.pipe(
				take(1),
				map(result => {
					return result.data;
				})
			);
	}

	public downloadSurvey(data: {
		payload: {
			survey_id: string | number;
			survey_name: string;
			anonymized: boolean;
			department_id?: string;
			from?: string;
			to?: string;
		};
	}): Observable<Blob> {
		if (!data.payload.survey_id) return;
		const params = createParams(data.payload);

		return this.api
			.get<Blob>(
				`api/surveys/${data.payload.survey_id}/print?anonymized=${data.payload.anonymized}`,
				{
					responseType: 'blob',
					params,
				}
			)
			.pipe(
				tap(result => {
					const url = window.URL.createObjectURL(result);
					const link = document.createElement('a');

					link.href = url;
					link.download = data.payload.survey_name + '.xlsx';
					link.click();
				})
			);
	}

	public downloadSurveyPDF(data: {
		payload: {
			survey_id: string | number;
			survey_name: string;
			anonymized: boolean;
			department_id?: string;
			from?: string;
			to?: string;
		};
	}): Observable<Blob> {
		if (!data.payload.survey_id) return;
		const params = createParams(data.payload);

		return this.api
			.get<Blob>(
				`api/surveys/${data.payload.survey_id}/print-pdf?anonymized=${data.payload.anonymized}`,
				{
					responseType: 'blob',
					params,
				}
			)
			.pipe(
				tap(result => {
					const url = window.URL.createObjectURL(result);
					const link = document.createElement('a');

					link.href = url;
					link.download = data.payload.survey_name + '.pdf';
					link.click();
				})
			);
	}
}
