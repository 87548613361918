import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconComponent } from '../../../utility-modules/icon/components/icon/icon.component';
import { InputComponent } from '../../../utility-modules/itd-form/components/input/input.component';

@Component({
	selector: 'itd-text-choice',
	templateUrl: './text-choice.component.html',
	styleUrls: ['./text-choice.component.scss'],
	standalone: true,
	imports: [InputComponent, IconComponent],
})
export class TextChoiceComponent implements OnInit {
	@Input() index: number;
	@Output() valueChange = new EventEmitter<string>();
	@Output() delete = new EventEmitter<boolean>();
	@Input() initValue: string;

	public value: string;

	constructor() {}

	ngOnInit(): void {
		this.value = this.initValue;
	}

	public emitInput(value: any): void {
		this.value = value;
		this.valueChange.emit(value);
	}

	public deleteInput() {
		this.delete.emit(true);
	}
}
