import { NgClass, NgFor, NgTemplateOutlet } from '@angular/common';
import {
	AfterContentInit,
	Component,
	ContentChildren,
	EventEmitter,
	Output,
	QueryList,
} from '@angular/core';
import { TimelineRadioItemDirective } from './timeline-radio-item.directive';

@Component({
	selector: 'itd-timeline-radio',
	templateUrl: './timeline-radio.component.html',
	styleUrls: ['./timeline-radio.component.scss'],
	standalone: true,
	imports: [NgFor, NgClass, NgTemplateOutlet],
})
export class TimelineRadioComponent implements AfterContentInit {
	@ContentChildren(TimelineRadioItemDirective) public items: QueryList<TimelineRadioItemDirective>;

	@Output() public onChange = new EventEmitter<string>();

	public active: string;

	ngAfterContentInit(): void {
		if (!this.items.length) {
			return;
		}

		this.active = this.items.get(0).key;
	}

	public click(value: string) {
		this.onChange.emit(value);
		this.active = value;
	}
}
