import { LayoutMobileState } from './../../../core/layout/children/layout-mobile.state';
import { WithDestroyComponent } from 'src/app/core/abstract/abstract-with-destroy-component';
import { ChangeDetectionStrategy, Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { INavigationItem, NavigationItemComponent } from '../navigation-item/navigation-item.component';
import { SetMobileFooterNavigationDrawer } from 'src/app/core/layout/children/layout-mobile.actions';
import { IOrganization } from 'src/app/core/organizations/organizations.interface';
import { OrganizationState } from 'src/app/core/organizations/organizations.state';
import { SideDrawerComponent } from '../side-drawer/side-drawer.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'itd-footer-navigation-side-drawer-mobile',
    templateUrl: './footer-navigation-side-drawer-mobile.component.html',
    styleUrls: ['./footer-navigation-side-drawer-mobile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        SideDrawerComponent,
        NgFor,
        NavigationItemComponent,
        AsyncPipe,
    ],
})
export class FooterNavigationSideDrawerMobileComponent extends WithDestroyComponent
	implements OnInit, OnDestroy {
	public navigation: INavigationItem[];
	private organization: IOrganization;

	@Select(LayoutMobileState.isFooterNavigationDrawer) public isActive$: Observable<boolean>;

	constructor(public store: Store, private route: ActivatedRoute) {
		super();
	}

	public ngOnInit(): void {
		this.organization = this.store.selectSnapshot<IOrganization>(OrganizationState.organization);
		const { permissions } = this.route.snapshot.data;
		const navigation: INavigationItem[] = this.route.snapshot.data.navigation;

		if (permissions && permissions.length) {
			this.navigation = navigation
				.filter(item => {
					const permission = permissions.find(perm => perm.title === item.title);
					if (permission) {
						return this.organization[permission.permissionName];
					} else return true;
				})
				.slice(3);
		} else {
			this.navigation = navigation.slice(3);
		}
	}

	public ngOnDestroy(): void {
		this.closeSideDrawer();
	}

	public closeSideDrawer() {
		this.store.dispatch(new SetMobileFooterNavigationDrawer({ isActive: false }));
	}
}
