import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../../utility-modules/modals/components/modal/modal.component';

@Component({
	selector: 'itd-simple-modal',
	templateUrl: './simple-modal.component.html',
	styleUrls: ['./simple-modal.component.scss'],
	standalone: true,
	imports: [ModalComponent, NgIf],
})
export class SimpleModalComponent {
	constructor(private activeModal: NgbActiveModal) {}

	@Input({ required: true }) public title: string;
	@Input({ required: true }) public text: string;
	@Input({ required: true }) public buttonName: string;
	@Input() public secondaryButtonName: string;

	@Output() public buttonClicked = new EventEmitter<void>();

	public buttonClick() {
		this.buttonClicked.emit();
		this.activeModal.close();
	}

	public cancelClick() {
		this.activeModal.close();
	}
}
