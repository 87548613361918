<div class="container">
	<div class="header" *ngIf="header">
		<ng-template
			[ngTemplateOutletContext]="{ $implicit: isActiveBody }"
			[ngTemplateOutlet]="header"
		></ng-template>
	</div>

	<div *ngIf="isActiveBody" class="body">
		<div class="body__wrapper">
			<ng-template [ngTemplateOutlet]="body"></ng-template>
		</div>
	</div>
</div>
