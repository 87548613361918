<div
	class="input-wrapper"
	[ngStyle]="{
		'maxWidth.px': maxWidth
	}"
>
	<itd-input
		[focusAfterInit]="focusAfterInit"
		sizeType="small"
		name="filter"
		type="text"
		icon="search"
		iconPosition="left"
		[hasBorder]="hasBorder"
		[hasShadow]="hasShadow"
		[value]="value"
		[isInline]="true"
		[placeholder]="placeholder | translate"
		(write)="valueChange($event); emitChange($event)"
	>
	</itd-input>
</div>
