import { LayoutMobileState } from './../../../../core/layout/children/layout-mobile.state';
import { INavigationItem } from './../../navigation-item/navigation-item.component';
import { ProfileState } from './../../../../core/profile/profile.state';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { IProfile } from 'src/app/core/profile/profile.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LogoutModalComponent } from 'src/app/shared/modals/logout-modal/logout-modal.component';
import { ActivatedRoute } from '@angular/router';
import { SetMobileHeaderProfileDrawer } from 'src/app/core/layout/children/layout-mobile.actions';
import { NavigationItemComponent } from '../../navigation-item/navigation-item.component';
import { UserAvatarComponent } from '../../user-avatar/user-avatar.component';
import { SideDrawerComponent } from '../../side-drawer/side-drawer.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'itd-header-profile-side-drawer-mobile',
    templateUrl: './header-profile-side-drawer-mobile.component.html',
    styleUrls: ['./header-profile-side-drawer-mobile.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        SideDrawerComponent,
        UserAvatarComponent,
        NgFor,
        NavigationItemComponent,
        AsyncPipe,
    ],
})
export class HeaderProfileSideDrawerMobileComponent implements OnInit, OnDestroy {
	public navigation: INavigationItem[];

	@Select(LayoutMobileState.isHeaderProfileDrawer) public isActive$: Observable<IProfile>;
	@Select(ProfileState.profile) public profile$: Observable<IProfile>;

	constructor(private store: Store, private modals: NgbModal, private route: ActivatedRoute) {}

	public ngOnInit() {
		const isAdmin = this.store.selectSnapshot(ProfileState.profile).user_role !== 'std_user';

		this.navigation = (this.route.snapshot.data.secondNavigation as INavigationItem[]).filter(item => {
			if (item.isForAdmin) {
				return isAdmin;
			}

			return true;
		});
	}

	public ngOnDestroy(): void {
		this.closeSideDrawer();
	}

	public closeSideDrawer() {
		this.store.dispatch(new SetMobileHeaderProfileDrawer({ isActive: false }));
	}

	public logout() {
		this.modals.open(LogoutModalComponent);
	}
}
