export interface IAchievementsIcon {
	id: number;
	filename: string;
}

export const achievementsIcons: IAchievementsIcon[] = [
	{
		id: 0,
		filename: 'achieved',
	},
	{
		id: 1,
		filename: 'completed-task',
	},
	{
		id: 2,
		filename: 'god-like',
	},
	{
		id: 3,
		filename: 'newbie',
	},
	{
		id: 4,
		filename: 'run-like-cheetah',
	},
	{
		id: 5,
		filename: 'synced',
	},
	{
		id: 6,
		filename: 'barefoot',
	},
	{
		id: 7,
		filename: 'crippled',
	},
	{
		id: 8,
		filename: 'gold',
	},
	{
		id: 9,
		filename: 'no-junk',
	},
	{
		id: 10,
		filename: 'silver',
	},
	{
		id: 11,
		filename: 'target-achieved',
	},
	{
		id: 12,
		filename: 'big-foot',
	},
	{
		id: 13,
		filename: 'dehydrated',
	},
	{
		id: 14,
		filename: 'high-tops',
	},
	{
		id: 15,
		filename: 'powered-up',
	},
	{
		id: 16,
		filename: 'slow-start',
	},
	{
		id: 17,
		filename: 'timer',
	},
	{
		id: 18,
		filename: 'bronze',
	},
	{
		id: 19,
		filename: 'eat-healthy',
	},
	{
		id: 20,
		filename: 'hooked-up',
	},
	{
		id: 21,
		filename: 'rest-time',
	},
	{
		id: 22,
		filename: 'stopwatch',
	},
	{
		id: 23,
		filename: 'to-the-moon-and-back',
	},
	{
		id: 24,
		filename: 'champion',
	},
	{
		id: 25,
		filename: 'flip-flop',
	},
	{
		id: 26,
		filename: 'light-bringer',
	},
	{
		id: 27,
		filename: 'robotic-legs',
	},
	{
		id: 28,
		filename: 'swim-like-shark',
	},
	{
		id: 29,
		filename: 'training-alarm',
	},
];
