import { Directive, ViewContainerRef, ElementRef } from '@angular/core';

@Directive({
    selector: '[itdRowOutlet]',
    standalone: true
})
export class RowOutletDirective {
	// tslint:disable-next-line: no-any
	constructor(public viewContainer: ViewContainerRef, public elementRef: ElementRef) {}
}
