import { ITasklist, ITasklistEdit } from './tasklist.interface';

export class LoadAllTasklists {
	public static readonly type = '[Tasklist] Load All';
}

export class LoadAllTasklistsIfEmpty {
	public static readonly type = '[Tasklist] Load All If Empty';
}

export class CreateTasklist {
	public static readonly type = '[Tasklist] Create';
	constructor(public payload: { data: ITasklistEdit }) {}
}

export class UpdateTasklist {
	public static readonly type = '[Tasklist] Update';
	constructor(public payload: { id: string; data: ITasklistEdit }) {}
}

export class DeleteTasklist {
	public static readonly type = '[Tasklist] Delete';
	constructor(public payload: { id: string }) {}
}

export class SelectTasklist {
	public static readonly type = '[Tasklist] Select Tasklist ';
	constructor(public payload: { data: ITasklist }) {}
}

export class ClearSelectedTasklist {
	public static readonly type = '[Tasklist] Clear';

	constructor() {}
}

export class TasklistCreateTask {
	public static readonly type = '[Tasklist] Create Task';

	constructor(public payload: { data: any }) {}
}

export class TasklistUpdateTask {
	public static readonly type = '[Tasklist] Update Task';

	constructor(public payload: { index: number; data: any }) {}
}

export class TasklistDeleteTask {
	public static readonly type = '[Tasklist] Delete Task';

	constructor(public payload: { id: string }) {}
}

export class TasklistClearTasks {
	public static readonly type = '[Tasklist] Clear Task';

	constructor() {}
}
