import { Injectable } from '@angular/core';
import { Selector, State } from '@ngxs/store';

export interface FontStateModel {
	defaultFonts: Array<{ name: string; fontString: string }>;
}

@State<FontStateModel>({
	name: 'fonts',
	defaults: {
		defaultFonts: [
			{
				name: 'Arial',
				fontString: 'Arial, Helvetica, sans-serif',
			},
			{
				name: 'Arial Black',
				fontString: 'Arial Black, Gadget, sans-serif',
			},
			{
				name: 'Calibri',
				fontString: 'Calibri, Arial, Helvetica, sans-serif',
			},
			{
				name: 'Cambria Math',
				fontString: '"Cambria Math", Arial, Helvetica, sans-serif',
			},
			{
				name: 'Courier New',
				fontString: 'Courier New, monospace',
			},
			{
				name: 'Georgia',
				fontString: 'Georgia, serif',
			},
			{
				name: 'Helvetica',
				fontString: 'Helvetica Neue,Helvetica,Arial,sans-serif',
			},
			{
				name: 'Lucida Console/Monaco',
				fontString: 'Lucida Console, Monaco, monospace',
			},
			{
				name: 'Lucida Sans/Lucida Grande',
				fontString: 'Lucida Sans Unicode, Lucida Grande, sans-serif',
			},
			{
				name: 'Palatino',
				fontString: 'Palatino Linotype, Book Antiqua, Palatino, serif',
			},
			{
				name: 'Poppins',
				fontString: 'Poppins, Arial, Helvetica, sans-serif',
			},
			{
				name: 'Sofia Pro',
				fontString: '"Sofia Pro", Helvetica, sans-serif',
			},
			{
				name: 'Tahoma/Geneva',
				fontString: 'Tahoma, Geneva, sans-serif',
			},
			{
				name: 'Times New Roman',
				fontString: 'Times New Roman, Times, serif',
			},
			{
				name: 'Trebuchet MS',
				fontString: 'Trebuchet MS, sans-serif',
			},
			{
				name: 'Verdana/Geneva',
				fontString: 'Verdana, Geneva, sans-serif',
			},
			{
				name: 'Chainalysis',
				fontString: 'Chainalysis',
			},
			{
				name: 'Nationalbanken',
				fontString: 'Nationalbanken',
			},
			{
				name: 'Sweco Sans',
				fontString: '"SwecoSans", sans-serif',
			},
		],
	},
})
@Injectable()
export class FontState {
	constructor() {}

	@Selector()
	public static getDefaultFonts(state: FontStateModel): unknown {
		return state.defaultFonts;
	}
}
