import { IEIS, IPersonalEIS, IPersonalEISDTO } from './eis.interface';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { EISService } from './eis.service';
import { AbstractApiState } from '../abstract/abstract-api-state';
import { tap } from 'rxjs';
import { LoadPersonalEIS } from './eis.action';

export interface EISStateModel {
	personalEIS: IPersonalEIS;
	eis: IEIS;
}

@State<EISStateModel>({
	name: 'eis',
	defaults: {
		personalEIS: null,
		eis: null,
	},
})
@Injectable()
export class EISState extends AbstractApiState<IPersonalEIS, IPersonalEISDTO> {
	constructor(protected service: EISService, store: Store) {
		super(service, store);
	}

	@Selector()
	public static getState(state: EISStateModel) {
		return state;
	}

	@Selector()
	public static personalEIS(state: EISStateModel) {
		return state.personalEIS;
	}

	@Action(LoadPersonalEIS)
	public loadPersonalEIS({ patchState }: StateContext<EISStateModel>) {
		return this.service.getPersonalEIS().pipe(
			tap(result => {
				patchState({
					personalEIS: result,
				});
			})
		);
	}

	@Selector()
	public static EIS(state: EISStateModel) {
		return state.eis;
	}
}
