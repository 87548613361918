import { IconModule } from '../icon/icon.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidebarComponent } from './sidebar/matsidebar.component';

const declarations = [MatSidebarComponent];

@NgModule({
    exports: [...declarations],
    imports: [CommonModule, IconModule, ...declarations],
})
export class MatSideBarModule {}
