import { EventEmitter, Input } from '@angular/core';
import { ChangeDetectionStrategy, Component, OnInit, Output } from '@angular/core';
import { TTimelineBoarding } from 'src/app/core/timelines/timelines.interface';
import { NgFor, NgClass } from '@angular/common';

export type TCalendarFilter = Record<TTimelineBoarding, boolean>;
export interface ICalendarFilter {
	color: 'orange' | 'blue' | 'purple';
	label: string;
	value: string;
}

@Component({
	selector: 'itd-calendar-filters',
	templateUrl: './calendar-filters.component.html',
	styleUrls: ['./calendar-filters.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgFor, NgClass],
})
export class CalendarFiltersComponent implements OnInit {
	@Input() public filters: TCalendarFilter;

	@Output() public onChange = new EventEmitter<TCalendarFilter>();

	public items: ICalendarFilter[] = [
		{ color: 'orange', label: 'Preboarding', value: 'preboarding' },
		{ color: 'blue', label: 'Onboarding', value: 'onboarding' },
		{ color: 'purple', label: 'Offboarding', value: 'offboarding' },
	];

	constructor() {}

	ngOnInit(): void {}

	public toggle(value: TTimelineBoarding | string) {
		this.filters[value] = !this.filters[value];

		this.onChange.emit(this.filters);
	}
}
