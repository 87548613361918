import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { ApiClientService } from '../api/api.service';
import { TResults } from '../models/results.interface';
import {
	IOrganization,
	IOrganizationDTO,
	IOrganizationEdit,
	IOrganizationEditDTO,
} from './organizations.interface';
import { AbstractApiService } from '../abstract/abstract-api-service';
import { IMessageTypeEdit } from '../messages/messages.interface';
import { ILoginResponse } from '../models/login.interface';

@Injectable({
	providedIn: 'root',
})
export class OrganizationService extends AbstractApiService<
	IOrganization,
	IOrganizationDTO,
	IOrganizationEdit,
	IOrganizationEditDTO
> {
	protected key: string = 'org';

	constructor(protected api: ApiClientService, store: Store) {
		super(api, store);
	}

	public getMyOrg(): Observable<IOrganization> {
		return this.api
			.get<TResults<IOrganizationDTO>>('api/org/myorg')
			.pipe(map(response => this.parse(response.data)));
	}

	// TODO: Consider to move this into the profile service
	public changeMyOrg(userId: string, org_id: string): Observable<IOrganization & ILoginResponse> {
		return this.api
			.post<TResults<IOrganizationDTO> & ILoginResponse>(`api/users/${userId}/change-org`, {
				org_id,
			})
			.pipe(
				map(response => {
					if (!response.success) {
						return null;
					}

					return { ...this.parse(response.data), token: response.token };
				})
			);
	}

	public getOne(id: string): Observable<IOrganization> {
		return this.api.get<TResults<IOrganizationDTO>>(`api/${this.key}/${id}`).pipe(
			map(response => {
				if (!response.success) {
					return null;
				}

				return this.parse(response.data);
			})
		);
	}

	public getPublicSignup(id: string, linkId?: string): Observable<IOrganization> {
		let params = {};
		if (linkId) {
			params = {
				params: {
					link_id: linkId,
				},
			};
		}
		return this.api.get<TResults<IOrganizationDTO>>(`open-api/${this.key}/${id}`, params).pipe(
			map(response => {
				if (!response.success) {
					return null;
				}
				return this.parse(response.data);
			})
		);
	}

	public getAll(): Observable<IOrganization[]> {
		return this.api
			.get<TResults<IOrganizationDTO[]>>('api/org?limit=1000')
			.pipe(map(response => response.data.map(organization => this.parse(organization))));
	}

	public updateMessage(id: number | string, payload: IMessageTypeEdit): Observable<IOrganization> {
		return this.api.put<TResults<IOrganizationDTO>>(`api/${this.key}/${id}`, payload).pipe(
			map(response => {
				return this.parse(response.data);
			})
		);
	}

	protected parse(value: IOrganizationDTO): IOrganization {
		const { org_name, org_logo_media_id } = value;

		return {
			...value,
			name: org_name,
			logo_url: org_logo_media_id,
		};
	}

	protected parseEditDTO(value: IOrganizationEdit): IOrganizationEditDTO {
		const { name, logo_url } = value;

		return {
			...value,
			org_name: name,
			org_logo_media_id: logo_url,
		};
	}
}
