import {
	ISurvey,
	ISurveyAnswerDTO,
	ISurveyAnswerDetails,
	ISurveyDTO,
	ISurveyEdit,
	ISurveyEditDTO,
} from './surveys.interface';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { SurveysService } from './survey.service';
import { AbstractApiState } from '../abstract/abstract-api-state';
import { tap } from 'rxjs';
import {
	CreateSurvey,
	DeleteSurvey,
	DeleteSurveyAnswers,
	DuplicateSurvey,
	LoadAllSurveyAnswers,
	LoadAllSurveys,
	LoadAllSurveysIfEmpty,
	LoadSurvey,
	LoadSurveyAnswers,
	LoadSurveyStats,
	ResetActiveSurvey,
	UpdateSurvey,
} from './surveys.action';

export interface SurveysStateModel {
	items: ISurvey[];
	activeSurvey?: ISurvey;
	activeSurveyAnswers?: ISurveyAnswerDetails[];
	surveyStats?: any;
	allSurveyAnswers?: ISurveyAnswerDTO[];
}

@State<SurveysStateModel>({
	name: 'surveys',
	defaults: {
		items: null,
		activeSurvey: null,
		activeSurveyAnswers: null,
		surveyStats: null,
		allSurveyAnswers: null,
	},
})
@Injectable()
export class SurveysState extends AbstractApiState<
	ISurvey,
	ISurveyDTO,
	ISurveyEdit,
	ISurveyEditDTO
> {
	constructor(private survey: SurveysService, store: Store) {
		super(survey, store);
	}

	@Selector()
	public static getState(state: SurveysStateModel) {
		return state;
	}

	@Selector()
	public static activeSurvey(state: SurveysStateModel) {
		return state.activeSurvey;
	}

	@Selector()
	public static activeSurveyAnswers(state: SurveysStateModel) {
		return state.activeSurveyAnswers;
	}

	@Selector()
	public static allSurveyAnswers(state: SurveysStateModel) {
		return state.allSurveyAnswers;
	}

	@Selector()
	public static surveyStats(state: SurveysStateModel) {
		return state.surveyStats;
	}

	@Selector()
	public static items(state: SurveysStateModel) {
		return state.items;
	}

	@Action(LoadAllSurveys)
	public loadAll(ctx: StateContext<SurveysStateModel>) {
		return super.loadAll(ctx);
	}

	@Action(LoadSurvey)
	public loadOneSurvey(ctx: StateContext<SurveysStateModel>, data: LoadSurvey) {
		const { patchState } = ctx;
		return super.loadOne(data.payload.id).pipe(
			tap(result => {
				patchState({
					activeSurvey: result,
				});
			})
		);
	}

	@Action(LoadSurveyAnswers)
	public loadSurveyAnswers(ctx: StateContext<SurveysStateModel>, data: LoadSurvey) {
		const { patchState } = ctx;
		return this.survey.getSurveyAnswers(data.payload.id).pipe(
			tap(result => {
				patchState({
					activeSurveyAnswers: result,
				});
			})
		);
	}

	@Action(DeleteSurveyAnswers)
	public deleteSurveyAnswer(ctx: StateContext<SurveysStateModel>, data: LoadSurvey) {
		return this.survey.deleteSurveyAnswer(data.payload.id).pipe(
			tap(() => {
				const _items = ctx.getState().allSurveyAnswers;

				if (!_items || !_items.length) {
					return;
				}

				const items = [..._items];
				const index = items.findIndex(item => item._id === data.payload.id);

				if (index === -1) {
					return;
				}

				items.splice(index, 1);

				ctx.patchState({
					allSurveyAnswers: items,
				});
			})
		);
	}

	@Action(LoadAllSurveyAnswers)
	public loadAllSurveyAnswers(ctx: StateContext<SurveysStateModel>, data: LoadSurvey) {
		const { patchState } = ctx;
		return this.survey.getAllSurveyAnswers(data.payload.id).pipe(
			tap(result => {
				patchState({
					allSurveyAnswers: result,
				});
			})
		);
	}

	@Action(LoadSurveyStats)
	public LoadSurveyStats(ctx: StateContext<SurveysStateModel>, data: LoadSurveyStats) {
		const { patchState } = ctx;

		return this.survey.getSurveyStats(data.payload.id, data.payload.department_id).pipe(
			tap(result => {
				patchState({
					surveyStats: result?.data,
				});
			})
		);
	}

	@Action(ResetActiveSurvey)
	public resetActiveSurvey(ctx: StateContext<SurveysStateModel>) {
		const { patchState } = ctx;
		patchState({
			activeSurvey: null,
			activeSurveyAnswers: null,
		});
	}

	@Action(LoadAllSurveysIfEmpty)
	public loadAllIfEmpty(ctx: StateContext<SurveysStateModel>) {
		return super.loadAllIfEmpty(ctx);
	}

	@Action(CreateSurvey)
	public create(ctx: StateContext<SurveysStateModel>, data: CreateSurvey) {
		return super.create(ctx, data);
	}

	@Action(UpdateSurvey)
	public update(ctx: StateContext<SurveysStateModel>, data: UpdateSurvey) {
		return super.update(ctx, data);
	}

	@Action(DeleteSurvey)
	public delete(ctx: StateContext<SurveysStateModel>, data: DeleteSurvey) {
		return super.delete(ctx, data);
	}

	@Action(DuplicateSurvey)
	public duplicate(ctx: StateContext<SurveysStateModel>, data: DuplicateSurvey) {
		return super.duplicate(ctx, data);
	}
}
