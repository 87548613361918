import { AsyncPipe, DatePipe, NgFor, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { IOrganization } from 'src/app/core/organizations/organizations.interface';
import { OrganizationState } from 'src/app/core/organizations/organizations.state';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { UpdateTaskAssignment } from 'src/app/core/task-assignment/task-assignment.action';
import { ITaskAssignment } from 'src/app/core/task-assignment/task-assignment.interface';
import { getDateXDaysAgo } from 'src/app/core/utils/dates.util';
import { AbstractFormComponent } from 'src/app/utility-modules/itd-form/components/abstract-form/abstract-form.component';
import { GridItemComponent } from '../../../utility-modules/grid/components/grid-item/grid-item.component';
import { GridComponent } from '../../../utility-modules/grid/components/grid/grid.component';
import { IconComponent } from '../../../utility-modules/icon/components/icon/icon.component';
import { ButtonComponent } from '../../../utility-modules/itd-form/components/button/button.component';
import { FormErrorComponent } from '../../../utility-modules/itd-form/components/form-error/form-error.component';
import { ModalComponent } from '../../../utility-modules/modals/components/modal/modal.component';
import { HighlightLinksPipe } from '../../pipes/highlight-links.pipe';

@Component({
	selector: 'itd-cantdo-task-assignment-modal',
	templateUrl: './cantdo-task-assignment-modal.component.html',
	styleUrls: ['./cantdo-task-assignment-modal.component.scss'],
	standalone: true,
	imports: [
		ReactiveFormsModule,
		ModalComponent,
		NgIf,
		GridComponent,
		GridItemComponent,
		IconComponent,
		FormErrorComponent,
		NgFor,
		ButtonComponent,
		AsyncPipe,
		DatePipe,
		TranslateModule,
		HighlightLinksPipe,
	],
})
export class CantdoTaskAssignmentModalComponent extends AbstractFormComponent implements OnInit {
	public form: FormGroup;
	@Select(OrganizationState.organization) public organization$: Observable<IOrganization>;
	public getDateXDaysAgo = getDateXDaysAgo;

	@Input() public addNotesOnly: boolean = false;
	@Input() public viewDetails: boolean = false;
	@Input() public taskAssignment: ITaskAssignment;

	@Output() public error = new EventEmitter<HttpErrorResponse>();
	@Output() public success = new EventEmitter<void>();

	constructor(
		public store: Store,
		public validationMessages: ValidationMessagesService,
		public elementRef: ElementRef,
		protected cd: ChangeDetectorRef,
		private activeModal: NgbActiveModal
	) {
		super(cd);
	}

	async ngOnInit(): Promise<void> {
		if (this.addNotesOnly) {
			this.form = new FormGroup({
				notes: new FormControl('', [Validators.required]),
			});
		} else if (this.viewDetails) {
			// Hack for disabling form errors, so we can close modal
			this.form = new FormGroup({
				notes: new FormControl(''),
			});
		} else {
			this.form = new FormGroup({
				notes: new FormControl('', [Validators.required]),
				due_date: new FormControl(this.taskAssignment.due_date),
			});
		}
	}

	public onSubmitAction() {
		if (!this.viewDetails && !this.addNotesOnly) {
			return this.store.dispatch(
				new UpdateTaskAssignment({
					id: this.taskAssignment.id,
					data: { ...this.formValue, status: 'cantdo', completed: true },
				})
			);
		}
		if (this.addNotesOnly) {
			return this.store.dispatch(
				new UpdateTaskAssignment({
					id: this.taskAssignment.id,
					data: { ...this.formValue },
				})
			);
		}
		if (this.viewDetails) {
			this.activeModal.close();
		}
	}

	public onSubmitSuccess() {
		this.closeModal();
	}

	public closeModal() {
		this.activeModal.close();
	}
}
