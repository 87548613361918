<itd-select
	sizeType="tiny"
	dropdownPosition="top"
	printLabelIndex="title"
	outputValueIndex="value"
	(ngModelChange)="onChange($event)"
	[data]="items"
	[ngModel]="value"
	[isSearchable]="false"
	[isClearable]="false"
	[hasBorder]="true"
>
</itd-select>
