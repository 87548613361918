import { IMedia } from '../media/media.interface';
import { MakeUrlUtil } from './make-url';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { MediaState } from '../media/media.state';
import { IEnvironment } from 'src/environments/environment.interface';

@Injectable({ providedIn: 'root' })
export class ImageUrlFromKey {
	constructor(
		@Inject('ENV') public env: IEnvironment,
		private makeUrlUtil: MakeUrlUtil,
		private store: Store
	) {}

	makeUrl(key: string, images: IMedia[]) {
		if (!!images?.length) {
			const image = images.find((image) => image.id === key);
			if (!!image) {
				return this.makeUrlUtil.makeUrl(image.url);
			} else {
				return null;
			}
		}
	}

	makeUrlFromKey(key: string) {
		const images = this.store.selectSnapshot(MediaState.getImages);
		return this.makeUrlUtil.makeUrl(images?.find((image) => image.id === key)?.url);
	}

	makeUrlFromKeyWithoutDomain(key: string) {
		const images = this.store.selectSnapshot(MediaState.getImages);
		let fullUrl = this.makeUrlUtil.makeUrl(images?.find((image) => image.id === key).url);
		return fullUrl.replace(`${this.env.mediaUrl}/`, '');
	}
}
