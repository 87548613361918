<label class="label {{sizeType}}" *ngIf="label">{{label}}</label>

<div class="container">

	<quill-editor
		format="html"
		[modules]="{
			toolbar: toolbar
		}"
		(onContentChanged)="modelChange($event)"
		[(ngModel)]="value"
	></quill-editor>
</div>
