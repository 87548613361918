export const shallowEqual = (
	valueA: Object | string | number,
	valueB: Object | string | number
) => {
	if (valueA === null || valueB === null) {
		return;
	}

	if (typeof valueA !== 'object' && typeof valueB !== 'object') {
		return valueA === valueB;
	}

	const keys1 = Object.keys(valueA);
	const keys2 = Object.keys(valueB);

	for (const k of keys1) if (!keys2.includes(k)) return false;
	for (const k of keys2) if (!keys1.includes(k)) return false;

	for (const key of keys1) if (valueA[key] !== valueB[key]) return false;

	return true;
};
