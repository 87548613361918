export interface IFilterGroupItemDTO<T> {
    id: string;
    title: string;
    fun: (item: T) => boolean;
    enabled?: boolean;
    dot?: boolean;
    text?: string;
    icon?: {
        name: string;
        width?: number;
        height?: number;
    };
    color: string;
}

export interface IFilterGroupItem<T> {
    id: string;
    title: string;
    fun: (item: T) => boolean;
    enabled: boolean;
    counter: number;
    color: string;
    dot?: boolean;
    text?: string;
    icon?: {
        name: string;
        width?: number;
        height?: number;
    };
}

export interface IFilterGroup<T> {
    id: string;
    title: string;
    fields: IFilterGroupItem<T>[];
}

export class DataSourceFilter<T> {
    public _filterGroups: { [key: string]: IFilterGroup<T> } = {};

    constructor() {
    }

    public getFilterGroup(groupName: string): IFilterGroup<T> {
        return this._filterGroups[groupName];
    }

    public getFilterItems(groupName: string): IFilterGroupItem<T>[] {
        return this.getFilterGroup(groupName)?.fields || null;
    }

    public getEnabledFilters(): IFilterGroupItem<T>[] {
        const filters = [];

        const groups = this.getFilterGroupsArray();

        for (const group of groups) {
            for (const item of group.fields) {
                if (item.enabled) {
                    filters.push(item);
                }
            }
        }

        return filters;
    }

    public defineFilterGroup(group: { id: string, title: string }): void {
        this._filterGroups[group.id] = {
            id: group.id,
            title: group.title,
            fields: [],
        };
    }

    public defineFilterGroupItem(groupName: string, item: IFilterGroupItemDTO<T>): void {
        if (!this._filterGroups[groupName]) {
            return;
        }

        this._filterGroups[groupName].fields.push({
            ...item,
            enabled: item.enabled ? true : false,
            counter: 0,
        });
    }

    public toggleFilter(groupName: string, itemId: string, enable: boolean = null) {
        if (!this._filterGroups[groupName]) {
            return;
        }

        const field = this._filterGroups[groupName].fields.find(f => f.id === itemId);

        if (enable === null) {
            enable = !(field.enabled);
        }

        field.enabled = enable;
    }

    public clearFilters(): void {
        const groups = this.getFilterGroupsArray();

        for (const group of groups) {
            for (const item of group.fields) {
                item.enabled = false;
            }
        }
    }

    public isFiltering(): boolean {
        const groups = this.getFilterGroupsArray();

        for (const group of groups) {
            for (const item of group.fields) {
                if (item.enabled) {
                    return true;
                }
            }
        }

        return false;
    }

    private getFilterGroupsArray(): IFilterGroup<T>[] {
        const groups = [];
        // tslint:disable-next-line: forin
        for (const key in this._filterGroups) {
            const group = this._filterGroups[key];
            groups.push(group);
        }

        return groups;
    }

    // public resolveCounters(data: T[]): void {
    //     const groups = this.getFilterGroupsArray();
    //     for (const group of groups) {
    //         const restGroups = groups.filter(g => g.id !== group.id);
    //         let filteredData = data;
    //         let skipFilter = true;

    //         for (const restGroup of restGroups) {
    //             filteredData = filteredData.filter(d => {
    //                 for (const item of restGroup.fields) {
    //                     if (!item.enabled) {
    //                         continue;
    //                     }

    //                     skipFilter = false;

    //                     if (item.fun(d)) {
    //                         return true;
    //                     }
    //                 }

    //                 return skipFilter;
    //             });
    //         }

    //         for (const item of group.fields) {
    //             item.counter = 0;

    //             for (const d of filteredData) {
    //                 if (item.fun(d)) {
    //                     item.counter++;
    //                 }

    //             }
    //         }
    //     }
    // }

    public resolveCounters(data: T[]): void {
        const groups = this.getFilterGroupsArray();
        for (const group of groups) {

            for (const item of group.fields) {
                item.counter = 0;

                for (const d of data) {
                    if (item.fun(d)) {
                        item.counter++;
                    }
                }
            }
        }
    }


    public filter(data: T[]): T[] {
        if (!data) {
            return data;
        }

        this.resolveCounters(data);

        let filteredData = data;

        // tslint:disable-next-line: forin
        for (const key in this._filterGroups) {
            const group = this._filterGroups[key];
            let skipFilter = true;

            filteredData = filteredData.filter(d => {
                for (const item of group.fields) {
                    if (!item.enabled) {
                        continue;
                    }

                    skipFilter = false;

                    if (item.fun(d)) {
                        return true;
                    }
                }

                return skipFilter;
            });
        }

        return filteredData;
    }
}
