import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ErrorHandlerService } from './errorHandler.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
	constructor(private errorHandler: ErrorHandlerService, private readonly injector: Injector) {}

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		try {
			const translate = this.injector.get(TranslateService);
			return next.handle(request).pipe(
				catchError((error: HttpErrorResponse) => {
					error.error.message = error?.error?.message
						? translate.instant(error.error.message)
						: error?.error?.message;

					if (error.status === 423) {
						this.errorHandler.handleLockedContentError(error);
					} else {
						this.errorHandler.handleError(error);
					}
					return throwError(error);
				})
			);
		} catch {
			return next.handle(request).pipe(
				catchError((error: HttpErrorResponse) => {
					if (error.status === 423) {
						this.errorHandler.handleLockedContentError(error);
					} else {
						this.errorHandler.handleError(error);
					}
					// let errorMsg: string = '';
					// if (error.error instanceof ErrorEvent) {
					// 	console.log('this is client side error');
					// 	errorMsg = `Error: ${error.error.message}`;
					// } else {
					// 	console.log('this is server side error');
					// 	errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
					// Its possible, for example, to redirect to a custom error page when hte application breaks
					// case 500:
					// 	const navigationExtras: NavigationExtras = { state: { error: err.error } };
					// 	this.router.navigateByUrl("/server-error", navigationExtras);
					// 	break;
					// }
					return throwError(error);
				})
			);
		}
	}
}
