import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { HelpService } from './help.service';
import { IHelpStateModel } from './help.interface';
import { tap } from 'rxjs/operators';
import { GetAllHelpArticles, GetAllHelpArticlesIfEmpty } from './help.actions';

@State<IHelpStateModel>({
	name: 'help',
	defaults: {
		articles: null,
	},
})
@Injectable()
export class HelpState {
	constructor(private service: HelpService) {}

	@Selector()
	public static getState(state: IHelpStateModel) {
		return state;
	}

	@Selector()
	public static articles(state: IHelpStateModel) {
		return state.articles;
	}

	@Action(GetAllHelpArticles)
	public getArticles(ctx: StateContext<IHelpStateModel>) {
		return this.service.getArticles().pipe(
			tap(articles => {
				ctx.patchState({
					articles,
				});
			})
		);
	}

	@Action(GetAllHelpArticlesIfEmpty)
	public getArticlesIfEmpty(ctx: StateContext<IHelpStateModel>) {
		const articles = ctx.getState().articles;

		if (articles && articles.length) {
			return;
		}

		return this.getArticles(ctx);
	}
}
