import { ChangeDetectionStrategy, Component, Input, Optional } from '@angular/core';
import { AbstractInputComponent } from '../abstract-input/abstract-input.component';
import { NgControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ValidationMessagesService } from '../../../../core/services/validation-messages.service';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgClass } from '@angular/common';

@Component({
    selector: 'itd-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        ReactiveFormsModule,
        FormsModule,
        NgxIntlTelInputModule,
    ],
})
export class CheckboxComponent extends AbstractInputComponent<boolean> {
	@Input() fullWidth: boolean = false;

	constructor(@Optional() control: NgControl, validationMessages: ValidationMessagesService) {
		super(control, validationMessages);
	}

	public onModelChange(value: boolean): void {
		this.writeValue(value);
	}
}
