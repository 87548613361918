import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IEnvironment } from 'src/environments/environment.interface';

type THttpOptions = {
	headers?:
		| HttpHeaders
		| {
				[header: string]: string | string[];
		  };
	observe?: 'body';
	// tslint:disable-next-line: no-any
	params?: any;
	reportProgress?: boolean;
	// tslint:disable-next-line: no-any
	responseType?: any; // 'blob' | 'json'
	withCredentials?: boolean;
	'Content-Type'?: string;
};

@Injectable({
	providedIn: 'root',
})
export class ApiClientService {
	constructor(@Inject('ENV') private env: IEnvironment, public httpClient: HttpClient) {}

	public get<T>(endPoint: string, options?: THttpOptions): Observable<T> {
		return this.httpClient.get<T>(this.env.apiUrl + endPoint, options);
	}

	public uploadMedia<T>(endPoint: string, files: File[]): Observable<T> {
		const formData = new FormData();
		const isSingle = files.length === 1;

		if (isSingle) {
			formData.append('file', files[0]);
			return this.httpClient.post<T>(this.env.apiUrl + endPoint, formData);
		} else {
			files.forEach(file => {
				formData.append(`files`, file);
			});
			return this.httpClient.post<T>(this.env.apiUrl + endPoint + '/multiple', formData);
		}
	}

	// TODO: Use this method when multiple files are sent. Cleaner and allows us to call a different method in state that properly uses the parser.
	// The current version above throws console error because files are improperly added to media state
	// public uploadMultipleMedia<T>(endPoint: string, files: File[]): Observable<T> {
	// 	const formData = new FormData();

	// 	files.forEach(file => {
	// 		formData.append(`files`, file);
	// 	});

	// 	return this.httpClient.post<T>(this.env.apiUrl + endPoint + '/multiple', formData);
	// }

	public delete<T>(endPoint: string, options?: THttpOptions): Observable<T> {
		return this.httpClient.delete<T>(this.env.apiUrl + endPoint, options);
	}

	public post<T>(endPoint: string, body?: any, options?: THttpOptions): Observable<T> {
		if (typeof body !== 'string') {
			body = JSON.stringify(body);
		}

		return this.httpClient.post<T>(this.env.apiUrl + endPoint, body, options);
	}

	public patch<T>(endPoint: string, body?: any, options?: THttpOptions): Observable<T> {
		if (typeof body !== 'string') {
			body = JSON.stringify(body);
		}

		return this.httpClient.patch<T>(this.env.apiUrl + endPoint, body, options);
	}

	public put<T>(endPoint: string, body?: any, options?: THttpOptions): Observable<T> {
		return this.httpClient.put<T>(this.env.apiUrl + endPoint, body, options);
	}
}
