import {
	Component,
	Input,
	ContentChildren,
	QueryList,
	ContentChild,
	ViewChild,
	AfterContentInit,
	IterableDiffer,
	IterableDiffers,
	IterableChangeRecord,
	OnDestroy,
	ElementRef,
	OnInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	TemplateRef,
	EmbeddedViewRef,
} from '@angular/core';
import { ColumnDefDirective } from '../../directives/column-def.directive';
import { RowOutletDirective } from '../../directives/row-outlet.directive';
import { RowDefDirective } from '../../directives/row-def.directive';
import {
	CellOutletDirective,
	RowBeforeOutletDirective,
	RowAfterOutletDirective,
} from '../table-row/table-row.component';
import { HeaderRowOutletDirective } from '../../directives/header-row-outlet.directive';
import { HeaderRowDefDirective } from '../../directives/header-row-def.directive';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TableEmptyComponent, TEmptyRowElement } from '../table-empty/table-empty.component';
import { ICellData } from '../../directives/cell-def.directive';
import { CustomDataSource } from 'src/app/core/data-source/data-source';
import { RowBeforeDefDirective } from '../../directives/row-before-def.directive';
import { Store } from '@ngxs/store';
import { ITableSnapshot } from '../../models/table-snapshot.interface';
import { SetTableSnapshot } from 'src/app/core/layout/layout.actions';
import { fadeAnimation } from 'src/app/core/animations/fade.animation';
import { blockInitialRenderAnimation } from 'src/app/core/animations/block-initial-render.animation';
import { LayoutState } from 'src/app/core/layout/layout.state';
import { RowAfterDefDirective } from '../../directives/row-after-def.directive';
import { TableLoadingStateComponent } from '../table-loading-state/table-loading-state.component';
import { NgStyle, NgClass, NgIf } from '@angular/common';

export interface IRenderRow<T> {
	data: T;
	dataIndex: number;
	row: RowDefDirective<T>;
}

@Component({
    selector: 'itd-table',
    templateUrl: './table.component.html',
    host: {
        class: 'table',
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeAnimation, blockInitialRenderAnimation],
    standalone: true,
    imports: [
        HeaderRowOutletDirective,
        NgStyle,
        NgClass,
        RowOutletDirective,
        NgIf,
        TableLoadingStateComponent,
        TableEmptyComponent,
    ],
})
export class TableComponent<T> implements AfterContentInit, OnInit, OnDestroy {
	private _data: T[];
	private _renderRows: IRenderRow<T>[];

	private _dataDiffer: IterableDiffer<IRenderRow<T>>;
	private _cachedRenderRowsMap = new Map<T, IRenderRow<T>>();

	private _fromIndex: number = 0;
	private _toIndex: number = 0;

	private _headerViewRef: EmbeddedViewRef<{}>;

	public _virtualScrollHeight: number;
	public _virtualScrollTopIndex: number;

	private destroy$: Subject<boolean> = new Subject<boolean>();

	@ViewChild(HeaderRowOutletDirective, { static: true })
	private _headerRowOutlet: HeaderRowOutletDirective;
	@ViewChild(RowOutletDirective, { static: true })
	private _rowOutlet: RowOutletDirective;
	@ViewChild('emptyCell', { static: true }) private _emptyCell: TemplateRef<ICellData<T>>;
	@ViewChild('tableContentInner', { static: true })
	private _tableContentInner: ElementRef<HTMLElement>;
	@ContentChild(TableEmptyComponent, { static: false })
	public emptyComponent: TableEmptyComponent;

	@ContentChildren(ColumnDefDirective, { descendants: true })
	private _contentColumnDefs: QueryList<ColumnDefDirective<T>>;

	@ContentChild(RowBeforeDefDirective)
	private _contentBeforeDef: RowBeforeDefDirective<T>;

	@ContentChild(RowAfterDefDirective)
	private _contentAfterDef: RowAfterDefDirective<T>;

	@ContentChild(RowDefDirective) private _contentRowDef: RowDefDirective<T>;

	@ContentChild(HeaderRowDefDirective)
	private _contentHeaderRowDef: HeaderRowDefDirective<T>;

	public isLoading: boolean = true;
	public isEmpty: boolean = false;
	public isSearching: boolean = false;
	public isFiltering: boolean = false;

	@Input()
	public dataSource: CustomDataSource<T>;

	@Input() public tableName: string;

	@Input() public showHeaderWhenEmpty: boolean = false;
	@Input() public additionalColumnDefs: QueryList<ColumnDefDirective<T>>;
	@Input() public virtualScroll: boolean = true;
	@Input() public stickyHeader: boolean = true;
	@Input() public columnHeight: number = 68;
	@Input() public emptyColumnHeight: number = 70;
	@Input() public emptyRowsCount: number = 3;
	@Input() public virtualScrollOffset: number = 4;
	@Input() public emptyText: string = 'Brak danych do wyświetlenia';
	@Input() public emptyRowElements: TEmptyRowElement[] = [
		{ name: 'square' },
		{ name: 'square', randomWidth: true, grow: true },
	];

	constructor(
		protected readonly _differs: IterableDiffers,
		private _elementRef: ElementRef<HTMLElement>,
		private _cd: ChangeDetectorRef,
		private store: Store
	) {}

	public ngOnInit(): void {
		this._dataDiffer = this._differs.find([]).create((_i: number, dataRow: IRenderRow<T>) => {
			return dataRow;
		});

		fromEvent(window, 'scroll')
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				if (this._headerViewRef) {
					this._headerViewRef.detectChanges();
				}

				if (this.virtualScroll && this.parseVirtualScroll()) {
					this.renderAllRows();
				}
			});
	}

	public ngAfterContentInit(): void {
		let tableSettingsSnapshot: ITableSnapshot;

		if (this.tableName) {
			tableSettingsSnapshot = this.store.selectSnapshot(LayoutState.tableSnapshot(this.tableName));
		}

		if (!this.dataSource) {
			this.isLoading = false;
			this.isEmpty = true;
		} else {
			if (tableSettingsSnapshot) {
				const dataSourceSettings = tableSettingsSnapshot.dataSourceSettings;

				if (dataSourceSettings.sortType) {
					this.dataSource.sort(dataSourceSettings.sortType, dataSourceSettings.sortDirection);
				}

				if (dataSourceSettings.searchQuery) {
					this.dataSource.search(dataSourceSettings.searchQuery);
				}
			}

			this.dataSource
				.connect()
				.pipe(takeUntil(this.destroy$))
				.subscribe(d => {
					if (this.dataSource.currentSearchQuery) {
						this.isSearching = true;
					} else {
						this.isSearching = false;
					}

					this.isFiltering = this.dataSource.isFiltering();

					this._data = d;

					if (!d) {
						this.isLoading = true;
					} else {
						this.isLoading = false;
						this.isEmpty = d.length === 0;
					}

					if (
						(!(this.isEmpty && !this.showHeaderWhenEmpty) && !this.isLoading) ||
						this.isSearching ||
						this.isFiltering
					) {
						this.renderHeaderRow();
					} else {
						this._headerRowOutlet.viewContainer.clear();
						this._headerViewRef = null;
					}

					if (this.virtualScroll) {
						this.parseVirtualScroll();
					}

					this.renderAllRows();

					this._cd.detectChanges();
				});
		}
	}

	public ngOnDestroy(): void {
		this._rowOutlet.viewContainer.clear();
		this._headerRowOutlet.viewContainer.clear();

		if (this.tableName) {
			this.store.dispatch(
				new SetTableSnapshot({
					name: this.tableName,
					data: {
						dataSourceSettings: this.dataSource?.getDataSourceSettings(),
					},
				})
			);
		}

		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	private renderAllRows(): void {
		this._renderRows = this.getAllRenderRows();
		const changes = this._dataDiffer.diff(this._renderRows);

		if (!changes) {
			return;
		}

		const viewContainer = this._rowOutlet.viewContainer;

		changes.forEachOperation(
			(
				record: IterableChangeRecord<IRenderRow<T>>,
				prevIndex: number | null,
				currentIndex: number | null
			) => {
				if (record.previousIndex === null) {
					this.renderRow(record.item, currentIndex!);
				} else if (currentIndex === null) {
					viewContainer.remove(prevIndex!);
				} else {
					const view = viewContainer.get(prevIndex!);
					viewContainer.move(view!, currentIndex);
				}
			}
		);

		/*changes.forEachIdentityChange((record: IterableChangeRecord<IRenderRow<T>>) => {
            // tslint:disable-next-line: no-any
            const rowView = viewContainer.get(record.currentIndex!) as any;
            rowView.context.$implicit = record.item.data;
        });*/

		if (this._tableContentInner) {
			this._tableContentInner.nativeElement.style.top = this._virtualScrollTopIndex + 'px';
		}
	}

	private getAllRenderRows(): IRenderRow<T>[] {
		if (!this._data) {
			return;
		}

		const rows: IRenderRow<T>[] = [];

		const prevCachedRenderRows = this._cachedRenderRowsMap;
		this._cachedRenderRowsMap = new Map();

		const toIndex = Math.min(
			this._toIndex > 0 ? this._toIndex : this._data.length,
			this._data.length
		);

		for (let i = this._fromIndex; i < toIndex; i++) {
			const data = this._data[i];
			const row = prevCachedRenderRows.get(data) || {
				data,
				dataIndex: i,
				row: this._contentRowDef,
			};

			if (!this._cachedRenderRowsMap.has(data)) {
				this._cachedRenderRowsMap.set(data, row);
			}

			rows.push(row);
		}

		return rows;
	}

	public renderRow(row: IRenderRow<T>, index: number): void {
		const outlet = this._rowOutlet;

		const columns = [...this._contentColumnDefs.toArray()];

		if (this.additionalColumnDefs) {
			columns.push(...this.additionalColumnDefs.toArray());
		}

		const context = {
			$implicit: row.data,
			index: row.dataIndex + 1,
			arrayIndex: row.dataIndex,
		};

		const rowRef = outlet.viewContainer.createEmbeddedView(
			this._contentRowDef.template,
			context,
			index
		);

		if (this._contentBeforeDef && RowBeforeOutletDirective.latestCellOutlet) {
			RowBeforeOutletDirective.latestCellOutlet.viewContainer.createEmbeddedView(
				this._contentBeforeDef.template,
				context
			);
		}

		if (this._contentAfterDef && RowAfterOutletDirective.latestCellOutlet) {
			RowAfterOutletDirective.latestCellOutlet.viewContainer.createEmbeddedView(
				this._contentAfterDef.template,
				context
			);
		}

		for (const column of columns) {
			const template = column.cellDef ? column.cellDef.template : this._emptyCell;
			const ref = CellOutletDirective.latestCellOutlet.viewContainer.createEmbeddedView(
				template,
				context
			);

			column.prepareCell(ref.rootNodes[0]);
		}

		rowRef.detectChanges();
	}

	public clear(): void {
		this.dataSource.search('');
	}

	public clearFilters(): void {
		this.dataSource.clearFilters();
	}

	private renderHeaderRow(): void {
		const columns = [...this._contentColumnDefs.toArray()];

		if (this.additionalColumnDefs) {
			columns.push(...this.additionalColumnDefs.toArray());
		}

		if (this._headerViewRef) {
			return;
		}

		const outlet = this._headerRowOutlet;
		this._headerViewRef = outlet.viewContainer.createEmbeddedView(
			this._contentHeaderRowDef.template,
			{},
			0
		);

		if (this.stickyHeader && this._headerViewRef.rootNodes[0]) {
			this._headerViewRef.rootNodes[0].classList.add('isSticky');
		}

		for (const column of columns) {
			const template = column.headerCellDef ? column.headerCellDef.template : this._emptyCell;
			const ref = CellOutletDirective.latestCellOutlet.viewContainer.createEmbeddedView(template);

			column.prepareCell(ref.rootNodes[0], true);
		}
	}

	private parseVirtualScroll(): boolean {
		const length = this._data ? this._data.length : 0;
		const maxVisibleElement =
			Math.floor(window.innerHeight / this.columnHeight) + this.virtualScrollOffset * 2;
		const element = this._elementRef.nativeElement;
		const rect = element.getBoundingClientRect();

		const fromIndex = Math.max(
			0,
			Math.floor((rect.top * -1) / this.columnHeight - this.virtualScrollOffset)
		);
		const toIndex = Math.min(fromIndex + maxVisibleElement, length);

		this._virtualScrollHeight = length * this.columnHeight;
		this._virtualScrollTopIndex = fromIndex * this.columnHeight;

		if (fromIndex === this._fromIndex && toIndex === this._toIndex) {
			return false;
		}

		this._fromIndex = fromIndex;
		this._toIndex = toIndex;

		return true;
	}
}
