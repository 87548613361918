import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { AbstractApiState } from '../abstract/abstract-api-state';
import { TaskAssignmentService } from './task-assignment.service';
import {
	ClearTaskAssignments,
	CreateTaskAssignment,
	DeleteTaskAssignment,
	LoadAllTaskAssignments,
	LoadAllTaskAssignmentsIfEmpty,
	LoadMyTaskAssignments,
	LoadTaskAssignments,
	UpdateTaskAssignment,
} from './task-assignment.action';
import {
	ITaskAssignment,
	ITaskAssignmentDTO,
	ITaskAssignmentEdit,
	ITaskAssignmentEditDTO,
} from './task-assignment.interface';
import { tap } from 'rxjs';

export interface TaskAssignmentStateModel {
	items: ITaskAssignment[];
	myAssignments?: ITaskAssignment[];
	taskAssignments?: ITaskAssignment | ITaskAssignment[];
}

@State<TaskAssignmentStateModel>({
	name: 'taskAssignment',
	defaults: {
		items: null,
		myAssignments: null,
		taskAssignments: null,
	},
})
@Injectable()
export class TaskAssignmentState extends AbstractApiState<
	ITaskAssignment,
	ITaskAssignmentDTO,
	ITaskAssignmentEdit,
	ITaskAssignmentEditDTO
> {
	constructor(protected service: TaskAssignmentService, store: Store) {
		super(service, store);
	}

	@Selector()
	public static getState(state: TaskAssignmentStateModel) {
		return state;
	}

	@Selector()
	public static items(state: TaskAssignmentStateModel): ITaskAssignment[] {
		return state.items;
	}

	@Selector()
	public static myAssignments(state: TaskAssignmentStateModel): ITaskAssignment[] {
		return state.myAssignments;
	}

	@Action(LoadAllTaskAssignments)
	public loadAll(ctx: StateContext<TaskAssignmentStateModel>) {
		return super.loadAll(ctx);
	}

	@Action(LoadTaskAssignments)
	public loadOneTaskAssignment(
		ctx: StateContext<TaskAssignmentStateModel>,
		data: LoadTaskAssignments
	) {
		const { patchState } = ctx;
		return this.service.getTaskAssignments(data.payload.id).pipe(
			tap(result => {
				patchState({
					items: result,
					taskAssignments: result,
				});
			})
		);
	}

	@Action(LoadAllTaskAssignmentsIfEmpty)
	public loadAllIfEmpty(ctx: StateContext<TaskAssignmentStateModel>) {
		return super.loadAllIfEmpty(ctx);
	}

	@Action(LoadMyTaskAssignments)
	public loadMyAssignments({ patchState }: StateContext<TaskAssignmentStateModel>) {
		return this.service.getMyAssignments().pipe(
			tap(result => {
				patchState({
					items: result,
					myAssignments: result,
				});
			})
		);
	}

	@Action(CreateTaskAssignment)
	public create(ctx: StateContext<TaskAssignmentStateModel>, data: CreateTaskAssignment) {
		return super.create(ctx, data);
	}

	@Action(UpdateTaskAssignment)
	public update(ctx: StateContext<TaskAssignmentStateModel>, data: UpdateTaskAssignment) {
		return super.update(ctx, data);
	}

	@Action(DeleteTaskAssignment)
	public delete(ctx: StateContext<TaskAssignmentStateModel>, data: DeleteTaskAssignment) {
		return super.delete(ctx, data);
	}

	@Action(ClearTaskAssignments)
	public clearMyAssignments({ patchState }: StateContext<TaskAssignmentStateModel>) {
		return patchState({
			items: null,
			myAssignments: null,
		});
	}
}
