import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ApiClientService } from './api/api.service';

export class IntrodusTranslateLoader implements TranslateLoader {
	constructor(private api: ApiClientService) {}

	public getTranslation(lang: string): Observable<string[]> {
		return this.api.get(`api/locale/${lang}`);
	}
}
