import { IFilterAnalytics } from '../analytics/analytics.interface';

export class LoadPersonalEIS {
	public static readonly type = '[EIS] Load Personal';
}

export class LoadEIS {
	public static readonly type = '[EIS] Load Organization or Department';
	constructor(public payload: IFilterAnalytics) {}
}
