import * as dayjs from 'dayjs';
import { ITaskAssignment } from '../task-assignment/task-assignment.interface';
import { ITask } from '../task/task.interface';

// Get date x days ago
// If 'allowEarlierThanToday', it can return a date in the past, otherwise defaults to 'today'
export const getDateXDaysAgo = (numOfDays: number, date: Date, allowEarlierThanToday: boolean) => {
	if (typeof date === 'string') {
		date = new Date(date);
	}

	let daysAgo = new Date(date.getTime());
	daysAgo.setDate(date.getDate() - numOfDays);

	if (!allowEarlierThanToday) {
		daysAgo = dateNoEarlierThanToday(daysAgo);
	}
	return dayjs(daysAgo);
};

export const calculateDueDate = (thing: ITask | ITaskAssignment, date?: Date) => {
	if (!date) {
		return getDateXDaysAgo(thing.days_prior, new Date(), false);
	}

	return getDateXDaysAgo(thing.days_prior, new Date(date), false);
};

const dateNoEarlierThanToday = (date: Date): Date => {
	const now = dayjs();
	const inputDate = dayjs(date);

	if (inputDate.isAfter(now)) {
		return inputDate.toDate();
	} else {
		return now.toDate();
	}
};

export const addDays = (date: Date, days: number): Date => {
	var newDate = new Date(date.valueOf());
	newDate.setDate(newDate.getDate() + days);
	return newDate;
};
