import { Component, Inject, Input, OnInit } from '@angular/core';
import { IEnvironment } from 'src/environments/environment.interface';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';

@Component({
    selector: 'itd-pdf-reader',
    templateUrl: './pdf-reader.component.html',
    styleUrls: ['./pdf-reader.component.scss'],
    standalone: true,
    imports: [PdfJsViewerModule],
})
export class PdfReaderComponent implements OnInit {
	private _src: string;
	public isOpen: boolean = false;

	@Input() public set src(value: string) {
		this._src = value;
	}

	public get src(): string {
		return [this.env.mediaUrl, this._src].join('/');
	}

	constructor(@Inject('ENV') public env: IEnvironment) {}

	ngOnInit(): void {}
}
