import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { ICellData } from './cell-def.directive';

@Directive({
    selector: '[itdRowBeforeDef]',
    standalone: true
})
export class RowBeforeDefDirective<T> {
	constructor(
		public template: TemplateRef<ICellData<T>>,
		public viewContainer: ViewContainerRef
	) {}
}
