<div
	[ngClass]="{
		isFocus: isFocus,
		isError: invalid || controlInvalid
	}"
	[ngStyle]="{
		'maxWidth.px': maxWidth,
		'minWidth.px': minWidth
	}"
	class="container style-{{ styleType }}"
>
	<label class="label" *ngIf="label">
		{{ label | translate }} <span *ngIf="isRequired">*</span>
	</label>

	<div
		[ngClass]="{
			hasBorder: hasBorder
		}"
		class="content"
	>
		<ngx-intl-tel-input
			#input
			class="input size-{{ sizeType }}"
			[ngClass]="{
				hasShadow: hasShadow,
				'has-input-border': inputBorder === true
			}"
			[preferredCountries]="preferredCountriesList"
			[searchCountryFlag]="true"
			[separateDialCode]="false"
			[searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
			[selectFirstCountry]="false"
			[selectedCountryISO]="preferredCountriesList[0]"
			[maxLength]="16"
			[name]="name"
			(blur)="setFocus(false)"
			(focus)="setFocus(true)"
			(ngModelChange)="onModelChange($event)"
			[(ngModel)]="value"
		></ngx-intl-tel-input>

		<p *ngIf="controlInvalid && invalidMessage" class="validation">
			{{ invalidMessage }}
		</p>
	</div>
</div>
