import { ISurveyEdit } from './surveys.interface';

export class LoadAllSurveys {
	public static readonly type = '[Surveys] Load All';
}

export class LoadSurvey {
	public static readonly type = '[Surveys] Load';
	constructor(public payload: { id: string }) {}
}

export class LoadSurveyAnswers {
	public static readonly type = '[Surveys] Load Answers';
	constructor(public payload: { id: string }) {}
}

export class DeleteSurveyAnswers {
	public static readonly type = '[Surveys] Delete Answer';
	constructor(public payload: { id: string }) {}
}

export class LoadAllSurveyAnswers {
	public static readonly type = '[Surveys] Load All Answers';
	constructor(public payload: { id: string }) {}
}

export class LoadSurveyStats {
	public static readonly type = '[Surveys] Load Stats';
	constructor(public payload: { id: string; department_id?: string }) {}
}

export class ResetActiveSurvey {
	public static readonly type = '[Surveys] Reset';
	constructor() {}
}

export class LoadAllSurveysIfEmpty {
	public static readonly type = '[Surveys] Load All If Empty';
}

// Create and edit shouldnt use the same types, as create shouldnt expect an ID (which is built into the type)
export class CreateSurvey {
	public static readonly type = '[Surveys] Create';
	constructor(public payload: { data: ISurveyEdit }) {}
}

export class UpdateSurvey {
	public static readonly type = '[Surveys] Update';
	constructor(public payload: { id: string; data: ISurveyEdit }) {}
}

export class DeleteSurvey {
	public static readonly type = '[Surveys] Delete';
	constructor(public payload: { id: string }) {}
}

export class DuplicateSurvey {
	public static readonly type = '[Surveys] Duplicate';
	constructor(public payload: { id: string }) {}
}
