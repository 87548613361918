import {
	Component,
	ChangeDetectionStrategy,
	ContentChild,
	TemplateRef,
	OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CustomDataSource } from 'src/app/core/data-source/data-source';
import { ITimeline } from 'src/app/core/timelines/timelines.interface';
import { TimelinesService } from 'src/app/core/timelines/timelines.service';
import { SidenavService } from '../matsidebar.service';
import { TranslateModule } from '@ngx-translate/core';
import { TableRowComponent } from '../../table/components/table-row/table-row.component';
import { RowDefDirective } from '../../table/directives/row-def.directive';
import { TableHeaderRowComponent } from '../../table/components/table-header-row/table-header-row.component';
import { HeaderRowDefDirective } from '../../table/directives/header-row-def.directive';
import { MenuItemComponent } from '../../../shared/components/menu/menu-item/menu-item.component';
import { MenuComponent } from '../../../shared/components/menu/menu.component';
import { CellDefDirective } from '../../table/directives/cell-def.directive';
import { HeaderCellDefDirective } from '../../table/directives/header-cell-def.directive';
import { ColumnDefDirective } from '../../table/directives/column-def.directive';
import { TableEmptyComponent } from '../../table/components/table-empty/table-empty.component';
import { TableComponent } from '../../table/components/table/table.component';
import { MatSidebarComponent } from '../sidebar/matsidebar.component';

@Component({
    selector: 'itd-module-used-in-timelines',
    templateUrl: './module-used-in-timelines.component.html',
    styleUrls: ['./module-used-in-timelines.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatSidebarComponent,
        TableComponent,
        TableEmptyComponent,
        ColumnDefDirective,
        HeaderCellDefDirective,
        CellDefDirective,
        MenuComponent,
        MenuItemComponent,
        HeaderRowDefDirective,
        TableHeaderRowComponent,
        RowDefDirective,
        TableRowComponent,
        TranslateModule,
    ],
})
export class ModuleUsedInTimelinesComponent implements OnInit {
	@ContentChild('header') header: TemplateRef<any>;
	@ContentChild('content') content: TemplateRef<any>;
	@ContentChild('footer') footer: TemplateRef<any>;

	public timelines$: Observable<ITimeline[]>;
	public timelinesDataSource: CustomDataSource<ITimeline> = new CustomDataSource();

	constructor(
		private sidenav: SidenavService,
		private timelinesService: TimelinesService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.timelines$ = this.timelinesService.getTimelinesByModuleId(this.sidenav.moduleId);
		this.timelines$.subscribe((data: ITimeline[]) => {
			this.prepareTable(data);
		});
	}

	private prepareTable(data: ITimeline[]) {
		this.timelinesDataSource.next(data);
	}

	public close() {
		this.sidenav.close();
	}

	public goToTimeline(timeline: ITimeline) {
		this.sidenav.close();
		this.router.navigate([`admin/timelines/${timeline.id}/edit`]);
	}
}
