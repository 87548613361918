import { ChangeDetectorRef, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { Component } from '@angular/core';
import { ControlValueAccessor, NgControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { IUser } from 'src/app/core/users/users.interface';
import { UsersState } from 'src/app/core/users/users.state';
import { SelectUserModalComponent } from 'src/app/shared/modals/select-user-modal/select-user-modal.component';
import { AbstractInputComponent } from '../abstract-input/abstract-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../../../icon/components/icon/icon.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgClass, NgStyle, NgIf, AsyncPipe } from '@angular/common';

@Component({
	selector: 'itd-input-user',
	templateUrl: './input-user.component.html',
	styleUrls: ['./input-user.component.scss'],
	standalone: true,
	imports: [
		NgClass,
		ReactiveFormsModule,
		NgStyle,
		FormsModule,
		NgxIntlTelInputModule,
		IconComponent,
		NgIf,
		AsyncPipe,
		TranslateModule,
	],
})
export class InputUserComponent extends AbstractInputComponent<string>
	implements ControlValueAccessor, OnInit {
	@Select(UsersState.items) public users$: Observable<IUser[]>;

	@Input() public width: number;
	// TODO Can we find a better way?
	// 'User' is only used to filter out the user in the list.  (ie "paul" cant select "paul" as manager (filtering out 'self'))
	@Input() public user: IUser;
	@Input() public userList$: Observable<IUser[]>; // List of filtered users
	@Input({ required: true }) public label: string;
	@Input({ required: true }) public placeholder: string;
	@Input({ required: true }) public modalTitle: string;
	@Output() public userSelected: EventEmitter<IUser> = new EventEmitter<IUser>();

	public currentlySelectedUserName: string = '';

	constructor(
		@Optional() control: NgControl,
		validationMessages: ValidationMessagesService,
		private modals: NgbModal,
		private cd: ChangeDetectorRef
	) {
		super(control, validationMessages);
	}

	ngOnInit() {
		if (this.value) {
			this.users$.subscribe(users => {
				this.currentlySelectedUserName = users?.find(
					user => user.user_id === this.value
				)?.full_name;
			});
		}
	}

	public removeSelected(e: Event) {
		e.preventDefault();
		e.stopPropagation();

		this.writeValue(null);
		this.userSelected.emit(null);
		this.currentlySelectedUserName = '';
		this.cd.detectChanges();
	}

	public openSelectUserModal() {
		const component = this.modals.open(SelectUserModalComponent);
		const componentInstance = component.componentInstance as SelectUserModalComponent;

		componentInstance.initialUserId = this.value;
		componentInstance.omitUserId = this.user?.user_id;
		componentInstance.title = this.modalTitle;
		componentInstance.userList$ = this.userList$;

		component.closed.subscribe((user: IUser) => {
			if (user) {
				this.currentlySelectedUserName = user.full_name;
				this.writeValue(user.user_id);
				this.userSelected.emit(user);
			}
			this.cd.detectChanges();
		});
	}
}
