<div class="task-image">
	<div
		class="status-sec"
		*ngIf="due_date || cantdo"
		[ngStyle]="{ 'background-color': bgColor }"
	></div>
	<div class="icon" [ngStyle]="{ 'width.px': width, 'height.px': height }">
		<itd-icon [icon]="name" [type]="'task'" [fill]="color ? color : 'black'"></itd-icon>
	</div>
	<itd-user-avatar
		*ngIf="user"
		class="user"
		[user]="user"
		folder="user-images"
		[height]="10"
		[width]="10"
		[itdTooltip]="user.first_name + ' ' + user.last_name"
	></itd-user-avatar>
</div>
