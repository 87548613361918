import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { AbstractApiService } from '../abstract/abstract-api-service';
import { ApiClientService } from '../api/api.service';
import { TResults } from '../models/results.interface';
import { arrayToCommaList } from '../utils/array-string.util';
// import { IFastTrackConfiguration } from './fast-track-configuration.interface';
import {
	IFastTrackConfiguration,
	IFastTrackConfigurationDTO,
	IFastTrackConfigurationEdit,
	IFastTrackConfigurationEditDTO,
} from './fast-track-configuration.interface';

@Injectable({
	providedIn: 'root',
})
export class FastTrackConfigurationService extends AbstractApiService<
	IFastTrackConfiguration,
	IFastTrackConfigurationDTO,
	IFastTrackConfigurationEdit,
	IFastTrackConfigurationEditDTO
> {
	protected key: string = 'fast-track/configuration';

	constructor(protected api: ApiClientService, protected store: Store) {
		super(api, store);
	}

	protected loadOne(id: string): Observable<IFastTrackConfiguration> {
		return this.api.get<TResults<any>>(`api/${this.key}/${id}`).pipe(map(res => res.data));
	}

	public getConfig(id: IFastTrackConfiguration['org_id']): Observable<IFastTrackConfiguration> {
		return this.api.get<TResults<any>>(`api/${this.key}/${id}`).pipe(
			map(res => {
				res.data.map(item => {
					item.organization_inbox_emails = arrayToCommaList(item.organization_inbox_emails);
					return item;
				});

				return res.data;
			})
		);
	}

	protected parse(value: IFastTrackConfigurationDTO): IFastTrackConfiguration {
		const { _id, ...rest } = value;

		return {
			...rest,
			id: _id,
		};
	}

	protected parseEditDTO(value: IFastTrackConfigurationEdit): IFastTrackConfigurationEditDTO {
		// const { name, logo_url } = value;

		return {
			...value,
		};
	}
}
