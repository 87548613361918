<div class="avatar">
	<!-- TODO: Should we refactor to use <itd-image> here instead of <img> for consistency? -->
	<img
		[src]="src"
		[ngStyle]="{ 'width.px': width, 'height.px': height }"
		class="image"
		*ngIf="user?.image_key"
		alt="alt"
	/>
	<div
		class="initials"
		[ngStyle]="{ 'width.px': width, 'height.px': height }"
		*ngIf="!user?.image_key && user?.first_name"
		[ngClass]="{ inactive: !user || !user?.first_name, secondary: secondary }"
	>
		<span *ngIf="user">{{ user?.first_name | uppercase | slice: 0:1 }}</span>
	</div>
	<div
		class="initials"
		[ngStyle]="{ 'width.px': width, 'height.px': height }"
		*ngIf="!user?.image_key && !user?.first_name && user?.name"
		[ngClass]="{ inactive: !user || !user?.name, secondary: secondary }"
	>
		<span *ngIf="user">{{ user?.name | uppercase | slice: 0:1 }}</span>
	</div>
	<div
		class="unknown-avatar"
		*ngIf="!user || (!user?.image_key && !user?.first_name && !user?.name)"
	>
		<itd-icon icon="user" [height]="width - 40" [width]="width - 40"></itd-icon>
	</div>
</div>
