import { NgStyle } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CustomDataSource } from 'src/app/core/data-source/data-source';
import { InputComponent } from '../../../utility-modules/itd-form/components/input/input.component';

@Component({
	selector: 'itd-custom-data-source-search-input',
	templateUrl: './custom-data-source-search-input.component.html',
	styleUrls: ['./custom-data-source-search-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgStyle, InputComponent, TranslateModule],
})
export class CustomDataSourceInputComponent<T> implements OnDestroy {
	private dataSourceSubscription: Subscription;

	@Input() public hasBorder: boolean = false;
	@Input() public hasShadow: boolean = false;
	@Input() public placeholder?: string;
	@Input() public maxWidth: number;
	@Input() public focusAfterInit: boolean = false;

	@Input() public set dataSource(value: CustomDataSource<T>) {
		if (this.source !== value) {
			this.source = value;
			this.connect();
		}
	}
	@Output() writeChange = new EventEmitter<string>();

	public get dataSource(): CustomDataSource<T> {
		return this.source;
	}

	public value: string;
	private source: CustomDataSource<T>;

	constructor(private cd: ChangeDetectorRef) {}

	public ngOnDestroy(): void {
		if (this.dataSourceSubscription) {
			this.dataSourceSubscription.unsubscribe();
		}
	}

	public connect(): void {
		if (this.dataSourceSubscription) {
			this.dataSourceSubscription.unsubscribe();
		}

		if (!this.dataSource) {
			return;
		}

		this.dataSourceSubscription = this.dataSource.search$.subscribe(v => {
			if (v !== this.value) {
				this.value = v;
				this.source.search(this.value);
				this.cd.detectChanges();
			}
		});
	}

	public valueChange(val: any): void {
		this.value = val;

		if (!this.source) {
			return;
		}

		this.source.search(this.value);
		this.cd.detectChanges();
	}
	public emitChange(value: any) {
		this.writeChange.emit(value);
	}
}
