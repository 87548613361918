import { Routes } from '@angular/router';
import { NoAuthGuard } from './core/auth/no-auth.guard';
import { DevEnvGuard } from './core/guards/dev.guard';
import { MobileGuard } from './core/guards/mobile.guard';
import { PublicSignupOrganizationResolver } from './core/organizations/public-signup-organization.resolver';
import { LoginResetPasswordComponent } from './modules/login/pages/login-reset-password/login-reset-password.component';
import { UserSignupPageComponent } from './modules/user/pages/user-signup-page/user-signup-page.component';

export const APP_ROUTES: Routes = [
	{
		path: 'login',
		loadChildren: () => import('./modules/login/login.module'),
	},
	{
		path: 'reset-password',
		component: LoginResetPasswordComponent,
		canActivate: [NoAuthGuard],
	},
	{
		path: 'user',
		loadChildren: () => import('./modules/user/user.module'),
	},
	{
		canActivate: [MobileGuard],
		// TODO: Insert canload for admin users
		path: 'admin',
		loadChildren: () => import('./modules/admin/admin.module'),
	},
	{
		// TODO: Insert canload for super_admin users
		canActivate: [MobileGuard],
		path: 'superadmin',
		loadChildren: () => import('./modules/superadmin/superadmin.module'),
	},
	{
		path: 'timeline-assign',
		loadChildren: () => import('./modules/timeline-assign/timeline-assign.module'),
	},
	{
		path: 'fast-track',
		loadChildren: () => import('./modules/fast-track/fast-track.module'),
	},
	{
		path: 'dialogue-report',
		loadChildren: () => import('./modules/dialogue-report/dialogue-report.module'),
	},
	{
		path: 'dev',
		canLoad: [DevEnvGuard],
		loadChildren: () => import('./modules/dev/dev/dev.module'),
	},
	{
		path: 'admin-timelines',
		loadChildren: () => import('./modules/admin/modules/admin-timelines/admin-timelines.module'),
	},
	{
		path: 'public-signup/:id',
		component: UserSignupPageComponent,
		resolve: {
			id: PublicSignupOrganizationResolver,
		},
	},
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{ path: '**', redirectTo: 'login', pathMatch: 'full' },
];
