import {
	Component,
	ChangeDetectionStrategy,
	ContentChild,
	Input,
	Optional,
	TemplateRef,
	Directive,
	ChangeDetectorRef,
	ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { shallowEqual } from 'src/app/core/utils/shallow-equal';
import { AbstractInputComponent } from '../abstract-input/abstract-input.component';
import { NgTemplateOutlet } from '@angular/common';

@Directive({
	selector: '[itdInputCustomRadio]',
	standalone: true,
})
export class InputCustomRadioDirective {
	constructor(public tpl: TemplateRef<any>) {}
}

@Component({
	selector: 'itd-input-custom-radio',
	templateUrl: './input-custom-radio.component.html',
	styleUrls: ['./input-custom-radio.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgTemplateOutlet],
})
export class InputCustomRadioComponent<T = any> extends AbstractInputComponent<T> {
	@ViewChild('input', { static: true }) public input: HTMLInputElement;

	@Input() public data: T;

	@ContentChild(InputCustomRadioDirective)
	public item: InputCustomRadioDirective;
	public isActive: boolean;

	constructor(
		@Optional() public control: NgControl,
		validationMessages: ValidationMessagesService,
		private cd: ChangeDetectorRef
	) {
		super(control, validationMessages);
	}

	ngOnInit() {
		this.setFormControl();

		super.ngOnInit();
	}

	private setFormControl() {
		if (!this.control) {
		}

		this.isActive = shallowEqual(this.control.value as T, this.data);

		this.control.valueChanges.subscribe(v => {
			const isActive = shallowEqual(this.control.value as T, this.data);

			if (this.isActive !== isActive) {
				this.isActive = isActive;

				this.cd.detectChanges();
			}
		});
	}

	public click() {
		this.writeValue(this.data);
	}
}
