import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { SetColorPrimary, SetFontPrimary } from '../layout/layout.actions';
import {
	IOrganization,
	IOrganizationDTO,
	IOrganizationEdit,
	IOrganizationEditDTO,
} from './organizations.interface';
import { OrganizationService } from './organizations.service';
import {
	LoadAllOrganizations,
	LoadAllOrganizationsIfEmpty,
	GetMyOrganization,
	CreateOrganization,
	UpdateOrganization,
	DeleteOrganization,
	UpdateOrganizationMessage,
} from './organizations.actions';
import { AbstractApiState } from '../abstract/abstract-api-state';
import { IMessageTypeEdit } from '../messages/messages.interface';

export interface IOrganizationStateModel {
	items: IOrganization[];
	currentOrganization?: IOrganization;
}

@State<IOrganizationStateModel>({
	name: 'organization',
	defaults: {
		items: null,
		currentOrganization: null,
	},
})
@Injectable()
export class OrganizationState extends AbstractApiState<
	IOrganization,
	IOrganizationDTO,
	IOrganizationEdit,
	IOrganizationEditDTO
> {
	constructor(private organization: OrganizationService, store: Store) {
		super(organization, store);
	}

	@Selector()
	public static getState(state: IOrganizationStateModel) {
		return state.items;
	}

	@Selector()
	public static organization(state: IOrganizationStateModel) {
		return state.currentOrganization;
	}

	@Selector()
	public static items(state: IOrganizationStateModel) {
		return state.items;
	}

	@Action(GetMyOrganization)
	public get({ patchState, dispatch }) {
		return this.organization.getMyOrg().pipe(
			tap(organization => {
				const { primary_color, basic_font } = organization;

				patchState({ currentOrganization: organization });

				return dispatch([
					new SetColorPrimary({ color: primary_color }),
					new SetFontPrimary({ font: basic_font }),
				]);
			})
		);
	}

	@Action(LoadAllOrganizations)
	public loadAll(ctx: StateContext<IOrganizationStateModel>) {
		return super.loadAll(ctx);
	}

	@Action(LoadAllOrganizationsIfEmpty)
	public loadAllIfEmpty(ctx: StateContext<IOrganizationStateModel>) {
		return super.loadAllIfEmpty(ctx);
	}

	@Action(CreateOrganization)
	public create(ctx: StateContext<IOrganizationStateModel>, data: CreateOrganization) {
		return super.create(ctx, data);
	}

	@Action(UpdateOrganization)
	public update(ctx: StateContext<IOrganizationStateModel>, data: UpdateOrganization) {
		return super.update(ctx, data);
	}

	@Action(UpdateOrganizationMessage)
	public updateMessage(
		{ patchState, getState }: StateContext<IOrganizationStateModel>,
		data: { payload: { data: Partial<IMessageTypeEdit> } }
	) {
		const { org_id } = getState().currentOrganization;

		return this.organization.updateMessage(org_id, data.payload.data).pipe(
			tap(organization => {
				patchState({ currentOrganization: organization });
			})
		);
	}

	@Action(DeleteOrganization)
	public delete(ctx: StateContext<IOrganizationStateModel>, data: DeleteOrganization) {
		return super.delete(ctx, data).pipe(
			tap(() => {
				const _items = ctx.getState().items;

				if (!_items || !_items.length) {
					return;
				}

				const items = [..._items];
				const index = items.findIndex(item => item.org_id === data.payload.id);

				if (index === -1) {
					return;
				}

				items.splice(index, 1);

				ctx.patchState({
					items,
				});
			})
		);
	}
}
