import { IModuleEdit } from './modules.interface';

export class LoadAllModules {
	public static readonly type = '[Modules] Load All';
}

export class LoadModule {
	public static readonly type = '[Modules] Load';
	constructor(public payload: { id: string }) {}
}

export class ResetActiveModule {
	public static readonly type = '[Modules] Reset';
	constructor() {}
}

export class LoadAllModulesIfEmpty {
	public static readonly type = '[Modules] Load All If Empty';
}

export class CreateModule {
	public static readonly type = '[Modules] Create';
	constructor(public payload: { data: IModuleEdit }) {}
}

export class UpdateModule {
	public static readonly type = '[Modules] Update';
	constructor(public payload: { id: string; data: IModuleEdit }) {}
}

export class DeleteModule {
	public static readonly type = '[Modules] Delete';
	constructor(public payload: { id: string }) {}
}

export class DuplicateModule {
	public static readonly type = '[Module] Duplicate';
	constructor(public payload: { id: string }) {}
}
