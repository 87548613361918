import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { AbstractApiService } from '../abstract/abstract-api-service';
import { ApiClientService } from '../api/api.service';
import { TResults } from '../models/results.interface';
import {
	ICreateFastTrackAssignmentDTO,
	IFastTrackAssignment,
	IFastTrackAssignmentDTO,
	IFastTrackAssignmentEdit,
	IFastTrackAssignmentEditDTO,
} from './fast-track-assignment.interface';

@Injectable({
	providedIn: 'root',
})
export class FastTrackAssignmentService extends AbstractApiService<
	IFastTrackAssignment,
	IFastTrackAssignmentDTO,
	IFastTrackAssignmentEdit,
	IFastTrackAssignmentEditDTO
> {
	protected key: string = 'fast-track/assignment';

	constructor(protected api: ApiClientService, protected store: Store) {
		super(api, store);
	}

	public getFastTrackAssignment(assignmentId: string): Observable<IFastTrackAssignment> {
		return this.api
			.get<TResults<IFastTrackAssignment>>(`api/${this.key}/${assignmentId}`)
			.pipe(map(res => res.data));
	}

	public getFastTrackAssignmentsForUser(userId: string): Observable<IFastTrackAssignment[]> {
		return this.api
			.get<TResults<IFastTrackAssignment[]>>(`api/${this.key}s/user/${userId}`)
			.pipe(map(res => res.data));
	}

	public finishFastTrackAssignment(
		assignmentId: string,
		data: any
	): Observable<IFastTrackAssignment> {
		return this.api
			.put<TResults<IFastTrackAssignment>>(`api/${this.key}/${assignmentId}/finish`, data)
			.pipe(map(res => res.data));
	}

	public updateFastTrackActionPlan(
		assignmentId: string,
		data: any
	): Observable<IFastTrackAssignment> {
		return this.api
			.put<TResults<IFastTrackAssignment>>(`api/${this.key}/${assignmentId}`, data)
			.pipe(map(res => res.data));
	}

	// TODO: How do we fix a type like this so it *actually* returns what we expect?
	public createFastTrackAssignment(data: ICreateFastTrackAssignmentDTO) {
		return this.api.post<TResults<ICreateFastTrackAssignmentDTO>>(`api/${this.key}s`, data).pipe(
			map(res => {
				return res.data;
			})
		);
	}

	public deleteFastTrackAssignment(assignmentId: IFastTrackAssignment['assignment_id']) {
		return this.api.delete<TResults>(`api/${this.key}s/${assignmentId}`).pipe(
			map(res => {
				return res.data;
			})
		);
	}

	protected parse(value: IFastTrackAssignmentDTO): IFastTrackAssignment {
		const { _id, ...rest } = value;

		return {
			id: _id,
			...rest,
		};
	}

	protected parseEditDTO(value: IFastTrackAssignmentEdit): IFastTrackAssignmentEditDTO {
		const { id, ...rest } = value;

		return {
			_id: id,
			...rest,
		};
	}
}
