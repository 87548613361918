import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '../icon/icon.module';
import { NotificationService } from './services/notification.service';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
	declarations: [],
	providers: [NotificationService],
	imports: [
		CommonModule,
		IconModule,
		TranslateModule,
		ToastrModule.forRoot({
			positionClass: 'toast-bottom-right',
		}),
	],
})
export class NotificationModule {}
