import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { lastValueFrom, Observable, throwError } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { WithDestroyComponent } from 'src/app/core/abstract/abstract-with-destroy-component';
import { FormState } from 'src/app/core/models/form.interface';
import {
	TErrorMessage,
	ValidationMessagesService,
} from 'src/app/core/services/validation-messages.service';

@Component({
	template: '',
})
// tslint:disable-next-line: no-any
export abstract class AbstractFormComponent<T = any> extends WithDestroyComponent
	implements OnDestroy {
	public abstract elementRef: ElementRef<HTMLElement>;
	public abstract validationMessages: ValidationMessagesService;

	public form: FormGroup;
	public initFormValue: T = {} as T;
	public formState: FormState = FormState.Initial;
	public errorMessage$: Observable<TErrorMessage>;

	public success?: EventEmitter<void>;
	public error?: EventEmitter<HttpErrorResponse>;

	protected constructor(protected cd: ChangeDetectorRef) {
		super();
	}

	public get isEdit(): boolean {
		return !!this.initFormValue;
	}

	public get formValue(): T {
		return this.form?.value as T;
	}

	public get loading(): boolean {
		return this.formState === FormState.Loading;
	}

	public afterInit(): void {
		this.errorMessage$ = this.form.statusChanges.pipe(
			map(() => {
				return this.validationMessages.parseMessage(this.form.errors);
			})
		);

		this._setInitialFormValue();

		this.form.markAsPristine();
		this.cd.detectChanges();
	}

	private _setInitialFormValue() {
		const initFormValue = this.setInitialFormValue();

		if (this.initFormValue) {
			this.form.patchValue(initFormValue);
		}
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	public async onSubmit(): Promise<void> {
		if (this.formState === FormState.Loading || this.formState === FormState.BeforeLoading) {
			return;
		}

		this.formState = FormState.BeforeLoading;

		this.validateFields(this.form);

		if (this.afterValidateFields) {
			this.afterValidateFields(this.form.valid);
		}

		if (!this.form.valid) {
			this.formState = FormState.Error;
			return;
		}

		if (this.afterValidation) {
			this.afterValidation();
		}

		if (this.onBeforeSubmit) {
			const conf = await lastValueFrom(this.onBeforeSubmit().pipe(take(1)));

			if (!conf) {
				this.formState = FormState.Initial;

				this.cd.markForCheck();
				return;
			}
		}

		if (this.onBeforeSubmitAsync) {
			const conf = await this.onBeforeSubmitAsync();

			if (!conf) {
				this.formState = FormState.Initial;

				this.cd.markForCheck();
				return;
			}
		}

		this.formState = FormState.Loading;

		this.cd.markForCheck();

		this.onSubmitAction()
			.pipe(take(1))
			.subscribe(
				a => {
					this.formState = FormState.Success;

					this.initFormValue = this.formValue;
					this.form.markAsPristine();

					if (this.onSubmitSuccess) {
						this.onSubmitSuccess(a);
					}

					if (this.success) {
						this.success.emit();
					}

					this.cd.markForCheck();
				},
				(e: HttpErrorResponse) => {
					this.formState = FormState.Error;

					if (this.error) {
						this.error.emit(e);
					}

					if (e.error?.errors) {
						this.parseServerSideErrors(e.error.errors);

						if (this.afterValidateFields) {
							this.afterValidateFields(this.form.valid);
						}
					} else if (e.error?.message) {
						this.form.setErrors({ ssr: e.error.message });
					} else {
						this.form.setErrors({
							ssr: 'API_ERROR',
						});
					}

					if (this.onSubmitError) {
						this.onSubmitError(e);
					}

					this.cd.markForCheck();

					return throwError(e);
				}
			);
	}

	protected validateFields(group: FormGroup): void {
		Object.keys(group.controls).forEach(field => {
			const control = group.get(field);
			if (control instanceof FormControl) {
				control.markAsTouched({ onlySelf: true });

				control.updateValueAndValidity();
			} else if (control instanceof FormGroup) {
				this.validateFields(control);
			}

			if ((control as FormGroup).controls) {
				this.validateFields(control as FormGroup);
			}
		});

		setTimeout(() => {
			const formElement = this.elementRef.nativeElement.querySelector('form');
			const firstInvalidElementGroup = formElement.querySelector('.is-error');
			if (firstInvalidElementGroup) {
				const firstInvalidElement = firstInvalidElementGroup.querySelector(
					'input, .select, textarea'
				) as HTMLElement;
				const bodyRect = document.body.getBoundingClientRect().top;
				const elementPosition = firstInvalidElement.getBoundingClientRect().top - bodyRect;
				const offsetPosition = elementPosition - 100;

				if (!document.body.classList.contains('modal-open')) {
					window.scrollTo({
						top: offsetPosition,
						behavior: 'auto',
					});
				}

				setTimeout(() => {
					firstInvalidElement.focus();
				});
			}
		});
	}

	protected parseServerSideErrors(errors: { general: string; fields: { [key: string]: string } }) {
		if (!errors) {
			return;
		}

		if (errors.general) {
			this.form.setErrors({ ssr: errors.general });
		}

		for (const key in errors.fields) {
			if (errors.fields.hasOwnProperty(key)) {
				const error = errors.fields[key];
				this.setError(this.form, key, error);
			}
		}

		setTimeout(() => {
			const formElement = this.elementRef.nativeElement.querySelector('form');
			const firstInvalidElementGroup = formElement.querySelector('.is-error');
			if (firstInvalidElementGroup) {
				const firstInvalidElement = firstInvalidElementGroup.querySelector(
					'input, .select, textarea'
				) as HTMLElement;
				const bodyRect = document.body.getBoundingClientRect().top;
				const elementPosition = firstInvalidElement.getBoundingClientRect().top - bodyRect;
				const offsetPosition = elementPosition - 100;

				if (!document.body.hasAttribute('data-scroll-lock-saved-inline-overflow-y-property')) {
					window.scrollTo({
						top: offsetPosition,
						behavior: 'auto',
					});
				}

				setTimeout(() => {
					firstInvalidElement.focus();
				});
			}
		});
	}

	protected setInitialFormValue(): T | Partial<T> {
		return this.formValue as T;
	}

	// tslint:disable-next-line: no-any
	protected abstract onSubmitAction(): Observable<any>;

	protected onBeforeSubmit?(): Observable<boolean>;

	protected onBeforeSubmitAsync?(): Promise<boolean>;

	// tslint:disable-next-line: no-any
	protected onSubmitSuccess?(result: any): void;

	protected onSubmitError?(e: HttpErrorResponse): void;

	protected afterValidateFields?(valid: boolean): void;

	protected afterValidation?(): void;

	// tslint:disable-next-line: no-any
	private setError(
		parent: AbstractControl,
		key: string,
		error: string | Array<string> | any
	): void {
		const field = parent.get(key);

		if (!field) {
			return;
		}

		if (typeof error === 'string' || typeof error === 'number') {
			field.setErrors({ ssr: error });
		} else {
			try {
				for (const keyChild in error) {
					if (error.hasOwnProperty(keyChild)) {
						const errorChild = error[keyChild];
						this.setError(field, keyChild, errorChild);
					}
				}
			} catch (error) {
				//
			}
		}
	}
}
