import { Location, NgIf, NgClass, NgTemplateOutlet } from '@angular/common';
import {
	Component,
	Input,
	OnInit,
	EventEmitter,
	Output,
	ContentChild,
	TemplateRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormState } from 'src/app/utility-modules/itd-form/components/abstract-form/abstract-form-new.component';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipDirective } from '../../directives/tooltip.directive';
import { ButtonComponent } from '../../../utility-modules/itd-form/components/button/button.component';
import { ButtonBackComponent } from '../../components/button-back/button-back.component';

@Component({
	selector: 'itd-edit-template',
	templateUrl: './edit-template.component.html',
	styleUrls: ['./edit-template.component.scss'],
	standalone: true,
	imports: [
		NgIf,
		ButtonBackComponent,
		NgClass,
		NgTemplateOutlet,
		ButtonComponent,
		TooltipDirective,
		TranslateModule,
	],
})
export class EditTemplateComponent implements OnInit {
	@ContentChild('left') left: TemplateRef<string>;
	@ContentChild('right') right: TemplateRef<any>;
	@ContentChild('action') action: TemplateRef<any>;

	@Input() public backLink: string[];
	@Input() public backLinkData: any = null;
	@Input() public title: string;
	@Input() public formState?: FormState;
	@Input() public disabled: boolean;
	@Output() public onSave = new EventEmitter();
	@Output() public onDelete = new EventEmitter();
	@Output() public onShowPreview = new EventEmitter();
	@Input() public buttonText: string = 'SAVE';
	@Input() public form: any;
	@Input() public timelineName: string;
	@Input() public showBackButton = true;
	@Input() public showDeleteButton = false;

	typeOfPreview: string = '';
	constructor(private location: Location, public modals: NgbModal, private router: Router) {}

	ngOnInit(): void {}

	public clickSave() {
		if (!this.onSave) {
			return;
		}
		this.onSave.emit();
	}

	public clickDelete() {
		this.onDelete.emit();
	}

	public goBackClick() {
		if (this.backLink) {
			this.router.navigate(this.backLink, {
				state: { form: this.backLinkData },
			});
		} else {
			this.location.back();
		}
	}

	public showPreviewButtons() {
		if (this.router.url.includes('/messages/edit/') || this.router.url.includes('/messages/new')) {
			return true;
		} else {
			return false;
		}
	}

	public showPreview(e, type) {
		e.preventDefault();
		this.onShowPreview.emit(type);
	}
}
