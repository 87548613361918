<div class="container" [style.max-width.px]="maxWidth">
	<div class="header" *ngIf="header">
		<ng-template [ngTemplateOutlet]="header"></ng-template>
	</div>

	<div class="body">
		<div class="body__wrapper">
			<ng-template [ngTemplateOutlet]="body"></ng-template>
		</div>
	</div>
</div>
