import { Component, Inject, Input, OnInit } from '@angular/core';
import { MediaService } from 'src/app/core/media/media.service';
import { IUser } from 'src/app/core/users/users.interface';
import { daysDiffernce } from 'src/app/core/utils/days-difference.util';
import { IEnvironment } from 'src/environments/environment.interface';
import { TooltipDirective } from '../../directives/tooltip.directive';
import { UserAvatarComponent } from '../user-avatar/user-avatar.component';
import { IconComponent } from '../../../utility-modules/icon/components/icon/icon.component';
import { NgIf, NgStyle } from '@angular/common';

@Component({
    selector: 'itd-task-image',
    templateUrl: './task-image.component.html',
    styleUrls: ['./task-image.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgStyle,
        IconComponent,
        UserAvatarComponent,
        TooltipDirective,
    ],
})
export class TaskImageComponent implements OnInit {
	@Input() public user: IUser;
	@Input() public name: string;
	@Input() public color: string;
	@Input() public height: number;
	@Input() public width: number;
	@Input() bgColor: string = '';
	@Input() due_date: string;
	@Input() cantdo: boolean = false;

	constructor(@Inject('ENV') public env: IEnvironment, protected mediaService: MediaService) {}

	ngOnInit(): void {
		this.height = this.height | 34;
		this.width = this.width | 34;
		if (this.due_date) {
			this.changeIconColor();
		}
		if (this.cantdo) {
			this.bgColor = '#de350b';
		}
	}

	public get path(): string {
		return `/assets/icons/tasks/${this.name}.png`;
	}

	changeIconColor(): void {
		const diff = daysDiffernce(this.due_date);
		if (diff >= 9) {
			this.bgColor = 'green';
		} else if (diff < 9 && diff >= 6) {
			this.bgColor = 'yellow';
		} else if (diff < 6 && diff >= 3) {
			this.bgColor = 'orange';
		} else if (diff < 3) {
			this.bgColor = '#de350b';
		}
	}
}
