import { Directive, TemplateRef } from '@angular/core';

export interface ICellData<T> {
	$implicit: T;
	index?: number;
	arrayIndex?: number;
}

@Directive({
    selector: '[itdCellDef]',
    standalone: true,
})
export class CellDefDirective<T> {
	constructor(public template: TemplateRef<ICellData<T>>) {}
}
