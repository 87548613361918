<div class="strength">
	<ul class="strengthBar">
		<li class="point" [style.background-color]="bar0"></li>
		<li class="point" [style.background-color]="bar1"></li>
		<li class="point" [style.background-color]="bar2"></li>
		<li class="point" [style.background-color]="bar3"></li>
	</ul>

	<p class="text-center" [style.color]="messageColor" *ngIf="passwordToCheck?.length">
		{{ message | translate }}
	</p>
</div>
