import { Directive, ElementRef, Input, HostListener } from '@angular/core';
import { NotificationService } from 'src/app/utility-modules/notification/services/notification.service';

@Directive({
    selector: '[itdCopyToClipboard]',
    standalone: true,
})
export class CopyToClipboardDirective {
	@Input('itdCopyToClipboard') public copyValue: string;
	//
	constructor(private el: ElementRef, private notification: NotificationService) {
		this.el.nativeElement.style.cursor = 'pointer';
	}

	public ngOnInit() {}

	@HostListener('mouseenter') onMouseEnter() {
		this.highlight('var(--color-primary)');
	}

	@HostListener('mouseleave') onMouseLeave() {
		this.highlight('');
	}

	@HostListener('click') onMouseClick() {
		navigator.clipboard.writeText(this.copyValue);
		this.notification.add({
			text: `Copied ${this.copyValue} to clipboard`,
		});
	}

	private highlight(color: string) {
		this.el.nativeElement.style.backgroundColor = color;
	}
}
