import { Directive, ContentChild, Input } from '@angular/core';
import { CellDefDirective } from './cell-def.directive';
import { HeaderCellDefDirective } from './header-cell-def.directive';

@Directive({
    selector: '[itdColumnDef]',
    standalone: true
})
export class ColumnDefDirective<T> {
	@Input() public width: string | number = 0;
	@Input() public textAlign: string = 'left';
	@Input() public padding: number = 20;

	@ContentChild(CellDefDirective) public cellDef: CellDefDirective<T>;
	@ContentChild(HeaderCellDefDirective) public headerCellDef: HeaderCellDefDirective<T>;

	public get flexBasis(): string {
		if (typeof this.width === 'number' && this.width > 0) {
			return this.width.toString() + 'px';
		}

		return this.width as string;
	}

	constructor() {}

	public prepareCell(element: HTMLElement, header: boolean = false): void {
		if (header) {
			element.classList.add('table__header-cell');
		}

		element.classList.add('table__cell');

		element.style.width = this.flexBasis;
		element.style.textAlign = this.textAlign;

		element.style.paddingLeft = this.padding + 'px';
		element.style.paddingRight = this.padding + 'px';

		if (this.width === 0) {
			element.classList.add('is-grow');
		}
	}
}
