import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { NgClass, NgIf, NgFor, NgStyle } from '@angular/common';

export type TEmptyRowElement = {
	name: 'square' | 'circle';
	width?: number;
	randomWidth?: true;
	grow?: boolean;
	color?: string;
};

@Component({
    selector: 'itd-table-empty',
    templateUrl: './table-empty.component.html',
    styleUrls: ['./table-empty.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        NgFor,
        NgStyle,
    ],
})
export class TableEmptyComponent {
	@Input() public heading?: string;
	@Input() public rowHeight?: number = 70;
	@Input() public rowCount?: number;
	@Input() public inline: boolean = false;
	@Input() public align: 'center' | 'left' | 'right' = 'center';
	@Input() public rowElements: TEmptyRowElement[] = [
		{ name: 'circle' },
		{ name: 'square', randomWidth: true, grow: true },
	];

	constructor() {}

	public get createArray(): number[] {
		return new Array(this.rowCount);
	}

	public get randomSquareWidth(): number {
		return Math.floor(Math.random() * (180 - 115)) + 115;
	}
}
