import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { IOrganization } from 'src/app/core/organizations/organizations.interface';
import { OrganizationState } from 'src/app/core/organizations/organizations.state';
import { ISurveyAnswerDetails, ISurveyExtended } from 'src/app/core/surveys/surveys.interface';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { IconComponent } from '../../../utility-modules/icon/components/icon/icon.component';
import { TooltipDirective } from '../../directives/tooltip.directive';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
	selector: 'itd-timeline-survey',
	templateUrl: './timeline-survey.component.html',
	styleUrls: ['./timeline-survey.component.scss'],
	standalone: true,
	imports: [
		NgIf,
		NgFor,
		NgClass,
		TooltipDirective,
		IconComponent,
		MatSliderModule,
		ReactiveFormsModule,
		FormsModule,
		TranslateModule,
	],
})
export class TimelineSurveyComponent {
	@Input() activeSurvey: ISurveyExtended;
	@Input() surveyAnswers: ISurveyAnswerDetails[];
	@Input() resultsPage?: boolean = false;
	@Select(OrganizationState.organization) public organization$: Observable<IOrganization>;

	@Output() answersChanged = new EventEmitter();
	@Output() newComment = new EventEmitter();

	public truncateLength = 47;

	constructor() {}

	// TODO https://introdus.atlassian.net/browse/NF-347

	answerClicked(currentQuestionIndex, question, answerIndex) {
		this.answersChanged.emit({
			question: question,
			index: currentQuestionIndex,
			answer: answerIndex,
		});
	}

	onInputChange(event, question, currentQuestionIndex) {
		this.answersChanged.emit({
			question: question,
			index: currentQuestionIndex,
			answer: event.value,
		});
	}

	isSelected(question, answerIndex) {
		let surveyAnswerIndex = this.surveyAnswers.findIndex(ans => ans.id === question.id);
		return this.surveyAnswers[surveyAnswerIndex]?.option_answer === answerIndex;
	}

	public typed(data: any, question, currentQuestionIndex) {
		this.answersChanged.emit({
			question: question,
			index: currentQuestionIndex,
			answer: data.target.value,
		});
	}

	commentAdded(data: any, question, currentQuestionIndex) {
		this.newComment.emit({
			question: question,
			index: currentQuestionIndex,
			answer: data.target.value,
		});
	}

	public displayThumbValue(value: number | string): string {
		if (typeof value === 'number') return value.toString();
		return value;
	}

	public getAnswer(questionIndex: number): any {
		return this.surveyAnswers.find(
			answer => answer.id === this.activeSurvey.questions[questionIndex].id
		);
	}
}
