const HeadwayWidgetClassName = 'HW_widget_component';
const HeadwayWidgetSelector = '.' + HeadwayWidgetClassName;
const HeadwayWidgetTriggerClassName = 'HW_trigger';
const HeadwayWidgetTriggerSelector = '.' + HeadwayWidgetTriggerClassName;
const HeadwayWidgetId = 'HW_change_log';

export {
	HeadwayWidgetClassName,
	HeadwayWidgetSelector,
	HeadwayWidgetTriggerClassName,
	HeadwayWidgetTriggerSelector,
	HeadwayWidgetId,
};
