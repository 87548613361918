import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { ICellData } from './cell-def.directive';

@Directive({
    selector: '[itdRowAfterDef]',
    standalone: true
})
export class RowAfterDefDirective<T> {
	constructor(public template: TemplateRef<ICellData<T>>, public viewContainer: ViewContainerRef) {}
}
