<form (ngSubmit)="onSubmit()" [formGroup]="form">
	<itd-modal icon="arrow-right" [size]="600">
		<ng-template #header>
			{{ 'MOVE' | translate }} {{ 'DEPARTMENT' | translate }}: {{ departmentToMove?.title }}
		</ng-template>

		<ng-template #content>
			<div class="wrapper">
				<p>
					{{ 'DEPARTMENTS.MOVE_BODY' | translate }}
				</p>
				<p>{{ 'DEPARTMENTS.MOVE_BODY_TWO' | translate }}</p>
				<itd-grid-item>
					<itd-select
						label="DEPARTMENT"
						sizeType="default"
						formControlName="new_parent_id"
						printLabelIndex="title"
						outputValueIndex="department_id"
						[hasParentDepartment]="true"
						[isClearable]="true"
						[hasBorder]="false"
						[hasShadow]="true"
						[observableData]="filteredDepartments$"
					></itd-select>
				</itd-grid-item>
			</div>
		</ng-template>
		<ng-template #footer>
			<itd-button [disabled]="!form.valid" [enableAfterSuccess]="false" [formState]="formState">
				{{ 'CONFIRM' | translate }}
			</itd-button>

			<itd-form-error [errorObservable]="errorMessage$"></itd-form-error>
		</ng-template>
	</itd-modal>
</form>
