<mat-slider discrete [displayWith]="displayThumbValue" max="100" min="0" step="1" #ngSlider>
	<input
		matSliderThumb
		[id]="name"
		[name]="name"
		[(ngModel)]="value"
		(change)="changeValue({ source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value })"
		#ngSliderThumb="matSliderThumb"
	/>
</mat-slider>
