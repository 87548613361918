<form (ngSubmit)="onSubmit()" [formGroup]="form">
	<itd-modal icon="plus" [size]="690">
		<ng-template #header>
			{{ 'CONTENT.MEDIA_UPLOAD.HEADER' | translate }}
		</ng-template>

		<ng-template #content>
			<itd-input-file
				formControlName="files"
				[isMultiple]="true"
				[label]="'CONTENT.MEDIA_UPLOAD.PLACEHOLDER' | translate"
				(write)="fileUploaded($event)"
			></itd-input-file>

			<itd-form-error [errorObservable]="errorMessage$"></itd-form-error>
		</ng-template>

		<ng-template #footer>
			<itd-button [formState]="formState">
				{{ 'CONTENT.MEDIA_UPLOAD.BUTTON' | translate }}
			</itd-button>
		</ng-template>
	</itd-modal>
</form>
