export interface IAchievementsThemes {
	name: string;
	colors: {
		text: string;
		gradientStart: string;
		gradientStop: string;
	};
}

export const achievementsThemes: IAchievementsThemes[] = [
	{
		name: 'Plain white',
		colors: {
			text: '#000',
			gradientStart: '#fff',
			gradientStop: '#fff',
		},
	},
	{
		name: 'Plain black',
		colors: {
			text: '#fff',
			gradientStart: '#000',
			gradientStop: '#000',
		},
	},
	{
		name: 'Warm Flame',
		colors: {
			text: '#000',
			gradientStart: '#ff9a9e',
			gradientStop: '#fad0c4',
		},
	},
	{
		name: 'Juicy Peach',
		colors: {
			text: '#000',
			gradientStart: '#ffecd2',
			gradientStop: '#fcb69f',
		},
	},
	{
		name: 'Lady Lips',
		colors: {
			text: '#000',
			gradientStart: '#ff9a9e',
			gradientStop: '#fecfef',
		},
	},
	{
		name: 'Winter Neva',
		colors: {
			text: '#000',
			gradientStart: '#a1c4fd',
			gradientStop: '#c2e9fb',
		},
	},
	{
		name: 'Heavy Rain',
		colors: {
			text: '#000',
			gradientStart: '#cfd9df',
			gradientStop: '#e2ebf0',
		},
	},
	{
		name: 'Cloudy Knoxville',
		colors: {
			text: '#000',
			gradientStart: '#fdfbfb',
			gradientStop: '#ebedee',
		},
	},
	{
		name: 'Saint Petersberg',
		colors: {
			text: '#000',
			gradientStart: '#f5f7fa',
			gradientStop: '#c3cfe2',
		},
	},
	{
		name: 'Plum Plate',
		colors: {
			text: '#fff',
			gradientStart: '#667eea',
			gradientStop: '#764ba2',
		},
	},
	{
		name: 'Everlasting Sky',
		colors: {
			text: '#000',
			gradientStart: '#fdfcfb',
			gradientStop: '#e2d1c3',
		},
	},
	{
		name: 'Happy Fisher',
		colors: {
			text: '#fff',
			gradientStart: '#89f7fe',
			gradientStop: '#66a6ff',
		},
	},
	{
		name: 'Fly High',
		colors: {
			text: '#fff',
			gradientStart: '#48c6ef',
			gradientStop: '#6f86d6',
		},
	},
	{
		name: 'Fresh Milk',
		colors: {
			text: '#000',
			gradientStart: '#feada6',
			gradientStop: '#f5efef',
		},
	},
	{
		name: 'Great Whale',
		colors: {
			text: '#fff',
			gradientStart: '#a3bded',
			gradientStop: '#6991c7',
		},
	},
	{
		name: 'Aqua Splash',
		colors: {
			text: '#fff',
			gradientStart: '#13547a',
			gradientStop: '#80d0c7',
		},
	},
	{
		name: 'Clean Mirror',
		colors: {
			text: '#000',
			gradientStart: '#93a5cf',
			gradientStop: '#e4efe9',
		},
	},
	{
		name: 'Premium Dark',
		colors: {
			text: '#fff',
			gradientStart: '#434343',
			gradientStop: '#000000',
		},
	},
	{
		name: 'Cochiti Lake',
		colors: {
			text: '#000',
			gradientStart: '#93a5cf',
			gradientStop: '#e4efe9',
		},
	},
	{
		name: 'Passionate Bed',
		colors: {
			text: '#fff',
			gradientStart: '#ff758c',
			gradientStop: '#ff7eb3',
		},
	},
	{
		name: 'Mountain Rock',
		colors: {
			text: '#fff',
			gradientStart: '#868f96',
			gradientStop: '#596164',
		},
	},
	{
		name: 'Desert Hump',
		colors: {
			text: '#fff',
			gradientStart: '#c79081',
			gradientStop: '#dfa579',
		},
	},
	{
		name: 'Eternal Constance',
		colors: {
			text: '#fff',
			gradientStart: '#09203f',
			gradientStop: '#537895',
		},
	},
	{
		name: 'Healthy Water',
		colors: {
			text: '#fff',
			gradientStart: '#96deda',
			gradientStop: '#50c9c3',
		},
	},
	{
		name: 'Vicious Stance',
		colors: {
			text: '#fff',
			gradientStart: '#29323c',
			gradientStop: '#485563',
		},
	},
	{
		name: 'Nega',
		colors: {
			text: '#fff',
			gradientStart: '#ee9ca7',
			gradientStop: '#ffdde1',
		},
	},
	{
		name: 'Night Sky',
		colors: {
			text: '#fff',
			gradientStart: '#1e3c72',
			gradientStop: '#2a5298',
		},
	},
	{
		name: 'Gentle Care',
		colors: {
			text: '#fff',
			gradientStart: '#ffc3a0',
			gradientStop: '#ffafbd',
		},
	},
	{
		name: 'Morning Salad',
		colors: {
			text: '#fff',
			gradientStart: '#B7F8DB',
			gradientStop: '#50A7C2',
		},
	},
];
