import {
	Component,
	EventEmitter,
	HostBinding,
	HostListener,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { Color, ColorPickerControl, ColorPickerModule } from '@iplab/ngx-color-picker';
import { NgIf } from '@angular/common';

@Component({
	selector: 'itd-input-color-picker',
	templateUrl: './input-color-picker.component.html',
	styleUrls: ['./input-color-picker.component.scss'],
	standalone: true,
	imports: [NgIf, ColorPickerModule],
})
export class InputColorPickerComponent implements OnInit {
	public colorControl = new ColorPickerControl();

	public isVisible: boolean = false;

	@Input()
	public set color(color: string) {
		this.colorControl.setValueFrom(color);
	}

	@Output()
	public colorChange = new EventEmitter<string>();

	@HostBinding('style.background-color')
	public get background(): string {
		return this.colorControl.value.toHexString();
	}

	public ngOnInit() {
		this.colorControl.valueChanges.subscribe((value: Color) =>
			this.colorChange.emit(value.toHexString())
		);
	}

	@HostListener('click', ['$event'])
	public showColorPicker(event: MouseEvent) {
		if (this.isVisible === true) {
			return;
		}

		this.isVisible = !this.isVisible;
	}

	public overlayClick(event: MouseEvent): void {
		event.preventDefault();
		event.stopPropagation();
		this.isVisible = false;
	}
}
