import { IEnvironment } from 'src/environments/environment.interface';
import { Inject, Injectable } from '@angular/core';


@Injectable({
	providedIn: 'root',
})
export class DevEnvGuard  {
	constructor(@Inject('ENV') private env: IEnvironment) {}

	public canLoad(): boolean {
		return !this.env.production;
	}
}
