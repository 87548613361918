import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
	DeleteScheduledAssignment,
	LoadAllScheduledAssignments,
	LoadAllScheduledAssignmentsIfEmpty,
	CreateScheduledAssignment,
} from './scheduled-assignment.action';
import {
	IScheduledAssignment,
	IScheduledAssignmentDTO,
	IScheduledAssignmentEdit,
	IScheduledAssignmentEditDTO,
} from './scheduled-assignment.interface';
import { ScheduledAssignmentsService } from './scheduled-assignment.service';
import { AbstractApiState } from '../abstract/abstract-api-state';
import { tap } from 'rxjs';

export interface ScheduledAssignmentsStateModel {
	items: IScheduledAssignment[];
}

@State<ScheduledAssignmentsStateModel>({
	name: 'ScheduledAssignments',
	defaults: {
		items: null,
	},
})
@Injectable()
export class ScheduledAssignmentState extends AbstractApiState<
	IScheduledAssignment,
	IScheduledAssignmentDTO,
	IScheduledAssignmentEdit,
	IScheduledAssignmentEditDTO
> {
	constructor(protected scheduledAssignments: ScheduledAssignmentsService, store: Store) {
		super(scheduledAssignments, store);
	}

	@Selector()
	public static getState(state: ScheduledAssignmentsStateModel) {
		return state;
	}

	@Selector()
	public static items(state: ScheduledAssignmentsStateModel) {
		return state.items;
	}

	@Action(LoadAllScheduledAssignments)
	public loadAll(ctx: StateContext<ScheduledAssignmentsStateModel>) {
		return super.loadAll(ctx);
	}

	@Action(LoadAllScheduledAssignmentsIfEmpty)
	public loadAllIfEmpty(ctx: StateContext<ScheduledAssignmentsStateModel>) {
		return super.loadAllIfEmpty(ctx);
	}

	@Action(CreateScheduledAssignment)
	public create(
		ctx: StateContext<ScheduledAssignmentsStateModel>,
		data: CreateScheduledAssignment
	) {
		return super.create(ctx, data);
	}

	@Action(DeleteScheduledAssignment)
	public delete(
		ctx: StateContext<ScheduledAssignmentsStateModel>,
		data: DeleteScheduledAssignment
	) {
		return super.delete(ctx, data).pipe(
			tap(() => {
				this.store.dispatch(new LoadAllScheduledAssignments());
			})
		);
	}
}
