import { firstValueFrom, Observable } from 'rxjs';
import {
	Component,
	ChangeDetectionStrategy,
	Input,
	OnInit,
	ChangeDetectorRef,
	ElementRef,
	Output,
	EventEmitter,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { AbstractFormComponent } from 'src/app/utility-modules/itd-form/components/abstract-form/abstract-form.component';
import { confirmationValidator } from 'src/app/core/validators/confirmation.validator';
import { NotificationService } from 'src/app/utility-modules/notification/services/notification.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { FormErrorComponent } from '../../../utility-modules/itd-form/components/form-error/form-error.component';
import { ButtonComponent } from '../../../utility-modules/itd-form/components/button/button.component';
import { InputComponent } from '../../../utility-modules/itd-form/components/input/input.component';
import { SelectComponent } from '../../../utility-modules/itd-form/components/select/select.component';
import { NgIf } from '@angular/common';
import { ModalComponent } from '../../../utility-modules/modals/components/modal/modal.component';
import { InputDateComponent } from '../../../utility-modules/itd-form/components/input-date/input-date.component';

type FormValue = {
	confirm?: string;
	reason?: string;
	contract_end_date?: Date;
};

@Component({
	selector: 'itd-confirm-delete-modal',
	templateUrl: './confirm-delete-modal.component.html',
	styleUrls: ['./confirm-delete-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		ReactiveFormsModule,
		ModalComponent,
		NgIf,
		SelectComponent,
		InputComponent,
		ButtonComponent,
		FormErrorComponent,
		TranslateModule,
		InputDateComponent,
	],
})
export class ConfirmDeleteModalComponent extends AbstractFormComponent<FormValue>
	implements OnInit {
	@Input() data: {
		confirmText?: string;
		warningText?: string;
		content: string;
		reason?: boolean;
	};
	@Input() public onSubmitAction: () => Observable<any>;
	@Output() public error = new EventEmitter();
	@Output() public deleteSuccess = new EventEmitter();
	public reasons: Array<{ name: any; value: string }>;
	constructor(
		private notification: NotificationService,
		public activeModal: NgbActiveModal,
		public validationMessages: ValidationMessagesService,
		public elementRef: ElementRef,
		public cd: ChangeDetectorRef,
		private translate: TranslateService
	) {
		super(cd);
	}

	public async ngOnInit() {
		const { confirmText = '' } = this.data;

		this.form = new FormGroup({});

		if (confirmText) {
			this.form.setControl(
				'confirm',
				new FormControl('', [Validators.required, confirmationValidator('DELETE')])
			);
		}

		if (this.data.reason) {
			this.form.setControl('reason', new FormControl(''));
			this.form.setControl('contract_end_date', new FormControl(null));
		}

		this.reasons = [
			{
				name: await firstValueFrom(this.translate.get('EMPLOYEES.PLATFORM_NO_LONGER_NEEDED')),
				value: 'no_longer_need_platform',
			},
			{
				name: await firstValueFrom(this.translate.get('EMPLOYEES.CHANGED_JOBS')),
				value: 'changed_job',
			},
			{
				name: await firstValueFrom(this.translate.get('EMPLOYEES.CHANGED_INTERNAL_POSITION')),
				value: 'changed_internal_position',
			},
			{
				name: await firstValueFrom(this.translate.get('EMPLOYEES.OTHER_REASON')),
				value: 'other_reason',
			},
		];
		super.afterInit();
	}

	protected onSubmitSuccess() {
		this.notification.add({
			text: 'CONFIRM_DELETE.NOTIFICATION_SUCCESS',
		});

		this.deleteSuccess.emit();

		this.activeModal.close();
	}
}
