<div *ngIf="organization; else default">
	<label class="label {{ sizeType }}" *ngIf="label">
		{{ label | translate }}
		<span *ngIf="isRequired">*</span>
	</label>
	<div
		class="container position-{{ dropdownPosition }} size-{{ sizeType }} style-{{ styleType }}"
		[ngClass]="{
			isFocus: isFocus,
			isError: invalid,
			hasBorder: hasBorder,
			hasShadow: hasShadow,
			isOpen: isOpen,
			hasLabel: label
		}"
		[ngStyle]="{
			'maxWidth.px': maxWidth
		}"
	>
		<ng-select
			[placeholder]="placeholder"
			[(ngModel)]="defaultValue"
			(focus)="isFocus = true"
			(blur)="isFocus = false"
			(open)="isOpen = true"
			(close)="isOpen = false"
			[closeOnSelect]="!multiple"
			(ngModelChange)="writeValue($event)"
			[clearable]="isClearable && !multiple && !required"
			[dropdownPosition]="dropdownPosition"
			[multiple]="multiple"
			[items]="data || (observableData | async)"
			[bindLabel]="printLabelIndex"
			[notFoundText]="'FORM.SELECT.NOT_FOUND' | translate"
			[bindValue]="outputValueIndex"
			[searchable]="isSearchable"
			[ngClass]="{
				hasShadow: hasShadow
			}"
		>
			<div *ngIf="organization as org">
				<ng-template ng-label-tmp let-item="item">
					<span class="title">
						{{ item.title }}
					</span>
					<span class="label-msg welcome-msg" *ngIf="item.id === org.welcome_message">{{
						'CONTENT.MESSAGES.TYPE_WELCOME_MESSAGE' | translate
					}}</span>
					<span class="label-msg timeline-msg" *ngIf="item.id === org.timeline_message">{{
						'CONTENT.MESSAGES.TYPE_TIMELINE_NOTIFICATION' | translate
					}}</span>
					<span class="label-msg reminder-msg" *ngIf="item.id === org.reminder_message">{{
						'CONTENT.MESSAGES.TYPE_REMINDER_MESSAGE' | translate
					}}</span>
				</ng-template>

				<ng-template ng-option-tmp let-item="item" let-index="index">
					<span class="title">
						{{ item.title }}
					</span>
					<span class="label-msg welcome-msg" *ngIf="item.id === org.welcome_message">{{
						'CONTENT.MESSAGES.TYPE_WELCOME_MESSAGE' | translate
					}}</span>
					<span class="label-msg timeline-msg" *ngIf="item.id === org.timeline_message">{{
						'CONTENT.MESSAGES.TYPE_TIMELINE_NOTIFICATION' | translate
					}}</span>
					<span class="label-msg reminder-msg" *ngIf="item.id === org.reminder_message">{{
						'CONTENT.MESSAGES.TYPE_REMINDER_MESSAGE' | translate
					}}</span>
				</ng-template>
			</div>
		</ng-select>
	</div>

	<ng-container *ngIf="message$ | async as message">
		<p class="validation" *ngIf="invalid">
			{{ message.text }}
		</p>
	</ng-container>
</div>
<ng-template #default>
	<label class="label {{ sizeType }}" *ngIf="label">
		{{ label | translate }}
		<span *ngIf="isRequired">*</span>
	</label>
	<div
		class="container position-{{ dropdownPosition }} size-{{ sizeType }} style-{{ styleType }}"
		[ngClass]="{
			isFocus: isFocus,
			isError: invalid,
			hasBorder: hasBorder,
			hasShadow: hasShadow,
			isOpen: isOpen,
			hasLabel: label
		}"
		[ngStyle]="{
			'maxWidth.px': maxWidth
		}"
	>
		<ng-select
			[placeholder]="placeholder"
			[(ngModel)]="value"
			(focus)="isFocus = true"
			(blur)="isFocus = false"
			(open)="isOpen = true"
			(close)="isOpen = false"
			[closeOnSelect]="!multiple"
			(ngModelChange)="writeValue($event)"
			[clearable]="isClearable && !multiple && !required"
			[dropdownPosition]="dropdownPosition"
			[multiple]="multiple"
			[items]="data || (observableData | async)"
			[bindLabel]="printLabelIndex"
			[notFoundText]="'FORM.SELECT.NOT_FOUND' | translate"
			[bindValue]="outputValueIndex"
			[searchable]="isSearchable"
			[ngClass]="{
				hasShadow: hasShadow
			}"
		>
			<div class="department-parents-wrapper" *ngIf="hasParentDepartment">
				<ng-template ng-option-tmp let-item="item" let-index="index">
					<div class="title">
						{{ item.title }}
					</div>
					<div class="department-parents">
						<ng-container *ngFor="let department of getParents(item)">
							<span class="department-parents-item">{{ department?.title }}</span>
						</ng-container>
					</div>
				</ng-template>
			</div>
		</ng-select>
	</div>

	<ng-container *ngIf="message$ | async as message">
		<p class="validation" *ngIf="invalid">
			{{ message.text }}
		</p>
	</ng-container>
</ng-template>
