<div class="nav">
	<div class="nav__left">
		<itd-calendar-month-switcher
			[date]="currentMonth"
			(onChange)="changeMonth($event)"
		></itd-calendar-month-switcher>
	</div>

	<div class="nav__center">
		<itd-calendar-filters
			[filters]="filters"
			(onChange)="filtersChange($event)"
		></itd-calendar-filters>
	</div>

	<div class="nav__right">
		<button
			class="btn btn--color-secondary btn--size-small"
			[disabled]="isCurrentMonth"
			(click)="moveToCurrent()"
		>
			{{ 'TODAY' | translate }}
		</button>
	</div>
</div>

<div class="header">
	<div class="header__item" *ngFor="let dayOfWeek of daysOfWeek">
		{{ 'WEEK_DAYS.' + dayOfWeek | translate }}
	</div>
</div>

<div class="relative">
	<div class="body" [ngClass]="{ isLoading: isLoading }">
		<itd-calendar-day
			*ngFor="let day of visibleDays"
			[day]="day"
			[isFade]="day.get('month') !== currentMonth.get('month')"
			[isFocus]="day.isSame(focusDay)"
		>
			<ng-container
				[ngTemplateOutlet]="item.tpl"
				[ngTemplateOutletContext]="{
					day: day
				}"
			></ng-container>
		</itd-calendar-day>
	</div>

	<div class="loading loading--center" *ngIf="isLoading"></div>
</div>
