<itd-main-template>
	<ng-template #mainTemplateHeaderLeft>
		<div class="disclaimer" *ngIf="language === 'Danish'">
			<i>Kommer snart på Dansk!</i>
		</div>
	</ng-template>
	<ng-template #mainTemplateBody>
		<ng-container *ngIf="articles$ | async as articles">
			<itd-accordion-item *ngFor="let article of articles" [title]="article.title">
				<markdown>
					{{ article.content }}
				</markdown>
			</itd-accordion-item>
		</ng-container>
	</ng-template>
</itd-main-template>
