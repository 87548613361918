// Number of bytes on a megabyte
export const mbToBytes = (num: number) => {
	return num * 1024 * 1024;
};

// Check if file is larger than 5mb
export const is5MbOrLarger = (file: File) => {
	return file.size >= mbToBytes(5);
};

// Remove images >= 5mb
export const removeImagesLargerThan5Mb = (files: File[]) => {
	return files.filter(file => !is5MbOrLarger(file) || !file.type.startsWith('image'));
};
