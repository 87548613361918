import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
	APP_INITIALIZER,
	ENVIRONMENT_INITIALIZER,
	ErrorHandler,
	inject,
	InjectionToken,
	NgModule,
	Provider,
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule, Routes } from '@angular/router';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { SharedModule } from 'src/app/shared/shared.modules';
import { environment } from 'src/environments/environment';
import { AdminAnalyticsState } from '../modules/admin/modules/admin-analytics/store/admin-analytics.state';
import { AchievementsState } from './achievements/achievements.state';
import { AnalyticsState } from './analytics/analytics.state';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthState } from './auth/auth.state';
import { BasicDataProvider, BasicDataProviderFactory } from './basic-data.provider';
import { BuddyState } from './buddy/buddy.state';
import { DepartmentState } from './department/department.state';
import { DictionaryState } from './dictionary/dictionary.state';
import { EISState } from './employee-integration-score/eis.state';
import { ErrorHandlerInterceptor } from './errors/errorHandler.interceptor';
import { FastTrackAssignmentState } from './fast-track-assignment/fast-track-assignment.state';
import { FastTrackConfigurationState } from './fast-track-configuration/fast-track-configuration.state';
import { FontState } from './fonts/font.state';
import { HelpState } from './help/help.state';
import { LayoutMobileState } from './layout/children/layout-mobile.state';
import { LayoutState } from './layout/layout.state';
import { MediaState } from './media/media.state';
import { MessagesState } from './messages/messages.state';
import { ModulesState } from './modules/modules.state';
import { NetworkState } from './network/network.state';
import { OrganizationState } from './organizations/organizations.state';
import { ProfileState } from './profile/profile.state';
import { PublicSignupLinkState } from './public-signup-links/public-signup-links.state';
import { ScheduledAssignmentState } from './scheduled-assignment/scheduled-assignment.state';
import { SuperAdminUsersState } from './superadmin-users/superadmin-users.state';
import { SurveysState } from './surveys/surveys.state';
import { TaskAssignmentState } from './task-assignment/task-assignment.state';
import { TaskState } from './task/task.state';
import { TasklistAssignmentState } from './tasklist-assignment/tasklist-assignment.state';
import { TasklistState } from './tasklist/tasklist.state';
import { TimelineAssignState } from './timeline-assign/timeline-assign.state';
import { TimelineState } from './timelines/timelines.state';
import { UsersState } from './users/users.state';
import * as Sentry from '@sentry/angular-ivy';
// import { SMTPConfigurationState } from './smtp-configuration/smtp-configuration.state';

// create unique injection token for the guard
export const CORE_GUARD = new InjectionToken<string>('CORE_GUARD');
export interface CoreOptions {
	routes: Routes;
	reloadServicePollInterval?: number;
}

export function provideCore(options?: CoreOptions): Provider[] {
	return [
		// provideRouter(options.routes),
		{ provide: CORE_GUARD, useValue: 'CORE_GUARD' },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: BasicDataProviderFactory,
			deps: [BasicDataProvider],
			multi: true,
		},
		// Initializer has to be last
		{
			provide: ENVIRONMENT_INITIALIZER,
			multi: true,
			useValue() {
				const coreGuard = inject(CORE_GUARD, {
					skipSelf: true,
					optional: true,
				});
				if (coreGuard) {
					throw new TypeError(`provideCore() can be used only once
            per application (and never in library)`);
				}
				// other setup and long processes ...
			},
		},
	];
}

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		SharedModule,
		BrowserAnimationsModule,
		NgxsModule.forRoot(
			[
				AuthState,
				FastTrackAssignmentState,
				FastTrackConfigurationState,
				ProfileState,
				LayoutState,
				LayoutMobileState,
				HelpState,
				DictionaryState,
				DepartmentState,
				ModulesState,
				TimelineAssignState,
				TimelineState,
				SurveysState,
				OrganizationState,
				UsersState,
				MessagesState,
				NetworkState,
				MediaState,
				AchievementsState,
				AdminAnalyticsState,
				AnalyticsState,
				ScheduledAssignmentState,
				SuperAdminUsersState,
				FontState,
				SuperAdminUsersState,
				TasklistState,
				TasklistAssignmentState,
				TaskState,
				TaskAssignmentState,
				BuddyState,
				EISState,
				PublicSignupLinkState,
				// SMTPConfigurationState,
			],
			{
				developmentMode: !environment.production,
			}
		),
		NgxsStoragePluginModule.forRoot({
			key: ['auth.token', 'layout.language', 'layout.primaryColor'],
		}),
		NgxsRouterPluginModule.forRoot(),
		NgxsReduxDevtoolsPluginModule.forRoot({
			disabled: environment.production,
		}),
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorHandlerInterceptor,
			multi: true,
		},
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: BasicDataProviderFactory,
			deps: [BasicDataProvider],
			multi: true,
		},
	],
})
export class CoreModule {
	private coreModuleMultipleImportsGuard = inject(CoreModule, {
		skipSelf: true,
		optional: true,
	});

	// or previously with constructor injection...
	// constructor(@Optional() @SkipSelf() coreModuleGuard: CoreModule) {}

	constructor() {
		// prevent multiple instances (and multiple executions of the processes)
		if (this.coreModuleMultipleImportsGuard) {
			throw new Error(`CoreModule can be imported only once per application
        (and never in library)`);
		}
	}
}
