import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ITasklist } from 'src/app/core/tasklist/tasklist.interface';
import { AbstractAddModalComponent } from '../abstract-add-modal/abstract-add-modal.component';
import { IUser } from 'src/app/core/users/users.interface';
import { UsersState } from 'src/app/core/users/users.state';
import { TasklistState } from 'src/app/core/tasklist/tasklist.state';
import { LoadAllTasklistsIfEmpty } from 'src/app/core/tasklist/tasklist.action';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../utility-modules/itd-form/components/button/button.component';
import { UserAvatarComponent } from '../../components/user-avatar/user-avatar.component';
import { CheckboxComponent } from '../../../utility-modules/itd-form/components/checkbox/checkbox.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { ModalComponent } from '../../../utility-modules/modals/components/modal/modal.component';

@Component({
	selector: 'itd-tasklist-modal',
	templateUrl: './tasklist-modal.component.html',
	styleUrls: ['./tasklist-modal.component.scss'],
	standalone: true,
	imports: [
		ModalComponent,
		NgIf,
		NgFor,
		CheckboxComponent,
		UserAvatarComponent,
		ButtonComponent,
		AsyncPipe,
		TranslateModule,
	],
})
export class TasklistModalComponent extends AbstractAddModalComponent<ITasklist> implements OnInit {
	protected onLoad: () => Observable<void>;
	protected items$: Observable<ITasklist[]>;
	public key = 'TASK LIST';
	public departmentFoldersActive = false;

	public selectedTaskTemplates = [];

	@Output() public activeType = new EventEmitter<any>();

	@Select(TasklistState.items) public tasklists$: Observable<ITasklist[]>;
	@Select(UsersState.items) public users$: Observable<IUser[]>;

	constructor(activeModal: NgbActiveModal, private store: Store, cd: ChangeDetectorRef) {
		super(activeModal, cd);
	}

	public ngOnInit() {
		this.store.dispatch(LoadAllTasklistsIfEmpty);
	}

	public selectTaskTemplate(taskTemplate): void {
		if (this.selectedTaskTemplates.includes(taskTemplate)) {
			this.selectedTaskTemplates = this.selectedTaskTemplates.filter(
				taskTem => taskTem.id !== taskTemplate.id
			);
		} else {
			this.selectedTaskTemplates.push(taskTemplate);
		}
	}

	public selected(taskTemplate: ITasklist): boolean {
		return this.selectedTaskTemplates.map(taskTem => taskTem.id).includes(taskTemplate.id);
	}

	public save(): void {
		this.activeModal.close(this.selectedTaskTemplates);
	}

	public getUser(user_id: string) {
		let user: IUser = null;
		this.users$.subscribe(val => {
			return (user = val?.find(user => user.user_id === user_id && user.code === 'Active'));
		});
		return user;
	}
}
