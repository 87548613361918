<itd-modal icon="plus" [padding]="20">
	<ng-template #header>
		<div class="title">{{ 'EMPLOYEES.INVITE' | translate }}</div>
	</ng-template>

	<ng-template #footer>
		<div class="buttons">
			<button
				routerLink="admin/employees/invite-single-user"
				class="btn btn--color-secondary btn--size-default"
				style="min-width: 120px;"
			>
				<div class="button-name">{{ 'EMPLOYEES.SINGLE_USER' | translate }}</div>
			</button>
			<button
				routerLink="admin/employees/invite-multi-user"
				class="btn btn--color-secondary btn--size-default"
				style="min-width: 120px;"
			>
				<div class="button-name">{{ 'EMPLOYEES.MULTIPLE_USERS' | translate }}</div>
			</button>
		</div>
	</ng-template>
</itd-modal>
