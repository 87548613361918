export const daysDiffernce = (date: string) => {
	const currentDate = new Date();
	const endDate = new Date(date);

	return Math.floor(
		(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) -
			Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) /
			(1000 * 60 * 60 * 24)
	);
};
