import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChartModule } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import { IAnalyticsColumnGraphData } from '../../../../core/analytics/analytics.interface';
import { AbstractGraphComponent } from '../../abstract/abstract-graph/abstract-graph.component';

export type TColumGraphData = Array<IAnalyticsColumnGraphData>;

@Component({
	selector: 'itd-graph-column',
	templateUrl: './graph-column.component.html',
	styleUrls: ['./graph-column.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ChartModule],
})
export class GraphColumnComponent extends AbstractGraphComponent {
	@Input() public data: TColumGraphData;
	@Input() public label: string;
	@Input() public height: number;
	@Input() public includeLine = false;

	constructor() {
		super();
	}

	protected getChartOptions(): Highcharts.Options {
		let chartData = this.data;

		const baseConfig: Highcharts.Options = {
			chart: {
				type: 'column',
				plotBackgroundColor: null,
				plotBorderWidth: 0,
				plotShadow: false,
				animation: false,
				height: this.height ? `${this.height}px` : '200px',
				width: null,
			},
			xAxis: [
				{
					categories: chartData.map(item => {
						return item.answer;
					}),
				},
			],
			yAxis: [
				{
					// Primary yAxis
					min: 0,
					max: 100,
					title: {
						text: '%',
					},
					labels: {
						style: {
							color: Highcharts.getOptions().colors[1].toString(),
						},
					},
				},
				{
					// Secondary yAxis
					title: {
						text: '',
						style: {
							color: Highcharts.getOptions().colors[0].toString(),
						},
					},
					labels: {
						format: '',
						style: {
							color: Highcharts.getOptions().colors[0].toString(),
						},
					},
					visible: false,
				},
			],
			tooltip: {
				headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
				pointFormatter: function(this) {
					return (
						'<tr><td style="color:' +
						this.color +
						';padding:0">' +
						this.series.name +
						': </td>' +
						'<td style="padding:0"><b>' +
						this.y +
						' %</b></td></tr>' +
						'<tr><td style="color:' +
						this.color +
						';padding:0">Total: </td>' +
						'<td style="padding:0"><b>' +
						chartData[this.index].count +
						'</b></td></tr>'
					);
				},
				footerFormat: '</table>',
				shared: true,
				useHTML: true,
			},
			legend: {
				align: 'left',
				verticalAlign: 'top',
			},
			series: [
				{
					name: this.label,
					type: 'column',
					data: chartData.map(item => {
						return +item.percentage.toFixed(2);
					}),
					colorByPoint: true,
					colors: chartData.map(item => {
						return item.color;
					}),
					dataLabels: [
						{
							enabled: true,
							inside: true,
							style: {
								fontSize: '12px',
							},
							formatter: function(this, options) {
								return (
									'<div style="background-color: rgba(0, 0, 0, 0.2);padding:5px; border-radius:10px">' +
									chartData.find(a => a.answer === this.key).count +
									'</div>'
								);
							},
							useHTML: true,
						},
					],
					tooltip: {},
				},
			],
			plotOptions: {
				series: {
					marker: {
						enabled: false,
						states: {
							hover: {
								enabled: false,
							},
						},
					},
				},
			},
		};

		if (this.includeLine) {
			const lineData = this.data.map((item, index) => {
				return [index, item.percentage]; // Use index as x, percentage as y
			});

			baseConfig.series.push({
				type: 'spline',
				data: lineData,
				maxPointWidth: 30,
			});
		}

		return baseConfig;
	}
}
