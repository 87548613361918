<div class="container">
	<div class="not-allowed" *ngIf="!organization">
		<h1>{{ 'PUBLIC_SIGNUP.NOT_ALLOWED' | translate }}</h1>
		<a class="btn btn--color-primary btn--size-default" href="https://introdusapp.com">
			{{ 'HOME' | translate }}
		</a>
	</div>
	<form
		class="right-container"
		(ngSubmit)="onSubmit()"
		[formGroup]="form"
		*ngIf="organization && !publicSignUpSuccess"
	>
		<h1>{{ 'CREATE_ACCOUNT' | translate }}</h1>
		<div class="image-placeholder">
			<itd-image [url]="organization.logo_url" [hasRetina]="true"></itd-image>
		</div>
		<itd-input
			[isRequired]="true"
			class="input"
			name="email"
			type="email"
			formControlName="email"
			sizeType="small"
			label="{{ 'EMAIL' | translate }}"
			[hasShadow]="false"
			[hasBorder]="true"
			[focusAfterInit]="true"
		></itd-input>
		<itd-input-date
			[icon]="null"
			sizeType="small"
			class="input"
			label="{{ 'PUBLIC_SIGNUP.START_DATE' | translate }} *"
			[hasShadow]="true"
			[startDate]="paramsStartDate ? paramsStartDate : null"
			name="start_date"
			formControlName="start_date"
			(write)="changeStartDate($event)"
			[isDisabledInput]="isDisabledPicker"
		></itd-input-date>
		<itd-form-error [errorObservable]="errorMessage$"></itd-form-error>
		<itd-button [width]="120" [formState]="formState">
			{{ 'SIGN_UP' | translate }}
		</itd-button>
		<hr />
		<span
			>{{ 'PUBLIC_SIGNUP.ALREADY_HAVE_ACCOUNT' | translate }}
			<a class="btn btn--color-secondary btn--size-small" routerLink="/login">{{
				'LOGIN' | translate
			}}</a></span
		>
	</form>
	<div class="right-container" *ngIf="publicSignUpSuccess">
		<h1>
			{{ 'PUBLIC_SIGNUP.GET_STARTED' | translate }}
		</h1>
	</div>
</div>
