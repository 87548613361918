import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { CSSBreakpoints } from './core/constans';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Observable } from 'rxjs';
import { LayoutState } from './core/layout/layout.state';
import { SetIsMobile } from './core/layout/children/layout-mobile.actions';
import { NgIf, AsyncPipe } from '@angular/common';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [NgbModalConfig, NgbModal],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        RouterOutlet,
        NgIf,
        AsyncPipe,
    ],
})
export class AppComponent implements OnInit {
	@Select(LayoutState.loading) public loading$: Observable<number>;

	constructor(
		private store: Store,
		private breakpointObserver: BreakpointObserver,
		private actions: Actions,
		private modals: NgbModal,
		protected modalsConfig: NgbModalConfig
	) {
		modalsConfig.centered = true;
	}

	public ngOnInit(): void {
		// Check mobile size
		this.breakpointObserver
			.observe([`(max-width: ${CSSBreakpoints.md - 1}px)`])
			.pipe(map((state: BreakpointState) => state.matches))
			.subscribe(event => {
				this.store.dispatch(new SetIsMobile({ isMobile: event }));
			});

		this.actions.pipe(ofActionSuccessful(RouterNavigation)).subscribe(() => {
			window.scrollTo({
				top: 0,
				behavior: 'auto',
			});

			if (this.modals.hasOpenModals()) {
				this.modals.dismissAll();
			}
		});
	}
}
