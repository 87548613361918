// import { IFilterAnalytics } from '../analytics/analytics.interface';
import { IPagination } from '../constans';
import {
	ITimelineAssignEdit,
	ITimelineResendAssignment,
	QueryDTO,
} from './timeline-assign.interface';

export class GetAllTimelinesAssign {
	public static readonly type = '[Timeline Assign] Get All';
}

export class GetAllTimelinesAssignIfEmpty {
	public static readonly type = '[Timeline Assign] Get All If Empty';
}

export class CreateTimelineAssign {
	public static readonly type = '[Timeline Assign] Create';
	constructor(public payload: ITimelineAssignEdit) {}
}

export class ResendAssignment {
	public static readonly type = '[Timeline Assign] Resend Assignment';
	constructor(public payload: ITimelineResendAssignment) {}
}

export class DeleteTimelineAssign {
	public static readonly type = '[Timeline Assign] Delete Timeline Assign';
	constructor(public payload: { tl_id: number; user_id: string }) {}
}

export class GetFilteredTimelinesAssign {
	public static readonly type = '[Timeline Assign] Get Filtered Timeline Assign';
	constructor(public payload: { id: string; query: QueryDTO }) {}
}

export class LoadAllEmployees {
	public static readonly type = '[Timeline Assign] Load All';
	constructor(public payload: { params: { skip: number; limit: number; all?: boolean } }) {}
}

export class LoadAllEmployeesIfEmpty {
	public static readonly type = '[Timeline Assign] Load All If Empty';
	constructor(public payload: { params: { skip: number; limit: number; all?: boolean } }) {}
}

export class AddPaginationForEmployees {
	public static readonly type = '[Timeline Assign] Add Pagination For Employees';
	constructor(public payload: IPagination) {}
}

export class GetUserTimelines {
	public static readonly type = '[Timeline Assign] Get User Timelines';
	constructor(public payload: { userId: string }) {}
}
