import { IUserRole } from '../users/users.interface';

// * We should probably make a number-based permission system, with object representations, so its easier to introduce more roles in the future

export const isUserAllowed = (userRole: IUserRole, requiredRole: IUserRole) => {
	if (!userRole || !requiredRole) return true;
	if (userRole === 'std_user' && requiredRole !== 'std_user') return false;

	if (userRole === 'dept_admin' && requiredRole === 'dept_admin') return true;
	if (userRole === 'dept_admin' && requiredRole === 'org_admin') return false;
	if (userRole === 'dept_admin' && requiredRole === 'super_admin') return false;

	if (userRole === 'org_admin' && requiredRole === 'dept_admin') return true;
	if (userRole === 'org_admin' && requiredRole === 'org_admin') return true;
	if (userRole === 'org_admin' && requiredRole === 'super_admin') return true;

	if (userRole === 'super_admin') return true;

	return true;
};
