import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { AbstractApiState } from '../abstract/abstract-api-state';
import {
	CreateMessage,
	DeleteMessage,
	DuplicateMessage,
	LoadAllMessages,
	LoadAllMessagesIfEmpty,
	UpdateMessage,
} from './messages.action';
import { IMessage, IMessageDTO, IMessageEdit, IMessageEditDTO } from './messages.interface';
import { MessagesService } from './messages.service';

export interface MessagesStateModel {
	items: IMessage[];
}

@State<MessagesStateModel>({
	name: 'messages',
	defaults: {
		items: null,
	},
})
@Injectable()
export class MessagesState extends AbstractApiState<
	IMessage,
	IMessageDTO,
	IMessageEdit,
	IMessageEditDTO
> {
	constructor(protected messages: MessagesService, store: Store) {
		super(messages, store);
	}

	@Selector()
	public static getState(state: MessagesStateModel) {
		return state;
	}

	@Selector()
	public static items(state: MessagesStateModel) {
		return state.items;
	}

	// @Selector()
	// public static getCheCkedMessages(state: MessagesStateModel) {
	// 	return state.messagesChecked;
	// }

	// @Action(LoadMessage)
	// public loadOneMessage(ctx: StateContext<MessagesStateModel>, data: LoadMessage) {
	// 	const { patchState } = ctx;
	// 	// return super.loadOne(data.payload.id).pipe(
	// 	// 	tap(result => {
	// 	// 		patchState({
	// 	// 			activeSurvey: result,
	// 	// 		});
	// 	// 	})
	// 	// );
	// }

	@Action(LoadAllMessages)
	public loadAll(ctx: StateContext<MessagesStateModel>) {
		return super.loadAll(ctx);
	}

	@Action(LoadAllMessagesIfEmpty)
	public loadAllIfEmpty(ctx: StateContext<MessagesStateModel>) {
		return super.loadAllIfEmpty(ctx);
	}

	@Action(CreateMessage)
	public create(ctx: StateContext<MessagesStateModel>, data: CreateMessage) {
		return super.create(ctx, data);
	}

	@Action(UpdateMessage)
	public update(ctx: StateContext<MessagesStateModel>, data: UpdateMessage) {
		return super.update(ctx, data);
	}

	@Action(DeleteMessage)
	public delete(ctx: StateContext<MessagesStateModel>, data: DeleteMessage) {
		return super.delete(ctx, data);
	}

	@Action(DuplicateMessage)
	public duplicate(ctx: StateContext<MessagesStateModel>, data: DuplicateMessage) {
		return super.duplicate(ctx, data);
	}

	// @Action(LoadAllCheckedMessages)
	// public loadAllCheckedMessages(ctx: StateContext<MessagesStateModel>) {
	// 	return this.messages.loadMessagesChecked().pipe(
	// 		tap(items => {
	// 			ctx.setState(
	// 				patch<MessagesStateModel>({
	// 					messagesChecked: items,
	// 				})
	// 			);
	// 		})
	// 	);
	// }

	// @Action(SelectMessageChecked)
	// public selectMessage(ctx: StateContext<MessagesStateModel>, { payload }: SelectMessageChecked) {
	// 	const { messagesChecked } = ctx.getState();
	// 	ctx.setState(
	// 		patch<MessagesStateModel>({
	// 			messagesChecked: messagesChecked.map(message => {
	// 				if (message.id === payload) {
	// 					return {
	// 						...message,
	// 						checked: true,
	// 					};
	// 				}
	// 				return {
	// 					...message,
	// 					checked: false,
	// 				};
	// 			}),
	// 		})
	// 	);
	// }
}
