<div class="icon">
	<itd-icon [icon]="icon" [width]="34" [height]="34"></itd-icon>
</div>

<p class="title">
	<ng-content></ng-content>
</p>

<a [routerLink]="[link]" class="btn btn--size-big btn--color-primary btn--block" *ngIf="button">
	{{ button }}
</a>
