<form (ngSubmit)="onSubmit()" [formGroup]="form">
	<itd-modal icon="plus">
		<ng-template #header *ngIf="viewDetails">
			{{ 'TASK' | translate }} {{ 'DETAILS' | translate }}
		</ng-template>
		<ng-template #header *ngIf="addNotesOnly">
			{{ 'TASKS.ADD_NOTE' | translate }}
		</ng-template>
		<ng-template #header>
			{{ 'TASKS.CANT_DO' | translate }}
		</ng-template>

		<ng-template #content>
			<itd-grid>
				<itd-grid-item size="100%" [mobileResponsive]="true">
					<div class="icon-and-label">
						<itd-icon
							icon="calendar"
							[width]="24"
							[height]="24"
							[fill]="(organization$ | async).primary_color"
						></itd-icon>
						<label class="label">{{ 'TASKS.IS_DUE' | translate }}</label>
					</div>
					<p class="disabled">{{ taskAssignment.due_date | date: 'fullDate' }}</p>
				</itd-grid-item>

				<itd-grid-item size="100%" [mobileResponsive]="true">
					<div class="icon-and-label">
						<itd-icon
							icon="calendar"
							[width]="24"
							[height]="24"
							[fill]="(organization$ | async).primary_color"
						/>
						<label class="label">{{ 'REMINDERS' | translate }}</label>
					</div>
					<div class="reminder" *ngFor="let reminder of taskAssignment.reminders">
						<p class="disabled">
							{{
								getDateXDaysAgo(reminder.days_prior, taskAssignment.due_date, true).toDate()
									| date: 'fullDate'
							}}
						</p>
						<itd-icon [width]="24" [height]="24" icon="checkmark" *ngIf="reminder?.isSent" />
					</div>
				</itd-grid-item>

				<itd-grid-item size="100%" [mobileResponsive]="true" *ngIf="taskAssignment.description">
					<div class="icon-and-label">
						<itd-icon
							icon="content"
							[width]="24"
							[height]="24"
							[fill]="(organization$ | async).primary_color"
						></itd-icon>
						<label class="label">{{ 'TASKS.DESCRIPTION' | translate }}</label>
					</div>
					<p [innerHTML]="taskAssignment.description | highlightLinks" class="disabled"></p>
				</itd-grid-item>

				<itd-grid-item size="100%" [mobileResponsive]="true" *ngIf="addNotesOnly">
					<label class="label">{{ 'TASKS.ADD_NOTE' | translate }}</label>
					<textarea
						placeholder="{{ 'TASKS.ADD_NOTE_PLACEHOLDER' | translate }}"
						formControlName="notes"
						name="notes"
						class="input-text-area input"
					></textarea>
				</itd-grid-item>

				<itd-grid-item size="100%" [mobileResponsive]="true" *ngIf="!viewDetails && !addNotesOnly">
					<label class="label">{{ 'TASKS.CANT_DO_REASON' | translate }} *</label>
					<textarea
						placeholder="{{ 'TASKS.CANT_DO_REASON_PLACEHOLDER' | translate }}"
						formControlName="notes"
						name="notes"
						class="input-text-area input"
					></textarea>
				</itd-grid-item>
			</itd-grid>

			<div class="py-4"></div>
			<itd-form-error [errorObservable]="errorMessage$"></itd-form-error>

			<hr
				class="divider"
				*ngIf="(viewDetails || addNotesOnly) && taskAssignment.notes.length >= 1"
			/>

			<div
				class="notes-wrapper"
				*ngIf="(viewDetails || addNotesOnly) && taskAssignment.notes.length >= 1"
			>
				<div class="icon-and-label for-notes">
					<itd-icon
						icon="document"
						[width]="24"
						[height]="24"
						[fill]="(organization$ | async).primary_color"
					></itd-icon>
					<label class="label">{{ 'NOTES' | translate }}</label>
				</div>
				<!-- TODO: Sort these by most recent first -->
				<div class="notes">
					<div class="note" *ngFor="let note of taskAssignment.notes">
						{{ note }}
					</div>
				</div>
			</div>
		</ng-template>

		<ng-template #footer>
			<itd-button [formState]="formState" [width]="120" *ngIf="!viewDetails">
				{{ 'CONFIRM' | translate }}
			</itd-button>

			<itd-button [width]="120" *ngIf="viewDetails" [setType]="'button'" (click)="closeModal()">
				{{ 'CLOSE' | translate }}
			</itd-button>
		</ng-template>
	</itd-modal>
</form>
