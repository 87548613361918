import { achievementsThemes, IAchievementsThemes } from './achievements-themes';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { AbstractApiState } from '../abstract/abstract-api-state';
import { achievementsIcons, IAchievementsIcon } from './achievements-icons';
import {
	CreateAchievement,
	DeleteAchievement,
	LoadAllAchievements,
	LoadAllAchievementsIfEmpty,
	LoadOneAchievement,
	UpdateAchievement,
} from './achievements.action';
import {
	IAchievement,
	IAchievementEdit,
	IAchievementDTO,
	IAchievementEditDTO,
} from './achievements.interface';
import { AchievementsService } from './achievements.service';
import { tap } from 'rxjs';

export interface AchievementsStateModel {
	items: IAchievement[];
	icons?: IAchievementsIcon[];
	themes?: IAchievementsThemes[];
	currentAchievement?: IAchievement;
}

@State<AchievementsStateModel>({
	name: 'achievements',
	defaults: {
		items: null,
		icons: achievementsIcons,
		themes: achievementsThemes,
		currentAchievement: null,
	},
})
@Injectable()
export class AchievementsState extends AbstractApiState<
	IAchievement,
	IAchievementDTO,
	IAchievementEdit,
	IAchievementEditDTO
> {
	constructor(achievements: AchievementsService, store: Store) {
		super(achievements, store);
	}

	@Selector()
	public static getState(state: AchievementsStateModel) {
		return state;
	}

	@Selector()
	public static items(state: AchievementsStateModel) {
		return state.items;
	}

	@Selector()
	public static icons(state: AchievementsStateModel) {
		return state.icons;
	}

	@Selector()
	public static themes(state: AchievementsStateModel) {
		return state.themes;
	}

	@Selector()
	public static currentAchievement(state: AchievementsStateModel) {
		return state.currentAchievement;
	}

	@Selector()
	public static hasAchievements(state: AchievementsStateModel) {
		return state.items && state.items.length > 0;
	}

	@Action(LoadAllAchievements)
	public loadAll(ctx: StateContext<AchievementsStateModel>) {
		return super.loadAll(ctx);
	}

	@Action(LoadAllAchievementsIfEmpty)
	public loadAllIfEmpty(ctx: StateContext<AchievementsStateModel>) {
		return super.loadAllIfEmpty(ctx);
	}

	@Action(LoadOneAchievement)
	public loadOneAchievement(ctx: StateContext<AchievementsStateModel>, data: LoadOneAchievement) {
		const { patchState } = ctx;
		return super.loadOne(data.payload.id).pipe(
			tap(result => {
				patchState({
					currentAchievement: result,
				});
			})
		);
	}

	@Action(CreateAchievement)
	public create(ctx: StateContext<AchievementsStateModel>, data: CreateAchievement) {
		return super.create(ctx, data);
	}

	@Action(UpdateAchievement)
	public update(ctx: StateContext<AchievementsStateModel>, data: UpdateAchievement) {
		return super.update(ctx, data);
	}

	@Action(DeleteAchievement)
	public delete(ctx: StateContext<AchievementsStateModel>, data: DeleteAchievement) {
		return super.delete(ctx, data);
	}
}
