import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'itd-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class GridComponent {
	@Input()
	public space: 'default' | 'big' = 'default';

	constructor() { }
}
