import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { IDepartment } from 'src/app/core/department/department.interface';
import { DepartmentState } from 'src/app/core/department/department.state';

@Injectable({
	providedIn: 'root',
})
export class FindDepartmentService {
	@Select(DepartmentState.departments) public departments$: Observable<IDepartment[]>;
	constructor() {}

	public findDepartment(department_id) {
		let departments = new Array<IDepartment>();
		let departmentsOutputString = '';
		let department_ids = department_id?.split('.');
		this.departments$.subscribe(
			(val) => (departments = val.filter((dep) => department_ids?.includes(dep.department_id)))
		);
		for (const dep of departments) {
			departmentsOutputString += `> ${dep.title} `;
		}
		return departmentsOutputString.substring(2);
	}
}
