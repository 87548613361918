<div class="wrap wrap--default">
	<div class="header sticky" *ngIf="showBackButton">
		<itd-button-back (click)="goBackClick()"></itd-button-back>
	</div>

	<div class="body">
		<div class="row">
			<div class="col-left">
				<div class="sticky">
					<h1 class="title" [ngClass]="{ 'timeline-title-color': timelineName }">{{ title }}</h1>
					<h3 class="timeline-name">{{ timelineName }}</h3>

					<ng-container *ngIf="left">
						<ng-template [ngTemplateOutlet]="left"></ng-template>
					</ng-container>

					<div class="action" *ngIf="formState || action">
						<ng-template [ngTemplateOutlet]="action"></ng-template>

						<itd-button
							*ngIf="formState"
							sizeType="default"
							[formState]="formState"
							[isBlock]="true"
							[disabled]="disabled"
						>
							{{ buttonText | translate }}
						</itd-button>

						<itd-button
							*ngIf="showDeleteButton"
							setType="button"
							sizeType="default"
							styleType="secondary"
							[isBlock]="true"
							(click)="clickDelete()"
						>
							{{ 'DELETE' | translate }}
						</itd-button>

						<div class="preview-btns-block" *ngIf="showPreviewButtons()">
							<div
								class="preview-icon"
								[itdTooltip]="'CONTENT.MESSAGES.TOOLTIP_SMS_PREVIEW' | translate"
								tooltipPosition="bottom"
							>
								<img
									src="../../../../assets/images/preview/sms_preview_icon.svg"
									alt="Icon for SMS preview for message"
									(click)="showPreview($event, 'SMS')"
								/>
							</div>
							<div
								class="preview-icon"
								[itdTooltip]="'CONTENT.MESSAGES.TOOLTIP_EMAIL_PREVIEW' | translate"
								tooltipPosition="bottom"
							>
								<img
									src="../../../../assets/images/preview/email_preview_icon.svg"
									alt="Icon for Email preview for message"
									(click)="showPreview($event, 'EMAIL')"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-right">
				<ng-container *ngIf="right">
					<ng-template [ngTemplateOutlet]="right"></ng-template>
				</ng-container>
			</div>
		</div>
	</div>
</div>
