import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { AbstractApiState } from '../abstract/abstract-api-state';
import {
	ClearSelectedTasklist,
	TasklistCreateTask,
	CreateTasklist,
	TasklistDeleteTask,
	DeleteTasklist,
	LoadAllTasklists,
	LoadAllTasklistsIfEmpty,
	SelectTasklist,
	TasklistUpdateTask,
	UpdateTasklist,
} from './tasklist.action';
import { ITasklistDTO, ITasklist, ITasklistEdit, ITasklistEditDTO } from './tasklist.interface';
import { TasklistService } from './tasklist.service';

export interface TasklistStateModel {
	items: ITasklist[];
	selectedTask?: ITasklist;
}

@State<TasklistStateModel>({
	name: 'tasklist',
	defaults: {
		items: null,
		selectedTask: null,
	},
})
@Injectable()
export class TasklistState extends AbstractApiState<
	ITasklist,
	ITasklistDTO,
	ITasklistEdit,
	ITasklistEditDTO
> {
	constructor(tasklistService: TasklistService, store: Store) {
		super(tasklistService, store);
	}

	@Selector()
	public static getState(state: TasklistStateModel) {
		return state;
	}

	@Selector()
	public static items(state: TasklistStateModel): ITasklist[] {
		return state.items;
	}

	@Selector()
	public static getSelected(state: TasklistStateModel): ITasklist {
		return state.selectedTask;
	}

	@Action(LoadAllTasklists)
	public loadAll(ctx: StateContext<TasklistStateModel>) {
		return super.loadAll(ctx);
	}

	@Action(LoadAllTasklistsIfEmpty)
	public loadAllIfEmpty(ctx: StateContext<TasklistStateModel>) {
		return super.loadAllIfEmpty(ctx);
	}

	@Action(CreateTasklist)
	public create(ctx: StateContext<TasklistStateModel>, data: CreateTasklist) {
		return super.create(ctx, data);
	}

	@Action(UpdateTasklist)
	public update(ctx: StateContext<TasklistStateModel>, data: UpdateTasklist) {
		return super.update(ctx, data);
	}

	@Action(DeleteTasklist)
	public delete(ctx: StateContext<TasklistStateModel>, data: DeleteTasklist) {
		return super.delete(ctx, data);
	}

	@Action(SelectTasklist)
	public selectTask(ctx: StateContext<TasklistStateModel>, { payload: { data } }: SelectTasklist) {
		ctx.setState(
			patch<TasklistStateModel>({
				selectedTask: data,
			})
		);
	}

	@Action(ClearSelectedTasklist)
	public clear(ctx: StateContext<TasklistStateModel>) {
		return ctx.patchState({ selectedTask: null });
	}

	@Action(TasklistCreateTask)
	public createTask(
		ctx: StateContext<TasklistStateModel>,
		{ payload: { data } }: TasklistCreateTask
	) {
		const selectedTask = ctx.getState().selectedTask;
		data._id = selectedTask?.tasks.length.toString();
		let item = null;

		if (!selectedTask?.tasks || !selectedTask?.tasks.length) {
			item = [data];
		} else {
			item = [...selectedTask.tasks, data];
		}

		ctx.patchState({
			selectedTask: {
				...selectedTask,
				tasks: item,
			},
		});
	}

	@Action(TasklistUpdateTask)
	public updateTask(ctx: StateContext<TasklistStateModel>, { payload }: TasklistUpdateTask) {
		const { index, data } = payload;
		const selectedTask = ctx.getState().selectedTask;

		if (!selectedTask.tasks || !selectedTask.tasks.length) {
			return;
		}

		const items = [...selectedTask.tasks];

		if (index === -1) {
			return;
		}

		items.splice(index, 1, data);

		ctx.patchState({
			selectedTask: {
				...selectedTask,
				tasks: items,
			},
		});
	}

	@Action(TasklistDeleteTask)
	public deleteTask(ctx: StateContext<TasklistStateModel>, { payload }: TasklistDeleteTask) {
		const { id } = payload;
		const selectedTask = ctx.getState().selectedTask;

		if (!selectedTask.tasks || !selectedTask.tasks.length) {
			return;
		}

		const items = [...selectedTask.tasks];

		if (!items || !items.length) {
			return;
		}

		const index = items.findIndex(item => item._id === id);

		if (index === -1) {
			return;
		}

		items.splice(index, 1);

		ctx.patchState({
			selectedTask: {
				...selectedTask,
				tasks: items,
			},
		});
	}
}
