<p class="header">
	<span class="counter">
		{{ day.format('DD') }}
	</span>

	<span class="label" *ngIf="day.date() === 1">
		{{ 'MONTHS.' + day.format('MMMM').toUpperCase() | translate }}
	</span>
</p>
<div class="content">
	<ng-content></ng-content>
</div>
