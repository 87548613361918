import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ResetLoading } from './../layout/layout.actions';
import { Logout } from './auth.action';
import { AuthState } from './auth.state';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private store: Store) {}

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = this.store.selectSnapshot(AuthState.token);
		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

		let headers = request.headers
			.append('accept', 'application/json')
			.append('X-Requested-With', 'XMLHttpRequest')
			.append('X-Timezone', timezone);

		if (!(request.body instanceof FormData)) {
			headers = headers.append('Content-Type', 'application/json');
		}

		if (token) {
			headers = headers.append('x-access-token', token);
		}

		const AuthRequest = request.clone({ headers });

		return next.handle(AuthRequest).pipe(catchError(e => this.catchError(e)));
	}

	private catchError(e: HttpErrorResponse): Observable<HttpEvent<null>> {
		const { status, error } = e;

		const isTokenExpired = status === 401 && error.message === 'TOKEN_EXPIRED';

		if (isTokenExpired) {
			this.store.dispatch(new Logout({ navigate: true }));
		}

		this.store.dispatch(ResetLoading);

		return throwError(() => new Error(e.message));
	}
}
