<div class="task-image">
	<itd-user-avatar
		[ngStyle]="{ 'width.px': width, 'height.px': height }"
		class="image"
		[user]="user"
		folder="user-images"
		[width]="width"
		[height]="height"
		[secondary]="secondary"
	></itd-user-avatar>
	<p *ngIf="showUserName" class="new-hire-name">{{ this.fullNameString }}</p>
	<div *ngIf="numberOfTasks" class="tasks" [ngClass]="{ secondary: secondary }">
		{{ numberOfTasks }}
	</div>
</div>
