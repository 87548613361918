import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AbstractApiService } from '../abstract/abstract-api-service';
import { ApiClientService } from '../api/api.service';
import { IBuddy, IBuddyDTO, IBuddyEdit, IBuddyEditDTO } from './buddy.interface';

@Injectable({
	providedIn: 'root',
})
export class BuddiesService extends AbstractApiService<
	IBuddy,
	IBuddyDTO,
	IBuddyEdit,
	IBuddyEditDTO
> {
	protected key = 'buddies';

	constructor(protected api: ApiClientService, protected store: Store) {
		super(api, store);
	}

	protected parse(value: IBuddyDTO): IBuddy {
		return {
			...value,
		};
	}

	protected parseEditDTO(value: IBuddyEdit): IBuddyEditDTO {
		return {
			...value,
		};
	}
}
