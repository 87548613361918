<div class="container">
	<div class="wrap wrap--default">
		<div class="inner">
			<div class="logos">
				<div class="logo org-logo">
					<itd-organization-logo sizeType="tiny"></itd-organization-logo>
				</div>
				<div class="logo user-avatar">
					<button class="profile" *ngIf="profile$ | async as profile" (click)="openSideDrawer()">
						<itd-user-avatar
							[user]="profile"
							folder="user-images"
							[width]="46"
							[height]="46"
						></itd-user-avatar>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

<itd-header-profile-side-drawer-mobile></itd-header-profile-side-drawer-mobile>
