<div
	class="container"
	[ngClass]="{
		'is-active': value,
		'is-focus': isFocus,
		'is-error': invalid,
		'is-warning': isWarning,
		hasShadow: hasShadow,
		hasSideLabel: sideLabel
	}"
>
	<label [ngClass]="{ label: label, sideLabel: sideLabel }" *ngIf="label || sideLabel">
		{{ label || sideLabel | translate }}
	</label>
	<div [ngClass]="{ hasLabel: label, hasBorder: hasBorder }">
		<ng-select
			class="input size-{{ sizeType }}"
			[ngStyle]="{ 'width.px': width }"
			autocomplete="off"
			[placeholder]="placeholder"
			[disabled]="isDisabled"
			bindLabel="name"
			bindValue="name"
			[addTag]="allowTagCreation"
			[multiple]="true"
			[clearable]="false"
			[hideSelected]="true"
			[trackByFn]="trackByFn"
			[minTermLength]="2"
			[loading]="tagsLoading"
			[typeToSearchText]="typeToSearchText"
			[typeahead]="tagsInput$"
			[items]="tags$ | async"
			[(ngModel)]="selectedTags"
			(change)="submitTags()"
		></ng-select>

		<div class="icon-reset" *ngIf="selectedTags.length > 0" (click)="clearSelectedTags()">
			<div class="clear-Icon ng-clear-wrapper" *ngIf="clearIcon"></div>
		</div>
	</div>

	<div *ngIf="message$ | async as message" class="input-group__validation">
		<div *ngIf="invalid" [innerHTML]="message.text"></div>
	</div>
</div>
