<div class="container">
	<div class="thumbnail" *ngIf="imageUrl">
		<itd-module-item-image [url]="imageUrl" [borderRadius]="24"></itd-module-item-image>
	</div>

	<h2 class="title">
		{{ data.title }}
	</h2>

	<div class="description" *ngIf="data.description">
		{{ data.description | truncate }}
	</div>

	<div class="footer">
		<div
			class="footer__left"
			[ngClass]="{ 'with-progress-bar': !newLearning, 'without-progress-bar': newLearning }"
		>
			<p class="meta">
				<span *ngIf="newLearning">{{ data.numberOfDoneModules }}</span>
				<span *ngIf="!newLearning">{{ data.numberOfDoneModules }}/{{ data.numberOfModules }}</span>
				{{ 'PARTS' | translate | lowercase }}
				<span class="meta--time" *ngIf="showTimeEstimate"
					>• {{ (data.estimatedCompletionTimeInSeconds / 60).toFixed() }}
					{{ 'MINUTES' | translate }}</span
				>
			</p>

			<div class="progress">
				<itd-progress-bar
					*ngIf="!newLearning"
					[value]="(data.numberOfDoneModules / data.numberOfModules) * 100"
				></itd-progress-bar>
			</div>
		</div>

		<div class="footer__right">
			<a
				*ngIf="!isTimelineOpen(data)"
				(click)="open(data.tl_id)"
				class="btn btn--color-primary btn--size-default"
			>
				{{ 'OPEN' | translate }}
			</a>
			<div *ngIf="isTimelineOpen(data)">
				<itd-icon icon="lock-keyhole" [width]="24" [height]="24"></itd-icon>
			</div>
		</div>
	</div>
</div>
