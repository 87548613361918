import { Directive, TemplateRef } from '@angular/core';
import { ICellData } from './cell-def.directive';

@Directive({
    selector: '[itdRowDef]',
    standalone: true
})
export class RowDefDirective<T> {
	// tslint:disable-next-line: no-any
	constructor(public template: TemplateRef<ICellData<T>>) {}
}
