import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { ModalComponent } from '../../../../../../utility-modules/modals/components/modal/modal.component';

@Component({
    selector: 'itd-invite-modal',
    templateUrl: './invite-modal.component.html',
    styleUrls: ['./invite-modal.component.scss'],
    standalone: true,
    imports: [
        ModalComponent,
        RouterLink,
        TranslateModule,
    ],
})
export class InviteModalComponent {
	constructor() {}
}
