import { Injectable } from '@angular/core';
import { ApiClientService } from '../api/api.service';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AbstractApiService } from '../abstract/abstract-api-service';
import { Tag } from './tag.interface';

@Injectable({
	providedIn: 'root',
})
export class TagService extends AbstractApiService<Tag, any, any, any> {
	protected key: string = 'tags';

	constructor(protected api: ApiClientService, store: Store) {
		super(api, store);
	}

	public search(query: string): Observable<string[]> {
		return this.api.post(`api/${this.key}/search`, { query });
	}

	public assignTags(entityType: string, entityId: string, tags: string[]): Observable<void> {
		return this.api.post(`api/${this.key}/${entityType}/${entityId}`, { tags });
	}

	parse(item: any): Tag {
		return item;
	}

	parseEditDTO(item: any): any {
		return item;
	}
}
