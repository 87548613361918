<button class="button button--prev" type="button" (click)="decrementMonth()">
	<itd-icon icon="chevron-left" [width]="4" [height]="7"></itd-icon>
</button>

<button class="button button--next" type="button" (click)="incrementMonth()">
	<itd-icon icon="chevron-left" [width]="4" [height]="7"></itd-icon>
</button>

<p class="title">
	{{ date.format('MMMM') | translate }} {{ date.format('YYYY') }}
</p>
