import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { AbstractApiState } from '../abstract/abstract-api-state';
import { ClearTasks, CreateTask, DeleteTask, LoadAllTasks, UpdateTask } from './task.action';
import { ITaskDTO, ITask, ITaskEdit, ITaskEditDTO } from './task.interface';
import { TaskService } from './task.service';
import { tap } from 'rxjs';

export interface TaskStateModel {
	items: ITask[];
}

@State<TaskStateModel>({
	name: 'task',
	defaults: {
		items: null,
	},
})
@Injectable()
export class TaskState extends AbstractApiState<ITask, ITaskDTO, ITaskEdit, ITaskEditDTO> {
	constructor(private taskService: TaskService, store: Store) {
		super(taskService, store);
	}

	@Selector()
	public static getState(state: TaskStateModel) {
		return state;
	}

	@Selector()
	public static items(state: TaskStateModel): ITask[] {
		return state.items;
	}

	@Action(LoadAllTasks)
	public LoadAllTasks(ctx: StateContext<TaskStateModel>) {
		return this.taskService.getAllTasks().pipe(
			tap(items => {
				ctx.patchState({
					items,
				});
			})
		);
	}

	@Action(CreateTask)
	public createTask(ctx: StateContext<TaskStateModel>, data: CreateTask) {
		return super.create(ctx, data);
	}

	@Action(UpdateTask)
	public updateTask(ctx: StateContext<TaskStateModel>, data: UpdateTask) {
		return super.update(ctx, data);
	}

	@Action(DeleteTask)
	public deleteTask(ctx: StateContext<TaskStateModel>, data: DeleteTask) {
		return super.delete(ctx, data);
	}

	@Action(ClearTasks)
	public clear(ctx: StateContext<TaskStateModel>) {
		return ctx.patchState({ items: null });
	}
}
