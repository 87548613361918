import { IHelpArticle } from './../../../core/help/help.interface';
import { Observable } from 'rxjs';
import { HelpState } from './../../../core/help/help.state';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GetAllHelpArticlesIfEmpty } from 'src/app/core/help/help.actions';
import { ProfileState } from 'src/app/core/profile/profile.state';
import { MarkdownModule } from 'ngx-markdown';
import { AccordionItemComponent } from '../../components/accordion-item/accordion-item.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MainTemplateComponent } from '../../templates/main-template/main-template.component';

@Component({
    selector: 'itd-help-center-page',
    templateUrl: './help-center-page.component.html',
    styleUrls: ['./help-center-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MainTemplateComponent,
        NgIf,
        NgFor,
        AccordionItemComponent,
        MarkdownModule,
        AsyncPipe,
    ],
})
export class HelpCenterPageComponent implements OnInit {
	@Select(HelpState.articles) public articles$: Observable<IHelpArticle[]>;
	public language: string = this.store.selectSnapshot(ProfileState.language);

	constructor(private store: Store) {}

	ngOnInit() {
		this.store.dispatch(GetAllHelpArticlesIfEmpty);
	}
}
