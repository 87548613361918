<itd-modal [size]="550">
	<ng-template #header> Preview - {{ typeOfPreview.toLowerCase() }} </ng-template>
	<ng-template #content>
		<div class="preview-example">
			<div *ngIf="typeOfPreview.toLowerCase() === 'sms'" class="mobile">
				<div class="phone-screen">
					<h4>{{ previewMessage.title }}</h4>
					<div [innerHtml]="previewMessage.message"></div>
				</div>
			</div>
			<div *ngIf="typeOfPreview.toLowerCase() === 'email'" class="email">
				<itd-organization-logo></itd-organization-logo>
				<div class="message-content" [innerHtml]="previewMessage.message"></div>
				<div *ngIf="previewMessage.btnText" class="email-button">
					<button class="btn">{{ previewMessage.btnText }}</button>
				</div>
			</div>
		</div>
	</ng-template>
</itd-modal>
