import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from './components/icon/icon.component';
import { InlineSVGModule } from 'ng-inline-svg-2';

@NgModule({
    imports: [CommonModule, InlineSVGModule.forRoot(), IconComponent],
    exports: [IconComponent],
})
export class IconModule {}
