import { Dayjs } from 'dayjs';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'itd-calendar-day',
    templateUrl: './calendar-day.component.html',
    styleUrls: ['./calendar-day.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, TranslateModule],
})
export class CalendarDayComponent implements OnInit {
	@HostBinding('class.isFade')
	@Input()
	public isFade: boolean;

	@HostBinding('class.isFocus')
	@Input()
	public isFocus: boolean;

	@Input() public day: Dayjs;

	constructor() {}

	ngOnInit(): void {}
}
