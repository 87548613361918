<h1 class="title" *ngIf="title" [ngClass]="{ 'small-padding': headerText }">
	{{ title + '.HEADER' | translate }}
</h1>

<div id="topSpacerNoTitle" *ngIf="!title && !noTopSpacing"></div>

<div class="back-button" *ngIf="backPath">
	<itd-button-back (click)="goBackClick(backPath)"></itd-button-back>
</div>

<div class="header" *ngIf="hasHeader">
	<div class="header__wrapper">
		<div
			class="header__column header__left"
			*ngIf="(headerLeft || subNavigation.length) && !headerText"
		>
			<itd-page-select [items]="subNavigation" *ngIf="subNavigation.length > 1"></itd-page-select>

			<ng-container *ngTemplateOutlet="headerLeft"></ng-container>
		</div>

		<div class="header__column header__right" *ngIf="headerRight">
			<ng-container *ngTemplateOutlet="headerRight"></ng-container>
		</div>
	</div>
</div>

<div class="main" *ngIf="main">
	<ng-container *ngTemplateOutlet="main"></ng-container>
</div>
