import { Observable } from 'rxjs';
import { ProfileState } from '../../../core/profile/profile.state';
import { Component } from '@angular/core';
import { IProfile } from 'src/app/core/profile/profile.interface';
import { Select, Store } from '@ngxs/store';
import { SetMobileHeaderProfileDrawer } from 'src/app/core/layout/children/layout-mobile.actions';
import { HeaderProfileSideDrawerMobileComponent } from './header-profile-side-drawer-mobile/header-profile-side-drawer-mobile.component';
import { UserAvatarComponent } from '../user-avatar/user-avatar.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { OrganizationLogoComponent } from '../organization-logo/organization-logo.component';

@Component({
    selector: 'itd-header-mobile',
    templateUrl: './header-mobile.component.html',
    styleUrls: ['./header-mobile.component.scss'],
    standalone: true,
    imports: [
        OrganizationLogoComponent,
        NgIf,
        UserAvatarComponent,
        HeaderProfileSideDrawerMobileComponent,
        AsyncPipe,
    ],
})
export class HeaderMobileComponent {
	@Select(ProfileState.profile) public profile$: Observable<IProfile>;

	constructor(private store: Store) {}

	public openSideDrawer() {
		this.store.dispatch(new SetMobileHeaderProfileDrawer({ isActive: true }));
	}
}
