import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AbstractApiService } from '../abstract/abstract-api-service';
import { ApiClientService } from '../api/api.service';
import { ITasklist, ITasklistDTO, ITasklistEdit, ITasklistEditDTO } from './tasklist.interface';

@Injectable({
	providedIn: 'root',
})
export class TasklistService extends AbstractApiService<
	ITasklist,
	ITasklistDTO,
	ITasklistEdit,
	ITasklistEditDTO
> {
	protected key = 'tasklists';

	constructor(protected api: ApiClientService, protected store: Store) {
		super(api, store);
	}

	protected parse(value: ITasklistDTO): ITasklist {
		const { _id, createdAt, updatedAt, ...rest } = value;
		return {
			...rest,
			id: _id,
			createdAt: new Date(createdAt),
			updatedAt: new Date(updatedAt),
		};
	}

	public parseEditDTO(value: ITasklistEdit): ITasklistEditDTO {
		const { id, ...rest } = value;
		return {
			...rest,
			_id: id,
		};
	}
}
