import { filter, map, takeUntil } from 'rxjs/operators';
import {
	OnInit,
	AfterViewInit,
	Input,
	ViewChild,
	ChangeDetectorRef,
	Component,
} from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { WithDestroyComponent } from 'src/app/core/abstract/abstract-with-destroy-component';
import { InputComponent } from 'src/app/utility-modules/itd-form/components/input/input.component';

@Component({
	template: '',
})
export abstract class AbstractAddModalComponent<I extends { id: string }>
	extends WithDestroyComponent
	implements OnInit, AfterViewInit {
	public abstract key: string;
	public abstract departmentFoldersActive: boolean;
	protected abstract items$: Observable<I[]>;
	public items: I[];

	public startingData: I[] = null;
	public isSearching: boolean = false;
	public filteredData$: Observable<I[]>;

	public form: FormGroup;

	@ViewChild(InputComponent, { static: false })
	public input: InputComponent;

	@Input() data: {
		hideIds?: string[];
	};

	@Input() public onSave: (value: I[]) => Observable<void> | void;

	public get hideIds(): string[] {
		return this.data.hideIds;
	}

	public get searchValue(): string {
		const value = this.input.value;

		return value ? value : '';
	}

	public get itemsFormArray(): FormArray {
		return this.form.get('items') as FormArray;
	}

	constructor(public activeModal: NgbActiveModal, public cd: ChangeDetectorRef) {
		super();
	}

	ngOnInit(): void {
		this.form = new FormGroup({
			items: new FormArray([]),
		});

		this.items$
			.pipe(takeUntil(this.destroy$))
			.pipe(
				filter(items => !!items),
				map(items => items.filter(item => !this.hideIds.includes(`${item.id}`)))
			)
			.subscribe(v => {
				this.items = v;
				this.startingData = v;
				this.cd.detectChanges();
			});
	}

	public ngAfterViewInit() {
		setTimeout(() => {
			if (this.input) {
				this.input.inputRef.nativeElement.focus();

				this.onLoad();
			}
		});
	}

	public onSubmitAction() {
		const selectedModules = this.itemsFormArray.value;
		this.onSave(selectedModules);

		this.activeModal.close();
	}

	public prepareData(data) {
		this.items = data;
		this.cd.detectChanges();
	}

	public searchActivated(searchInput) {
		if (searchInput === '') {
			this.isSearching = false;
		} else {
			this.isSearching = true;
			this.prepareData(this.startingData);
		}
	}

	public renderNewData(data) {
		this.prepareData(data);
	}

	protected abstract onLoad: () => Observable<void>;
}
