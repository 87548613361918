import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs';
import { AbstractApiState } from '../abstract/abstract-api-state';
import {
	DeleteNetworkGroup,
	LoadAllNetworkGroups,
	LoadAllNetworkGroupsIfEmpty,
	LoadMyUnreadMessages,
} from './network.action';
import {} from './network.interface';
import { NetworkService } from './network.service';

export interface NetworkStateModel {
	items: any[];
	unread?: any[];
}

@State<NetworkStateModel>({
	name: 'network',
	defaults: {
		items: null,
		unread: null,
	},
})
@Injectable()
export class NetworkState extends AbstractApiState<any> {
	constructor(protected network: NetworkService, store: Store) {
		super(network, store);
	}

	@Selector()
	public static getState(state: NetworkStateModel) {
		return state;
	}

	@Selector()
	public static items(state: NetworkStateModel) {
		return state.items;
	}

	@Selector()
	public static unread(state: NetworkStateModel) {
		return state.unread;
	}

	@Action(LoadAllNetworkGroups)
	public loadAll(ctx: StateContext<NetworkStateModel>, data: LoadAllNetworkGroups) {
		return super.loadAll(ctx, data.payload);
	}

	@Action(LoadAllNetworkGroupsIfEmpty)
	public loadAllIfEmpty(ctx: StateContext<NetworkStateModel>) {
		return super.loadAllIfEmpty(ctx);
	}

	@Action(DeleteNetworkGroup)
	public delete(ctx: StateContext<NetworkStateModel>, data: DeleteNetworkGroup) {
		return super.delete(ctx, data).pipe(
			tap(() => {
				ctx.patchState({
					items: ctx.getState().items.filter(item => item._id !== data.payload.id),
				});
			})
		);
	}

	@Action(LoadMyUnreadMessages)
	public getMyUnreadMessages(ctx: StateContext<NetworkStateModel>) {
		return this.network.getMyUnreadMessages().pipe(
			tap(responseItems => {
				ctx.patchState({
					unread: responseItems,
				});
			})
		);
	}
}
