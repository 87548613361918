import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { AbstractApiService } from '../abstract/abstract-api-service';
import { ApiClientService } from '../api/api.service';
import { TResults } from '../models/results.interface';
import {
	ITasklistAssignment,
	ITasklistAssignmentDTO,
	ITasklistAssignmentEdit,
	ITasklistAssignmentEditDTO,
} from './tasklist-assignment.interface';

@Injectable({
	providedIn: 'root',
})
export class TasklistAssignmentService extends AbstractApiService<
	ITasklistAssignment,
	ITasklistAssignmentDTO,
	ITasklistAssignmentEdit,
	ITasklistAssignmentEditDTO
> {
	protected key = 'tasklist-assignments';

	constructor(protected api: ApiClientService, protected store: Store) {
		super(api, store);
	}

	public getSelectedAssignment(userId: string): Observable<ITasklistAssignment> {
		return this.api.get<TResults<any>>(`api/${this.key}/by-user/${userId}`).pipe(
			map(response => {
				return this.parse(response.data);
			})
		);
	}

	public getMyAssignments(): Observable<ITasklistAssignment[]> {
		return this.api.get<TResults<any>>(`api/${this.key}/me`).pipe(
			map(response => {
				return response.data;
			})
		);
	}

	public getTasklistAssignmentById(id: string): Observable<ITasklistAssignment[]> {
		return this.api.get<TResults<any>>(`api/v2/analytics/tasklist-assignment/${id}`).pipe(
			map(response => {
				return response.data;
			})
		);
	}

	protected parse(value: ITasklistAssignmentDTO): ITasklistAssignment {
		const { _id, ...rest } = value;

		return {
			...rest,
			id: value._id,
		};
	}

	public parseEditDTO(value: ITasklistAssignmentEdit): ITasklistAssignmentEditDTO {
		// TODO: We can refactor this to send a more minimalistic 'tasks' array to backend. Would require refactoring interfaces too
		const { id, ...rest } = value;

		return {
			...rest,
			_id: value.id,
		};
	}
}
