import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import * as dayjs from 'dayjs';
import { combineLatest, map, Observable } from 'rxjs';
import {
	LoadAllSurveyAnswers,
	LoadSurvey,
	LoadSurveyStats,
} from 'src/app/core/surveys/surveys.action';
import { ISurveyAnswerDTO } from 'src/app/core/surveys/surveys.interface';
import { SurveysState } from 'src/app/core/surveys/surveys.state';
import { TimelineSurveyResultsComponent } from '../../components/timeline-survey-results/timeline-survey-results.component';
import { ModalComponent } from '../../../utility-modules/modals/components/modal/modal.component';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'itd-survey-results-modal',
    templateUrl: './survey-results-modal.component.html',
    styleUrls: ['./survey-results-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        ModalComponent,
        TimelineSurveyResultsComponent,
        AsyncPipe,
    ],
})
export class SurveyResultsModal implements OnInit {
	@Input() surveyId: any;
	@Input() userId: any;

	@Select(SurveysState.allSurveyAnswers) public allSurveyAnswers$: Observable<ISurveyAnswerDTO[]>;
	@Select(SurveysState.surveyStats) public surveyStats$: Observable<any>;
	@Select(SurveysState.activeSurvey) public survey$: Observable<any>;

	results$: Observable<any>;

	constructor(public activeModal: NgbActiveModal, private store: Store) {}

	public ngOnInit() {
		this.store.dispatch([
			new LoadAllSurveyAnswers({ id: this.surveyId }),
			new LoadSurveyStats({ id: this.surveyId }),
			new LoadSurvey({ id: this.surveyId }),
		]);
		this.results$ = combineLatest([this.survey$, this.allSurveyAnswers$, this.surveyStats$]).pipe(
			map(([survey, allSurveyAnswers, surveyStats]) => {
				if (survey && allSurveyAnswers?.length && surveyStats?.length) {
					let data = allSurveyAnswers.map(ans => {
						if (ans.user_id !== this.userId) {
							return null;
						}

						let mappedActiveSurveyQuestions = survey.questions.map(q => {
							// Set question to Labeled questions, so they are shown nicely on the results page
							if (q.type === 'OPINION_SCALE') {
								let labels = [];
								for (let i = 0; i <= 10; i++) {
									labels.push(`${i}`);
								}
								return {
									...q,
									labels,
								};
							} else {
								let labels = [];
								for (let i = 0; i < q.labels.length; i++) {
									labels.push(`${q.labels[i]}`);
								}
								return {
									...q,
									labels,
								};
							}
						});

						return {
							activeSurvey: { ...survey, questions: mappedActiveSurveyQuestions },
							surveyAnswers: ans.survey_answers,
							dateOfCompletion: dayjs(ans.updatedAt).format('YYYY-MM-DD'),
							id: ans._id,
						};
					});
					let filteredData = data.find(element => element !== null);

					return filteredData;
				}
			})
		);
	}
}
