import { LogoutModalComponent } from './../../modals/logout-modal/logout-modal.component';
import { ProfileState } from 'src/app/core/profile/profile.state';
import { Store } from '@ngxs/store';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import {
	INavigationItem,
	NavigationItemComponent,
} from '../navigation-item/navigation-item.component';
import { IOrganization } from 'src/app/core/organizations/organizations.interface';
import { OrganizationState } from 'src/app/core/organizations/organizations.state';
import { SidebarModuleSwitcherComponent } from './sidebar-module-switcher/sidebar-module-switcher.component';
import { HeadwayWidgetBadgeComponent } from './headway-widget/headway-widget-badge/headway-widget-badge.component';
import { HeadwayWidgetTriggerComponent } from './headway-widget/headway-widget-trigger/headway-widget-trigger.component';
import { NgFor, NgIf } from '@angular/common';
import { OrganizationLogoComponent } from '../organization-logo/organization-logo.component';

@Component({
	selector: 'itd-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	standalone: true,
	imports: [
		OrganizationLogoComponent,
		NgFor,
		NavigationItemComponent,
		NgIf,
		HeadwayWidgetTriggerComponent,
		HeadwayWidgetBadgeComponent,
		SidebarModuleSwitcherComponent,
	],
})
export class SidebarComponent implements OnInit {
	public navigation: INavigationItem[];
	public secondNavigation: INavigationItem[];
	public isAdmin: boolean;
	public isSuperAdmin = false;
	private organization: IOrganization;

	constructor(private modals: NgbModal, private store: Store, private route: ActivatedRoute) {}

	public async ngOnInit() {
		this.organization = this.store.selectSnapshot<IOrganization>(OrganizationState.organization);
		const { navigation, secondNavigation, permissions } = this.route.snapshot.data;

		const userRole = this.store.selectSnapshot(ProfileState.role);

		this.isAdmin = userRole !== 'std_user';
		this.isSuperAdmin = userRole === 'super_admin';
		this.navigation = navigation;
		if (permissions?.length) {
			this.navigation = this.navigation?.filter(item => {
				const permission = permissions.find(perm => perm.title === item.title);
				if (permission) {
					return this.organization[permission.permissionName];
				} else return true;
			});
		}

		this.secondNavigation = secondNavigation?.filter((item: INavigationItem) => {
			if (item.isForAdmin) {
				return this.isAdmin;
			}

			return true;
		});
	}

	public logout() {
		this.modals.open(LogoutModalComponent);
	}
}
