<form [formGroup]="form" (ngSubmit)="onSubmit()">
	<div class="inputs">
		<div class="fasttrack" *ngIf="(organization$ | async)?.has_fasttrack">
			<p class="grid-label">{{ 'SETTINGS.ALERTS.INDEX_LABEL' | translate }}</p>
			<itd-grid>
				<itd-grid-item size="50%" [mobileResponsive]="true">
					<itd-checkbox name="index_under" formControlName="index_under" justifyContent="center">
						{{ 'SETTINGS.ALERTS.INDEX_UNDER_THRESHOLD' | translate }}
					</itd-checkbox>
					<itd-input
						[disabled]="isChecked('index_under')"
						type="number"
						name="index_under_threshold"
						formControlName="index_under_threshold"
						placeholder="20"
					>
					</itd-input>
				</itd-grid-item>

				<itd-grid-item size="50%" [mobileResponsive]="true">
					<itd-checkbox name="index_over" formControlName="index_over" justifyContent="center">
						{{ 'SETTINGS.ALERTS.INDEX_OVER_THRESHOLD' | translate }}
					</itd-checkbox>
					<itd-input
						[disabled]="isChecked('index_over')"
						type="number"
						name="index_over_threshold"
						formControlName="index_over_threshold"
						placeholder="80"
					>
					</itd-input>
				</itd-grid-item>
			</itd-grid>
			<p class="grid-label">{{ 'SETTINGS.ALERTS.NOT_ANSWERED_LABEL' | translate }}</p>
			<itd-grid>
				<itd-grid-item size="50%" [mobileResponsive]="true">
					<itd-checkbox
						tooltipPosition="right"
						name="not_answered"
						formControlName="not_answered"
						justifyContent="center"
						label="SETTINGS.ALERTS.NOT_ANSWERED_LABEL"
					>
						{{ 'SETTINGS.ALERTS.NOT_ANSWERED' | translate }}
					</itd-checkbox>
				</itd-grid-item>
			</itd-grid>
			<!-- TODO: Add checkboxes for current department and below -->
			<p class="grid-label">{{ 'SETTINGS.ALERTS.DEPARTMENTS_LABEL' | translate }}</p>
			<itd-grid>
				<itd-grid-item [mobileResponsive]="true">
					<div *ngFor="let department of departments">
						{{ department.title }}
					</div>
				</itd-grid-item>
			</itd-grid>
		</div>

		<p class="grid-label">{{ 'SETTINGS.ALERTS.NOTIFY_HOW' | translate }}</p>
		<itd-grid class="notification-types">
			<itd-grid-item size="20%" [mobileResponsive]="true">
				<itd-icon icon="envelope" [width]="32" [height]="32"></itd-icon>
				<itd-checkbox name="receive_email" formControlName="receive_email" justifyContent="center">
					{{ 'BY_EMAIL' | translate }}
				</itd-checkbox>
			</itd-grid-item>

			<itd-grid-item size="20%" [mobileResponsive]="true">
				<itd-icon icon="smartphone" [width]="32" [height]="32"></itd-icon>
				<itd-checkbox name="receive_sms" formControlName="receive_sms" justifyContent="center">
					{{ 'BY_SMS' | translate }}
				</itd-checkbox>
			</itd-grid-item>
		</itd-grid>

		<itd-form-error [errorObservable]="errorMessage$"></itd-form-error>
	</div>

	<div class="footer row">
		<div>
			<itd-button
				sizeType="default"
				[width]="90"
				[formState]="formState"
				[enableAfterSuccess]="true"
			>
				{{ 'UPDATE' | translate }}
			</itd-button>
		</div>
	</div>
</form>
