import {
	Component,
	OnInit,
	Input,
	ViewChild,
	ElementRef,
	Optional,
	Output,
	EventEmitter,
	ChangeDetectionStrategy,
} from '@angular/core';
import { ControlValueAccessor, NgControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { AbstractInputComponent } from '../abstract-input/abstract-input.component';
import { SearchCountryField, CountryISO, NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgStyle, NgIf } from '@angular/common';

@Component({
	selector: 'itd-input-phone',
	templateUrl: './input-phone.component.html',
	styleUrls: ['./input-phone.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		NgClass,
		NgStyle,
		NgIf,
		NgxIntlTelInputModule,
		ReactiveFormsModule,
		FormsModule,
		TranslateModule,
	],
})
export class InputPhoneComponent extends AbstractInputComponent<string>
	implements OnInit, ControlValueAccessor {
	@Input() public minWidth?: number = 0;
	@Input() public maxWidth?: number;

	@Input() public label?: string;
	@Input() public disabledKeys: Array<string | RegExp> = [];
	@Input() public allowedKeys: Array<string | RegExp> = [];
	@Input() public height?: number;
	@Input() public isInline?: boolean;
	@Input() public isRequired: boolean = false;

	@Input() public controlInvalid?: boolean = false;
	@Input() public invalidMessage?: string = 'This field is required';

	@Input() public maxLength?: number;
	@Input() public labelMaxValueLengthType: 'default' | 'hidden' | 'warning' = 'default';

	@Output() public inputkey = new EventEmitter<void>();
	@Output() public onDelete = new EventEmitter<void>();
	@Output() public onSend = new EventEmitter<void>();

	@ViewChild('input', { static: false }) public inputRef: ElementRef<HTMLInputElement>;

	public preferredCountriesList: CountryISO[] = [
		CountryISO.Denmark,
		CountryISO.UnitedStates,
		CountryISO.UnitedKingdom,
	];
	public SearchCountryField = SearchCountryField;

	public ngOnInit(): void {
		super.ngOnInit();
	}

	public get hasMaxValueLengthLabel(): boolean {
		if (this.labelMaxValueLengthType !== 'hidden') {
			if (this.labelMaxValueLengthType === 'warning') {
				return this.hasMaxValueLengthWarning;
			}

			return true;
		}

		return;
	}

	public get hasMaxValueLengthWarning(): boolean {
		if (!this.maxLength || !this.actualLength) {
			return;
		}

		return this.maxLength - this.actualLength <= 10;
	}

	constructor(@Optional() control: NgControl, validationMessages: ValidationMessagesService) {
		super(control, validationMessages);
	}

	public onModelChange(value: any): void {
		if (!value) return;
		this.writeValue(value.e164Number.toString());
	}

	public writeValue(value: string): void {
		if (value) {
			value = value.toString();
			value = this.parseValue(value);
		}

		const hasRenderedElement = !!this.inputRef && !!this.inputRef.nativeElement;

		if (hasRenderedElement) {
			this.inputRef.nativeElement.value = value || '';
		}

		super.writeValue(value);
	}

	public parseNewKey(event: KeyboardEvent): boolean {
		const key = event.key;
		const ignoredKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Del', 'Enter'];

		if (ignoredKeys.indexOf(key) !== -1 || event.ctrlKey) {
			return true;
		}

		const results: boolean[] = [];
		const disabled = [...[], ...this.disabledKeys];

		if (disabled.length > 0) {
			results.push(...disabled.map(s => new RegExp(s as string, 'g').test(key) === false));
		}

		this.inputkey.emit();
		return results.indexOf(false) === -1;
	}

	public parseValue(value: string): string {
		const allowed = [...this.allowedKeys];
		const disabled = [...this.disabledKeys];

		if (allowed) {
			for (const allowedChar of allowed) {
				const reg = typeof allowedChar === 'string' ? new RegExp(allowedChar, 'g') : allowedChar;
				const match = value.match(reg);

				if (match) {
					value = match.join('');
				}
			}
		}

		if (disabled.length > 0) {
			for (const disabledChar of disabled) {
				const reg = new RegExp(disabledChar as string, 'g');
				value = value.replace(reg, '');
			}
		}

		if (this.maxLength && value) {
			value = value.substring(0, this.maxLength);
		}

		return value;
	}

	public clear(): void {
		this.value = '';
		this.writeValue(this.value);

		if (this.inputRef && this.inputRef.nativeElement) {
			this.inputRef.nativeElement.focus();
		}
	}

	public setFocus(isFocus: boolean): void {
		super.setFocus(isFocus);
	}

	public clickDelete() {
		this.onDelete.emit();
	}

	public clickSend() {
		this.onSend.emit();
	}
}
