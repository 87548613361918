import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import * as dayjs from 'dayjs';
import { lastValueFrom, Observable } from 'rxjs';
import { ILanguage } from 'src/app/core/models/language.interface';
import { UpdateProfile } from 'src/app/core/profile/profile.actions';
import { IProfile, IUpdateProfile } from 'src/app/core/profile/profile.interface';
import { ProfileState } from 'src/app/core/profile/profile.state';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { emailValidator } from 'src/app/core/validators/email.validator';
import { AbstractFormComponent } from 'src/app/utility-modules/itd-form/components/abstract-form/abstract-form.component';
import { NotificationService } from 'src/app/utility-modules/notification/services/notification.service';
import { DictionaryLoadLanguagesIfEmpty } from '../../../../../core/dictionary/dictionary.actions';
import { DictionaryState } from '../../../../../core/dictionary/dictionary.state';
import { ProfileSettingsChangePasswordComponent } from '../profile-settings-change-password/profile-settings-change-password.component';
import { ButtonComponent } from '../../../../../utility-modules/itd-form/components/button/button.component';
import { FormErrorComponent } from '../../../../../utility-modules/itd-form/components/form-error/form-error.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { SelectComponent } from '../../../../../utility-modules/itd-form/components/select/select.component';
import { InputDateComponent } from '../../../../../utility-modules/itd-form/components/input-date/input-date.component';
import { InputPhoneComponent } from '../../../../../utility-modules/itd-form/components/input-phone/input-phone.component';
import { InputComponent } from '../../../../../utility-modules/itd-form/components/input/input.component';
import { GridItemComponent } from '../../../../../utility-modules/grid/components/grid-item/grid-item.component';
import { GridComponent } from '../../../../../utility-modules/grid/components/grid/grid.component';

interface IFormValues {
	first_name: string;
	last_name: string;
	email: string;
	phone_number: string;
	job_title: string;
	start_date?: Date;
	lang: string;
}

@Component({
    selector: 'itd-profile-settings-edit-form',
    templateUrl: './profile-settings-edit-form.component.html',
    styleUrls: ['./profile-settings-edit-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ReactiveFormsModule,
        GridComponent,
        GridItemComponent,
        InputComponent,
        InputPhoneComponent,
        InputDateComponent,
        SelectComponent,
        NgIf,
        FormErrorComponent,
        ButtonComponent,
        AsyncPipe,
        TranslateModule,
    ],
})
export class ProfileSettingsEditFormComponent extends AbstractFormComponent<IFormValues>
	implements OnInit {
	public profile: IProfile;

	public form = new FormGroup({
		first_name: new FormControl('', [Validators.required]),
		last_name: new FormControl('', [Validators.required]),
		email: new FormControl('', [Validators.required, emailValidator]),
		phone_number: new FormControl(''),
		job_title: new FormControl(''),
		start_date: new FormControl(dayjs().toDate()),
		lang: new FormControl('', [Validators.required]),
	});

	@Select(ProfileState.profile) public profile$: Observable<IProfile>;
	@Select(DictionaryState.languages) public languages$: Observable<ILanguage[]>;

	constructor(
		public store: Store,
		public validationMessages: ValidationMessagesService,
		public elementRef: ElementRef,
		public cd: ChangeDetectorRef,
		private notification: NotificationService,
		private modals: NgbModal,
		private translate: TranslateService
	) {
		super(cd);
	}

	public async ngOnInit(): Promise<void> {
		this.store.dispatch(DictionaryLoadLanguagesIfEmpty);

		const profile = this.store.selectSnapshot(ProfileState.profile);

		this.profile = profile;
		this.form.patchValue(this.profile);
		super.afterInit();
	}

	public onSubmitAction() {
		const profile = this.parseData(this.formValue);
		const profileSnapshot = this.store.selectSnapshot(ProfileState.profile);

		if (profile.email === profileSnapshot.email) delete profile.email;

		// if (profile.phone_number === profileSnapshot.phone_number || profile.phone_number === '+')
		// 	delete profile.phone_number;
		return this.store.dispatch(new UpdateProfile({ profile }));
	}

	public async onSubmitSuccess() {
		const text = await lastValueFrom(this.translate.get(`BACKEND_MESSAGES.USER_UPDATE_SUCCESS`));

		this.notification.add({
			text,
		});
	}

	private parseData(formValue: IFormValues): IUpdateProfile {
		return {
			...formValue,
		};
	}

	public changePassword() {
		this.modals.open(ProfileSettingsChangePasswordComponent);
	}

	public prependCountryCodeIndicator() {
		if (this.formValue.phone_number.indexOf('+') !== 0) {
			this.form.patchValue({
				phone_number: '+' + this.formValue.phone_number,
			});
		}
	}
}
