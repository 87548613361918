import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { ColorPickerModule } from '@iplab/ngx-color-picker';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { DpDatePickerModule } from 'ng2-date-picker';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { QuillModule } from 'ngx-quill';
import { LoadingDirective } from 'src/app/shared/directives/loading.directive';
import { IconModule } from '../icon/icon.module';
import { ButtonComponent } from './components/button/button.component';
import { CheckboxListLabelDirective } from './components/checkbox-list/checkbox-list-label.directive';
import { CheckboxListComponent } from './components/checkbox-list/checkbox-list.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { FormErrorComponent } from './components/form-error/form-error.component';
import { InputColorPickerComponent } from './components/input-color-picker/input-color-picker.component';
import {
	InputCustomRadioComponent,
	InputCustomRadioDirective,
} from './components/input-custom-radio/input-custom-radio.component';
import { InputDateComponent } from './components/input-date/input-date.component';
import { InputFileComponent } from './components/input-file/input-file.component';
import { InputUserComponent } from './components/input-user/input-user.component';
import { InputPhoneComponent } from './components/input-phone/input-phone.component';
import { InputRangeComponent } from './components/input-range/input-range.component';
import { InputTogglerComponent } from './components/input-toggler/input-toggler.component';
import { InputComponent } from './components/input/input.component';
import { SelectComponent } from './components/select/select.component';
import { WysiwygComponent } from './components/wysiwyg/wysiwyg.component';
import { DropDirective } from './directives/drop.directive';

const declarations = [
	LoadingDirective,
	CheckboxComponent,
	InputRangeComponent,
	InputComponent,
	InputPhoneComponent,
	ButtonComponent,
	FormErrorComponent,
	SelectComponent,
	InputDateComponent,
	WysiwygComponent,
	CheckboxListComponent,
	CheckboxListLabelDirective,
	InputCustomRadioComponent,
	InputCustomRadioDirective,
	InputFileComponent,
	InputUserComponent,
	DropDirective,
	InputTogglerComponent,
	InputColorPickerComponent,
];

const modules = [DragDropModule];

@NgModule({
	imports: [
		...modules,
		ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
		FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
		CommonModule,
		TranslateModule,
		MatSliderModule,
		IconModule,
		NgSelectModule,
		DpDatePickerModule,
		QuillModule.forRoot(),
		ColorPickerModule,
		NgxIntlTelInputModule,
		...declarations,
	],
	exports: [...declarations, ...modules],
})
export class ItdFormModule {}
