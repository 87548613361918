import { TemplateRef } from '@angular/core';
import { ContentChild } from '@angular/core';
import { Component, HostBinding, Output, EventEmitter } from '@angular/core';
import { fromRightAnimation } from 'src/app/core/animations/from-right.animation';
import { OrganizationLogoComponent } from '../organization-logo/organization-logo.component';
import { IconComponent } from '../../../utility-modules/icon/components/icon/icon.component';
import { NgTemplateOutlet } from '@angular/common';

@Component({
	selector: 'itd-side-drawer',
	templateUrl: './side-drawer.component.html',
	styleUrls: ['./side-drawer.component.scss'],
	animations: [fromRightAnimation],
	standalone: true,
	imports: [NgTemplateOutlet, IconComponent, OrganizationLogoComponent],
})
export class SideDrawerComponent {
	@ContentChild('header') header: TemplateRef<any>;
	@ContentChild('body') body: TemplateRef<any>;
	@ContentChild('footer') footer: TemplateRef<any>;

	@Output() public onClose = new EventEmitter();

	@HostBinding('@fromRightAnimation')
	public _() {}

	constructor() {}
}
