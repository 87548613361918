import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'itd-dot-status',
    templateUrl: './dot-status.component.html',
    styleUrls: ['./dot-status.component.scss'],
    standalone: true,
    imports: [NgClass, TranslateModule],
})
export class DotStatusComponent {
	@Input() public isActive: boolean;
}
