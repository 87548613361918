import { AfterViewInit, Component, OnInit, Optional, Input } from '@angular/core';
import { ControlValueAccessor, NgControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ContentChange, QuillToolbarConfig, QuillEditorComponent } from 'ngx-quill';
import * as QuillNamespace from 'quill';
let Quill: any = QuillNamespace;
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { AbstractInputComponent } from '../abstract-input/abstract-input.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgIf } from '@angular/common';

@Component({
    selector: 'itd-wysiwyg',
    templateUrl: './wysiwyg.component.html',
    styleUrls: ['./wysiwyg.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        QuillEditorComponent,
        ReactiveFormsModule,
        FormsModule,
        NgxIntlTelInputModule,
    ],
})
export class WysiwygComponent extends AbstractInputComponent<string>
	implements OnInit, AfterViewInit, ControlValueAccessor {
	@Input() public toolbar: QuillToolbarConfig;

	constructor(@Optional() control: NgControl, validationMessages: ValidationMessagesService) {
		super(control, validationMessages);
		this.configureQuill();
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

	private configureQuill() {
		const SizeStyle = Quill.import('attributors/style/size');
		const Alignment = Quill.import('attributors/style/align');
		Quill.register(SizeStyle, true);
		Quill.register(Alignment, true);
		// An example of how we might implement our custom fonts:
		// const FontAttributor = Quill.import('attributors/class/font');
		// FontAttributor.whitelist = [
		// 	'sofia', 'slabo', 'roboto', 'inconsolata', 'ubuntu'
		// ];
		// Quill.register(FontAttributor, true);
	}

	public modelChange(value: ContentChange): void {
		this.writeValue(value.html);
	}
}
