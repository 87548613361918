import { Directive, Output, HostListener, EventEmitter } from '@angular/core';

@Directive({
	selector: '[itdDrop]',
	standalone: true,
})
export class DropDirective {
	@Output() onFileDropped = new EventEmitter<File[]>();
	@Output() onChangeDragStatus = new EventEmitter<boolean>();

	@HostListener('dragover', ['$event']) public dragOver(e: Event) {
		e.preventDefault();
		e.stopPropagation();

		this.onChangeDragStatus.emit(true);
	}

	@HostListener('dragleave', ['$event']) public dragLeave(e: Event) {
		e.preventDefault();
		e.stopPropagation();

		this.onChangeDragStatus.emit(false);
	}

	@HostListener('drop', ['$event']) public drop(e: CustomEvent & { dataTransfer?: DataTransfer }) {
		e.preventDefault();
		e.stopPropagation();

		this.onChangeDragStatus.emit(false);

		const { files } = e.dataTransfer;

		if (!files.length) {
			return;
		}

		this.onFileDropped.emit(Array.from(files));
	}
}
