export class DataSourceSorter<T> {
	private currentSortName: string;
	private currentSortDirection: 'ASC' | 'DESC';
	private sorters: Map<string, (a: T, b: T) => number> = new Map();

	public get currentSort(): string {
		return this.currentSortName;
	}

	constructor() {}

	public addSortType(name: string, fn: (a: T, b: T) => number) {
		this.sorters.set(name, fn);
	}

	public sortBy(data: T[], name: string, direction: 'ASC' | 'DESC'): T[] {
		this.currentSortName = name;
		this.currentSortDirection = direction;

		return this.sort(data);
	}

	private sort(data: T[]): T[] {
		if (!data || !Array.isArray(data)) {
			return data;
		}

		const sortType = this.currentSortName;
		const sorter = this.sorters.get(sortType);
		let sortedData = [...data].sort(sorter);

		if (this.currentSortDirection === 'DESC') {
			sortedData = sortedData.reverse();
		}

		return sortedData;
	}
}
