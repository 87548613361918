import { IAchievementEdit } from './achievements.interface';

export class LoadAchievement {
	public static readonly type = '[Achievements] Load';
	constructor(public payload: { id: string }) {}
}

export class LoadAllAchievements {
	public static readonly type = '[Achievements] Load All';
}

export class LoadAllAchievementsIfEmpty {
	public static readonly type = '[Achievements] Load All If Empty';
}

export class LoadOneAchievement {
	public static readonly type = '[Achievements] Load One';
	constructor(public payload: { id: string }) {}
}

export class CreateAchievement {
	public static readonly type = '[Achievements] Create';
	constructor(public payload: { data: IAchievementEdit }) {}
}

export class UpdateAchievement {
	public static readonly type = '[Achievements] Update';
	constructor(public payload: { id: string; data: IAchievementEdit }) {}
}

export class DeleteAchievement {
	public static readonly type = '[Achievements] Delete';
	constructor(public payload: { id: string }) {}
}
