import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'itd-table-loading-state',
    templateUrl: './table-loading-state.component.html',
    styleUrls: ['./table-loading-state.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class TableLoadingStateComponent {
	@Input() public itemHeight: number;
	@Input() public size: string = 'big';

	constructor() {}
}
