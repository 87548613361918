<ng-template #image>
	<img
		[src]="url"
		class="image"
		[alt]="alt"
		[ngStyle]="{
			'border-radius.px': borderRadius
		}"
		[ngClass]="{ 'full-width': placement !== 'swiper' }"
	/>
</ng-template>

<ng-container *ngIf="dimension; else elseTpl">
	<div
		class="space"
		[ngStyle]="{
			'paddingBottom.%': dimension
		}"
	>
		<ng-container *ngTemplateOutlet="image"></ng-container>
	</div>
</ng-container>

<ng-template #elseTpl>
	<ng-container *ngTemplateOutlet="image"></ng-container>
</ng-template>
