<itd-modal [size]="800">
	<ng-template #header>
		{{ title | translate }}
	</ng-template>

	<ng-template #content>
		<div class="header">
			<itd-custom-data-source-search-input
				icon="search"
				placeholder="{{ 'EMPLOYEES.SEARCH_PLACEHOLDER' | translate }}"
				[dataSource]="employeesDataSource"
				[focusAfterInit]="true"
			>
			</itd-custom-data-source-search-input>
		</div>

		<div (scroll)="onScroll()" class="body">
			<itd-table [dataSource]="employeesDataSource">
				<itd-table-empty>
					<p>
						{{ 'EMPLOYEES.NOT_FOUND' | translate }}
					</p>
				</itd-table-empty>
				<ng-container itdColumnDef [width]="34" [padding]="0">
					<div *itdCellDef="let user">
						<itd-user-avatar [user]="user" folder="user-images"> </itd-user-avatar>
					</div>
				</ng-container>
				<ng-container itdColumnDef [width]="200">
					<div *itdCellHeaderDef>
						{{ 'NAME' | translate }}
					</div>
					<div *itdCellDef="let user">{{ user?.first_name }} {{ user?.last_name }}</div>
				</ng-container>
				<ng-container itdColumnDef [width]="200">
					<div *itdCellHeaderDef>
						{{ 'EMAIL' | translate }}
					</div>
					<div *itdCellDef="let user">
						<a class="truncate">
							<itd-truncate [value]="user?.email"> </itd-truncate>
						</a>
					</div>
				</ng-container>
				<ng-container itdColumnDef [width]="300">
					<div *itdCellHeaderDef>
						{{ 'DEPARTMENT' | translate }}
					</div>
					<div *itdCellDef="let user">
						<itd-truncate
							[value]="departmentServ.findDepartment(user.department_id)"
						></itd-truncate>
					</div>
				</ng-container>
				<itd-table-header-row *itdRowHeaderDef></itd-table-header-row>
				<itd-table-row
					*itdRowDef="let user"
					(rowClick)="selectUser(user)"
					class="table-row"
					[height]="56"
				></itd-table-row>
			</itd-table>
		</div>
	</ng-template>

	<ng-template #footer> </ng-template>
</itd-modal>
