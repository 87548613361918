import { KeyValue, NgFor, KeyValuePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipDirective } from '../../directives/tooltip.directive';
import { ModalComponent } from '../../../utility-modules/modals/components/modal/modal.component';

@Component({
	selector: 'itd-choose-question-type',
	templateUrl: './choose-question-type.component.html',
	styleUrls: ['./choose-question-type.component.scss'],
	standalone: true,
	imports: [ModalComponent, NgFor, TooltipDirective, KeyValuePipe, TranslateModule],
})
export class ChooseQuestionTypeComponent implements OnInit {
	@Input() data: { title: string; questionTypes: Map<string, string> };
	@Output() public activeType = new EventEmitter<string>();

	constructor(public activeModal: NgbActiveModal) {}

	ngOnInit(): void {}

	public emitQuestionType(type: KeyValue<string, string>): void {
		this.activeType.emit(type.value);
		this.activeModal.close();
	}
}
