<ng-template #content>
	<div class="icon">
		<itd-icon [icon]="data.icon"></itd-icon>
	</div>

	<span class="title">
		{{ 'NAVIGATION.' + data.title | translate }}
	</span>
</ng-template>

<a *ngIf="data.url" class="link" [routerLink]="data.url" routerLinkActive="is-active"
	[itdTooltip]="'NAVIGATION.' + data.tooltip | translate" tooltipPosition="right" [showTooltip]="hasTooltip && !!data.tooltip">
	<ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<button *ngIf="!data.url" class="link" [showTooltip]="hasTooltip && !!data.tooltip" [itdTooltip]="data.tooltip"
	tooltipPosition="right">
	<ng-container *ngTemplateOutlet="content"></ng-container>
</button>
