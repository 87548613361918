import { IDepartment } from '../department/department.interface';

// Leave only those data that are in the department and its subdepartments
export const removeAllOutsideDepartment = (userDepartmentId: string, data: any[]) => {
	return data?.filter(m => {
		if (!m.department_id || !m.department_id.includes(userDepartmentId)) {
			return false;
		} else {
			return true;
		}
	});
};

// Leave only those data that are in the department, its subdepartments and without department
export const removeAllInOtherDepartments = (
	userDepartmentId: string,
	userRole: string,
	data: any[]
) => {
	if (userRole === 'std_user') return data;

	return data?.filter(m => {
		if (!m.department_id) {
			return true;
		} else if (!m.department_id?.includes(userDepartmentId)) {
			return false;
		} else {
			return true;
		}
	});
};

// Get user department by department id
export const getUserDepartmentOnly = (userDepartmentId: string, data: any[]) => {
	if (userDepartmentId.includes('.')) {
		return data?.filter(m => {
			if (`${m.parent_chain}.${m.department_id}` === userDepartmentId) {
				return true;
			} else {
				return false;
			}
		});
	} else {
		return data?.filter(m => {
			if (m.department_id === userDepartmentId) {
				return true;
			} else {
				return false;
			}
		});
	}
};

// Leave only those data that are in the given department, and not in its subdepartments and without department
export const getDataInsideGivenDepartment = (departmentId: string, data: any[]) => {
	return data?.filter(m => {
		if (!m.department_id) {
			return false;
		} else if (m.department_id?.endsWith(departmentId)) {
			return true;
		} else {
			return false;
		}
	});
};

// Leave only those data that are without department
export const getDataWithoutDepartment = (data: any[]) => {
	return data?.filter(m => {
		if (!m.department_id) {
			return true;
		} else {
			return false;
		}
	});
};

// Leave only those data that are in any department
export const getDataInAnyDepartment = (data: any[]) => {
	return data?.filter(m => {
		if (!m.department_id) {
			return false;
		} else {
			return true;
		}
	});
};

// Return user department and its subdepartments
export const getDepartmentAndChildDepartments = (userDepartmentId: string, departments: any[]) => {
	let userDepartmentSingleId = null;
	if (userDepartmentId?.includes('.')) {
		const userDeptsIds = userDepartmentId.split('.');
		userDepartmentSingleId = userDeptsIds[userDeptsIds.length - 1];
	} else {
		userDepartmentSingleId = userDepartmentId;
	}
	return departments?.filter(m => {
		if (m.department_id === userDepartmentSingleId) {
			return true;
		} else if (!!m.parent_chain && m.parent_chain?.includes(userDepartmentSingleId)) {
			return true;
		} else {
			return false;
		}
	});
};

// TODO: Pass along current user ID so we can filter for departments that a department admin may be able to see
// Return parent departments array
export const getParentDepartments = (department: IDepartment, departments: IDepartment[]) => {
	if (!department.parent_chain) {
		return [];
	}
	if (department.parent_chain.includes('.')) {
		let parentIds = department.parent_chain.split('.');
		return parentIds.map(id => {
			return departments.find(d => d.department_id === id);
		});
	} else {
		return [departments.find(d => d.department_id === department.parent_chain)];
	}
};

// Return Division (Division is another word for Top level department,
// that is any department that does not have a parent department)
export const getDivision = (department: IDepartment, departments: IDepartment[]) => {
	if (!department?.parent_chain) {
		return department;
	}
	if (department.parent_chain.includes('.')) {
		let parentIds = department.parent_chain.split('.');
		return departments.find(d => d.department_id === parentIds[0]);
	} else {
		return departments.find(d => d.department_id === department.parent_chain);
	}
};
