import { Component, ContentChild, TemplateRef } from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'itd-edit-page',
    templateUrl: './edit-page.component.html',
    styleUrls: ['./edit-page.component.scss'],
    standalone: true,
    imports: [NgTemplateOutlet]
})
export class EditPageComponent {
	@ContentChild('left') public left: TemplateRef<any>;
	@ContentChild('right') public right: TemplateRef<any>;

	constructor() { }
}
