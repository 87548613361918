<div class="container">
	<ng-content #content> </ng-content>

	<ng-container *ngIf="!content">
		<div class="no-content-message">{{ noContentDisplayText | translate }}</div>
	</ng-container>

	<div class="footer">
		<ng-container *ngIf="removeBtnText && content">
			<button
				type="button"
				class="btn--span btn btn--size-default btn--color-secondary"
				(click)="removeAttachment()"
			>
				{{ removeBtnText | translate }}
			</button>
		</ng-container>

		<span [itdTooltip]="disabledTooltip" [showTooltip]="disabled" tooltipPosition="bottom">
			<button
				type="button"
				[disabled]="disabled"
				class="btn--span btn btn--size-default btn--color-secondary"
				(click)="btnClick()"
			>
				{{ btnText | translate }}
			</button>
		</span>
	</div>
</div>
