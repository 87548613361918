import { AbstractGraphComponent } from './../../abstract/abstract-graph/abstract-graph.component';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Router } from '@angular/router';
import { IPieChartData } from 'src/app/core/analytics/analytics.interface';
import { ChartModule } from 'angular-highcharts';

@Component({
	selector: 'itd-graph-doughnut',
	templateUrl: './graph-doughnut.component.html',
	styleUrls: ['./graph-doughnut.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ChartModule],
})
export class GraphDoughnutComponent extends AbstractGraphComponent {
	@Input() public data: IPieChartData[] = [];
	@Input() public labels: string[];
	@Input() public isClickable: boolean;

	public get total(): number {
		let totalCount = 0;
		this.data?.forEach(element => {
			totalCount = totalCount + element.count;
		});
		return totalCount;
	}

	constructor(private router: Router) {
		super();
	}

	goToDetailsPage(userType: any) {
		if (this.isClickable) {
			this.router.navigateByUrl(`/admin/analytics/organization/${userType}`);
		}
	}

	protected getChartOptions(): Highcharts.Options {
		const component = this;

		return {
			chart: {
				spacing: [20, 20, 20, 20],
				type: 'pie',
				marginLeft: 0,
				marginTop: 0,
				marginBottom: 0,
				plotBackgroundColor: null,
				plotShadow: false,
				plotBorderWidth: 0,
				width: 320,
				height: 200,
				events: {
					render() {
						const { renderer, plotHeight, plotWidth } = this;
						const OFFSET: number = 5;

						// Total Value
						const totalValue = renderer
							.text(component.total.toString())
							.css({
								fontSize: '21px',
							})
							.add();

						totalValue.translate(
							plotWidth / 2 - totalValue.getBBox().width / 2,
							plotHeight / 2 + OFFSET
						);
					},
				},
			},
			tooltip: {
				headerFormat: '<b>{point.key}</b><br>',
				pointFormat: '{point.percentage:.1f}%',
			},
			plotOptions: {
				pie: {
					showInLegend: true,
					allowPointSelect: true,
					// cursor: 'pointer',
					innerSize: 80,
					// gapSize: 0,
					// slicedOffset: 0,
					dataLabels: {
						enabled: false,
					},
				},
			},
			exporting: {
				enabled: false,
				chartOptions: {
					title: {
						text: this.title,
						align: 'right',
						verticalAlign: 'top',
					},
				},
			},
			legend: {
				floating: false,
				align: 'right',
				verticalAlign: 'middle',
				symbolPadding: 10,
				symbolWidth: 6,
				symbolHeight: 6,
				itemWidth: 150,
				itemStyle: {
					fontWeight: '',
					fontSize: '',
					fill: '',
					textOverflow: null,
				},
				labelFormatter() {
					// @ts-ignore
					const value = this.y;
					// @ts-ignore
					const color = this.color;

					return `
						<span style="color: #78828d; font-size: 11px;">${this.name}</span><br>
						<span style="color: ${color}; font-size: 15px;">${value}</span>
					`
						.trim()
						.replace(/\s*/, '');
				},
				useHTML: false,
			},
			series: [
				{
					borderColor: 'white',
					borderWidth: 2,
					data: this.data?.map((data, index) => {
						return {
							y: data.count,
							url: data.url,
							name: this.labels[index],
						};
					}),
					type: 'pie',
					enableMouseTracking: true,
					animation: {
						duration: 0,
					},
					point: {
						events: {
							click: event => this.goToDetailsPage(event.point.options['url']),
						},
					},
				},
			],
		};
	}
}
