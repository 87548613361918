import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { IUser } from 'src/app/core/users/users.interface';

// The contents of the sidebar opened with 'SidenavType' is set in main-page.component
export type SidenavType = 'filters' | 'user_sidedrawer' | 'module-used-in-timelines';

@Injectable({
	providedIn: 'root',
})
export class SidenavService {
	private sidenav: MatSidenav;
	private isShowResetFilter = false;
	public sidenavType: SidenavType = null;
	public filterType: string = null;
	public user: IUser = null;
	public moduleId?: number = null;

	public setSidenav(sidenav: MatSidenav) {
		this.sidenav = sidenav;
	}

	public setSidenavType(sidenavType: SidenavType) {
		this.sidenavType = sidenavType;
	}

	public setResetFilterStatus(status) {
		this.isShowResetFilter = status;
	}

	public getResetFilterStatus() {
		return this.isShowResetFilter;
	}

	public open(sidenavType: SidenavType) {
		this.setSidenavType(sidenavType);
		return this.sidenav.open();
	}

	public close() {
		this.user = null;
		this.sidenavType = null;
		return this.sidenav.close();
	}

	public toggle(): void {
		this.sidenav.toggle();
	}

	public setFilterType(type: string) {
		this.filterType = type;
	}

	public getFilterType() {
		return this.filterType;
	}

	public resetFilterType() {
		this.filterType = null;
	}

	public setUserTimelines(user: IUser) {
		this.user = { ...user };
	}
}
