import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { AuthState } from 'src/app/core/auth/auth.state';
import { SetLogin } from './auth/auth.action';
import { AuthService } from './auth/auth.service';
import {
	AddLoading,
	RemoveLoading,
	SetColorPrimary,
	SetFontPrimary,
	SetLanguage,
} from './layout/layout.actions';
import { LayoutState } from './layout/layout.state';

@Injectable({
	providedIn: 'root',
})
export class BasicDataProvider {
	constructor(private store: Store, private auth: AuthService) {}

	public async provide(): Promise<void> {
		const token = this.store.selectSnapshot(AuthState.token);

		this.setColorPrimary();
		this.setFontPrimary();
		this.setLanguage();

		if (!token) {
			return;
		}

		await firstValueFrom(this.auth.isTokenValid(token))
			.then(async isTokenValid => {
				if (isTokenValid) {
					await lastValueFrom(this.store.dispatch([AddLoading, new SetLogin({ token })]));

					this.store.dispatch(RemoveLoading);
				}
			})
			.catch(() => {});
	}

	private setColorPrimary() {
		const color = this.store.selectSnapshot(LayoutState.colorPrimary);

		if (!color) {
			return;
		}

		this.store.dispatch(new SetColorPrimary({ color }));
	}

	private setFontPrimary() {
		const font = this.store.selectSnapshot(LayoutState.fontPrimary);

		if (!font) {
			return;
		}

		this.store.dispatch(new SetFontPrimary({ font }));
	}

	private setLanguage(): void {
		const language = this.store.selectSnapshot(LayoutState.language);

		if (!language) {
			return;
		}

		this.store.dispatch(new SetLanguage({ language }));
	}
}

export function BasicDataProviderFactory(provider: BasicDataProvider) {
	return async () => await provider.provide();
}
