import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import { LegendOptions, TooltipOptions } from 'highcharts';
import { take } from 'rxjs/operators';

@Component({
	template: '',
})
export abstract class AbstractGraphComponent implements OnInit, OnChanges {
	@Input() public title: string;
	@Input() public colors: string[];
	@Input() public tooltip: TooltipOptions = null;
	@Input() public legend: LegendOptions = null;

	private _options: Highcharts.Options;

	public chartOptions: Highcharts.Options;
	public chart: Chart;

	@Input() public set options(value: Highcharts.Options) {
		this._options = value;
	}

	public get options(): Highcharts.Options {
		return this._options;
	}

	constructor() {}
	ngOnChanges(): void {
		this.setColors();
		this.setOptions();
	}

	ngOnInit() {}

	private setColors() {
		if (this.colors) {
			return;
		}

		this.colors = ['#56418E', '#D25899', '#F75661'];
	}

	private setOptions() {
		const options = this.getChartOptions();

		this.chart = new Chart({
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: 0,
				plotShadow: false,
				height: '200px',
				width: null,
				spacing: [0, 0, 0, 0],
			},
			xAxis: {
				type: 'datetime',
				title: null,
				dateTimeLabelFormats: {
					month: '%b',
					year: '%b',
				},
			},
			yAxis: {
				min: 0,
				title: null,
			},
			credits: { enabled: false },
			tooltip: this.tooltip,
			title: null,
			colors: this.colors,
			pane: null,
			exporting: {
				enabled: false,
				filename: this.title,
				chartOptions: {
					title: {
						text: this.title,
					},
				},
			},
			legend: this.legend,
			plotOptions: {
				series: {
					label: {
						connectorAllowed: false,
					},
					marker: {
						enabled: false,
					},
				},
			},
			drilldown: {
				animation: {
					duration: 0,
				},
			},
			...options,
		});
	}

	public download(value: 'svg' | 'png') {
		this.chart.ref$.pipe(take(1)).subscribe(chartRef => {
			// TODO: Workaround bug: Property 'exportChartLocal' does not exist on type 'Chart'.
			const chart = chartRef as any;

			if (!chart.exportChartLocal) {
				return;
			}

			const type = this.getType(value);

			chart.exportChartLocal({
				type,
			});
		});
	}

	private getType(type: 'svg' | 'png'): Highcharts.ExportingMimeTypeValue {
		switch (type) {
			case 'svg':
				return 'image/svg+xml';
			case 'png':
				return 'image/png';
		}
	}

	protected abstract getChartOptions(): Highcharts.Options;
}
