import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
	production: true,
	name: 'production',
	apiUrl: 'https://api.introdusapp.com/',
	mediaUrl: 'https://prod-media.introdusapp.com',
	passwordLength: 8,
	paginationDefault: 1500,
	posthogApiKey: 'phc_ub8uqTIAWmlC5T5YhdibPkcVnMsI8bG9ZYI3vGJW3ei',
};
