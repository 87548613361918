import { IDepartmentEdit } from './department.interface';

export class LoadAllDepartments {
	public static readonly type = '[Departments] Load All';
}

export class LoadAllDepartmentsIfEmpty {
	public static readonly type = '[Departments] Load All If Empty';
}
export class LoadAllParentDepartments {
	public static readonly type = '[Departments] Load All Parent Departments';
}
export class LoadDepartmentsFromParent {
	public static readonly type = '[Departments] Load Departments From Parent';
	constructor(public payload: { department_id: string; index: number }) {}
}
export class DeleteDepartment {
	public static readonly type = '[Departments] Delete Department';
	constructor(public payload: { department_id: string; index: number }) {}
}
export class CreateDepartment {
	public static readonly type = '[Departments] Create Department';
	constructor(public payload: { department: Partial<IDepartmentEdit>; index?: number }) {}
}
export class AddParent_id {
	public static readonly type = '[Departments] Add Parent_id';
	constructor(public payload: { parent_id?: string; index: number }) {}
}
export class UpdateDepartment {
	public static readonly type = '[Departments] Update Department';
	constructor(
		public payload: {
			parent_id: string;
			department: Partial<IDepartmentEdit>;
			department_id: string;
			index: number;
		}
	) {}
}

export class MoveDepartment {
	public static readonly type = '[Departments] Move Department';
	constructor(
		public payload: {
			department_id: string;
			new_parent_id: string;
		}
	) {}
}
