import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridComponent } from './components/grid/grid.component';
import { GridItemComponent } from './components/grid-item/grid-item.component';

const declarations = [
	GridComponent,
	GridItemComponent
]

@NgModule({
    imports: [
        CommonModule,
        ...declarations
    ],
    exports: [
        ...declarations
    ]
})
export class GridModule { }
