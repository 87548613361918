import { ITimelineEdit } from './timelines.interface';

export class LoadAllTimelines {
	public static readonly type = '[Timeline] Load All';
}

export class LoadAllTimelinesIfEmpty {
	public static readonly type = '[Timeline] Load All If Empty';
}

export class LoadTimelinesByModuleId {
	public static readonly type = '[Timeline] Load Timelines By Module Id';
	constructor(public payload: { id: string }) {}
}

export class CreateTimeline {
	public static readonly type = '[Timeline] Create';
	constructor(public payload: { data: ITimelineEdit }) {}
}

export class UpdateTimeline {
	public static readonly type = '[Timeline] Update';
	constructor(public payload: { id: string; data: ITimelineEdit }) {}
}

export class DeleteTimeline {
	public static readonly type = '[Timeline] Delete';
	constructor(public payload: { id: string }) {}
}

export class DuplicateTimeline {
	public static readonly type = '[Timeline] Duplicate';
	constructor(public payload: { id: string; deepCopy: boolean }) {}
}

export class GetNewLearning {
	public static readonly type = '[Timeline] Get New Learning';
}
