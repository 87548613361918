import { State, Selector, Action, StateContext } from '@ngxs/store';
import { SetIsMobile, SetMobileFooterNavigationDrawer, SetMobileHeaderProfileDrawer } from './layout-mobile.actions';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { Injectable } from '@angular/core';

export interface ILayoutMobileStateModel {
	isMobile: boolean;
	isFooterNavigationDrawer: boolean;
	isHeaderProfileDrawer: boolean;
}

@State<ILayoutMobileStateModel>({
	name: 'mobile',
	defaults: {
		isMobile: false,
		isFooterNavigationDrawer: false,
		isHeaderProfileDrawer: false,
	},
})
@Injectable()
export class LayoutMobileState {
	constructor() {}

	@Selector()
	public static getState(state: ILayoutMobileStateModel) {
		return state;
	}

	@Selector()
	public static isMobile(state: ILayoutMobileStateModel): boolean {
		return state.isMobile;
	}

	@Action(SetIsMobile)
	public setMobile(ctx: StateContext<ILayoutMobileStateModel>, { payload }: SetIsMobile) {
		const { isMobile } = payload;

		ctx.patchState({
			isMobile,
		});
	}

	@Action(SetMobileFooterNavigationDrawer)
	public setMobileFooterNavigationDrawer(ctx: StateContext<ILayoutMobileStateModel>, { payload }: SetMobileFooterNavigationDrawer) {
		const { isActive } = payload;

		if (!isActive) {
			enablePageScroll();
		} else {
			disablePageScroll();
		}

		return ctx.patchState({ isFooterNavigationDrawer: isActive });
	}

	@Selector()
	public static isFooterNavigationDrawer(state: ILayoutMobileStateModel) {
		return state.isFooterNavigationDrawer;
	}

	@Action(SetMobileHeaderProfileDrawer)
	public setMobileHeaderProfileDrawer(ctx: StateContext<ILayoutMobileStateModel>, { payload }: SetMobileHeaderProfileDrawer) {
		const { isActive } = payload;

		if (!isActive) {
			enablePageScroll();
		} else {
			disablePageScroll();
		}

		return ctx.patchState({ isHeaderProfileDrawer: isActive });
	}

	@Selector()
	public static isHeaderProfileDrawer(state: ILayoutMobileStateModel) {
		return state.isHeaderProfileDrawer;
	}
}
