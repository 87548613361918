import { importProvidersFrom } from '@angular/core';

import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideRouter } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { APP_ROUTES } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { ApiClientService } from './app/core/api/api.service';
import { CoreModule } from './app/core/core.module';
import { IntrodusTranslateLoader } from './app/core/introdus-translate-loader';
import { environment } from './environments/environment';
import posthog from 'posthog-js';
import * as Sentry from '@sentry/angular-ivy';

if (environment.production) {
	posthog.init(environment.posthogApiKey, {
		api_host: 'https://eu.i.posthog.com',
	});

	Sentry.init({
		environment: environment.name,
		dsn:
			'https://09c3448707ed0c80f93fd4d7a6d77016@o4507168068927488.ingest.de.sentry.io/4507177453551696',
		integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
		// Performance Monitoring
		tracesSampleRate: environment.production ? 0.05 : 1.0, //  Capture 5% of the transactions in production, capture all in development
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ['localhost', /^https:\/\/api\.introdusapp\.com\/api/],
		// Session Replay
		replaysSessionSampleRate: environment.production ? 0.1 : 1.0, // This sets the sample rate at 10% in production, and capture all in development.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

bootstrapApplication(AppComponent, {
	providers: [
		importProvidersFrom(
			BrowserModule,
			CoreModule,
			TranslateModule.forRoot({
				defaultLanguage: 'English',
				loader: {
					provide: TranslateLoader,
					useClass: IntrodusTranslateLoader,
					deps: [ApiClientService],
				},
			}),
			NgbModule,
			NgxsResetPluginModule.forRoot()
		),
		{
			provide: 'ENV',
			useValue: environment,
		},
		provideRouter(APP_ROUTES),
	],
}).catch(err => console.error(err));
