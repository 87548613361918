import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate',
    standalone: true,
})
export class TruncatePipe implements PipeTransform {
	// * Default is set so it fits about 2 lines in timeline-assign cards
	// Can be called with "| truncate:100:true:'[...]'"
	transform(value: string, limit = 100, completeWords = false, ellipsis = '...') {
		let finalString = '';

		if (completeWords) {
			finalString = value.slice(0, limit);
			finalString = finalString.slice(
				0,
				Math.min(finalString.length, finalString.lastIndexOf(' '))
			);
		} else {
			finalString = value.slice(0, limit);
		}

		return finalString.length < value.length ? finalString + ellipsis : value;
	}
}
