import { Directive, Input, Renderer2, ElementRef, OnInit, OnDestroy } from '@angular/core';

type TPosition = 'top' | 'right' | 'bottom' | 'left';

@Directive({
    selector: '[itdTooltip]',
    standalone: true,
})
export class TooltipDirective implements OnInit, OnDestroy {
	@Input('itdTooltip') public content: string;
	@Input() public showTooltip: boolean = true;
	@Input() public tooltipPosition?: TPosition = 'top';
	@Input() public tooltipOffset: number = 14;

	public tooltip: HTMLElement;

	private mouseEnterListener: EventListener;
	private mouseLeaveListener: EventListener;

	constructor(private el: ElementRef, private renderer: Renderer2) {}

	public ngOnInit(): void {
		this.mouseEnterListener = () => {
			this.onMouseEnter();
		};

		this.mouseLeaveListener = () => {
			this.onMouseLeave();
		};

		this.el.nativeElement.addEventListener('mouseenter', this.mouseEnterListener);
		this.el.nativeElement.addEventListener('mouseleave', this.mouseLeaveListener);
	}

	public ngOnDestroy(): void {
		this.el.nativeElement.removeEventListener('mouseenter', this.mouseEnterListener);
		this.el.nativeElement.removeEventListener('mouseleave', this.mouseLeaveListener);
	}

	public onMouseEnter() {
		if (!this.tooltip && this.content !== '') {
			this.show();
		}
	}
	public onMouseLeave() {
		if (this.tooltip && this.content !== '') {
			this.hide();
		}
	}

	public hideScroll(): void {
		this.hide();
	}

	private show() {
		if (!this.content || !this.showTooltip) {
			return;
		}

		const element = this.el.nativeElement as HTMLElement;
		this.tooltip = this.renderer.createElement('span');

		this.tooltip.innerHTML = this.content;
		this.renderer.appendChild(element, this.tooltip);

		this.renderer.addClass(this.tooltip, 'tooltip');
		this.renderer.addClass(this.tooltip, `tooltip--position-${this.tooltipPosition}`);

		const elementRect = element.getBoundingClientRect();
		const tooltipRect = this.tooltip.getBoundingClientRect();

		let positionY: number;
		let positionX: number = elementRect.left + (elementRect.width - tooltipRect.width) / 2;

		switch (this.tooltipPosition) {
			case 'top':
				positionY = elementRect.top - tooltipRect.height;
				positionY -= this.tooltipOffset;
				break;

			case 'right':
				positionY = elementRect.top - tooltipRect.height / 2 + elementRect.height / 2;
				positionX = elementRect.left + elementRect.width + this.tooltipOffset;
				break;

			case 'left':
				positionY = elementRect.top - tooltipRect.height / 2 + elementRect.height / 2;
				positionX = elementRect.left - tooltipRect.width - this.tooltipOffset;
				break;

			case 'bottom':
				positionY = elementRect.bottom;
				positionY += this.tooltipOffset;
				break;
		}

		// Check browser edges
		if (window.innerWidth < positionX + tooltipRect.width) {
			positionX = window.innerWidth - tooltipRect.width;
		} else if (positionX < 0) {
			positionX = 0;
		}

		this.renderer.setStyle(this.tooltip, 'top', `${positionY}px`);
		this.renderer.setStyle(this.tooltip, 'left', `${positionX}px`);
	}

	private hide() {
		if (!this.tooltip) {
			return;
		}

		this.renderer.removeChild(this.el.nativeElement, this.tooltip);
		this.tooltip = null;
	}
}
