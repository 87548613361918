<itd-modal>
	<ng-template #header>
		{{ 'CHOOSE_LANGUAGE' | translate }}
	</ng-template>

	<ng-template #content>
		<div class="container">
			<ng-container *ngFor="let lang of languages">
				<button
					class="question-type btn--block btn btn--size-default btn--color-secondary"
					(click)="emitQuestionType(lang)"
				>
					{{ lang }}
				</button>
			</ng-container>
		</div>
	</ng-template>
</itd-modal>
