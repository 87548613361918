import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { UsersService } from './users.service';
import {
	LoadAllUsers,
	LoadAllUsersIfEmpty,
	DeleteUser,
	CreateUser,
	UpdateUser,
	AddPaginationUser,
	LoadUsers,
	PublicSignUp,
	InviteMultipleUsers,
	InviteSingleUser,
	LoadUsersWithFilters,
} from './users.action';
import { IUser, IUserDTO, IUserEdit, IUserEditDTO } from './users.interface';
import { AbstractApiState } from '../abstract/abstract-api-state';
import { IPagination } from '../constans';

export interface UsersStateModel {
	items: IUser[];
	pagination?: IPagination;
}

@State<UsersStateModel>({
	name: 'users',
	defaults: {
		items: null,
		pagination: null,
	},
})
@Injectable()
export class UsersState extends AbstractApiState<IUser, IUserDTO, IUserEdit, IUserEditDTO> {
	constructor(protected users: UsersService, store: Store) {
		super(users, store);
	}

	@Selector()
	public static getState(state: UsersStateModel) {
		return state;
	}

	@Selector()
	public static items(state: UsersStateModel) {
		return state.items;
	}

	@Selector()
	public static getPagination(state: UsersStateModel) {
		return state.pagination;
	}

	@Action(LoadAllUsers)
	public loadAllUsers(ctx: StateContext<UsersStateModel>, payload: LoadAllUsers) {
		return super.loadAllAndAddToState(ctx, payload.params);
	}

	@Action(LoadUsers)
	public loadUsers(ctx: StateContext<UsersStateModel>, payload: LoadUsers) {
		return super.loadAll(ctx, payload.params);
	}

	@Action(LoadUsersWithFilters)
	public LoadUsersWithFilters(ctx: StateContext<UsersStateModel>, payload: LoadUsersWithFilters) {
		return super.loadAll(ctx, payload.params);
	}

	@Action(LoadAllUsersIfEmpty)
	public loadAllIfEmpty(ctx: StateContext<UsersStateModel>, payload: LoadAllUsersIfEmpty) {
		return super.loadAllIfEmpty(ctx, payload.params);
	}

	@Action(CreateUser)
	public create(ctx: StateContext<UsersStateModel>, data: CreateUser) {
		return super.create(ctx, data);
	}

	@Action(UpdateUser)
	public update(ctx: StateContext<UsersStateModel>, data: UpdateUser) {
		return super.update(ctx, data);
	}

	@Action(DeleteUser)
	public delete(ctx: StateContext<UsersStateModel>, data: DeleteUser) {
		return super.delete(ctx, data).pipe(
			tap(() => {
				const _items = ctx.getState().items;

				if (!_items || !_items.length) {
					return;
				}

				const items = [..._items];
				const index = items.findIndex(item => item.user_id === data.payload.id);

				if (index === -1) {
					return;
				}

				items.splice(index, 1);

				ctx.patchState({
					items,
				});
			})
		);
	}

	@Action(InviteMultipleUsers)
	public inviteMultiple(ctx: StateContext<UsersStateModel>, { payload }: InviteMultipleUsers) {
		return this.users.inviteMultiple(payload);
	}

	@Action(InviteSingleUser)
	public inviteSingle(ctx: StateContext<UsersStateModel>, { payload }: InviteSingleUser) {
		return this.users.invite(payload);
	}

	@Action(AddPaginationUser)
	public addPagination(ctx: StateContext<UsersStateModel>, data: AddPaginationUser) {
		return ctx.patchState(data.payload);
	}
	@Action(PublicSignUp)
	public publicSignUp(ctx: StateContext<UsersStateModel>, { payload }: PublicSignUp) {
		return this.users.publicSignUp(payload);
	}
}
