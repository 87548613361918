<div class="department-controls" *ngIf="departmentSection?.length !== 0">
	<input
		type="text"
		class="search-field"
		name="searchDepartments"
		id="searchDepartments"
		placeholder="{{ 'DEPARTMENTS.DEPARTMENT_SEARCH' | translate }} "
		[(ngModel)]="searchText"
	/>
	<button *ngIf="!user" class="btn create-btn" (click)="create()">
		{{ 'CREATE' | translate }}
	</button>
</div>
<div class="department-no-children" *ngIf="departmentSection?.length === 0">
	<itd-button *ngIf="!user" (click)="create()">
		{{ 'CREATE' | translate }}
	</itd-button>
</div>
<ng-container *ngIf="departmentSection?.length !== 0">
	<article
		[ngClass]="{
			selected: !!selectedDepartmentId && selectedDepartmentId === dep.department_id,
			localSelected: !!localSelectedDepartmentId && localSelectedDepartmentId === dep.department_id
		}"
		class="department-item"
		*ngFor="let dep of departmentSection | search: searchText"
	>
		<h2 class="department-text" (click)="getDepartment(dep.department_id)">
			{{ dep?.title }}
		</h2>
		<itd-menu *ngIf="!user" class="department-menu">
			<itd-menu-item icon="pen" [width]="12" [height]="12" (click)="edit(dep)">{{
				'EDIT' | translate
			}}</itd-menu-item>
			<itd-menu-item icon="arrow-right" [width]="12" [height]="12" (click)="move(dep)">{{
				'MOVE' | translate
			}}</itd-menu-item>
			<itd-menu-item
				icon="bin"
				[width]="12"
				[height]="12"
				(click)="delete(dep.title, dep.department_id)"
				>{{ 'DELETE' | translate }}</itd-menu-item
			>
		</itd-menu>
	</article>
</ng-container>
