<label class="container" type="button" [ngClass]="{ isActive: value }">
	<input
		#input
		type="checkbox"
		class="hidden-input"
		(ngModelChange)="onModelChange($event)"
		[(ngModel)]="value"
	/>

	<span class="checkbox"></span>
	<p class="label" [ngClass]="{ fullWidth: fullWidth }">
		<ng-content></ng-content>
	</p>
</label>
