<button type="button" (click)="sort()" class="sort-button" [ngClass]="{'is-active': isCurrentSortBy}">
    <div>
        {{label}}
    </div>

    <div class="sort-button__arrow" *ngIf="dataSource"
        [ngClass]="{'is-active': isCurrentSortBy, 'is-down': direction === 'ASC', 'is-up': direction === 'DESC'}">
        <itd-icon [icon]="'chevron-down-small'" [width]="10" [height]="6"></itd-icon>
    </div>
</button>
