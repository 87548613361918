import {
	LoadAllModules,
	LoadAllModulesIfEmpty,
	DeleteModule,
	CreateModule,
	UpdateModule,
	LoadModule,
	ResetActiveModule,
} from './modules.action';
import { IModule, IModuleDTO, IModuleEdit, IModuleEditDTO } from './modules.interface';
import { Injectable, NgZone } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { ModulesService } from './modules.service';
import { AbstractApiState } from '../abstract/abstract-api-state';
import { tap } from 'rxjs';
import { DuplicateModule } from './modules.action';
import { Router } from '@angular/router';

export interface ModulesStateModel {
	items: IModule[];
	activeModule?: IModule;
	result?: IModule;
}

@State<ModulesStateModel>({
	name: 'module',
	defaults: {
		items: null,
		activeModule: null,
		result: null,
	},
})
@Injectable()
export class ModulesState extends AbstractApiState<
	IModule,
	IModuleDTO,
	IModuleEdit,
	IModuleEditDTO
> {
	constructor(modules: ModulesService, private router: Router, private zone: NgZone, store: Store) {
		super(modules, store);
	}

	@Selector()
	public static getState(state: ModulesStateModel) {
		return state;
	}

	@Selector()
	public static activeModule(state: ModulesStateModel) {
		return state.activeModule;
	}

	@Selector()
	public static items(state: ModulesStateModel) {
		return state.items;
	}

	@Action(LoadAllModules)
	public loadAll(ctx: StateContext<ModulesStateModel>) {
		return super.loadAll(ctx);
	}

	@Action(LoadModule)
	public loadOneModule(ctx: StateContext<ModulesStateModel>, data: LoadModule) {
		const { patchState } = ctx;
		return super.loadOne(data.payload.id).pipe(
			tap(result => {
				patchState({
					activeModule: result,
				});
			})
		);
	}

	@Action(ResetActiveModule)
	public resetActiveModule(ctx: StateContext<ModulesStateModel>) {
		const { patchState } = ctx;
		patchState({
			activeModule: null,
		});
	}

	@Action(LoadAllModulesIfEmpty)
	public loadAllIfEmpty(ctx: StateContext<ModulesStateModel>) {
		return super.loadAllIfEmpty(ctx);
	}

	@Action(CreateModule)
	public create(ctx: StateContext<ModulesStateModel>, { payload: { data } }: CreateModule) {
		return this.service.create(data).pipe(
			tap(result => {
				const _items = ctx.getState().items;
				this.zone.run(() => {
					this.router.navigate([`/admin/content/modules/${result.id}/questions`], {
						state: result,
					});
				});

				const items = [..._items, result];

				ctx.patchState({
					items,
					result,
				});
			})
		);
	}

	@Action(UpdateModule)
	public update(ctx: StateContext<ModulesStateModel>, data: UpdateModule) {
		return super.update(ctx, data);
	}

	@Action(DeleteModule)
	public delete(ctx: StateContext<ModulesStateModel>, data: DeleteModule) {
		return super.delete(ctx, data);
	}

	@Action(DuplicateModule)
	public duplicate(ctx: StateContext<ModulesStateModel>, data: DuplicateModule) {
		return super.duplicate(ctx, data);
	}
}
