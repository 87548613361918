import { ActivatedRoute, Router } from '@angular/router';
import {
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	Input,
	OnInit,
	TemplateRef,
} from '@angular/core';
import { fadeAnimation } from 'src/app/core/animations/fade.animation';
import { IPageSelectItem, PageSelectComponent } from '../../components/page-select/page-select.component';
import { IOrganization } from 'src/app/core/organizations/organizations.interface';
import { Store } from '@ngxs/store';
import { OrganizationState } from 'src/app/core/organizations/organizations.state';
import { ProfileState } from 'src/app/core/profile/profile.state';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonBackComponent } from '../../components/button-back/button-back.component';
import { NgIf, NgClass, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'itd-main-template',
    templateUrl: './main-template.component.html',
    styleUrls: ['./main-template.component.scss'],
    animations: [fadeAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        ButtonBackComponent,
        PageSelectComponent,
        NgTemplateOutlet,
        TranslateModule,
    ],
})
export class MainTemplateComponent implements OnInit {
	@Input() headerText: string;
	public title: string;
	@Input() public noTopSpacing: boolean = false;
	@Input() public subNavigation: IPageSelectItem[];
	@Input() public backPath: string;

	@ContentChild('mainTemplateHeaderLeft') public headerLeft?: TemplateRef<any>;
	@ContentChild('mainTemplateHeaderRight') public headerRight?: TemplateRef<any>;
	@ContentChild('mainTemplateBody') public main: TemplateRef<any>;

	organization: IOrganization;
	private currentUserRole: string;

	public get hasHeader() {
		return this.headerLeft || this.headerRight || this.subNavigation.length;
	}

	constructor(private route: ActivatedRoute, private router: Router, private store: Store) {}

	public ngOnInit() {
		this.organization = this.store.selectSnapshot<IOrganization>(OrganizationState.organization);
		this.currentUserRole = this.store.selectSnapshot(ProfileState.role);
		const { title, subNavigation = [] } = this.route.snapshot.data;

		if (!this.subNavigation?.length) {
			this.subNavigation = subNavigation.filter(item => {
				// TODO: Remove this when a better version is available!
				if (
					item.title === 'TIMELINES' &&
					this.organization.org_id === 'aae1f8d94a4f80d6' &&
					this.currentUserRole === 'dept_admin'
				)
					return false;
				if (item.permissionName) {
					return this.organization[item.permissionName];
				} else return true;
			});
		}

		this.title = this.headerText ? this.headerText : title;
	}

	public goBackClick(path: string) {
		this.router.navigateByUrl(path);
	}
}
