import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
	DeleteTimeline,
	LoadAllTimelines,
	LoadAllTimelinesIfEmpty,
	CreateTimeline,
	UpdateTimeline,
	DuplicateTimeline,
	GetNewLearning,
	// LoadTimelinesByModuleId,
} from './timelines.action';
import { ITimeline, ITimelineDTO, ITimelineEdit, ITimelineEditDTO } from './timelines.interface';
import { TimelinesService } from './timelines.service';
import { AbstractApiState } from '../abstract/abstract-api-state';
import { tap } from 'rxjs';

export interface TimelinesStateModel {
	items: ITimeline[];
	newLearning?: ITimeline[];
}

@State<TimelinesStateModel>({
	name: 'timelines',
	defaults: {
		items: null,
		newLearning: null,
	},
})
@Injectable()
export class TimelineState extends AbstractApiState<
	ITimeline,
	ITimelineDTO,
	ITimelineEdit,
	ITimelineEditDTO
> {
	constructor(private timelines: TimelinesService, store: Store) {
		super(timelines, store);
	}

	@Selector()
	public static getState(state: TimelinesStateModel) {
		return state;
	}

	@Selector()
	public static items(state: TimelinesStateModel): ITimeline[] {
		return state.items;
	}

	@Selector()
	public static newLearning(state: TimelinesStateModel) {
		return state.newLearning;
	}

	@Action(LoadAllTimelines)
	public loadAll(ctx: StateContext<TimelinesStateModel>) {
		return super.loadAll(ctx);
	}

	@Action(LoadAllTimelinesIfEmpty)
	public loadAllIfEmpty(ctx: StateContext<TimelinesStateModel>) {
		return super.loadAllIfEmpty(ctx);
	}

	// TODO: Implement state for this call, if necessary
	// @Action(LoadTimelinesByModuleId)
	// public loadTimelinesByModuleId(ctx: StateContext<TimelinesStateModel>, id: string) {
	// 	this.timelines.getTimelinesByModuleId(id).pipe(tap(result => {

	// 	}))
	// }

	@Action(CreateTimeline)
	public create(ctx: StateContext<TimelinesStateModel>, data: CreateTimeline) {
		return super.create(ctx, data);
	}

	@Action(UpdateTimeline)
	public update(ctx: StateContext<TimelinesStateModel>, data: UpdateTimeline) {
		return super.update(ctx, data);
	}

	@Action(DeleteTimeline)
	public delete(ctx: StateContext<TimelinesStateModel>, data: DeleteTimeline) {
		return super.delete(ctx, data);
	}

	@Action(DuplicateTimeline)
	public duplicate(ctx: StateContext<TimelinesStateModel>, data: DuplicateTimeline) {
		return super.duplicate(ctx, data);
	}

	// TODO Fix this getter so it sets to state and doesnt load from API every time
	@Action(GetNewLearning)
	public getNewLearning({ patchState }: StateContext<TimelinesStateModel>) {
		return this.timelines.getNewLearning().pipe(
			tap(items => {
				return patchState({
					newLearning: items,
				});
			})
		);
	}
}
