import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { take, tap } from 'rxjs/operators';
import { IEIS } from '../employee-integration-score/eis.interface';
import { ISurveyDTO } from '../surveys/surveys.interface';
import { ITasklistAssignment } from '../tasklist-assignment/tasklist-assignment.interface';
import {
	DownloadSurvey,
	DownloadSurveyPDF,
	LoadAnalyticsEmployeeActivity,
	LoadAnalyticsEmployeeActivityIfEmpty,
	LoadAnalyticsEmployeeIntegrationScore,
	LoadAnalyticsEmployeeIntegrationScoreIfEmpty,
	LoadAnalyticsFastTrackAssignments,
	LoadAnalyticsFastTrackDimensions,
	LoadAnalyticsFastTrackDimensionsOverEmploymentTime,
	LoadAnalyticsFastTrackDimensionsOverTime,
	LoadAnalyticsLogins,
	LoadAnalyticsLoginsIfEmpty,
	LoadAnalyticsOnboardingIndex,
	LoadAnalyticsOnboardingIndexOverEmploymentTime,
	LoadAnalyticsOnboardingIndexOverTime,
	LoadAnalyticsSurvey,
	LoadAnalyticsSurveyAnswersOverTime,
	LoadAnalyticsSurveyAnswersOverTimeIfEmpty,
	LoadAnalyticsSurveyIfEmpty,
	LoadAnalyticsSurveyOverTime,
	LoadAnalyticsSurveyOverTimeIfEmpty,
	LoadAnalyticsTaskLists,
	LoadAnalyticsTaskListsIfEmpty,
	LoadAnalyticsTimeline,
	LoadAnalyticsTimelineIfEmpty,
	LoadAnalyticsUsers,
	LoadAnalyticsUsersIfEmpty,
	LoadAnalyticsUsersTasks,
	LoadAnalyticsUsersTasksIfEmpty,
	LoadAnalyticsUsersType,
	LoadAnalyticsUsersTypeIfEmpty,
} from './analytics.action';
import {
	IAnalyticsEmployeeActivity,
	IAnalyticsFastTrackDimensionData,
	IAnalyticsFastTrackDimensionOverTime,
	IAnalyticsFastTrackReport,
	IAnalyticsLogin,
	IAnalyticsOnboardingIndexOverEmploymentTime,
	IAnalyticsOnboardingIndexOverTime,
	IAnalyticsSurveyAnswersOverTime,
	IAnalyticsSurveyOverTime,
	IAnalyticsTimelineDetails,
	IAnalyticsUser,
	IAnalyticsUserTask,
	IAnalyticsUserType,
	IFilterAnalytics,
	TAnalyticsFastTrackDimensions,
} from './analytics.interface';
import { AnalyticsService } from './analytics.service';

export interface AnalyticsStateModel {
	usersType: IAnalyticsUserType;
	logins: IAnalyticsLogin[];
	users: IAnalyticsUser[];
	userTasks: IAnalyticsUserTask;
	EmployeeActivity: IAnalyticsEmployeeActivity;
	employeeIntegrationScore: IEIS;
	analyticsTimeLine: IAnalyticsTimelineDetails[];
	analyticsTask: ITasklistAssignment;
	analyticsSurveyOverTime: IAnalyticsSurveyOverTime[];
	analyticsSurveyOverAnswersTime: IAnalyticsSurveyAnswersOverTime[];
	analyticsSurvey: ISurveyDTO[];
	fastTrackReports: IAnalyticsFastTrackReport[];
	fastTrackDimensions: TAnalyticsFastTrackDimensions;
	fastTrackDimensionsOverTime: IAnalyticsFastTrackDimensionOverTime[];
	fastTrackDimensionsOverEmploymentTime: IAnalyticsFastTrackDimensionData[];
	onboardingIndex: number;
	onboardingIndexOverTime: IAnalyticsOnboardingIndexOverTime[];
	onboardingIndexOverEmploymentTime: IAnalyticsOnboardingIndexOverEmploymentTime[];
}

@State<AnalyticsStateModel>({
	name: 'analytics',
	defaults: {
		usersType: null,
		userTasks: null,
		logins: null,
		users: null,
		EmployeeActivity: null,
		employeeIntegrationScore: null,
		analyticsTimeLine: null,
		analyticsSurveyOverTime: null,
		analyticsSurveyOverAnswersTime: null,
		analyticsSurvey: null,
		analyticsTask: null,
		fastTrackReports: null,
		fastTrackDimensions: null,
		fastTrackDimensionsOverTime: null,
		fastTrackDimensionsOverEmploymentTime: null,
		onboardingIndex: null,
		onboardingIndexOverTime: null,
		onboardingIndexOverEmploymentTime: null,
	},
})
@Injectable()
export class AnalyticsState {
	constructor(private analytics: AnalyticsService) {}

	@Selector()
	public static getState(state: AnalyticsStateModel) {
		return state;
	}

	@Selector()
	public static usersType(state: AnalyticsStateModel) {
		return state.usersType;
	}

	@Action(LoadAnalyticsUsersType)
	public loadUsersType(ctx: StateContext<AnalyticsStateModel>, selectedDates: IFilterAnalytics) {
		return this.analytics.getUserTypes(selectedDates).pipe(
			take(1),
			tap(usersType => {
				ctx.patchState({
					usersType,
				});
			})
		);
	}

	@Action(LoadAnalyticsUsersTypeIfEmpty)
	public loadUsersTypeIfEmpty(
		ctx: StateContext<AnalyticsStateModel>,
		{ payload }: LoadAnalyticsUsersTypeIfEmpty
	) {
		const { usersType } = ctx.getState();
		if (usersType && !payload) {
			return;
		}
		return this.loadUsersType(ctx, payload);
	}

	@Selector()
	public static usersTasks(state: AnalyticsStateModel) {
		return state.userTasks;
	}

	@Action(LoadAnalyticsUsersTasks)
	public loadUsersTasks(ctx: StateContext<AnalyticsStateModel>, selectedDates: IFilterAnalytics) {
		return this.analytics.getUserTasks(selectedDates).pipe(
			take(1),
			tap(userTasks => {
				ctx.patchState({
					userTasks,
				});
			})
		);
	}

	@Action(LoadAnalyticsUsersTasksIfEmpty)
	public loadUsersTasksIfEmpty(
		ctx: StateContext<AnalyticsStateModel>,
		{ payload }: LoadAnalyticsUsersTasksIfEmpty
	) {
		const { usersType } = ctx.getState();
		if (usersType && !payload) {
			return;
		}
		return this.loadUsersTasks(ctx, payload);
	}

	@Selector()
	public static logins(state: AnalyticsStateModel) {
		return state.logins;
	}

	@Action(LoadAnalyticsLogins)
	public loadLogins(ctx: StateContext<AnalyticsStateModel>, selectedDates: IFilterAnalytics) {
		return this.analytics.getLogins(selectedDates).pipe(
			take(1),
			tap(logins => {
				ctx.patchState({
					logins,
				});
			})
		);
	}

	@Action(LoadAnalyticsLoginsIfEmpty)
	public loadLoginsIfEmpty(
		ctx: StateContext<AnalyticsStateModel>,
		{ payload }: LoadAnalyticsLoginsIfEmpty
	) {
		const { logins } = ctx.getState();

		if (logins && !payload) {
			return;
		}

		return this.loadLogins(ctx, payload);
	}

	@Selector()
	public static users(state: AnalyticsStateModel) {
		return state.users;
	}

	@Action(LoadAnalyticsUsers)
	public loadUsers(ctx: StateContext<AnalyticsStateModel>, selectedDates: IFilterAnalytics) {
		return this.analytics.getUsers(selectedDates).pipe(
			take(1),
			tap(users => {
				ctx.patchState({
					users,
				});
			})
		);
	}

	@Action(LoadAnalyticsUsersIfEmpty)
	public loadUsersIfEmpty(
		ctx: StateContext<AnalyticsStateModel>,
		{ payload }: LoadAnalyticsUsersIfEmpty
	) {
		const { users } = ctx.getState();

		if (users && !payload) {
			return;
		}

		return this.loadUsers(ctx, payload);
	}

	@Selector()
	public static employeeActivity(state: AnalyticsStateModel) {
		return state.EmployeeActivity;
	}

	@Action(LoadAnalyticsEmployeeActivity)
	public loadEmployeeActivity(
		ctx: StateContext<AnalyticsStateModel>,
		selectedDates: IFilterAnalytics
	) {
		return this.analytics.getEmployeeActivity(selectedDates).pipe(
			take(1),
			tap(employeeActivity => {
				ctx.patchState({
					EmployeeActivity: employeeActivity,
				});
			})
		);
	}

	@Action(LoadAnalyticsEmployeeActivityIfEmpty)
	public loadEmployeeActivityIfEmpty(
		ctx: StateContext<AnalyticsStateModel>,
		{ payload }: LoadAnalyticsEmployeeActivityIfEmpty
	) {
		const { EmployeeActivity: employeeActivity } = ctx.getState();
		if (employeeActivity && !payload) {
			return;
		}

		return this.loadEmployeeActivity(ctx, payload);
	}

	@Selector()
	public static employeeIntegrationScore(state: AnalyticsStateModel) {
		return state.employeeIntegrationScore;
	}

	@Action(LoadAnalyticsEmployeeIntegrationScore)
	public loadAnalyticsEmployeeIntegrationScore(
		{ patchState }: StateContext<AnalyticsStateModel>,
		selectedDates: IFilterAnalytics
	) {
		return this.analytics.getEmployeeIntegrationScore(selectedDates).pipe(
			take(1),
			tap(result => {
				patchState({
					employeeIntegrationScore: result,
				});
			})
		);
	}

	@Action(LoadAnalyticsEmployeeIntegrationScoreIfEmpty)
	public loadAnalyticsEmployeeIntegrationScoreIfEmpty(
		ctx: StateContext<AnalyticsStateModel>,
		{ payload }: LoadAnalyticsEmployeeIntegrationScoreIfEmpty
	) {
		const { employeeIntegrationScore } = ctx.getState();

		if (employeeIntegrationScore && !payload) {
			return;
		}

		return this.loadAnalyticsEmployeeIntegrationScore(ctx, payload);
	}

	@Selector()
	public static AnalyticsTimeLine(state: AnalyticsStateModel) {
		return state.analyticsTimeLine;
	}

	@Action(LoadAnalyticsTimeline)
	public loadAnalyticsTimeLine(
		ctx: StateContext<AnalyticsStateModel>,
		selectedFilters: IFilterAnalytics
	) {
		return this.analytics.analyticsTimeLine(selectedFilters).pipe(
			take(1),
			tap(analyticsTimeLine => {
				ctx.patchState({
					analyticsTimeLine,
				});
			})
		);
	}

	@Action(LoadAnalyticsTimelineIfEmpty)
	public loadAnalyticsTimeLineIfEmpty(
		ctx: StateContext<AnalyticsStateModel>,
		{ payload }: LoadAnalyticsTimelineIfEmpty
	) {
		const { analyticsTimeLine } = ctx.getState();

		if (analyticsTimeLine && !payload) {
			return;
		}

		return this.loadAnalyticsTimeLine(ctx, payload);
	}

	@Selector()
	public static AnalyticsSurveyOverTime(state: AnalyticsStateModel) {
		return state.analyticsSurveyOverTime;
	}

	@Action(LoadAnalyticsSurveyOverTime)
	public AnalyticsSurveyOverTime(
		ctx: StateContext<AnalyticsStateModel>,
		selectedDates: IFilterAnalytics
	) {
		return this.analytics.analyticsSurveyOverTime(selectedDates).pipe(
			take(1),
			tap(analyticsSurveyOverTime => {
				ctx.patchState({
					analyticsSurveyOverTime,
				});
			})
		);
	}

	@Action(LoadAnalyticsSurveyOverTimeIfEmpty)
	public AnalyticsSurveyOverTimeIfEmpty(
		ctx: StateContext<AnalyticsStateModel>,
		{ payload }: LoadAnalyticsSurveyOverTimeIfEmpty
	) {
		const { analyticsSurveyOverTime } = ctx.getState();

		if (analyticsSurveyOverTime && !payload) {
			return;
		}

		return this.AnalyticsSurveyOverTime(ctx, payload);
	}

	@Selector()
	public static AnalyticsTaskListAnalytics(state: AnalyticsStateModel) {
		return state.analyticsTask;
	}

	@Action(LoadAnalyticsTaskLists)
	public loadAnalyticsTaskLists(
		ctx: StateContext<AnalyticsStateModel>,
		selectedDates: IFilterAnalytics
	) {
		return this.analytics.analyticsTaskLists(selectedDates).pipe(
			take(1),
			tap(analyticsTask => {
				ctx.patchState({
					analyticsTask,
				});
			})
		);
	}

	@Action(LoadAnalyticsTaskListsIfEmpty)
	public loadAnalyticsTaskListsIfEmpty(
		ctx: StateContext<AnalyticsStateModel>,
		{ payload }: LoadAnalyticsTaskListsIfEmpty
	) {
		const { analyticsTask } = ctx.getState();

		if (analyticsTask && !payload) {
			return;
		}

		return this.loadAnalyticsTaskLists(ctx, payload);
	}

	@Selector()
	public static AnalyticsSurveyOverAnswersTime(state: AnalyticsStateModel) {
		return state.analyticsSurveyOverAnswersTime;
	}

	@Action(LoadAnalyticsSurveyAnswersOverTime)
	public AnalyticsSurveyOverAnswersTime(
		ctx: StateContext<AnalyticsStateModel>,
		selectedDates: IFilterAnalytics
	) {
		return this.analytics.analyticsSurveyAnswersOverTime(selectedDates).pipe(
			take(1),
			tap(analyticsSurveyOverAnswersTime => {
				ctx.patchState({
					analyticsSurveyOverAnswersTime,
				});
			})
		);
	}

	@Action(LoadAnalyticsSurveyAnswersOverTimeIfEmpty)
	public AnalyticsSurveyOverAnswersTimeIfEmpty(
		ctx: StateContext<AnalyticsStateModel>,
		{ payload }: LoadAnalyticsSurveyAnswersOverTimeIfEmpty
	) {
		const { analyticsSurveyOverAnswersTime } = ctx.getState();

		if (analyticsSurveyOverAnswersTime && !payload) {
			return;
		}

		return this.AnalyticsSurveyOverAnswersTime(ctx, payload);
	}

	@Selector()
	public static AnalyticsSurvey(state: AnalyticsStateModel) {
		return state.analyticsSurvey;
	}

	@Action(LoadAnalyticsSurvey)
	public AnalyticsSurvey(ctx: StateContext<AnalyticsStateModel>, selectedDates: IFilterAnalytics) {
		return this.analytics.analyticsSurvey(selectedDates).pipe(
			take(1),
			tap(analyticsSurvey => {
				ctx.patchState({
					analyticsSurvey,
				});
			})
		);
	}

	@Action(LoadAnalyticsSurveyIfEmpty)
	public AnalyticsSurveyIfEmpty(
		ctx: StateContext<AnalyticsStateModel>,
		{ payload }: LoadAnalyticsSurveyIfEmpty
	) {
		const { analyticsSurvey } = ctx.getState();

		if (analyticsSurvey && !payload) {
			return;
		}

		return this.AnalyticsSurvey(ctx, payload);
	}

	@Action(DownloadSurvey)
	public duplicate(ctx: StateContext<AnalyticsStateModel>, data: DownloadSurvey) {
		return this.analytics.downloadSurvey(data);
	}

	@Action(DownloadSurveyPDF)
	public downloadSurveyPDF(ctx: StateContext<AnalyticsStateModel>, data: DownloadSurvey) {
		return this.analytics.downloadSurveyPDF(data);
	}

	@Selector()
	public static AnalyticsFastTrackReports(state: AnalyticsStateModel) {
		return state.fastTrackReports;
	}

	@Action(LoadAnalyticsFastTrackAssignments)
	public loadAnalyticsFastTrackAssignments(
		ctx: StateContext<AnalyticsStateModel>,
		filter: IFilterAnalytics
	) {
		return this.analytics.loadAnalyticsFastTrackReports(filter).pipe(
			take(1),
			tap(result => {
				ctx.patchState({
					fastTrackReports: result,
				});
			})
		);
	}

	@Selector()
	public static AnalyticsOnboardingIndex(state: AnalyticsStateModel) {
		return state.onboardingIndex;
	}

	@Action(LoadAnalyticsOnboardingIndex)
	public loadAnalyticsOnboardingIndex(
		ctx: StateContext<AnalyticsStateModel>,
		filter: IFilterAnalytics
	) {
		return this.analytics.loadAnalyticsOnboardingIndex(filter).pipe(
			take(1),
			tap(result => {
				ctx.patchState({
					onboardingIndex: result,
				});
			})
		);
	}

	@Selector()
	public static OnboardingIndexOverTime(state: AnalyticsStateModel) {
		return state.onboardingIndexOverTime;
	}

	@Action(LoadAnalyticsOnboardingIndexOverTime)
	public loadAnalyticsOnboardingIndexOverTime(
		ctx: StateContext<AnalyticsStateModel>,
		{ payload }: LoadAnalyticsOnboardingIndexOverTime
	) {
		return this.analytics.loadAnalyticsOnboardingIndexOverTime(payload).pipe(
			take(1),
			tap(result => {
				ctx.patchState({
					onboardingIndexOverTime: result,
				});
			})
		);
	}

	@Selector()
	public static AnalyticsOnboardingIndexOverEmploymentTime(state: AnalyticsStateModel) {
		return state.onboardingIndexOverEmploymentTime;
	}

	@Action(LoadAnalyticsOnboardingIndexOverEmploymentTime)
	loadAnalyticsOnboardingIndexOverEmploymentTime(
		ctx: StateContext<AnalyticsStateModel>,
		action: LoadAnalyticsOnboardingIndexOverEmploymentTime
	) {
		return this.analytics.loadAnalyticsOnboardingIndexOverEmploymentTime(action.payload).pipe(
			tap(result => {
				ctx.patchState({
					onboardingIndexOverEmploymentTime: result,
				});
			})
		);
	}

	@Selector()
	public static AnalyticsFastTrackDimensions(state: AnalyticsStateModel) {
		return state.fastTrackDimensions;
	}

	@Action(LoadAnalyticsFastTrackDimensions)
	public loadAnalyticsFastTrackDimensions(
		ctx: StateContext<AnalyticsStateModel>,
		filter: IFilterAnalytics
	) {
		return this.analytics.loadAnalyticsFastTrackDimensions(filter).pipe(
			take(1),
			tap(result => {
				ctx.patchState({
					fastTrackDimensions: result,
				});
			})
		);
	}

	@Selector()
	public static AnalyticsFastTrackDimensionsOverTime(state: AnalyticsStateModel) {
		return state.fastTrackDimensionsOverTime;
	}

	@Action(LoadAnalyticsFastTrackDimensionsOverTime)
	loadFastTrackDimensionsOverTime(
		ctx: StateContext<AnalyticsStateModel>,
		action: LoadAnalyticsFastTrackDimensionsOverTime
	) {
		return this.analytics.loadAnalyticsFastTrackDimensionsOverTime(action.payload).pipe(
			tap(result => {
				ctx.patchState({
					fastTrackDimensionsOverTime: result,
				});
			})
		);
	}

	@Selector()
	public static AnalyticsFastTrackDimensionsOverEmploymentTime(state: AnalyticsStateModel) {
		return state.fastTrackDimensionsOverEmploymentTime;
	}

	@Action(LoadAnalyticsFastTrackDimensionsOverEmploymentTime)
	loadFastTrackDimensionsOverEmploymentTime(
		ctx: StateContext<AnalyticsStateModel>,
		action: LoadAnalyticsFastTrackDimensionsOverEmploymentTime
	) {
		return this.analytics.loadAnalyticsFastTrackDimensionsOverEmploymentTime(action.payload).pipe(
			tap(result => {
				ctx.patchState({
					fastTrackDimensionsOverEmploymentTime: result,
				});
			})
		);
	}
}
