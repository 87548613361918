import { MarkedOptions, MarkedRenderer } from 'ngx-markdown';

export function markedOptionsFactory(): MarkedOptions {
	const renderer = new MarkedRenderer();

	renderer.image = (href: string, title: string, text: string) => {
		return `
		<div class="asset">
			<div class="asset__content"><img class="asset__image" src="${href}" alt="${text}" title="${title}" /></div>
		</div>`.trim();
	};

	renderer.paragraph = (text: string) => {
		if (text[0] === '<') {
			return text;
		}
		return `<p>${text}</p>`;
	};

	return {
		renderer: renderer,
		gfm: true,
		breaks: false,
		pedantic: false,
		smartLists: true,
		smartypants: false,
	};
}
