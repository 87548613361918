import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChartModule } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import { IAnalyticsGraphData } from '../../../../core/analytics/analytics.interface';
import { AbstractGraphComponent } from '../../abstract/abstract-graph/abstract-graph.component';

export type TLineColumGraphData = Array<IAnalyticsGraphData>;

@Component({
	selector: 'itd-graph-line-column',
	templateUrl: './graph-line-column.component.html',
	styleUrls: ['./graph-line-column.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ChartModule],
})
export class GraphLineAndColumnComponent extends AbstractGraphComponent {
	@Input() public data: TLineColumGraphData;
	@Input() public labels: string[];

	constructor() {
		super();
	}

	protected getChartOptions(): Highcharts.Options {
		return {
			chart: {
				zooming: {
					type: 'xy',
				},
				plotBackgroundColor: null,
				plotBorderWidth: 0,
				plotShadow: false,
				animation: false,
				height: '200px',
				width: null,
			},
			xAxis: [
				{
					categories: this.data.map(item => {
						return item.date;
					}),
				},
			],
			yAxis: [
				{
					// Primary yAxis
					labels: {
						format: '{value}',
						style: {
							color: Highcharts.getOptions().colors[1].toString(),
						},
					},
					title: {
						text: '',
						style: {
							color: Highcharts.getOptions().colors[1].toString(),
						},
					},
				},
				{
					// Secondary yAxis
					title: {
						text: '',
						style: {
							color: Highcharts.getOptions().colors[0].toString(),
						},
					},
					labels: {
						format: '',
						style: {
							color: Highcharts.getOptions().colors[0].toString(),
						},
					},
					visible: false,
				},
			],
			tooltip: {
				shared: true,
			},
			legend: {
				align: 'left',
				verticalAlign: 'top',
				layout: 'horizontal',
			},
			series: [
				{
					name: this.labels[0],
					type: 'column',
					data: this.data.map(item => {
						return item.count;
					}),
					tooltip: {},
				},
				{
					name: this.labels[1],
					type: 'spline',
					data: this.data.map((curr, i, array) => {
						let sum = 0;
						for (let k = 0; k < i; k++) {
							sum += array[k].count;
						}
						return curr.count + sum;
					}),
					tooltip: {},
				},
			],
			plotOptions: {
				series: {
					marker: {
						enabled: false,
						states: {
							hover: {
								enabled: false,
							},
						},
					},
				},
			},
		};
	}
}
