<div class="menu" #container [ngClass]="{ active: isActive }">
	<div #link>
		<button type="button" class="menu__icon click-expand" (click)="toggle($event)">
			<itd-icon
				*ngIf="!htmlElementIncluded"
				icon="menu-vertical"
				[width]="2"
				[height]="10"
			></itd-icon>
			<ng-content *ngIf="htmlElementIncluded" select="itd-menu-parent"></ng-content>
		</button>
	</div>

	<div
		*ngIf="isActive"
		#content
		class="menu__content"
		[ngStyle]="positionStyle"
		[ngClass]="{ 'is-bottom': position === 'bottom' }"
		[@fadeAnimation]
	>
		<ng-content select="itd-menu-item"></ng-content>
	</div>
</div>
