import { Dayjs } from 'dayjs';

export class SetAdminAnalyticsFrom {
	public static readonly type = '[AdminAnalytic] Set From';
	constructor(public payload: { date: Dayjs }) {}
}

export class SetAdminAnalyticsTo {
	public static readonly type = '[AdminAnalytic] Set To';
	constructor(public payload: { date: Dayjs }) {}
}

export class SetAdminAnalyticsDepartmentId {
	public static readonly type = '[AdminAnalytic] Set DepartmentId';
	constructor(public payload: { department_id: string }) {}
}
