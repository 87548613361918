<itd-mat-sidebar [size]="480" icon="timeline">
	<ng-template #header>
		{{ 'CONTENT.MODULES.USED_IN_TIMELINES_HEADER' | translate }}
	</ng-template>

	<ng-template #content>
		<div class="content-header">
			<p>{{ 'CONTENT.MODULES.USED_IN_TIMELINES' | translate }}.</p>
		</div>
		<itd-table [dataSource]="timelinesDataSource">
			<itd-table-empty>
				<p>
					{{ 'TIMELINES.NOT_FOUND' | translate }}
				</p>
			</itd-table-empty>

			<ng-container itdColumnDef [width]="280">
				<div *itdCellHeaderDef>
					{{ 'TITLE' | translate }}
				</div>
				<div class="user-link" *itdCellDef="let timeline">
					{{ timeline?.title }}
				</div>
			</ng-container>

			<ng-container itdColumnDef [width]="120" textAlign="center">
				<div *itdCellHeaderDef>
					{{ 'ACTIONS' | translate }}
				</div>
				<div *itdCellDef="let timeline" class="no-font">
					<itd-menu>
						<itd-menu-item
							icon="timeline"
							[width]="12"
							[height]="12"
							(onClick)="goToTimeline(timeline)"
						>
							{{ 'TIMELINES.VIEW' | translate }}
						</itd-menu-item>
					</itd-menu>
				</div>
			</ng-container>

			<itd-table-header-row *itdRowHeaderDef></itd-table-header-row>
			<itd-table-row [height]="56" *itdRowDef="let timeline"></itd-table-row>
		</itd-table>
	</ng-template>
	<ng-template #footer></ng-template>
</itd-mat-sidebar>
