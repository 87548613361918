import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
	providedIn: 'root',
})
export class ErrorHandlerService {
	constructor(private toaster: ToastrService) {}

	public handleError(err: HttpErrorResponse) {
		const errorDetails = err.error?.data?.error ? err.error?.data?.error : null;
		// let errorMessage: string;

		// if (err.error instanceof ErrorEvent) {
		// 	errorMessage = err.error.message;
		// 	this.toaster.error(err.error.message);
		// } else {
		// 	// This else could mean its a server error

		// }
		if (errorDetails) {
			this.toaster.error(errorDetails, err.error.message);
		} else {
			this.toaster.error(err.error.message);
		}
	}

	public handleLockedContentError(err: HttpErrorResponse) {
		this.toaster.warning('Please come back later', 'Locked Content');
	}
}
