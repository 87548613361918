import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs';
import { AbstractApiState } from '../abstract/abstract-api-state';
import { IPagination } from '../constans';
import {
	AddPagination,
	DeleteSuperAdminUser,
	LoadAllSuperAdminUsers,
	LoadAllSuperAdminUsersIfEmpty,
	LoadSuperAdminUsers,
} from './superadmin-users.action';
import {
	ISuperAdminUser,
	ISuperAdminUserDTO,
	ISuperAdminUserEdit,
	ISuperAdminUserEditDTO,
} from './superadmin-users.interface';
import { SuperAdminUserService } from './superadmin-users.service';

export interface SuperAdminUsersStateModel {
	items: ISuperAdminUser[];
	pagination?: IPagination;
}

@State<SuperAdminUsersStateModel>({
	name: 'superadmin_users',
	defaults: {
		items: null,
		pagination: null,
	},
})
@Injectable()
export class SuperAdminUsersState extends AbstractApiState<
	ISuperAdminUser,
	ISuperAdminUserDTO,
	ISuperAdminUserEdit,
	ISuperAdminUserEditDTO
> {
	constructor(protected superAdminService: SuperAdminUserService, store: Store) {
		super(superAdminService, store);
	}

	@Selector()
	public static getUsers(state: SuperAdminUsersStateModel) {
		return state.items;
	}

	@Selector()
	public static getPagination(state: SuperAdminUsersStateModel) {
		return state.pagination;
	}

	@Action(LoadAllSuperAdminUsers)
	loadAllSuperAdminUsers(
		ctx: StateContext<SuperAdminUsersStateModel>,
		params: LoadAllSuperAdminUsers
	) {
		return super.loadAllAndAddToState(ctx, params.params);
	}

	@Action(LoadSuperAdminUsers)
	loadSuperAdminUser(
		ctx: StateContext<SuperAdminUsersStateModel>,
		payload: LoadAllSuperAdminUsers
	) {
		return super.loadAll(ctx, payload.params);
	}

	@Action(LoadAllSuperAdminUsersIfEmpty)
	getSuperAdminUsersIfEmpty(
		ctx: StateContext<SuperAdminUsersStateModel>,
		params: LoadAllSuperAdminUsersIfEmpty
	) {
		return super.loadAllIfEmpty(ctx, params.params);
	}

	@Action(DeleteSuperAdminUser)
	public delete(ctx: StateContext<SuperAdminUsersStateModel>, data: DeleteSuperAdminUser) {
		return super.delete(ctx, data).pipe(
			tap(() => {
				const _items = ctx.getState().items;

				if (!_items || !_items.length) {
					return;
				}

				const items = [..._items];
				const index = items.findIndex(item => item.user_id === data.payload.id);

				if (index === -1) {
					return;
				}

				items.splice(index, 1);

				ctx.patchState({
					items,
				});
			})
		);
	}

	@Action(AddPagination)
	public addPagination(ctx: StateContext<SuperAdminUsersStateModel>, data: AddPagination) {
		return ctx.patchState(data.payload);
	}
}
