<div class="wrap wrap--default">
	<div class="container">
		<div class="header">
			<ng-template [ngTemplateOutlet]="header"></ng-template>

			<button type="button" class="button-close click-expand" (click)="onClose.emit()">
				<itd-icon icon="close" [width]="8" [height]="8"></itd-icon>
			</button>
		</div>

		<div class="body">
			<ng-template [ngTemplateOutlet]="body"></ng-template>
		</div>

		<div class="footer">
			<itd-organization-logo></itd-organization-logo>
			<ng-template [ngTemplateOutlet]="footer"></ng-template>
		</div>
	</div>
</div>
