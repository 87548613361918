import { Injectable } from '@angular/core';
import { ApiClientService } from '../api/api.service';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { TResults } from '../models/results.interface';
import { map } from 'rxjs/operators';
import { IUser, IUserDTO, IUserEdit, IUserEditDTO } from './users.interface';
import { AbstractApiService } from '../abstract/abstract-api-service';
import { AddLoading, RemoveLoading } from '../layout/layout.actions';
import { AddPaginationUser } from './users.action';

@Injectable({
	providedIn: 'root',
})
export class UsersService extends AbstractApiService<IUser, IUserDTO, IUserEdit, IUserEditDTO> {
	protected key: string = 'users';

	constructor(protected api: ApiClientService, store: Store) {
		super(api, store);
	}
	public getAll(params: any): Observable<IUser[]> {
		this.store.dispatch(AddLoading);

		return this.api.get<TResults<IUserDTO[]>>('api/users', params).pipe(
			map(response => {
				this.store.dispatch(RemoveLoading);
				this.store.dispatch(new AddPaginationUser({ pagination: response?.pagination }));
				return response.data.map(user => this.parse(user));
			})
		);
	}

	public invite(data: any): Observable<IUser> {
		return this.api
			.post<TResults<IUserDTO>>('api/invite-flow', data, {})
			.pipe(map(response => this.parse(response.data)));
	}

	public resendInvite(userId: string, messageId: string): Observable<string> {
		return this.api
			.post<any>(`api/users/${userId}/resend`, { message_id: messageId })
			.pipe(map(res => res.message));
	}

	public lostPassword(userId: string): Observable<string> {
		return this.api
			.post<TResults>(`api/users/lost-password`, { user_id: userId })
			.pipe(map(res => res.message));
	}

	// TODO: Refactor once we graveyard old frontend (backend is unmalleable until then)
	public inviteMultiple(data: any): Observable<any> {
		const dataObj = {
			org_id: data.org_id,
			invites: data.invites.users,
			department_id: data.department_id,
			tasks_template: data.invites.tasks_template,
			send_message: data.invites.send_message,
			start_fasttrack: data.invites.start_fasttrack,
		};

		return this.api
			.post<TResults<IUserDTO>>('api/users/invite', dataObj, {})
			.pipe(map(response => this.parse(response.data)));
	}

	public publicSignUp(data: any): Observable<IUser> {
		return this.api
			.post<TResults<any>>(`open-api/org/sign-up/${data.org_id}`, data.signUpObject)
			.pipe(map(response => this.parse(response.data)));
	}

	protected parse(value: IUserDTO): IUser {
		if (!value?.tags) {
			return {
				...value,
				tags: [],
			};
		}

		const { tags, ...rest } = value;

		return {
			...rest,
			tags: tags ? tags.map((tag: any) => tag.name) : [],
		};
	}

	protected parseEditDTO(value: IUserEdit): IUserEditDTO {
		return {
			...value,
		};
	}
}
