import {
	IChangeProfilePasswordDTO,
	IActivateProfile,
	IUpdateProfile,
	IProfileAlertConfiguration,
} from './profile.interface';

export class GetProfile {
	public static readonly type = '[Profile] Get';
}

export class UpdateProfile {
	public static readonly type = '[Profile] Update';
	constructor(public payload: { profile: IUpdateProfile }) {}
}

export class UpdateProfileAlertConfiguration {
	public static readonly type = '[Profile] Update Alert Configuration';
	constructor(public payload: IProfileAlertConfiguration) {}
}

export class SetProfileAvatar {
	public static readonly type = '[Profile] Set Avatar';
	constructor(public payload: { avatar: File }) {}
}

export class GenerateProfileOpenApiToken {
	public static readonly type = '[Profile] Generate Open Api Token';
}

export class ChangeProfilePassword {
	public static readonly type = '[Profile] Change Password';
	constructor(public payload: IChangeProfilePasswordDTO) {}
}

export class ActivateProfile {
	public static readonly type = '[Profile] Activate';
	constructor(public payload: IActivateProfile) {}
}

// export class DeleteProfile {
// 	public static readonly type = '[Profile] Delete';
// }
