import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { IOrganization } from './organizations.interface';
import { OrganizationService } from './organizations.service';

export const PublicSignupOrganizationResolver: ResolveFn<IOrganization> = (route: ActivatedRouteSnapshot) => {
	const organizationService = inject(OrganizationService)
	const id: string = route.params['id'];
	const linkId: string = route.queryParams['link_id'];

	return organizationService.getPublicSignup(id, linkId);
}
