import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Optional,
	Output,
} from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
	TErrorMessage,
	TValidationMessages,
	ValidationMessagesService,
} from 'src/app/core/services/validation-messages.service';

@Component({
	template: '',
})
export abstract class AbstractInputComponent<T> implements OnInit, AfterViewInit {
	@Input() public name: string;
	@Input() public sizeType: 'tiny' | 'small' | 'default' | 'big' = 'default';
	@Input() public styleType: 'primary' | 'grey' = 'primary';
	@Input() public label?: string = '';
	@Input() public sideLabel?: string = '';
	@Input() public placeholder: string = '';
	@Input() public id: string;
	@Input() public customMessages: TValidationMessages;

	@Input() public icon?: string;

	@Input() public hasShadow: boolean = true;
	@Input() public hasBorder: boolean = false;
	@Input() public inputBorder: boolean | string = true;

	@Input() public iconWidth: number = 24;
	@Input() public iconHeight: number = 24;

	@Input() public set isDisabled(value: boolean) {
		this._disabled = value;
	}

	public get isDisabled(): boolean {
		if (this.control?.disabled) {
			return true;
		}

		return this._disabled;
	}

	@Output() public firstFocus = new EventEmitter<AbstractControl>();
	@Output() public focus = new EventEmitter<AbstractControl>();
	@Output() public blur = new EventEmitter<AbstractControl>();
	@Output() public write = new EventEmitter<T>();

	@Input() public value: T;

	public isFocus: boolean = false;
	public wasFocused: boolean = false;

	public message$: Observable<TErrorMessage>;

	private _disabled: boolean = false;

	public get invalid(): boolean {
		return this.control ? this.control.invalid && this.control.touched : false;
	}

	public get required(): boolean {
		if (!this.control || !this.control.control) {
			return false;
		}

		if (!this.control.control.validator) {
			return false;
		}

		const validator = this.control.control.validator({} as AbstractControl);

		return validator ? validator.required : false;
	}

	public get actualLength(): number {
		if (!this.control || !this.control.control) {
			return 0;
		}

		const value = this.control.control.value;

		if (!value || !value.length) {
			return 0;
		}

		return value.length;
	}

	public get isWarning(): boolean {
		if (!this.control || !this.control.control) {
			return false;
		}

		if (!this.control.control.errors) {
			return false;
		}

		return (
			this.control.control.errors['warning'] !== undefined &&
			this.control.control.errors['warning'] !== null
		);
	}

	constructor(
		@Optional() public control: NgControl,
		private validationMessages: ValidationMessagesService
	) {
		if (control) {
			this.control.valueAccessor = this;
		}
	}

	public ngOnInit() {
		this.id = this.id || this.name;

		if (!this.control) {
			return;
		}

		if (this.control?.statusChanges) {
			this.message$ = this.control.statusChanges.pipe(
				map(() => {
					return this.validationMessages.parseMessage(this.control.errors, this.customMessages);
				})
			);
		} else {
			setTimeout(() => {
				if (this.control?.statusChanges) {
					this.message$ = this.control.statusChanges.pipe(
						map(() => {
							return this.validationMessages.parseMessage(this.control.errors, this.customMessages);
						})
					);
				}
			});
		}
	}

	public ngAfterViewInit(): void {
		if (this.control?.statusChanges) {
			this.message$ = this.control.statusChanges.pipe(
				map(() => {
					return this.validationMessages.parseMessage(this.control.errors, this.customMessages);
				})
			);
		}
	}

	public setFocus(isFocus: boolean): void {
		if (this.isDisabled) {
			return;
		}

		this.isFocus = isFocus;
		const control = this.control ? this.control.control : null;

		if (!this.wasFocused && isFocus) {
			this.wasFocused = true;
			this.firstFocus.emit(control);
		}

		if (!isFocus) {
			this.blur.emit(control);
		} else {
			this.focus.emit(control);
		}
	}

	public writeValue(value: T): void {
		this.value = value;
		this.propagateChange(value);
		this.write.emit(value);
	}

	public registerOnChange(fn): void {
		this.propagateChange = fn;
	}

	public registerOnTouched(): void {}

	// tslint:disable-next-line: no-any
	public propagateChange: any = () => {};
}
