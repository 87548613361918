<itd-modal [size]="1600">
	<ng-template #header>
	</ng-template>
	<ng-template #content>
		<itd-admin-departments-page
			[user]="user"
			[selectedDepartmentId]="selectedDepartmentId"
			(departmentSelected)="departmentSelected($event)"
		></itd-admin-departments-page>
	</ng-template>

	<ng-template #footer>
		<itd-button (click)="onSelect()" [width]="120">
			{{ 'SELECT' | translate }}
		</itd-button>
	</ng-template>
</itd-modal>
