<div class="container" [ngStyle]="{ 'maxWidth.px': size, 'minWidth.px': minWidth, 'padding.px': padding }">
	<div class="header" *ngIf="header">
		<div
			class="header__wrapper"
			[ngClass]="{
				'space-left': icon,
				'header-button': headerButton
			}"
		>
			<div class="decoration-icon" *ngIf="icon">
				<itd-icon [icon]="icon" [width]="14" [height]="14"></itd-icon>
			</div>

			<ng-template [ngTemplateOutlet]="header"></ng-template>

			<button type="button" class="close-button" (click)="close()">
				<itd-icon icon="close" [width]="12" [height]="12"></itd-icon>
			</button>
		</div>
	</div>

	<div class="content">
		<ng-template [ngTemplateOutlet]="content"></ng-template>
	</div>

	<div class="footer" *ngIf="footer">
		<ng-template [ngTemplateOutlet]="footer"></ng-template>
	</div>
</div>
