import { AbstractFormComponent } from 'src/app/utility-modules/itd-form/components/abstract-form/abstract-form.component';
import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	ElementRef,
	Input,
} from '@angular/core';
import { FormGroup, Validators, FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { CreateMedia, LoadAllMedia } from 'src/app/core/media/media.action';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { NotificationService } from 'src/app/utility-modules/notification/services/notification.service';
import { CropImageModalComponent } from 'src/app/shared/modals/crop-image-modal/crop-image-modal.component';
import { removeImagesLargerThan5Mb } from 'src/app/core/utils/files.util';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../../../../utility-modules/itd-form/components/button/button.component';
import { FormErrorComponent } from '../../../../../../utility-modules/itd-form/components/form-error/form-error.component';
import { InputFileComponent } from '../../../../../../utility-modules/itd-form/components/input-file/input-file.component';
import { ModalComponent } from '../../../../../../utility-modules/modals/components/modal/modal.component';

interface IFormValues {
	files: File[];
}

@Component({
    selector: 'itd-admin-content-media-upload-modal',
    templateUrl: './admin-content-media-upload-modal.component.html',
    styleUrls: ['./admin-content-media-upload-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ReactiveFormsModule,
        ModalComponent,
        InputFileComponent,
        FormErrorComponent,
        ButtonComponent,
        TranslateModule,
    ],
})
export class AdminContentMediaUploadModalComponent extends AbstractFormComponent<IFormValues>
	implements OnInit {
	@Input() public afterSubmitSuccess: () => any;
	@Input() public aspectRatio: number;

	constructor(
		public store: Store,
		public validationMessages: ValidationMessagesService,
		public elementRef: ElementRef,
		protected cd: ChangeDetectorRef,
		private activeModal: NgbActiveModal,
		private notification: NotificationService,
		private modals: NgbModal
	) {
		super(cd);
	}

	ngOnInit(): void {
		this.form = new FormGroup({
			files: new FormControl([], [Validators.required]),
		});
		this.afterInit();
	}

	public fileUploaded(uploadData: any) {
		console.log(uploadData);

		let data = removeImagesLargerThan5Mb(uploadData);

		if (data.length !== uploadData.length) {
			this.form.patchValue({ files: data });
			this.notification.add({
				text: 'BACKEND_MESSAGES.FILE_SIZE_EXCEEDED',
				type: 'error',
			});
			return;
		}

		if (!data || data.length === 0 || !this.aspectRatio) {
			return;
		}

		const notCroppedImage = data.find(
			img => !img.name.includes('_cropped_' + this.aspectRatio?.toFixed(2).replace('.', '_'))
		);

		if (!notCroppedImage) {
			return;
		}

		const notCroppedImageIndex = data.findIndex(
			img => !img.name.includes('_cropped_' + this.aspectRatio?.toFixed(2).replace('.', '_'))
		);

		this.openImageCropper(notCroppedImage, notCroppedImageIndex);
	}

	private openImageCropper(image, index) {
		const component = this.modals.open(CropImageModalComponent, {
			backdrop: 'static',
			keyboard: false,
		});
		const componentInstance = component.componentInstance as CropImageModalComponent;
		componentInstance.aspectRatio = this.aspectRatio;
		componentInstance.image = image;

		component.closed.subscribe(newImage => {
			if (!newImage) {
				this.form.patchValue({ files: [] });
				return;
			}

			let newFiles = [...this.form.value.files];
			newFiles[index] = newImage;
			this.form.patchValue({ files: newFiles });
		});
	}

	protected onSubmitAction() {
		return this.store.dispatch(new CreateMedia(this.formValue));
	}

	protected onSubmitSuccess(result) {
		this.notification.add({
			text: 'BACKEND_MESSAGES.MEDIA_CREATE_SUCCESS',
		});

		this.activeModal.close(result.media.lastCreated);
		this.store.dispatch(new LoadAllMedia());
		this.afterSubmitSuccess();
	}
}
