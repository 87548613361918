import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
	HeadwayWidgetClassName,
	HeadwayWidgetId,
	HeadwayWidgetSelector,
	HeadwayWidgetTriggerSelector,
} from '../headwayWidgetConstants';

interface WidgetOptions {
	trigger?: string;
}

@Component({
	selector: 'headway-widget-badge',
	templateUrl: './headway-widget-badge.component.html',
	styleUrls: ['./headway-widget-badge.component.scss'],
	standalone: true,
})
export class HeadwayWidgetBadgeComponent implements OnInit, OnDestroy {
	@Input() id: string = HeadwayWidgetId;
	@Input() account: string = 'yoVpWJ';
	@Input() trigger: boolean = false;
	@Input() badgePosition: string = 'bottom-right';
	@Input() widgetPosition: string = 'bottom-right';
	@Input() translations = {};
	@Input() options: WidgetOptions = {};

	@Output() widgetReady = new EventEmitter<boolean>();
	@Output() showWidget = new EventEmitter<boolean>();
	@Output() showDetails = new EventEmitter<boolean>();
	@Output() readMore = new EventEmitter<boolean>();
	@Output() hideWidget = new EventEmitter<boolean>();

	headwayWidgetClassName = HeadwayWidgetClassName;

	initHeadway() {
		const hwConfig = {
			selector: HeadwayWidgetSelector,
			account: this.account,
			trigger: this.trigger
				? HeadwayWidgetSelector + `_${this.id}`
				: this.options.trigger || HeadwayWidgetTriggerSelector + `_${this.id}`,
			callbacks: {
				onWidgetReady: widget => {
					this.widgetReady.emit(widget);
				},
				onShowWidget: () => this.showWidget.emit(),
				onShowDetails: changelog => {
					this.showDetails.emit(changelog);
				},
				onReadMore: changelog => this.readMore.emit(changelog),
				onHideWidget: () => this.hideWidget.emit(),
			},
			__component: true,
			badgePosition: this.badgePosition,
			widgetPosition: this.widgetPosition,
			translations: this.translations,
			...this.options,
		};
		(this as any).widget = (window as any).Headway.getNewWidget();
		(this as any).widget.init(hwConfig);
	}

	ngOnDestroy(): void {
		(this as any).widget?.destroy();
	}

	ngOnInit(): void {
		const head = document.getElementsByTagName('head')[0];
		if ((window as any).Headway) {
			head.removeChild(document.getElementById('HWScript'));
		}
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.id = 'HWScript';
		script.onload = () => {
			this.initHeadway();
		};
		script.src = 'https://cdn.headwayapp.co/widget.js';
		head.appendChild(script);
	}
}
