import {
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	Input,
	TemplateRef,
} from '@angular/core';
import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
	selector: 'itd-panel',
	templateUrl: './panel.component.html',
	styleUrls: ['./panel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass, NgIf, NgTemplateOutlet],
})
export class PanelComponent {
	@ContentChild('header') public header: TemplateRef<any>;
	@ContentChild('body') public body: TemplateRef<any>;

	@Input() public isActiveBody: boolean = true;
}
