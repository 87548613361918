<div class="table-empty" [ngClass]="{'isInline': inline}">
    <ng-container *ngIf="!heading">
        <div class="table-empty-custom is-{{align}}">
            <ng-content></ng-content>
        </div>
    </ng-container>

    <ng-container>
        <p *ngIf="heading" class="table-empty__title" [innerHTML]="heading"></p>
        <ng-container *ngIf="rowCount > 0">

            <div class="table-empty-row" *ngFor="let item of createArray; let i = index; let last = last"
                [ngStyle]="{'height.px': rowHeight}" [ngClass]="{
                'is-last': last,
                'is-white': i >= rowCount - 2
                }">
                <div class="table-empty-row__item" *ngFor="let element of rowElements"
                    [ngClass]="{'table-empty-row__item--grow': element.grow}">
                    <div class="table-empty-row__{{element.name}}" [ngStyle]="{
                            'width.px': element.width ? element.width : element.randomWidth ? randomSquareWidth : null,
                            'background': element.color
                        }">
                    </div>
                </div>
            </div>
        </ng-container>

    </ng-container>

</div>
