import { Directive, TemplateRef } from '@angular/core';

@Directive({
    selector: '[itdRowHeaderDef]',
    standalone: true
})
export class HeaderRowDefDirective<T> {
	// tslint:disable-next-line: no-any
	constructor(public template: TemplateRef<any>) {}
}
