import { Injectable } from '@angular/core';
import { ApiClientService } from '../api/api.service';
import { Store } from '@ngxs/store';
import { IPersonalEIS, IPersonalEISDTO } from './eis.interface';
import { AbstractApiService } from '../abstract/abstract-api-service';
import { Observable, map } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class EISService extends AbstractApiService<IPersonalEIS, IPersonalEISDTO> {
	protected key = 'eis';

	constructor(protected api: ApiClientService, protected store: Store) {
		super(api, store);
	}

	public getPersonalEIS(): Observable<IPersonalEIS> {
		return this.api.get<any>(`api/${this.key}/me`).pipe(map((response) => response?.data));
	}

	protected parse(value: IPersonalEISDTO): IPersonalEIS {
		const { user_id } = value;

		return {
			...value,
			id: user_id,
		};
	}

	public parseEditDTO(value: IPersonalEIS): IPersonalEISDTO {
		const { id } = value;

		return {
			...value,
			user_id: id,
		};
	}
}
