import { TResults } from './../models/results.interface';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ILanguage } from '../models/language.interface';
import { map } from 'rxjs/operators';
import { ApiClientService } from '../api/api.service';

@Injectable({
	providedIn: 'root',
})
export class DictionaryService {
	constructor(private api: ApiClientService) {}

	public getLanguages(): Observable<ILanguage[]> {
		return this.api
			.get<TResults<ILanguage[]>>('api/dictionary/languages')
			.pipe(map(results => results.data));
	}
}
