import { Directive, Input, ElementRef, OnDestroy } from '@angular/core';

@Directive({
    selector: '[itdLoading]',
    standalone: true,
})
export class LoadingDirective implements OnDestroy {
	private loadingEl: HTMLElement;

	@Input() public itdLoadingIsBlock: boolean;

	@Input('itdLoading')
	public set setActive(value: boolean) {
		switch (value) {
			case true:
				this.append();
				return;

			case false:
				this.remove();
				return;
		}
	}

	constructor(private el: ElementRef<HTMLElement>) {}

	public ngOnDestroy() {
		this.remove();
	}

	append() {
		if (this.loadingEl) {
			return;
		}

		this.loadingEl = document.createElement('div');
		this.loadingEl.classList.add('loading');

		if (this.itdLoadingIsBlock) {
			this.loadingEl.classList.add('isBlock');
		}

		const innerEl = document.createElement('div');
		innerEl.classList.add('loading__inner');

		this.loadingEl.appendChild(innerEl);

		this.el.nativeElement.parentElement.appendChild(this.loadingEl);
		innerEl.appendChild(this.el.nativeElement);
	}

	remove() {
		if (!this.loadingEl) {
			return;
		}

		this.loadingEl.parentElement.appendChild(this.el.nativeElement);
		this.loadingEl.remove();
		this.loadingEl = null;
	}
}
