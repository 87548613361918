import { trigger, transition, style, animate } from '@angular/animations';

export const fromRightAnimation = trigger('fromRightAnimation', [
	transition(':enter', [
		style({
			transform: 'translateX(100%)',
		}),
		animate(
			'.2s ease-in',
			style({
				transform: 'translateX(0)',
			})
		),
	]),
	transition(':leave', [
		style({
			transform: 'translateX(0)',
		}),
		animate(
			'.2s ease-in',
			style({
				transform: 'translateX(100%)',
			})
		),
	]),
]);
