import { Observable } from 'rxjs';
import {
	Component,
	ChangeDetectionStrategy,
	Input,
	OnInit,
	ChangeDetectorRef,
	ElementRef,
	Output,
	EventEmitter,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { AbstractFormComponent } from 'src/app/utility-modules/itd-form/components/abstract-form/abstract-form.component';
import { confirmationValidator } from 'src/app/core/validators/confirmation.validator';
import { NotificationService } from 'src/app/utility-modules/notification/services/notification.service';
import { TranslateModule } from '@ngx-translate/core';
import { UpperCasePipe } from '@angular/common';
import { FormErrorComponent } from '../../../utility-modules/itd-form/components/form-error/form-error.component';
import { ButtonComponent } from '../../../utility-modules/itd-form/components/button/button.component';
import { InputComponent } from '../../../utility-modules/itd-form/components/input/input.component';
import { ModalComponent } from '../../../utility-modules/modals/components/modal/modal.component';

type FormValue = {
	confirm?: string;
	reason?: string;
};

@Component({
	selector: 'itd-confirm-modal',
	templateUrl: './confirm-modal.component.html',
	styleUrls: ['./confirm-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		ReactiveFormsModule,
		ModalComponent,
		InputComponent,
		ButtonComponent,
		FormErrorComponent,
		UpperCasePipe,
		TranslateModule,
	],
})
export class ConfirmModalComponent extends AbstractFormComponent<FormValue> implements OnInit {
	@Input() data: {
		confirmText: string;
		successText: string;
	};
	@Input() public onSubmitAction: () => Observable<any>;
	@Output() public error = new EventEmitter();
	@Output() public success = new EventEmitter();
	public reasons: Array<{ name: any; value: string }>;
	constructor(
		private notification: NotificationService,
		public activeModal: NgbActiveModal,
		public validationMessages: ValidationMessagesService,
		public elementRef: ElementRef,
		public cd: ChangeDetectorRef
	) {
		super(cd);
	}

	public async ngOnInit() {
		const { confirmText = '' } = this.data;

		this.form = new FormGroup({});

		if (confirmText) {
			this.form.setControl(
				'confirm',
				new FormControl('', [Validators.required, confirmationValidator('CONFIRM')])
			);
		}
		super.afterInit();
	}

	protected onSubmitSuccess() {
		this.notification.add({
			text: this.data.successText,
		});

		this.success.emit();

		this.activeModal.close();
	}
}
