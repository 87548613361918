import { HttpErrorResponse } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable, of } from 'rxjs';
import { MoveDepartment } from 'src/app/core/department/department.actions';
import { IDepartment } from 'src/app/core/department/department.interface';
import { DepartmentState } from 'src/app/core/department/department.state';
import { IProfile } from 'src/app/core/profile/profile.interface';
import { ProfileState } from 'src/app/core/profile/profile.state';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { ImageUrlFromKey } from 'src/app/core/utils/image-url-from-key';
import { GridItemComponent } from 'src/app/utility-modules/grid/components/grid-item/grid-item.component';
import { AbstractFormComponent } from 'src/app/utility-modules/itd-form/components/abstract-form/abstract-form.component';
import { ButtonComponent } from 'src/app/utility-modules/itd-form/components/button/button.component';
import { FormErrorComponent } from 'src/app/utility-modules/itd-form/components/form-error/form-error.component';
import { SelectComponent } from 'src/app/utility-modules/itd-form/components/select/select.component';
import { ModalComponent } from 'src/app/utility-modules/modals/components/modal/modal.component';
import { NotificationService } from 'src/app/utility-modules/notification/services/notification.service';

@Component({
	selector: 'itd-move-department-modal',
	standalone: true,
	imports: [
		ModalComponent,
		ReactiveFormsModule,
		TranslateModule,
		GridItemComponent,
		SelectComponent,
		ButtonComponent,
		FormErrorComponent,
	],
	templateUrl: './move-department-modal.component.html',
	styleUrls: ['./move-department-modal.component.scss'],
})
export class MoveDepartmentModalComponent extends AbstractFormComponent implements OnInit {
	@Input() departmentToMove: IDepartment;

	@Select(ProfileState.profile) public profile$: Observable<IProfile>;
	@Select(DepartmentState.departments) private departments$: Observable<IDepartment[]>;

	@Output() public error = new EventEmitter<HttpErrorResponse>();
	@Output() public success = new EventEmitter<void>();

	public form: FormGroup;
	public filteredDepartments$: Observable<IDepartment[]> = of(null);

	constructor(
		public store: Store,
		public validationMessages: ValidationMessagesService,
		public elementRef: ElementRef,
		protected cd: ChangeDetectorRef,
		private activeModal: NgbActiveModal,
		public imageUrlFromKey: ImageUrlFromKey,
		private notification: NotificationService
	) {
		super(cd);
	}

	ngOnInit(): void {
		this.form = new FormGroup({
			new_parent_id: new FormControl<string>(null),
		});

		combineLatest([this.departments$, this.profile$]).subscribe(([departments, profile]) => {
			if (!!departments) {
				const departmentsWithFullChain = departments.map(department => {
					if (!!department.parent_chain) {
						return {
							...department,
							fullChain: department.parent_chain + '.' + department.department_id,
						};
					} else {
						return { ...department, fullChain: department.department_id };
					}
				});

				if (['super_admin', 'org_admin'].includes(profile.user_role)) {
					this.filteredDepartments$ = of(departmentsWithFullChain);
				} else if (!!profile.department_id) {
					let ids = profile.department_id.split('.');
					let userDepartmentId = ids.pop();
					let filteredDepartments = departmentsWithFullChain.filter(department => {
						if (department.department_id === userDepartmentId) {
							return true;
						} else {
							if (!department.parent_chain) {
								return false;
							} else {
								let parentIds = department.parent_chain.split('.');
								return parentIds.includes(userDepartmentId);
							}
						}
					});
					this.filteredDepartments$ = of(filteredDepartments);
				}
			}
		});
	}

	protected onSubmitAction(): Observable<any> {
		return this.store.dispatch(
			new MoveDepartment({
				department_id: this.departmentToMove?.department_id,
				new_parent_id: this.formValue.new_parent_id,
			})
		);
	}

	public onSubmitSuccess() {
		this.notification.add({
			text: 'BACKEND_MESSAGES.UPDATE_DEPARTMENT_SUCCESS',
		});
		this.activeModal.close();
	}
}
