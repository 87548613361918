import { ActivatedRoute, Router } from '@angular/router';
import { WithDestroyComponent } from 'src/app/core/abstract/abstract-with-destroy-component';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SelectComponent } from '../../../../utility-modules/itd-form/components/select/select.component';

@Component({
	selector: 'itd-sidebar-module-switcher',
	templateUrl: './sidebar-module-switcher.component.html',
	styleUrls: ['./sidebar-module-switcher.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [SelectComponent, ReactiveFormsModule, FormsModule],
})
export class SidebarModuleSwitcherComponent extends WithDestroyComponent implements OnInit {
	@Input() public isSuperAdmin = false;
	public value: string;

	// Superadmin has to be in the top, else change if statement in ngOnInit
	public items = [
		{
			title: 'Super Admin',
			value: 'superadmin',
		},
		{
			title: 'Admin',
			value: 'admin',
		},
		{
			title: 'User',
			value: 'user',
		},
	];

	constructor(private route: ActivatedRoute, private router: Router) {
		super();
	}

	ngOnInit(): void {
		this.route.parent?.url.pipe(takeUntil(this.destroy$)).subscribe(url => {
			const moduleValue = url[0].path;

			if (this.value === moduleValue) {
				return;
			}

			this.value = moduleValue;
		});

		// this checks if you're role can activate, if you cant then it removes the first item. So if you're not super admin, it removes it from the list.
		if (!this.isSuperAdmin) {
			this.items.shift();
		}
	}

	public onChange(value: string) {
		if (this.value === value) {
			return;
		}

		this.router.navigate([value]);
	}
}
