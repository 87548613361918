import { fadeAnimation } from './../../../../core/animations/fade.animation';
import { Input, Optional } from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { AbstractInputComponent } from '../abstract-input/abstract-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../../../icon/components/icon/icon.component';
import { NgClass, NgIf, NgFor, AsyncPipe } from '@angular/common';
import { DropDirective } from '../../directives/drop.directive';

@Component({
    selector: 'itd-input-file',
    templateUrl: './input-file.component.html',
    styleUrls: ['./input-file.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeAnimation],
    standalone: true,
    imports: [
        DropDirective,
        NgClass,
        NgIf,
        NgFor,
        IconComponent,
        AsyncPipe,
        TranslateModule,
    ],
})
export class InputFileComponent extends AbstractInputComponent<File[]>
	implements ControlValueAccessor {
	public isDraggedFocused: boolean = false;

	@Input() public allowedFormats?: string[] | string;
	@Input() public disallowedFormats?: string[];
	@Input() public formatLimits: {};
	@Input() public isMultiple: boolean = false;

	public get hasFiles(): boolean {
		return this.value && !!this.value.length;
	}

	public get selectedFiles(): File[] {
		return Array.from(this.value);
	}

	constructor(@Optional() control: NgControl, validationMessages: ValidationMessagesService) {
		super(control, validationMessages);
	}

	public inputChange(e: Event): void {
		const files = Array.from((e.target as HTMLInputElement).files);
		this.writeValue([...this.value, ...files]);
	}

	public droppedFiles(files: File[]) {
		this.writeValue([...this.value, ...files]);
	}

	public deleteFile(e: Event, index: number) {
		e.preventDefault();
		e.stopPropagation();

		const value = [...this.value];
		value.splice(index, 1);

		this.writeValue(value);
	}
}
