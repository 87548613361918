import {
	Component,
	ChangeDetectionStrategy,
	ContentChild,
	TemplateRef,
	Input,
} from '@angular/core';
import { SidenavService } from '../matsidebar.service';
import { IconComponent } from '../../icon/components/icon/icon.component';
import { NgStyle, NgIf, NgClass, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'itd-mat-sidebar',
    exportAs: 'sidebar',
    templateUrl: './matsidebar.component.html',
    styleUrls: ['./matsidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgStyle,
        NgIf,
        NgClass,
        IconComponent,
        NgTemplateOutlet,
    ],
})
export class MatSidebarComponent {
	@ContentChild('header') header: TemplateRef<any>;
	@ContentChild('content') content: TemplateRef<any>;
	@ContentChild('footer') footer: TemplateRef<any>;

	@Input() public icon?: string;
	@Input() public padding?: number = 0;
	@Input() public size?: number;
	@Input() public minWidth: number = 0;
	@Input() public headerButton: boolean = false;
	@Input() public closeFunction: any;

	constructor(private sidenav: SidenavService) {}

	public close() {
		this.sidenav.close();
	}
}
