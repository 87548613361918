<div class="container">
	<itd-organization-logo></itd-organization-logo>

	<ul class="item navigation">
		<li *ngFor="let item of navigation">
			<itd-navigation-item [data]="item"></itd-navigation-item>
		</li>
	</ul>

	<ul class="item actions">
		<ng-container *ngFor="let item of secondNavigation">
			<li>
				<itd-navigation-item [data]="item"></itd-navigation-item>
			</li>
		</ng-container>

		<li>
			<itd-navigation-item
				[data]="{
					icon: 'exit',
					title: 'LOGOUT'
				}"
				(click)="logout()"
			></itd-navigation-item>
		</li>

		<ng-container *ngIf="isAdmin">
			<li>
				<headway-widget-trigger>
					<itd-navigation-item
						[data]="{
							icon: 'question-mark-circle',
							title: 'WHATS_NEW'
						}"
					></itd-navigation-item>
					<headway-widget-badge></headway-widget-badge>
				</headway-widget-trigger>
			</li>
			<li>
				<itd-sidebar-module-switcher [isSuperAdmin]="isSuperAdmin"></itd-sidebar-module-switcher>
			</li>
		</ng-container>
	</ul>
</div>
