<div
	cdkDropList
	class="list"
	[ngClass]="{ 'is-sortable': isSortable }"
	(cdkDropListDropped)="sort($event)"
	[cdkDropListDisabled]="!isSortable"
>
	<div *ngFor="let item of data; let index = index">
		<div cdkDrag class="item" [ngClass]="{ 'is-hidden': isHidden(index) }">
			<div *cdkDragPlaceholder class="drag-placeholder"></div>

			<itd-checkbox [value]="isItemSelected(item)" (write)="click(item, index, $event)">
				<ng-container
					[ngTemplateOutlet]="labelRef.tpl"
					[ngTemplateOutletContext]="{ item: item }"
				></ng-container>
			</itd-checkbox>

			<itd-icon
				*ngIf="isSortable"
				cdkDragHandle
				class="drag-icon"
				icon="grid-circles"
				[height]="24"
				[width]="24"
			></itd-icon>
		</div>
	</div>
</div>
