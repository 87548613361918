import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { AbstractApiState } from '../abstract/abstract-api-state';
import { FastTrackConfigurationService } from '../fast-track-configuration/fast-track-configuration.service';
import {
	CreateFastTrackConfiguration,
	LoadFastTrackConfiguration,
	UpdateFastTrackConfiguration,
} from './fast-track-configuration.actions';
import {
	IFastTrackConfiguration,
	IFastTrackConfigurationDTO,
	IFastTrackConfigurationEdit,
	IFastTrackConfigurationEditDTO,
} from './fast-track-configuration.interface';

export interface IFasttrackConfigurationStateModel {
	items: IFastTrackConfiguration[];
	active?: IFastTrackConfiguration;
}

@State<IFasttrackConfigurationStateModel>({
	name: 'fasttrackconfiguration',
	defaults: {
		items: null,
		active: null,
	},
})
@Injectable()
export class FastTrackConfigurationState extends AbstractApiState<
	IFastTrackConfiguration,
	IFastTrackConfigurationDTO,
	IFastTrackConfigurationEdit,
	IFastTrackConfigurationEditDTO
> {
	constructor(protected fasttrackConfiguration: FastTrackConfigurationService, store: Store) {
		super(fasttrackConfiguration, store);
	}

	@Selector()
	public static getState(state: IFasttrackConfigurationStateModel) {
		return state.items;
	}

	@Selector()
	public static items(state: IFasttrackConfigurationStateModel) {
		return state.items;
	}

	@Action(LoadFastTrackConfiguration)
	public loadOneConfiguration(
		ctx: StateContext<IFasttrackConfigurationStateModel>,
		data: LoadFastTrackConfiguration
	) {
		const { patchState } = ctx;
		return super.loadOne(data.payload.id).pipe(
			tap(result => {
				patchState({
					active: result,
				});
			})
		);
	}

	@Action(CreateFastTrackConfiguration)
	public create(
		ctx: StateContext<IFasttrackConfigurationStateModel>,
		data: CreateFastTrackConfiguration
	) {
		return super.create(ctx, data);
	}

	@Action(UpdateFastTrackConfiguration)
	public update(
		ctx: StateContext<IFasttrackConfigurationStateModel>,
		data: UpdateFastTrackConfiguration
	) {
		return super.update(ctx, data);
	}
}
