import { IconModule } from './../icon/icon.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './components/modal/modal.component';

const declarations = [ModalComponent];

@NgModule({
    exports: [...declarations],
    imports: [CommonModule, IconModule, ...declarations],
})
export class ModalsModule {}
