import { LowerCasePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import * as dayjs from 'dayjs';
import _ from 'lodash';
import * as moment from 'moment';
import { map, Observable, of } from 'rxjs';
import { taskIcons } from 'src/app/core/constans';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { ITaskAssignment } from 'src/app/core/task-assignment/task-assignment.interface';
import { UpdateTask } from 'src/app/core/task/task.action';
import { ITask } from 'src/app/core/task/task.interface';
import { LoadAllUsersIfEmpty } from 'src/app/core/users/users.action';
import { IUser } from 'src/app/core/users/users.interface';
import { UsersState } from 'src/app/core/users/users.state';
import { AbstractFormComponent } from 'src/app/utility-modules/itd-form/components/abstract-form/abstract-form.component';
import { InputUserComponent } from 'src/app/utility-modules/itd-form/components/input-user/input-user.component';
import { NotificationService } from 'src/app/utility-modules/notification/services/notification.service';
import { GridItemComponent } from '../../../utility-modules/grid/components/grid-item/grid-item.component';
import { GridComponent } from '../../../utility-modules/grid/components/grid/grid.component';
import { IconComponent } from '../../../utility-modules/icon/components/icon/icon.component';
import { ButtonComponent } from '../../../utility-modules/itd-form/components/button/button.component';
import { FormErrorComponent } from '../../../utility-modules/itd-form/components/form-error/form-error.component';
import { InputColorPickerComponent } from '../../../utility-modules/itd-form/components/input-color-picker/input-color-picker.component';
import { InputDateComponent } from '../../../utility-modules/itd-form/components/input-date/input-date.component';
import { InputComponent } from '../../../utility-modules/itd-form/components/input/input.component';
import { ModalComponent } from '../../../utility-modules/modals/components/modal/modal.component';
import { TooltipDirective } from '../../directives/tooltip.directive';

type FormValue = {
	id?: string;
	title: string;
	description?: string;
	icon: string;
	color?: string;
	days_prior?: number;
	due_date?: string;
	reminders?: number[];
	reminder_0?: number;
	reminder_1?: number;
	reminder_2?: number;
	reminder_3?: number;
	reminder_4?: number;
	reminder_5?: number;
	reminder_6?: number;
	reminder_7?: number;
	reminder_date_0?: string;
	reminder_date_1?: string;
	reminder_date_2?: string;
	reminder_date_3?: string;
	reminder_date_4?: string;
	reminder_date_5?: string;
	reminder_date_6?: string;
	reminder_date_7?: string;
	default_assignee_id?: string;
	template_id?: string;
};

@Component({
	selector: 'itd-edit-task-modal',
	templateUrl: './edit-task-modal.component.html',
	styleUrls: ['./edit-task-modal.component.scss'],
	standalone: true,
	imports: [
		ReactiveFormsModule,
		ModalComponent,
		GridComponent,
		GridItemComponent,
		InputComponent,
		InputColorPickerComponent,
		NgFor,
		NgClass,
		IconComponent,
		NgIf,
		TooltipDirective,
		InputDateComponent,
		InputUserComponent,
		FormErrorComponent,
		ButtonComponent,
		TranslateModule,
		LowerCasePipe,
	],
})
export class EditTaskModalComponent extends AbstractFormComponent<FormValue> implements OnInit {
	public now: moment.Moment = moment();
	public color: string;
	public form: FormGroup;
	public availableUserRoles: object[];
	public icons = taskIcons.map(icon => icon.name);
	public selectedIcon: string;
	public startDate: string;
	public filteredUsers$: Observable<IUser[]> = of(null);

	@Input() public task: ITask;
	@Input() public task_template_id: string;
	@Input() public user: IUser;
	@Input() public index: number;
	@Input() public assignmentActive: boolean = false;

	@Output() public error = new EventEmitter<HttpErrorResponse>();
	@Output() public success = new EventEmitter<void>();

	@Select(UsersState.items) public users$: Observable<IUser[]>;

	constructor(
		public store: Store,
		public validationMessages: ValidationMessagesService,
		public elementRef: ElementRef,
		protected cd: ChangeDetectorRef,
		private activeModal: NgbActiveModal,
		private notification: NotificationService
	) {
		super(cd);
	}

	async ngOnInit() {
		this.store.dispatch(new LoadAllUsersIfEmpty({ params: { skip: 0, limit: 9999 } }));
		this.color = this.task?.color ? this.task.color : 'black';

		if (this.assignmentActive) {
			this.form = new FormGroup({
				id: new FormControl(null),
				title: new FormControl<FormValue['title']>(null, [Validators.required]),
				description: new FormControl<string>(null),
				icon: new FormControl<string>(null),
				color: new FormControl(this.color),
				due_date: new FormControl(
					((this.task as unknown) as ITaskAssignment)?.due_date.toString() ?? null,
					[Validators.required]
				),
				reminders: new FormArray([]),
				reminder_date_0: new FormControl(null),
				reminder_date_1: new FormControl(null),
				reminder_date_2: new FormControl(null),
				reminder_date_3: new FormControl(null),
				reminder_date_4: new FormControl(null),
				reminder_date_5: new FormControl(null),
				reminder_date_6: new FormControl(null),
				reminder_date_7: new FormControl(null),
				default_assignee_id: new FormControl<string>(null),
				template_id: new FormControl<string>(this.task_template_id),
			});
		} else {
			this.form = new FormGroup({
				id: new FormControl(null),
				title: new FormControl(null, [Validators.required]),
				description: new FormControl(null),
				icon: new FormControl(null),
				color: new FormControl(this.color),
				days_prior: new FormControl(null),
				reminders: new FormArray([]),
				reminder_0: new FormControl(null),
				reminder_1: new FormControl(null),
				reminder_2: new FormControl(null),
				reminder_3: new FormControl(null),
				reminder_4: new FormControl(null),
				reminder_5: new FormControl(null),
				reminder_6: new FormControl(null),
				reminder_7: new FormControl(null),
				default_assignee_id: new FormControl(null),
				template_id: new FormControl(this.task_template_id),
			});
		}

		// if no icon is selected, select the first one
		if (!this.formValue.icon && this.icons.length > 0) {
			this.onSelectIcon(this.icons[0]);
		}

		// if task has reminders, add them to the form
		this.populateReminders();

		this.filteredUsers$ = this.users$.pipe(map(users => users.filter(user => user?.full_name)));

		this.form.patchValue(this.task);
		this.selectedIcon = this.form.controls.icon.value;
	}

	public onSubmitAction() {
		// "serializing" the reminder data to match the DTO
		this.formValue.reminders = [];
		for (let i = 0; i < 8; i++) {
			if (this.assignmentActive) {
				this.formValue.reminders.push(
					dayjs(this.formValue.due_date).diff(this.formValue[`reminder_date_${i}`], 'days')
				);
			} else {
				this.formValue.reminders.push(parseInt(this.formValue[`reminder_${i}`]));
			}
		}

		const newData = {
			...this.formValue,
			reminders: this.formValue.reminders
				.filter(reminder => !isNaN(reminder)) // remove null values
				.map(reminder => ({ days_prior: parseInt(reminder.toString()) })), // convert to ITaskReminder
		};

		if (this.formValue.icon) {
			this.form.patchValue({ updatedAt: new Date(), color: this.color });
			if (this.assignmentActive) {
				this.activeModal.close({ id: newData.id, ...newData });
				return of();
			} else {
				return this.store.dispatch(new UpdateTask({ id: newData.id, data: newData }));
			}
		} else {
			this.notification.add({
				text: 'TASKS.NO_ICON',
				type: 'error',
			});
		}
	}

	public onSubmitSuccess() {
		this.notification.add({
			text: 'TASK_UPDATE_SUCCESS',
		});

		this.activeModal.close();
	}

	public onSelectIcon(icon: string) {
		this.selectedIcon = icon;
		this.form.patchValue({ icon });
	}

	public changeStartDate(date) {
		if (!date) {
			return;
		}
		this.formValue.due_date = dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
		this.calculateReminderDates();
	}

	// changes the reminder days prior when the user selects a new date
	public changeReminderDate(date, index) {
		if (!date || dayjs(date).isValid() === false) {
			return;
		}

		this.form.get('reminders').value[index] = {
			days_prior: dayjs(this.formValue.due_date).diff(date, 'days'),
		};
	}

	// add new reminder to the form
	public addReminder() {
		(<FormArray>this.form.get('reminders')).push(new FormControl(null));

		if (this.assignmentActive) {
			this.form.controls[`reminder_date_${this.form.get('reminders').value.length - 1}`].patchValue(
				this.now.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
			);
		}
	}

	// remove reminder from the form
	public removeReminder(index: number) {
		if (!this.assignmentActive) {
			this.form.controls[`reminder_${index}`].patchValue(null);
		}
		if (this.assignmentActive) {
			this.form.controls[`reminder_date_${index}`].patchValue(null);
			(<FormArray>this.form.get(`reminder_date_${index}`)).removeAt(index);
		}

		(<FormArray>this.form.get('reminders')).removeAt(index);
	}

	// populate reminder fields with the task's reminders
	private populateReminders(task: ITask = this.task) {
		if (task?.reminders?.length > 0) {
			task.reminders
				.map(reminder => reminder.days_prior)
				.forEach((reminder_days, index) => {
					(<FormArray>this.form.get('reminders')).push(new FormControl(reminder_days));
					if (!this.assignmentActive) {
						this.form.controls[`reminder_${index}`].patchValue(reminder_days);
					}

					if (this.assignmentActive) {
						this.form.controls[`reminder_date_${index}`].patchValue(
							this.calculateReminderDate(reminder_days)
						);
					}
				});
		}
	}

	private calculateReminderDates() {
		const reminders = this.form.get('reminders').value;
		reminders
			.map(reminder => reminder.days_prior)
			.forEach((reminder, index) => {
				this.form.controls[`reminder_date_${index}`].patchValue(
					this.calculateReminderDate(reminder)
				);
			});
	}

	private calculateReminderDate(daysPrior: number, dueDate = this.formValue.due_date) {
		const reminderDate = dayjs(dueDate)
			.subtract(daysPrior, 'days')
			.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

		return reminderDate;
	}
}
