<div #container>
	<button type="button" class="button" (click)="toggle()" [ngClass]="{ isActive: isActive }">
		<p class="button__title" *ngIf="activeItem">
			{{ 'NAVIGATION.' + activeItem.title | translate  }}
		</p>

		<div class="decoration">
			<div class="decoration__icon">
				<itd-icon icon="chevron-down" [height]="5" [width]="9"></itd-icon>
			</div>
		</div>
	</button>

	<div class="content" *ngIf="isActive" [@fadeAnimation]>
		<a
			class="link"
			routerLinkActive="isActive"
			*ngFor="let item of items"
			[routerLink]="item.url"
			(click)="toggle()"
		>
			{{ 'NAVIGATION.' + item.title | translate }}
		</a>
	</div>
</div>
