<itd-header-mobile *ngIf="isMobile"></itd-header-mobile>

<aside class="sidebar" *ngIf="!isMobile">
	<itd-sidebar></itd-sidebar>
</aside>

<mat-drawer-container>
	<mat-drawer #drawer class="drawer" position="end">
		<ng-container *ngIf="!isStdUser">
			<itd-filters *ngIf="sidenavService.sidenavType === 'filters'"></itd-filters>
			<itd-user-sidedrawer
				*ngIf="sidenavService.sidenavType === 'user_sidedrawer'"
				[user]="sidenavService.user"
			></itd-user-sidedrawer>
			<itd-module-used-in-timelines
				*ngIf="sidenavService.sidenavType === 'module-used-in-timelines'"
			></itd-module-used-in-timelines>
		</ng-container>
	</mat-drawer>
	<mat-drawer-content>
		<main class="main">
			<div class="wrap wrap--default">
				<div class="wrapper" [@routeAnimations]="prepareRoute(outlet)">
					<router-outlet #outlet="outlet" />
				</div>
			</div>
		</main>
	</mat-drawer-content>
</mat-drawer-container>

<itd-footer-navigation-mobile *ngIf="isMobile"></itd-footer-navigation-mobile>
<itd-footer-navigation-side-drawer-mobile
	*ngIf="isMobile"
></itd-footer-navigation-side-drawer-mobile>
