<div class="buttons-and-folders">
	<div *ngIf="currentFolderHistory.length || folders.length > maxFoldersNumber" class="buttons">
		<itd-button-back *ngIf="currentFolderHistory.length" (click)="goBack()"></itd-button-back>
		<div
			*ngIf="folders.length > maxFoldersNumber && !showAllFoldersActive"
			class="buttons-show-hide-folders"
			(click)="showAllFolders()"
		>
			{{ 'FOLDERS.VIEW_ALL' | translate }}
		</div>
		<div
			*ngIf="folders.length > maxFoldersNumber && showAllFoldersActive"
			class="buttons-show-hide-folders"
			(click)="hideFolders()"
		>
			{{ 'FOLDERS.HIDE_ALL' | translate }}
		</div>
	</div>
	<div class="folders">
		<ng-container *ngIf="showAllFoldersActive">
			<div
				class="folders-item"
				*ngFor="let folder of folders"
				(click)="folderClicked(folder)"
				[ngClass]="{ 'folders-item-small': sizeSmall }"
			>
				<itd-icon
					icon="folder"
					[width]="sizeSmall ? 25 : 40"
					[height]="sizeSmall ? 25 : 40"
					class="folders-item-image"
					[ngClass]="{ 'folders-item-image-small': sizeSmall }"
				></itd-icon>
				<div class="folders-item-title" [ngClass]="{ 'folders-item-title-small': sizeSmall }">
					{{ folder.name }}
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="!showAllFoldersActive">
			<div
				class="folders-item"
				*ngFor="let folder of foldersLimited"
				(click)="folderClicked(folder)"
				[ngClass]="{ 'folders-item-small': sizeSmall }"
			>
				<itd-icon
					icon="folder"
					[width]="sizeSmall ? 25 : 40"
					[height]="sizeSmall ? 25 : 40"
					class="folders-item-image"
					[ngClass]="{ 'folders-item-image-small': sizeSmall }"
				></itd-icon>
				<div class="folders-item-title" [ngClass]="{ 'folders-item-title-small': sizeSmall }">
					{{ folder.name }}
				</div>
			</div>
		</ng-container>
	</div>
</div>
