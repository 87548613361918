import { AsyncPipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Optional,
	Output,
} from '@angular/core';
import { FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { DropdownPosition, NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { Observable } from 'rxjs';
import { IDepartment } from 'src/app/core/department/department.interface';
import { DepartmentState } from 'src/app/core/department/department.state';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { getParentDepartments } from 'src/app/core/utils/departments.util';
import { AbstractInputComponent } from '../abstract-input/abstract-input.component';
import { fadeAnimation } from './../../../../core/animations/fade.animation';

@Component({
	selector: 'itd-select',
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [fadeAnimation],
	standalone: true,
	imports: [
		NgIf,
		NgClass,
		NgStyle,
		NgSelectModule,
		ReactiveFormsModule,
		FormsModule,
		NgxIntlTelInputModule,
		NgFor,
		AsyncPipe,
		TranslateModule,
	],
})
export class SelectComponent<T, O = T> extends AbstractInputComponent<O> implements OnInit {
	public isOpen: boolean = false;
	public isFocus: boolean = false;
	public value: any;
	private departments: IDepartment[] = null;

	@Input() public isLoading: boolean = false;
	@Input() public maxWidth: number;
	@Input() public dropdownPosition: DropdownPosition = 'bottom';
	@Input() public isClearable: boolean = true;
	@Input() public isSearchable: boolean = true;
	@Input() public defaultValue: string | number = '';
	@Input() public organization: any;
	@Input() public isRequired: boolean = false;

	@Input() public data: T[];
	@Input() public observableData: Observable<T[]>;
	@Input() public printLabelIndex: string;
	@Input() public outputValueIndex: string;
	@Input() public multiple: boolean = false;
	@Input() public hasParentDepartment: boolean = false;

	@Output() public onFirstOpen? = new EventEmitter<void>();

	constructor(
		@Optional() control: NgControl,
		validationMessages: ValidationMessagesService,
		private store: Store
	) {
		super(control, validationMessages);
	}

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();
		this.defaultValue ? (this.value = this.defaultValue) : (this.value = null);
		this.departments = this.store.selectSnapshot(DepartmentState.departments);
	}

	// TODO: Pass along current user ID so we can filter for departments that a department admin may be able to see
	public getParents(department: IDepartment) {
		return getParentDepartments(department, this.departments);
	}
}
