import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgStyle } from '@angular/common';

export interface IconComponentData {
	src: string;
	width?: number;
	height?: number;
}

@Component({
	selector: 'itd-icon',
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgStyle, InlineSVGModule],
})
export class IconComponent {
	@Input() public icon?: string;
	@Input() public width?: number;
	@Input() public height?: number;
	@Input() public fill?: string;
	@Input() public type?: 'task' | 'dimension' = null;

	@Input() public removeSVGAttributes: string[];
	public set data(v: IconComponentData) {
		const { src, width, height } = v;
		this.icon = src;
		this.width = width;
		this.height = height;
	}

	public get path(): string {
		let addonPath: string = '';

		switch (this.type) {
			case 'task':
				addonPath = 'tasks/';
				break;

			case 'dimension':
				addonPath = 'dimensions/';
				break;

			default:
				break;
		}

		return 'assets/icons/' + addonPath + this.icon + '.svg';
	}

	constructor() {}
}
