import { map, tap } from 'rxjs/operators';
import {
	ITimeline,
	ITimelineContent,
	ITimelineContentDTO,
	ITimelineDTO,
	ITimelineEdit,
	ITimelineEditDTO,
	TTimelineContentType,
} from './timelines.interface';
import { Injectable } from '@angular/core';
import { ApiClientService } from '../api/api.service';
import { Store } from '@ngxs/store';
import { AbstractApiService } from '../abstract/abstract-api-service';
import { Observable } from 'rxjs';
import { TResults } from '../models/results.interface';
import { timelineSectionsToContent } from '../utils/timelines.util';

@Injectable({
	providedIn: 'root',
})
export class TimelinesService extends AbstractApiService<
	ITimeline,
	ITimelineDTO,
	ITimelineEdit,
	ITimelineEditDTO
> {
	protected key = 'timelines';

	constructor(protected api: ApiClientService, protected store: Store) {
		super(api, store);
	}

	public getTimelinesByModuleId(moduleId: number): Observable<ITimeline[]> {
		return this.api.get<any>(`api/modules/${moduleId}/in-use`).pipe(
			map(result => {
				return result.data.map(item => this.parse(item));
			})
		);
	}

	public downloadExcel(id: string, fileName: string, filters?: object) {
		// TODO: Implement department in filter!
		// https://introdus.atlassian.net/browse/IK-660
		return this.api
			.get<Blob>(`api/v2/analytics/timeline-stats/${id}`, { responseType: 'blob', params: filters })
			.pipe(
				tap(result => {
					const url = window.URL.createObjectURL(result);
					const link = document.createElement('a');

					link.href = url;
					link.download = fileName + '.xlsx';
					link.click();
				})
			);
	}

	// THe typing on this is off, it is of type Timeline.
	public getNewLearning(): Observable<ITimeline[]> {
		return this.api.get<TResults<ITimelineDTO[]>>('api/learning-library').pipe(
			map(response =>
				response.data.map(timeline => {
					return this.parse(timeline);
				})
			)
		);
	}

	private parseContent(items: ITimelineContentDTO[]): ITimelineContent[] {
		return items.map(item => {
			const type: TTimelineContentType = item.survey_id ? 'survey' : 'module';
			let output;

			switch (type) {
				case 'survey':
					const { survey_id, survey_title } = item;

					output = {
						id: survey_id,
						title: survey_title,
					};

					break;
				case 'module':
					const { module_id, module_title } = item;

					output = {
						id: module_id,
						title: module_title,
					};

					break;
			}

			output['department_id'] = item.department_id;
			output['type'] = type;
			output['image_key'] = item.image_key;

			return output;
		});
	}

	protected parse(value: ITimelineDTO): ITimeline {
		const { tl_title, tl_id } = value;
		const { preboarding, preboarding_title, preboarding_gatekeeper } = value;
		const { onboarding, onboarding_title, onboarding_gatekeeper } = value;
		const { offboarding, offboarding_title, offboarding_gatekeeper } = value;

		return {
			...value,
			title: tl_title,
			id: tl_id,
			preboarding: {
				title: preboarding_title,
				gatekeeper: preboarding_gatekeeper,
				content: this.parseContent(preboarding),
			},
			onboarding: {
				title: onboarding_title,
				gatekeeper: onboarding_gatekeeper,
				content: this.parseContent(onboarding),
			},
			offboarding: {
				title: offboarding_title,
				gatekeeper: offboarding_gatekeeper,
				content: this.parseContent(offboarding),
			},
		};
	}

	protected parseEditDTO(value: ITimelineEdit): ITimelineEditDTO {
		const { title, sections } = value;
		const { preboarding, onboarding, offboarding } = timelineSectionsToContent(sections);

		return {
			...value,
			tl_title: title,
			preboarding: preboarding.content,
			preboarding_title: preboarding.title,
			preboarding_gatekeeper: preboarding.gatekeeper,
			onboarding: onboarding.content,
			onboarding_title: onboarding.title,
			onboarding_gatekeeper: onboarding.gatekeeper,
			offboarding: offboarding.content,
			offboarding_title: offboarding.title,
			offboarding_gatekeeper: offboarding.gatekeeper,
		};
	}
}
