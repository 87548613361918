<div class="tags-assign">
	<itd-tags-input
		[label]="label"
		[tags]="tags"
		[clearIcon]="true"
		[hasBorder]="hasBorder"
		[hasShadow]="hasShadow"
		[allowTagCreation]="true"
		(tagsChange)="onTagsChange($event)"
	></itd-tags-input>
</div>
