import { Observable } from 'rxjs';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../utility-modules/itd-form/components/button/button.component';
import { ModalComponent } from '../../../utility-modules/modals/components/modal/modal.component';

@Component({
    selector: 'itd-are-you-sure-modal',
    templateUrl: './are-you-sure-modal.component.html',
    styleUrls: ['./are-you-sure-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ModalComponent,
        ButtonComponent,
        TranslateModule,
    ],
})
export class AreYouSureModalComponent {
	@Input() public onSubmitAction: () => Observable<any>;
	public reasons: Array<{ name: any; value: string }>;
	constructor(public activeModal: NgbActiveModal) {}

	public confirm() {
		this.onSubmitAction();
		this.activeModal.close();
	}
}
