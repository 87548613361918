import { GetMyOrganization } from '../organizations/organizations.actions';
import { DictionaryState } from './../dictionary/dictionary.state';
import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { StateResetAll } from 'ngxs-reset-plugin';
import { mergeMap, tap } from 'rxjs/operators';
import { GetProfile } from '../profile/profile.actions';
import { SetToken, SetIsLoggedIn, Logout, Login, SetLogin, SetOriginalUrl } from './auth.action';
import { AuthService } from './auth.service';

export interface IAuthStateModel {
	token: string;
	isLoggedIn: boolean;
	originalUrl?: string;
}

@State<IAuthStateModel>({
	name: 'auth',
	defaults: {
		token: null,
		isLoggedIn: false,
		originalUrl: null,
	},
})
@Injectable()
export class AuthState {
	constructor(private auth: AuthService) {}

	@Selector()
	public static getState(state: IAuthStateModel) {
		return state;
	}

	@Selector()
	static originalUrl(state: IAuthStateModel) {
		return state.originalUrl;
	}

	@Action(Login)
	public login({ dispatch }: StateContext<IAuthStateModel>, { payload }: Login) {
		const { loginDTO } = payload;

		return this.auth.login(loginDTO).pipe(
			mergeMap(response => {
				const { token } = response;

				return dispatch([new SetLogin({ token })]);
			})
		);
	}

	@Action(SetLogin)
	public setLogin({ dispatch }: StateContext<IAuthStateModel>, { payload }: SetLogin) {
		const { token } = payload;

		return dispatch([
			new SetToken({ token }),
			new SetIsLoggedIn({ value: true }),
			GetMyOrganization,
			GetProfile,
		]);
	}

	@Action(SetOriginalUrl)
	setOriginalUrl(ctx: StateContext<IAuthStateModel>, action: SetOriginalUrl) {
		ctx.patchState({ originalUrl: action.payload });
	}

	@Action(Logout)
	public logout({ dispatch }: StateContext<IAuthStateModel>, { payload }: Logout) {
		const { navigate } = payload;

		return this.auth.logout().pipe(
			tap(() => {
				dispatch([new StateResetAll(DictionaryState), new SetToken({ token: null })]);

				if (navigate) {
					dispatch(new Navigate(['/login']));
				}
			})
		);
	}

	@Action(SetToken)
	public setToken(ctx: StateContext<IAuthStateModel>, { payload }: SetToken) {
		const { token } = payload;

		return ctx.patchState({
			token,
		});
	}

	@Selector()
	public static token(state: IAuthStateModel) {
		return state.token;
	}

	@Action(SetIsLoggedIn)
	public setIsLoggedIn(ctx: StateContext<IAuthStateModel>, { payload }: SetIsLoggedIn) {
		const { value } = payload;

		return ctx.patchState({
			isLoggedIn: value,
		});
	}

	@Selector()
	public static loggedIn(state: IAuthStateModel) {
		return state.isLoggedIn;
	}
}
