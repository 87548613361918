import { IOrganization } from '../../../core/organizations/organizations.interface';
import { OrganizationState } from '../../../core/organizations/organizations.state';
import { Component, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'itd-organization-logo',
    templateUrl: './organization-logo.component.html',
    styleUrls: ['./organization-logo.component.scss'],
    standalone: true,
    imports: [NgIf, AsyncPipe],
})
export class OrganizationLogoComponent {
	@Select(OrganizationState.organization) public organization$: Observable<IOrganization>;
	@Input() public sizeType: string;

	constructor() {}
}
