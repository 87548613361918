<itd-modal [size]="800">
	<ng-template #header>{{ 'CROP_IMAGE' | translate }}</ng-template>
	<ng-template #content>
		<div class="image-container">
			<image-cropper
				[imageFile]="image"
				[maintainAspectRatio]="true"
				[aspectRatio]="aspectRatio"
				format="webp"
				(imageCropped)="imageCropped($event)"
			></image-cropper>

			<itd-button [setType]="'button'" (click)="addImage()">{{ 'CONFIRM' | translate }}</itd-button>
		</div>
	</ng-template>
</itd-modal>
