import { ValidationMessagesService } from './../../../../core/services/validation-messages.service';
import { NgControl } from '@angular/forms';
import { AfterViewInit, Component, ContentChild, EventEmitter, Input, Output, TemplateRef, Optional } from '@angular/core';
import { AbstractInputComponent } from '../abstract-input/abstract-input.component';
import { IconComponent } from '../../../icon/components/icon/icon.component';
import { NgIf, NgTemplateOutlet, AsyncPipe, TitleCasePipe } from '@angular/common';

@Component({
    selector: 'itd-input-toggler',
    templateUrl: './input-toggler.component.html',
    styleUrls: ['./input-toggler.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        IconComponent,
        NgTemplateOutlet,
        AsyncPipe,
        TitleCasePipe,
    ],
})
export class InputTogglerComponent extends AbstractInputComponent<string> implements AfterViewInit {
  @ContentChild('input') input: TemplateRef<string>;
  @Input() title: string;
  @Input() public toggle: boolean = false;
  @Output() public toggleChange = new EventEmitter<boolean>();
  constructor(@Optional() control: NgControl, validationMessages: ValidationMessagesService) {
    super(control, validationMessages);
   }
  ngAfterViewInit(): void {
    this.toggle = !!this.title;
  }
  public toggleInput() {
    if(this.title){
      this.toggle = !this.toggle;
      this.toggleChange.emit(this.toggle);
    }
  }
}
