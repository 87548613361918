import { IBuddyEdit } from './buddy.interface';

export class LoadAllBuddies {
	public static readonly type = '[Buddy] Load All';
}

export class LoadAllBuddiesIfEmpty {
	public static readonly type = '[Buddy] Load All If Empty';
}

export class CreateBuddy {
	public static readonly type = '[Buddy] Create';
	constructor(public payload: { data: IBuddyEdit }) {}
}

export class UpdateBuddy {
	public static readonly type = '[Buddy] Update';
	constructor(public payload: { id: string; data: IBuddyEdit }) {}
}

export class DeleteBuddy {
	public static readonly type = '[Buddy] Delete';
	constructor(public payload: { id: string }) {}
}
