<itd-modal icon="exit">
	<ng-template #header>
		{{ 'NAVIGATION.LOGOUT' | translate }}
	</ng-template>

	<ng-template #content>
		<div class="align-center">
			{{ 'NAVIGATION.LOGOUT_CONTENT' | translate }}
		</div>
	</ng-template>

	<ng-template #footer>
		<button (click)="logout()" class="btn btn--color-primary btn--size-default" style="min-width: 120px;">
			{{ 'NAVIGATION.LOGOUT' | translate }}
		</button>
	</ng-template>
</itd-modal>
