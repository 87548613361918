import { animate, style, transition, trigger } from '@angular/animations';

export const expandAnimation = trigger('expandAnimation', [
	transition(':enter', [
		style({
			height: 0,
			overflow: 'hidden',
		}),
		animate('.2s', style({ height: '*' })),
	]),
	transition(':leave', [
		style({ overflow: 'hidden' }),
		animate('.2s', style({ height: 0 })),
	]),
]);
