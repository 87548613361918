import { TLoginDTO } from '../profile/profile.service';

export class SetToken {
	public static readonly type = '[Auth] Set token';
	constructor(public payload: { token: string }) {}
}

export class SetIsLoggedIn {
	public static readonly type = '[Auth] Set is logged in';
	constructor(public payload: { value: boolean }) {}
}

export class SetOriginalUrl {
	public static readonly type = '[Auth] Set Original URL';
	constructor(public payload: string) {}
}

export class Login {
	public static readonly type = '[Auth] Login';
	constructor(public payload: { loginDTO: TLoginDTO }) {}
}

export class SetLogin {
	public static readonly type = '[Auth] Set Login';
	constructor(public payload: { token: string }) {}
}

export class Logout {
	public static readonly type = '[Auth] Logout';
	constructor(public payload: { navigate: boolean }) {}
}
