export const CSSBreakpoints = {
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200,
	xxl: 1440,
};

export const taskIcons = [
	{ name: 'flag' },
	{ name: 'conference' },
	{ name: 'superstar' },
	{ name: 'training' },
	{ name: 'book' },
	{ name: 'briefcase' },
	{ name: 'browser' },
	{ name: 'calendar' },
	{ name: 'chat' },
	{ name: 'clock' },
	{ name: 'communication' },
	{ name: 'design' },
	{ name: 'diagram' },
	{ name: 'diploma' },
	{ name: 'display' },
	{ name: 'document' },
	{ name: 'earth' },
	{ name: 'envelope' },
	{ name: 'graph' },
	{ name: 'house' },
	{ name: 'id-card' },
	{ name: 'idea' },
	{ name: 'internet' },
	{ name: 'interview' },
	{ name: 'ladder' },
	{ name: 'lightbulb' },
	{ name: 'magnifier' },
	{ name: 'meeting' },
	{ name: 'music-player' },
	{ name: 'notepad' },
	{ name: 'office' },
	{ name: 'publish' },
	{ name: 'resume' },
	{ name: 'ribbon' },
	{ name: 'seminar' },
	{ name: 'slideshow' },
	{ name: 'star-graph' },
	{ name: 'student' },
	{ name: 'task-list' },
	{ name: 'worker' },
];

export interface IPagination {
	limit: number;
	noMoreItems: boolean;
	skip: number;
	total: number;
}
