import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
	DeleteBuddy,
	LoadAllBuddies,
	LoadAllBuddiesIfEmpty,
	CreateBuddy,
	UpdateBuddy,
} from './buddy.action';
import { IBuddy, IBuddyDTO, IBuddyEdit, IBuddyEditDTO } from './buddy.interface';
import { BuddiesService } from './buddy.service';
import { AbstractApiState } from '../abstract/abstract-api-state';
import { tap } from 'rxjs';

export interface BuddiesStateModel {
	items: IBuddy[];
	result?: IBuddy;
}

@State<BuddiesStateModel>({
	name: 'Buddies',
	defaults: {
		items: null,
		result: null,
	},
})
@Injectable()
export class BuddyState extends AbstractApiState<IBuddy, IBuddyDTO, IBuddyEdit, IBuddyEditDTO> {
	constructor(protected buddies: BuddiesService, store: Store) {
		super(buddies, store);
	}

	@Selector()
	public static getState(state: BuddiesStateModel) {
		return state;
	}

	@Selector()
	public static items(state: BuddiesStateModel) {
		return state.items;
	}

	@Selector()
	public static result(state: BuddiesStateModel) {
		return state.result;
	}

	@Action(LoadAllBuddies)
	public loadAll(ctx: StateContext<BuddiesStateModel>) {
		return super.loadAll(ctx);
	}

	@Action(LoadAllBuddiesIfEmpty)
	public loadAllIfEmpty(ctx: StateContext<BuddiesStateModel>) {
		return super.loadAllIfEmpty(ctx);
	}

	@Action(CreateBuddy)
	public create(ctx: StateContext<BuddiesStateModel>, data: CreateBuddy) {
		return super.create(ctx, data).pipe(
			tap(res => {
				ctx.patchState({ result: res });
			})
		);
	}

	@Action(UpdateBuddy)
	public update(ctx: StateContext<BuddiesStateModel>, data: UpdateBuddy) {
		return super.update(ctx, data);
	}

	@Action(DeleteBuddy)
	public delete(ctx: StateContext<BuddiesStateModel>, data: DeleteBuddy) {
		return super.delete(ctx, data).pipe(
			tap(() => {
				const _items = ctx.getState().items;

				if (!_items || !_items.length) {
					return;
				}

				const items = [..._items];
				const index = items.findIndex(item => item.user_id === data.payload.id);

				if (index === -1) {
					return;
				}

				items.splice(index, 1);

				ctx.patchState({
					items,
				});
			})
		);
	}
}
