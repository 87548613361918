import { TResults } from '../models/results.interface';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiClientService } from '../api/api.service';
import { IDepartment, IDepartmentEdit, IMoveDepartment } from './department.interface';

@Injectable({
	providedIn: 'root',
})
export class DepartmentService {
	protected key = 'departments';
	constructor(private api: ApiClientService) {}

	public getAll(): Observable<IDepartment[]> {
		return this.api
			.get<TResults<IDepartment[]>>('api/departments')
			.pipe(map(response => response.data));
	}
	public getParents(): Observable<IDepartment[]> {
		return this.api
			.get<TResults<IDepartment[]>>('api/departments/parents')
			.pipe(map(response => response.data));
	}
	public getDepartmentsFromParent(department_id: string): Observable<IDepartment[]> {
		return this.api
			.get<TResults<IDepartment[]>>(`api/departments/${department_id}`)
			.pipe(map(response => response.data));
	}
	public delete(department_id: string): Observable<boolean> {
		return this.api.delete<TResults>(`api/departments/${department_id}`).pipe(
			map(response => {
				return response.success;
			})
		);
	}
	public create(payload: Partial<IDepartmentEdit>): Observable<boolean> {
		return this.api.post<TResults>(`api/departments`, payload).pipe(
			map(response => {
				return response.success;
			})
		);
	}
	public update(payload: {
		department: Partial<IDepartmentEdit>;
		department_id: string;
	}): Observable<boolean> {
		return this.api
			.put<TResults>(`api/departments/${payload.department_id}`, payload.department)
			.pipe(map(response => response.success));
	}

	public move(payload: IMoveDepartment): Observable<IDepartment> {
		return this.api
			.put<TResults<IDepartment>>(`api/${this.key}/${payload.department_id}/move`, {
				new_parent_id: payload.new_parent_id,
			})
			.pipe(
				map(response => {
					return response.data;
				})
			);
	}
}
