<form [formGroup]="form" (ngSubmit)="onSubmit()">
	<div class="inputs">
		<itd-grid>
			<itd-grid-item size="50%" [mobileResponsive]="true">
				<itd-input
					name="first_name"
					formControlName="first_name"
					[isRequired]="true"
					[label]="'FIRST_NAME' | translate"
					placeholder="{{ 'SETTINGS.FIRST_NAME_PLACEHOLDER' | translate }}"
				>
				</itd-input>
			</itd-grid-item>

			<itd-grid-item size="50%" [mobileResponsive]="true">
				<itd-input
					name="last_name"
					formControlName="last_name"
					[isRequired]="true"
					[label]="'LAST_NAME' | translate"
					placeholder="{{ 'SETTINGS.LAST_NAME_PLACEHOLDER' | translate }}"
				>
				</itd-input>
			</itd-grid-item>

			<itd-grid-item size="50%" [mobileResponsive]="true">
				<itd-input
					type="email"
					[label]="'EMAIL' | translate"
					placeholder="{{ 'SETTINGS.EMAIL_PLACEHOLDER' | translate }}"
					formControlName="email"
					[isRequired]="true"
				>
				</itd-input>
			</itd-grid-item>

			<itd-grid-item size="50%" [mobileResponsive]="true">
				<itd-input-phone
					name="phone_number"
					[maxLength]="16"
					formControlName="phone_number"
					[label]="'PHONE' | translate"
					placeholder="{{ 'SETTINGS.PHONE_PLACEHOLDER' | translate }}"
				>
				</itd-input-phone>
			</itd-grid-item>

			<itd-grid-item size="50%" [mobileResponsive]="true">
				<itd-input
					name="job_title"
					formControlName="job_title"
					[label]="'SETTINGS.JOB_TITLE' | translate"
					placeholder="{{ 'SETTINGS.JOB_TITLE_PLACEHOLDER' | translate }}"
				>
				</itd-input>
			</itd-grid-item>

			<itd-grid-item size="50%" [mobileResponsive]="true">
				<itd-input-date
					name="start_date"
					formControlName="start_date"
					icon="calendar"
					[label]="'START_DATE' | translate"
					[clearIcon]="true"
					[hasShadow]="true"
				>
				</itd-input-date>
			</itd-grid-item>

			<hr />

			<itd-grid-item size="50%" [mobileResponsive]="true">
				<itd-select
					[observableData]="languages$"
					[hasShadow]="true"
					[isRequired]="true"
					[isLoading]="!(languages$ | async)"
					name="lang"
					formControlName="lang"
					[label]="'SETTINGS.LANGUAGE' | translate"
					placeholder="{{ 'SETTINGS.LANGUAGE_PLACEHOLDER' | translate }}"
					[defaultValue]="profile.lang"
					printLabelIndex="name"
					outputValueIndex="name"
				>
				</itd-select>
			</itd-grid-item>

			<hr />

			<itd-grid-item size="50%" [mobileResponsive]="true" *ngIf="profile.external_id">
				<itd-input
					name="external_id"
					[value]="profile.external_id"
					[label]="'EXTERNAL_ID' | translate"
					[disabled]="true"
				>
				</itd-input>
			</itd-grid-item>
			<itd-grid-item size="50%" [mobileResponsive]="true"></itd-grid-item>
		</itd-grid>

		<itd-form-error [errorObservable]="errorMessage$"></itd-form-error>
	</div>

	<div class="footer row">
		<div>
			<itd-button
				sizeType="default"
				[width]="90"
				[formState]="formState"
				[enableAfterSuccess]="true"
			>
				{{ 'UPDATE' | translate }}
			</itd-button>
		</div>
	</div>
</form>
