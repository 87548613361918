<itd-modal>
	<ng-template #header>
		{{ data.title | translate }}
	</ng-template>

	<ng-template #content>
		<div class="container">
			<ng-container *ngFor="let type of data.questionTypes | keyvalue">
				<button
					class="question-type btn--block btn btn--size-default btn--color-secondary"
					(click)="emitQuestionType(type)"
					[itdTooltip]="(type.key + '_TOOLTIP') | translate"
					tooltipPosition="right"
				>
					{{ type.key | translate }}
				</button>
			</ng-container>
		</div>
	</ng-template>
</itd-modal>
