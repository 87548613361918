<div
	[ngClass]="{
		isFocus: isFocus,
		isError: invalid || controlInvalid
	}"
	[ngStyle]="{
		'maxWidth.px': maxWidth,
		'minWidth.px': minWidth
	}"
	class="container style-{{ styleType }}"
>
	<label class="label" *ngIf="label">
		{{ label | translate }} <span *ngIf="isRequired">*</span>
	</label>

	<div
		[ngClass]="{
			hasBorder: hasBorder,
			'only-icon-click': searchWithIcon
		}"
		class="content"
	>
		<input
			*ngIf="type !== 'positiveNegativeNumbers'"
			#input
			class="input size-{{ sizeType }}"
			auto
			[autocomplete]="hasAutocomplete ? 'on' : 'off'"
			[name]="name"
			[ngClass]="{
				'space-left': hasSpaceLeft,
				'space-right': hasSpaceRight,
				hasShadow: hasShadow,
				'has-input-border': inputBorder === true
			}"
			[placeholder]="placeholder | translate"
			[type]="type"
			(blur)="setFocus(false)"
			(focus)="setFocus(true)"
			(ngModelChange)="onModelChange($event)"
			[(ngModel)]="value"
			[disabled]="disabled"
		/>
		<input
			*ngIf="type === 'positiveNegativeNumbers'"
			#input
			class="input size-{{ sizeType }}"
			[name]="name"
			[ngClass]="{
				'space-left': hasSpaceLeft,
				'space-right': hasSpaceRight,
				hasShadow: hasShadow,
				'has-input-border': inputBorder === true
			}"
			[placeholder]="placeholder | translate"
			[type]="type"
			(blur)="setFocus(false)"
			(focus)="setFocus(true)"
			(ngModelChange)="onModelChange($event)"
			[(ngModel)]="value"
			[disabled]="disabled"
		/>

		<ng-container *ngIf="hasDelete">
			<button (click)="clickDelete()" class="button" type="button">
				<itd-icon [height]="19" [width]="19" icon="bin"></itd-icon>
			</button>
		</ng-container>

		<ng-container *ngIf="hasSend && !!value">
			<button (click)="clickSend()" class="button" type="button">
				<itd-icon [height]="19" [width]="19" icon="send"></itd-icon>
			</button>
		</ng-container>

		<ng-container *ngIf="isPassword">
			<button
				(click)="toggleType()"
				[ngClass]="{
					'is-active': type === 'text'
				}"
				class="button"
				type="button"
			>
				<itd-icon [height]="14" [width]="19" icon="eye"></itd-icon>
			</button>
		</ng-container>

		<div *ngIf="icon" [style]="iconStyle" class="icon">
			<itd-icon [height]="iconHeight" [icon]="icon" [width]="iconWidth"></itd-icon>
		</div>
	</div>

	<ng-container *ngIf="message$ | async as message">
		<p *ngIf="invalid" class="validation">
			{{ message.text }}
		</p>
	</ng-container>

	<p *ngIf="controlInvalid && invalidMessage" class="validation">
		{{ invalidMessage }}
	</p>
</div>
