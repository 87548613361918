import { IFilterAnalytics } from './analytics.interface';

export class LoadAnalyticsUsersType {
	public static readonly type = '[Analytics] Load Users Type';
}

export class LoadAnalyticsUsersTypeIfEmpty {
	public static readonly type = '[Analytics] Load Users Type If Empty';
	constructor(public payload: IFilterAnalytics) {}
}

export class LoadAnalyticsUsersTasks {
	public static readonly type = '[Analytics] Load Users tasks';
}

export class LoadAnalyticsUsersTasksIfEmpty {
	public static readonly type = '[Analytics] Load Users tasks If Empty';
	constructor(public payload: IFilterAnalytics) {}
}

export class LoadAnalyticsLogins {
	public static readonly type = '[Analytics] Load Logins';
}

export class LoadAnalyticsLoginsIfEmpty {
	public static readonly type = '[Analytics] Load Logins If Empty';
	constructor(public payload: IFilterAnalytics) {}
}

export class LoadAnalyticsUsers {
	public static readonly type = '[Analytics] Load Users';
}

export class LoadAnalyticsUsersIfEmpty {
	public static readonly type = '[Analytics] Load Users If Empty';
	constructor(public payload: IFilterAnalytics) {}
}

export class LoadAnalyticsEmployeeActivity {
	public static readonly type = '[Analytics] Employee Activity';
}

export class LoadAnalyticsEmployeeActivityIfEmpty {
	public static readonly type = '[Analytics] Employee Activity If Empty';
	constructor(public payload: IFilterAnalytics) {}
}

export class LoadAnalyticsEmployeeIntegrationScore {
	public static readonly type = '[Analytics] Employee Integration Score';
}

export class LoadAnalyticsEmployeeIntegrationScoreIfEmpty {
	public static readonly type = '[Analytics] Employee Integration Score If Empty';
	constructor(public payload: IFilterAnalytics) {}
}

export class LoadAnalyticsTimeline {
	public static readonly type = '[Analytics] Assignment Timeline  ';
}

export class LoadAnalyticsTimelineIfEmpty {
	public static readonly type = '[Analytics] Assignment Timeline If Empty';
	constructor(public payload: IFilterAnalytics) {}
}

export class LoadAnalyticsTaskListsIfEmpty {
	public static readonly type = '[Analytics] Assignment Task List If Empty';
	constructor(public payload: IFilterAnalytics) {}
}

export class LoadAnalyticsTaskLists {
	public static readonly type = '[Analytics] Assignment Tasks';
}
export class LoadAnalyticsSurveyOverTimeIfEmpty {
	public static readonly type = '[Analytics] Surver Timeline If Empty';
	constructor(public payload: IFilterAnalytics) {}
}

export class LoadAnalyticsSurveyOverTime {
	public static readonly type = '[Analytics] Surver Timeline';
}

export class LoadAnalyticsSurveyAnswersOverTimeIfEmpty {
	public static readonly type = '[Analytics] Surver Answers Timeline If Empty';
	constructor(public payload: IFilterAnalytics) {}
}

export class LoadAnalyticsSurveyAnswersOverTime {
	public static readonly type = '[Analytics] Surver Answers Timeline';
}

export class LoadAnalyticsSurveyIfEmpty {
	public static readonly type = '[Analytics] Surver Answers Timeline If Empty';
	constructor(public payload: IFilterAnalytics) {}
}

export class LoadAnalyticsSurvey {
	public static readonly type = '[Analytics] Surver Answers Timeline';
}

export class DownloadSurvey {
	public static readonly type = '[Analytics] Download Analytics Survey ';
	constructor(
		public payload: {
			survey_id: string;
			survey_name: string;
			anonymized: boolean;
			department_id?: string;
			from?: string;
			to?: string;
		}
	) {}
}

export class DownloadSurveyPDF {
	public static readonly type = '[Analytics] Download Analytics Survey PDF';
	constructor(
		public payload: {
			survey_id: string;
			survey_name: string;
			anonymized: boolean;
			department_id?: string;
			from?: string;
			to?: string;
		}
	) {}
}

// TODO Export fast track analytics (all below) to a separate action/state/service for transparency

export class LoadAnalyticsFastTrackAssignments {
	public static readonly type = '[Analytics] Fast Track Assignments';
	constructor(public payload?: IFilterAnalytics) {}
}

export class LoadAnalyticsOnboardingIndex {
	public static readonly type = '[Analytics] Onboarding Index';
	constructor(public payload?: IFilterAnalytics) {}
}

export class LoadAnalyticsOnboardingIndexOverTime {
	public static readonly type = '[Analytics] Onboarding Index Over Time';
	constructor(public payload?: IFilterAnalytics) {}
}

export class LoadAnalyticsOnboardingIndexOverEmploymentTime {
	public static readonly type = '[Analytics] Onboarding Index Over Employment Time';
	constructor(public payload?: IFilterAnalytics) {}
}

export class LoadAnalyticsFastTrackDimensions {
	public static readonly type = '[Analytics] FastTrack Dimensions';
	constructor(public payload?: IFilterAnalytics) {}
}

export class LoadAnalyticsFastTrackDimensionsOverTime {
	public static readonly type = '[Analytics] FastTrack Dimensions Over Time';
	constructor(public payload: IFilterAnalytics) {}
}

export class LoadAnalyticsFastTrackDimensionsOverEmploymentTime {
	public static readonly type = '[Analytics] FastTrack Dimensions Over Employment Time';
	constructor(public payload: IFilterAnalytics) {}
}
