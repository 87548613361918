import { Injectable } from '@angular/core';
import { ApiClientService } from '../api/api.service';
import { Store } from '@ngxs/store';
import { IMessage, IMessageDTO, IMessageEdit, IMessageEditDTO } from './messages.interface';
import { AbstractApiService } from '../abstract/abstract-api-service';
import { map, Observable } from 'rxjs';
import { AddLoading, RemoveLoading } from '../layout/layout.actions';
import { TResults } from '../models/results.interface';

@Injectable({
	providedIn: 'root',
})
export class MessagesService extends AbstractApiService<
	IMessage,
	IMessageDTO,
	IMessageEdit,
	IMessageEditDTO
> {
	protected key = 'messages';

	constructor(protected api: ApiClientService, protected store: Store) {
		super(api, store);
	}

	public loadMessagesChecked() {
		this.store.dispatch(AddLoading);

		return this.api
			.get<TResults<IMessageDTO[]>>(`api/${this.key}`, {
				params: {
					dept_show_above: true,
					limit:25,
					skip:0
				}
			})
			.pipe(
				map(response => {
					this.store.dispatch(RemoveLoading);
					return response.data.map(module => ({...this.parse(module), checked: false}));
				})
			);
	}

	public create(data: IMessageEdit): Observable<IMessage> {
		const DTO = this.parseEditDTO(data);

		return this.api.post<TResults>(`api/${this.key}/create`, DTO).pipe(
			map(response => {
				return this.parse(response.data);
			})
		);
	}

	protected parse(value: IMessageDTO): IMessage {
		const {
			message_type,
			message_title,
			message_subject,
			message_button_text,
			message_blocks = [],
			message_id,
		} = value;

		return {
			...value,
			type: message_type,
			title: message_title,
			subject: message_subject,
			button_text: message_button_text,
			message: message_blocks?.length ? message_blocks[0].block_content : null,
			id: message_id,
		};
	}

	public parseEditDTO(value: IMessageEdit): IMessageEditDTO {
		const { type, title, subject, button_text, message, id } = value;

		return {
			...value,
			message_type: type,
			message_title: title,
			message_subject: subject,
			message_button_text: button_text,
			message_blocks: message ? [{ block_content: message }] : null,
			message_id: id,
		};
	}
}
