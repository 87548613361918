import { NgClass, NgIf, NgStyle, SlicePipe, UpperCasePipe } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MediaService } from 'src/app/core/media/media.service';
import { IEnvironment } from 'src/environments/environment.interface';
import { IconComponent } from '../../../utility-modules/icon/components/icon/icon.component';

@Component({
	selector: 'itd-user-avatar',
	templateUrl: './user-avatar.component.html',
	styleUrls: ['./user-avatar.component.scss'],
	standalone: true,
	imports: [NgIf, NgStyle, NgClass, IconComponent, UpperCasePipe, SlicePipe],
})
export class UserAvatarComponent implements OnInit {
	@Input({ required: true }) user: any;
	@Input() public url: string;
	@Input() public height: number;
	@Input() public width: number;
	@Input() public folder?: string | number;
	@Input() public secondary?: boolean = false;

	public get src(): string {
		return this.getImagePath();
	}

	constructor(@Inject('ENV') public env: IEnvironment, protected mediaService: MediaService) {}

	ngOnInit(): void {
		this.height = this.height | 34;
		this.width = this.width | 34;
	}
	getImagePath(): string {
		if (this.url) {
			return this.url;
		}
		const path = [this.env.mediaUrl, this.folder, `${this.user.image_key}`]
			.filter(value => value)
			.join('/');

		if (this.width || this.height) {
			return `${path}_${200}x${200}`;
		}
		return path;
	}
}
