import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const MobileGuard: CanActivateFn = () => {
	const router = inject(Router);
	if (window.innerWidth <= 768) {
		router.navigate(['/']);
		return false;
	}
	return true;
};
