import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Injectable()
export abstract class WithDestroyComponent implements OnDestroy {
	protected destroy$: Subject<boolean> = new Subject<boolean>();
	protected eventSubscriptions: Subscription[] = [];

	public ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();

		this.removeSubscriptions();
	}

	protected removeSubscriptions() {
		while (this.eventSubscriptions.length > 0) {
			const item = this.eventSubscriptions.shift();
			item.unsubscribe();
		}
	}
}
