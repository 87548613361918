import { IScheduledAssignmentEdit } from './scheduled-assignment.interface';

export class LoadAllScheduledAssignments {
	public static readonly type = '[ScheduledAssignment] Load All';
}

export class LoadAllScheduledAssignmentsIfEmpty {
	public static readonly type = '[ScheduledAssignment] Load All If Empty';
}

export class CreateScheduledAssignment {
	public static readonly type = '[ScheduledAssignment] Create';
	constructor(public payload: { data: IScheduledAssignmentEdit }) {}
}

export class DeleteScheduledAssignment {
	public static readonly type = '[ScheduledAssignment] Delete';
	constructor(public payload: { id: string }) {}
}
