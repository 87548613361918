<form (ngSubmit)="onSubmit()" [formGroup]="form">
	<itd-modal icon="document">
		<ng-template #header>
			{{ 'CONFIRM_DUPLICATE.HEADER' | translate }}
		</ng-template>

		<ng-template #content>
			<div *ngIf="data.content" class="content">
				{{ data.content }}
				<b *ngIf="data.title">{{ data.title }}</b>
				?
			</div>
			<div *ngIf="!deepCopyChecked && warningText" class="warning">{{ warningText }}</div>

			<itd-checkbox
				*ngIf="data.showCheckbox"
				(change)="checkDeepCopy()"
				[value]="false"
				formControlName="deepCopy"
			>
				{{ checkboxText }}
			</itd-checkbox>
		</ng-template>

		<ng-template #footer>
			<itd-button [disabled]="!form.valid" [enableAfterSuccess]="false" [formState]="formState">
				{{ 'CONFIRM' | translate }}
			</itd-button>

			<itd-form-error [errorObservable]="errorMessage$"></itd-form-error>
		</ng-template>
	</itd-modal>
</form>
