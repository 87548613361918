<form [formGroup]="form" (ngSubmit)="onSubmit()">
	<div class="inputs">
		<p class="grid-label">{{ 'SETTINGS.CHANGE_PASSWORD.TITLE' | translate }}</p>
		<itd-grid>
			<itd-grid-item size="100%" [mobileResponsive]="true">
				<itd-input
					class="input"
					name="old_password"
					type="password"
					formControlName="old_password"
					placeholder="{{ 'SETTINGS.CHANGE_PASSWORD.PLACEHOLDER_OLD' | translate }}"
				></itd-input>
			</itd-grid-item>
			<itd-grid-item class="input main-password-input" size="100%" [mobileResponsive]="true">
				<itd-input
					class="input"
					name="new_password"
					type="password"
					formControlName="new_password"
					placeholder="{{ 'SETTINGS.CHANGE_PASSWORD.PLACEHOLDER_NEW' | translate }}"
				></itd-input>
			</itd-grid-item>
			<itd-password-strength [passwordToCheck]="form.value.new_password"></itd-password-strength>

			<itd-grid-item size="100%" [mobileResponsive]="true">
				<itd-input
					class="input"
					name="confirm_new_password"
					type="password"
					formControlName="confirm_new_password"
					placeholder="{{ 'SETTINGS.CHANGE_PASSWORD.PASSWORD_CONFIRMATION' | translate }}"
				></itd-input>
			</itd-grid-item>
		</itd-grid>

		<itd-form-error [errorObservable]="errorMessage$"></itd-form-error>
	</div>

	<!-- <ng-template #footer>
		<itd-button [formState]="formState" [width]="120">
			{{ 'SETTINGS.CHANGE_PASSWORD.UPDATE' | translate }}
		</itd-button>
	</ng-template> -->
	<div class="footer row">
		<div>
			<itd-button
				sizeType="default"
				[width]="90"
				[formState]="formState"
				[enableAfterSuccess]="true"
			>
				{{ 'UPDATE' | translate }}
			</itd-button>
		</div>

		<div>
			<!-- <button
				type="button"
				(click)="changePassword()"
				class="btn btn--size-default btn--color-secondary"
			>
				{{ 'SETTINGS.CHANGE_PASSWORD.TITLE' | translate }}
			</button> -->
		</div>
	</div>
</form>
