<itd-modal [padding]="20">
	<ng-template #content>
		<div class="align-center">
			<div class="title">{{ title }}</div>
			<div class="text">{{ text }}</div>
		</div>
	</ng-template>

	<ng-template #footer>
		<button
			(click)="buttonClick()"
			class="btn btn--color-primary btn--size-default"
			style="min-width: 120px;"
		>
			{{ buttonName }}
		</button>

		<button
			*ngIf="secondaryButtonName"
			(click)="cancelClick()"
			class="btn btn--color-secondary btn--size-default secondary"
			style="min-width: 120px;"
		>
			{{ secondaryButtonName }}
		</button>
	</ng-template>
</itd-modal>
