import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
    selector: '[itdTimelineRadioItem]',
    standalone: true
})
export class TimelineRadioItemDirective {
	@Input('itdTimelineRadioItem') public key!: string;
	constructor(public readonly tpl: TemplateRef<any>) {}
}
