<form (ngSubmit)="onSubmitAction()" [formGroup]="form">
	<itd-modal [size]="800">
		<ng-template #header>
			{{ 'EMPLOYEES.INVITE_ADD_TIMELINES' | translate }}
		</ng-template>

		<ng-template #content>
			<div class="header">
				<itd-input
					(write)="searchActivated($event)"
					#searchInput
					placeholder="{{ 'TIMELINES.SEARCH_PLACEHOLDER' | translate }}"
					icon="search"
					[hasBorder]="true"
					iconPosition="left"
					sizeType="small"
					[hasShadow]="false"
					value=""
				></itd-input>
			</div>

			<div class="body">
				<itd-folders
					*ngIf="!isSearching && departmentFoldersActive"
					[data]="startingData"
					[sizeSmall]="true"
					(newData)="renderNewData($event)"
				></itd-folders>
				<itd-checkbox-list
					*ngIf="items"
					controlName="items"
					searchProperty="title"
					[data]="items"
					[searchQuery]="searchInput.value"
					[formGroup]="form"
					[isSortable]="false"
				>
					<ng-template itdCheckboxListLabel let-item="item">
						{{ item.internal_title || item.title }}
						<span class="greyed" *ngIf="item.internal_title && item.title">
							({{ item.title }})
						</span>
					</ng-template>
				</itd-checkbox-list>

				<span class="loading loading--center" *ngIf="!items"></span>
			</div>
		</ng-template>

		<ng-template #footer>
			<itd-button [disabled]="!itemsFormArray.length" [isBlock]="true">
				{{ 'CONFIRM' | translate }}
			</itd-button>
		</ng-template>
	</itd-modal>
</form>
