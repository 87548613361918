import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { IconComponent } from '../../../utility-modules/icon/components/icon/icon.component';

@Component({
    selector: 'itd-empty-content',
    templateUrl: './empty-content.component.html',
    styleUrls: ['./empty-content.component.scss'],
    standalone: true,
    imports: [
        IconComponent,
        NgIf,
        RouterLink,
    ],
})
export class EmptyContentComponent {
	@Input() public icon: string;
	@Input() public button: string;
	@Input() public link: string;
}
