<div class="questions-wrap" *ngIf="activeSurvey.questions && surveyAnswers.length" #survey>
	<h1 *ngIf="!resultsPage">{{ activeSurvey.title }}</h1>
	<small class="anonymized" *ngIf="activeSurvey.anonymized">
		<i>{{ 'CONTENT.SURVEYS.ANONYMIZED' | translate }}</i>
	</small>

	<div class="description" *ngIf="activeSurvey.description && activeSurvey.description.length >= 1">
		<p>{{ activeSurvey.description }}</p>
	</div>

	<div *ngFor="let question of activeSurvey.questions; let currentQuestionIndex = index" class="questions">
		<div class="question-number">
			{{ 'QUESTION' | translate }} {{ currentQuestionIndex + 1 }}/{{
			activeSurvey.questions.length
			}}
		</div>
		<div class="question-title">{{ question.question }}</div>

		<div *ngIf="question.type === 'LABELED_QUESTIONS'" class="answers" [ngClass]="{
				scrollable: question.labels.length >= 2,
				'center-aligned': question.labels.length < 3
			}">
			<div *ngFor="let answer of question.labels; let answerIndex = index" class="answer"
				[ngClass]="{ isActive: isSelected(question, answerIndex) }"
				(click)="!resultsPage && answerClicked(currentQuestionIndex, question, answerIndex)">
				<div class="text-div" [itdTooltip]="answer" [showTooltip]="answer.length > truncateLength ? true : false">
					<itd-icon *ngIf="isSelected(question, answerIndex)" class="checkmark" icon="checkmark-circle" [width]="24"
						[height]="24"></itd-icon>
					<itd-icon *ngIf="!isSelected(question, answerIndex)" class="checkmark" icon="circle" [width]="24"
						[height]="24"></itd-icon>
					<p class="text">
						{{ answer }}
					</p>
				</div>
			</div>
		</div>

		<div class="slider-container" *ngIf="
				question.type === 'OPINION_SCALE' &&
				(!!getAnswer(currentQuestionIndex)?.option_answer ||
					getAnswer(currentQuestionIndex)?.option_answer === 0)
			">
			<mat-slider discrete [displayWith]="displayThumbValue" min="0" max="10" step="1"
				id="{{ 'myslider' + question.id }}" #ngSlider>
				<input matSliderThumb
					(input)="onInputChange({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value}, question, currentQuestionIndex)"
					#ngSliderThumb="matSliderThumb" value="{{ getAnswer(currentQuestionIndex)?.option_answer }}" />
			</mat-slider>
		</div>

		<div class="text-input-container" *ngIf="
				question.type === 'TEXT_QUESTIONS' &&
				(!!getAnswer(currentQuestionIndex)?.option_answer ||
					getAnswer(currentQuestionIndex)?.option_answer === '')
			">
			<textarea (input)="typed($event, question, currentQuestionIndex)" name="Fika" id="{{ currentQuestionIndex }}"
				rows="5" [(ngModel)]="getAnswer(currentQuestionIndex).option_answer"></textarea>
		</div>

		<div class="text-input-container" *ngIf="question.allowComment && !resultsPage">
			<textarea [placeholder]="'CONTENT.SURVEY_FORM.ADDITIONAL_COMMENTS' | translate"
				(input)="commentAdded($event, question, currentQuestionIndex)" [disabled]="resultsPage"
				name="{{ 'comment' + currentQuestionIndex }}" id="{{ 'comment' + currentQuestionIndex }}" rows="1"
				[(ngModel)]="getAnswer(currentQuestionIndex).comment"></textarea>
		</div>
	</div>
</div>