import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { map, mergeMap, tap } from 'rxjs/operators';
import {
	IProfileAlertConfiguration,
	IUpdateProfileDTO,
} from 'src/app/core/profile/profile.interface';
import { SetLanguage } from '../layout/layout.actions';
import { IUserRole } from '../users/users.interface';
import { SetLogin } from './../auth/auth.action';
import {
	ActivateProfile,
	ChangeProfilePassword,
	GenerateProfileOpenApiToken,
	GetProfile,
	SetProfileAvatar,
	UpdateProfile,
	UpdateProfileAlertConfiguration,
} from './profile.actions';
import { IProfile } from './profile.interface';
import { ProfileService } from './profile.service';

export interface ProfileStateModel extends IProfile {}

@State<ProfileStateModel>({
	name: 'profile',
	defaults: null,
})
@Injectable()
export class ProfileState {
	constructor(private profileService: ProfileService, private store: Store) {}

	@Selector()
	public static getState(state: ProfileStateModel) {
		return state;
	}

	@Selector()
	public static profile(state: ProfileStateModel): IProfile {
		return state;
	}

	@Selector()
	public static alertConfiguration(state: ProfileStateModel): IProfileAlertConfiguration {
		return state.alert_configuration;
	}

	@Selector()
	public static role(state: ProfileStateModel): IUserRole {
		return state.user_role;
	}

	@Selector()
	public static isGlobalAdmin(state: ProfileStateModel): boolean {
		return state.user_role === 'super_admin' || state.user_role === 'org_admin';
	}

	@Selector()
	public static departmentId(state: ProfileStateModel): IProfile['department_id'] {
		return state.department_id;
	}

	@Selector()
	public static language(state: ProfileStateModel): string {
		return state.lang;
	}

	@Action(GetProfile)
	public get({ patchState, dispatch }: StateContext<ProfileStateModel>) {
		return this.profileService.get().pipe(
			tap(profile => {
				patchState(profile);

				const { lang } = profile;

				if (lang) {
					dispatch(new SetLanguage({ language: lang }));
				}
			})
		);
	}

	// @Action(DeleteProfile)
	// public delete({ dispatch, getState }: StateContext<ProfileStateModel>) {
	// 	const { user_id } = getState();

	// 	return this.profileService.delete(user_id).pipe(
	// 		tap(async () => {
	// 			this.notification.add({
	// 				text: 'BACKEND_MESSAGES.USER_DELETE_SUCCESS',
	// 			});

	// 			dispatch(new Logout({ navigate: true }));
	// 		})
	// 	);
	// }

	@Action(UpdateProfile)
	public update(
		{ patchState, getState }: StateContext<ProfileStateModel>,
		{ payload }: UpdateProfile
	) {
		const oldProfile = getState();
		const user_id = oldProfile.user_id;

		const profile: IUpdateProfileDTO = {
			...payload.profile,
		};

		return this.profileService.update(user_id, profile).pipe(
			tap(profile => {
				patchState(profile);

				return this.store.dispatch(new SetLanguage({ language: profile.lang }));
			})
		);
	}

	@Action(UpdateProfileAlertConfiguration)
	public updateAlertConfiguration(
		{ patchState, getState }: StateContext<ProfileStateModel>,
		payload: { payload: IProfileAlertConfiguration }
	) {
		const oldProfile = getState();
		const user_id = oldProfile.user_id;

		const profile: IUpdateProfileDTO = {
			alert_configuration: payload.payload,
		};

		return this.profileService.update(user_id, profile).pipe(
			tap(profile => {
				patchState(profile);
			})
		);
	}

	@Action(SetProfileAvatar)
	public setAvatar({ patchState }: StateContext<ProfileStateModel>, { payload }: SetProfileAvatar) {
		const { avatar } = payload;

		return this.profileService.setAvatar(avatar).pipe(
			map(profile => {
				return patchState(profile);
			})
		);
	}

	@Action(ChangeProfilePassword)
	public profileChangePassword(
		{}: StateContext<ProfileStateModel>,
		{ payload }: ChangeProfilePassword
	) {
		return this.profileService.changePassword(payload);
	}

	@Action(GenerateProfileOpenApiToken)
	public generateOpenApiToken({ patchState, getState }: StateContext<ProfileStateModel>) {
		const { user_id } = getState();

		return this.profileService.generateApiToken(user_id).pipe(
			map(profile => {
				patchState(profile);
			})
		);
	}

	@Action(ActivateProfile)
	public activate({ dispatch }: StateContext<ProfileStateModel>, { payload }: ActivateProfile) {
		return this.profileService.activate(payload).pipe(
			mergeMap(result => {
				const { token } = result;

				return dispatch(new SetLogin({ token }));
			})
		);
	}
}
