import { LowerCasePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { of } from 'rxjs';
import { taskIcons } from 'src/app/core/constans';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { CreateTask } from 'src/app/core/task/task.action';
import { IUser } from 'src/app/core/users/users.interface';
import { AbstractFormComponent } from 'src/app/utility-modules/itd-form/components/abstract-form/abstract-form.component';
import { InputUserComponent } from 'src/app/utility-modules/itd-form/components/input-user/input-user.component';
import { NotificationService } from 'src/app/utility-modules/notification/services/notification.service';
import { GridItemComponent } from '../../../utility-modules/grid/components/grid-item/grid-item.component';
import { GridComponent } from '../../../utility-modules/grid/components/grid/grid.component';
import { IconComponent } from '../../../utility-modules/icon/components/icon/icon.component';
import { ButtonComponent } from '../../../utility-modules/itd-form/components/button/button.component';
import { FormErrorComponent } from '../../../utility-modules/itd-form/components/form-error/form-error.component';
import { InputColorPickerComponent } from '../../../utility-modules/itd-form/components/input-color-picker/input-color-picker.component';
import { InputDateComponent } from '../../../utility-modules/itd-form/components/input-date/input-date.component';
import { InputComponent } from '../../../utility-modules/itd-form/components/input/input.component';
import { ModalComponent } from '../../../utility-modules/modals/components/modal/modal.component';
import { TooltipDirective } from '../../directives/tooltip.directive';

type FormValue = {
	title: string;
	description: string;
	icon: string;
	color?: string;
	days_prior?: number;
	reminders?: number[];
	reminder_0?: number;
	reminder_1?: number;
	reminder_2?: number;
	reminder_3?: number;
	reminder_4?: number;
	reminder_5?: number;
	reminder_6?: number;
	reminder_7?: number;
	due_date?: string;
	default_assignee_id: string;
	template_id: string;
	createdAt: string;
	updatedAt: string;
};

@Component({
	selector: 'itd-create-task-modal',
	templateUrl: './create-task-modal.component.html',
	styleUrls: ['./create-task-modal.component.scss'],
	standalone: true,
	imports: [
		ReactiveFormsModule,
		ModalComponent,
		GridComponent,
		GridItemComponent,
		InputComponent,
		InputColorPickerComponent,
		NgFor,
		NgClass,
		IconComponent,
		NgIf,
		TooltipDirective,
		InputDateComponent,
		InputUserComponent,
		FormErrorComponent,
		ButtonComponent,
		TranslateModule,
		LowerCasePipe,
	],
})
export class CreateTaskModalComponent extends AbstractFormComponent<FormValue> implements OnInit {
	public now: moment.Moment = moment();
	public color: string;
	public form: FormGroup;
	public availableUserRoles: object[];
	public icons = taskIcons.map(icon => icon.name);
	public selectedIcon: string;
	public startDate: string;

	@Input() public task_template_id?: string;
	@Input() public user?: IUser;
	@Input() public assignmentActive: boolean = false;

	@Output() public error = new EventEmitter<HttpErrorResponse>();
	@Output() public success = new EventEmitter<void>();

	constructor(
		public store: Store,
		public validationMessages: ValidationMessagesService,
		public elementRef: ElementRef,
		protected cd: ChangeDetectorRef,
		private activeModal: NgbActiveModal,
		private notification: NotificationService
	) {
		super(cd);
	}

	async ngOnInit(): Promise<void> {
		this.color = 'black';
		this.form = new FormGroup({
			title: new FormControl(null, [Validators.required]),
			description: new FormControl(null),
			icon: new FormControl(null),
			color: new FormControl(this.color),
			days_prior: new FormControl(null),
			reminders: new FormArray([]),
			reminder_0: new FormControl(null),
			reminder_1: new FormControl(null),
			reminder_2: new FormControl(null),
			reminder_3: new FormControl(null),
			reminder_4: new FormControl(null),
			reminder_5: new FormControl(null),
			reminder_6: new FormControl(null),
			reminder_7: new FormControl(null),
			due_date: new FormControl(null),
			default_assignee_id: new FormControl(null),
			template_id: new FormControl(this.task_template_id),
			createdAt: new FormControl(null),
			updatedAt: new FormControl(null),
		});

		// set default icon
		if (!this.formValue.icon && this.icons.length > 0) {
			this.onSelectIcon(this.icons[0]);
		}

		if (this.assignmentActive) {
			this.setValidators();
		}
	}

	public onSubmitAction() {
		// "serializing" the reminder data to match the DTO
		this.formValue.reminders = [];
		for (let i = 0; i < 8; i++) {
			this.formValue.reminders.push(this.formValue[`reminder_${i}`]);
		}

		const newData = {
			...this.formValue,
			reminders: this.formValue.reminders
				.filter(reminder => reminder !== null) // remove null values
				.map(reminder => ({ days_prior: parseInt(reminder.toString()) })), // convert to ITaskReminder
		};

		if (this.formValue.icon) {
			this.form.patchValue({ createdAt: new Date(), updatedAt: new Date(), color: this.color });
			if (this.assignmentActive) {
				this.activeModal.close(this.formValue);
				return of();
			} else {
				return this.store.dispatch(new CreateTask({ data: newData }));
			}
		} else {
			this.notification.add({
				text: 'TASKS.NO_ICON',
				type: 'error',
			});
		}
	}

	public onSubmitSuccess() {
		this.activeModal.close();
	}

	public onSelectIcon(icon: string) {
		this.selectedIcon = icon;
		this.form.patchValue({ icon });
	}

	public changeStartDate(date: any) {
		if (!date) {
			return;
		}
		this.formValue.due_date = date.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
	}

	public setValidators(): void {
		this.form.get('default_assignee_id').setValidators([Validators.required]);
	}

	// add new reminder to the form
	public addReminder() {
		(<FormArray>this.form.get('reminders')).push(new FormControl(null));
	}

	// remove reminder from the form
	public removeReminder(index: number) {
		for (let i = index; i < 8; i++) {
			this.form.patchValue({
				[`reminder_${i}`]: this.formValue[`reminder_${i + 1}`],
			});
		}
		(<FormArray>this.form.get('reminders')).removeAt(this.formValue.reminders.length - 1);
	}
}
