import {
	Component,
	Directive,
	ViewContainerRef,
	ElementRef,
	OnDestroy,
	Input,
	Output,
	EventEmitter,
} from '@angular/core';
import { NgStyle } from '@angular/common';

@Directive({
    selector: '[itdCellOutlet]',
    standalone: true
})
export class CellOutletDirective implements OnDestroy {
	public static latestCellOutlet: CellOutletDirective | null = null;

	// tslint:disable-next-line: no-any
	constructor(public viewContainer: ViewContainerRef, public elementRef: ElementRef) {
		CellOutletDirective.latestCellOutlet = this;
	}

	public ngOnDestroy(): void {
		if (CellOutletDirective.latestCellOutlet === this) {
			CellOutletDirective.latestCellOutlet = null;
		}
	}
}

@Directive({
    selector: '[itdRowBeforeOutlet]',
    standalone: true
})
export class RowBeforeOutletDirective implements OnDestroy {
	public static latestCellOutlet: RowBeforeOutletDirective | null = null;

	// tslint:disable-next-line: no-any
	constructor(public viewContainer: ViewContainerRef, public elementRef: ElementRef) {
		RowBeforeOutletDirective.latestCellOutlet = this;
	}

	public ngOnDestroy(): void {
		if (RowBeforeOutletDirective.latestCellOutlet === this) {
			RowBeforeOutletDirective.latestCellOutlet = null;
		}
	}
}

@Directive({
    selector: '[itdRowAfterOutlet]',
    standalone: true
})
export class RowAfterOutletDirective implements OnDestroy {
	public static latestCellOutlet: RowAfterOutletDirective | null = null;

	// tslint:disable-next-line: no-any
	constructor(public viewContainer: ViewContainerRef, public elementRef: ElementRef) {
		RowAfterOutletDirective.latestCellOutlet = this;
	}

	public ngOnDestroy(): void {
		if (RowAfterOutletDirective.latestCellOutlet === this) {
			RowAfterOutletDirective.latestCellOutlet = null;
		}
	}
}

@Component({
    selector: 'itd-table-row',
    template: `
		<div class="table__row-before">
			<ng-container itdRowBeforeOutlet></ng-container>
		</div>
		<div
			class="table__row is-{{ align }} is-{{ rowStyle }}"
			[ngStyle]="{
				'min-height.px': height,
				background: background,
				cursor: clickable ? 'pointer' : 'auto'
			}"
			(click)="rowClicked()"
		>
			<ng-container itdCellOutlet></ng-container>
		</div>
		<div class="table__row-after">
			<ng-container itdRowAfterOutlet></ng-container>
		</div>
	`,
    host: {
        class: '',
    },
    standalone: true,
    imports: [
        RowBeforeOutletDirective,
        NgStyle,
        CellOutletDirective,
        RowAfterOutletDirective,
    ],
})
export class TableRowComponent {
	@Input() public height: number = 70;
	@Input() public background: string = '#fff';
	@Input() public rowStyle: 'default' | 'inline' = 'default';
	@Input() public align: 'flex-start' | 'flex-end' | 'center' = 'center';
	@Input() public clickable: boolean = false;
	@Output() public rowClick = new EventEmitter();

	constructor() {}

	public rowClicked() {
		this.rowClick.emit(null);
	}
}
