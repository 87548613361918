<div class="header header-results">
	<itd-button-back (click)="goBackClick()"></itd-button-back>
	<h1 class="title" *ngIf="title">
		<span class="grey">{{ 'RESULTS_FOR' | translate }}</span>
		{{ this.title }}
	</h1>
</div>
<div class="header" *ngIf="hasHeader">
	<div class="header__wrapper">
		<div class="header__column header__left" *ngIf="headerLeft">
			<ng-container *ngTemplateOutlet="headerLeft"></ng-container>
		</div>

		<div class="header__column header__right" *ngIf="headerRight">
			<ng-container *ngTemplateOutlet="headerRight"></ng-container>
		</div>
	</div>
</div>
<div class="main" *ngIf="main">
	<ng-container *ngTemplateOutlet="main"></ng-container>
</div>
