import dayjs from 'dayjs';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
	SetAdminAnalyticsDepartmentId,
	SetAdminAnalyticsFrom,
	SetAdminAnalyticsTo,
} from './admin-analytics.action';

// const now = dayjs();
// const TWELVE_MONTHS_AGO = now.subtract(12, 'months');

export interface IAdminAnalyticsStateModel {
	from: dayjs.Dayjs;
	to: dayjs.Dayjs;
	department_id: string;
}

@State<IAdminAnalyticsStateModel>({
	name: 'adminAnalytics',
	defaults: {
		from: null,
		to: null,
		department_id: null,
	},
})
@Injectable()
export class AdminAnalyticsState {
	@Selector()
	public static getState(state: IAdminAnalyticsStateModel) {
		return state;
	}

	@Selector()
	public static from(state: IAdminAnalyticsStateModel) {
		return state.from;
	}

	@Action(SetAdminAnalyticsFrom)
	public setFrom(ctx: StateContext<IAdminAnalyticsStateModel>, { payload }: SetAdminAnalyticsFrom) {
		const { date } = payload;

		ctx.patchState({
			from: date,
		});
	}

	@Selector()
	public static to(state: IAdminAnalyticsStateModel) {
		return state.to;
	}

	@Action(SetAdminAnalyticsTo)
	public setTo(ctx: StateContext<IAdminAnalyticsStateModel>, { payload }: SetAdminAnalyticsTo) {
		const { date } = payload;

		ctx.patchState({
			to: date,
		});
	}

	@Selector()
	public static departmentId(state: IAdminAnalyticsStateModel) {
		return state.department_id;
	}

	@Action(SetAdminAnalyticsDepartmentId)
	public setDepartmentId(
		ctx: StateContext<IAdminAnalyticsStateModel>,
		{ payload }: SetAdminAnalyticsDepartmentId
	) {
		const { department_id } = payload;

		ctx.patchState({
			department_id,
		});
	}
}
