import { Component, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { LoadOneAchievement } from 'src/app/core/achievements/achievements.action';
import { IAchievement } from 'src/app/core/achievements/achievements.interface';
import { Observable } from 'rxjs';
import { AchievementsState } from 'src/app/core/achievements/achievements.state';
import { AsyncPipe } from '@angular/common';
import { AdminContentAchievementPreviewComponent } from '../../../modules/admin/modules/admin-content/components/admin-content-achievement-preview/admin-content-achievement-preview.component';

@Component({
    selector: 'itd-achievement-preview-modal',
    templateUrl: './achievement-preview-modal.component.html',
    styleUrls: ['./achievement-preview-modal.component.scss'],
    standalone: true,
    imports: [AdminContentAchievementPreviewComponent, AsyncPipe],
})
export class AchievementPreviewModalComponent implements OnInit {
	@Input() achievementId;
	@Select(AchievementsState.currentAchievement) public achievement$: Observable<IAchievement>;

	constructor(private store: Store) {}

	ngOnInit() {
		this.store.dispatch(new LoadOneAchievement({ id: this.achievementId }));
	}
}
